export const getSafePropertyName = (name: string) =>
  Array.from(name.match(/([\w])/g) || [])
    .join("")
    .replace(/^\w/, (char) => char.toLowerCase());

export const setTimeExtremum = (date: Date, isMax = true) => {
  const params = [date.getDate()];

  if (isMax) {
    params.push(23, 59, 59, 999);
  } else {
    params.push(0);
  }

  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), ...params));
};

export function omitProps<T extends object>(
  object: T,
  excludeProps: (keyof T)[] = []
) {
  const newObject = {};

  Object.keys(object).forEach((key: string) => {
    if (!excludeProps.some((prop) => prop === key)) {
      newObject[key] = object[key];
    }
  });

  return newObject;
}
