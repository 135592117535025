import React from "react";

import "./InfoSection.scss";

interface IInfoSection {
  isWarning?: boolean;
  children: React.ReactNode;
}

export const InfoSection: React.FC<IInfoSection> = ({
  children,
  isWarning = false,
}) => (
  <div className={`c-info-section ${isWarning ? "warning" : ""}`}>
    {children}
  </div>
);
