import React from "react";
import {
  IPopupProps,
  IAlertMessageProps,
  Popup,
  AlertMessage,
} from "component-library";

import {
  IGlobalContextProps,
  GlobalContextEventTypes,
  withGlobalContext,
} from "../../../contexts/GlobalContext";

import GlobalAlertMessage from "./GlobalAlertMessage";

export interface IGlobalAlertProps extends IGlobalContextProps {}

interface IGlobalAlertState {
  popupProps: IPopupProps | null;
  alertMessage: IAlertMessageProps | null;
  innerComponent?: React.ReactNode;
}

export class GlobalAlert extends React.Component<
  IGlobalAlertProps,
  IGlobalAlertState
> {
  constructor(props: IGlobalAlertProps) {
    super(props);

    this.state = {
      popupProps: null,
      alertMessage: null,
      innerComponent: null,
    };

    this.initListener();
  }

  public render() {
    let view: JSX.Element | null = null;

    const { popupProps, alertMessage } = this.state;

    if (popupProps && alertMessage) {
      view = (
        <Popup {...popupProps}>
          <AlertMessage {...alertMessage}>
            {this.state.innerComponent}
          </AlertMessage>
        </Popup>
      );
    }

    return view;
  }

  private initListener() {
    const { globalContext } = this.props;

    globalContext.addListener(
      GlobalContextEventTypes.notifyingGlobalAlert,
      (data?: GlobalAlertMessage) => {
        if (data) {
          data.alertMessageProps.buttons.push({
            ...data.closeButton,
            click: this.createCloseAlertFunction(data.closeButton.click),
          });
          this.setState({
            popupProps: {
              ...data.popupProps,
              // @ts-ignore
              close: this.createCloseAlertFunction(data.popupProps.close),
            },
            alertMessage: data.alertMessageProps,
            innerComponent: data.children,
          });
        } else {
          this.setState({ popupProps: null, alertMessage: null });
        }
      }
    );

    globalContext.addListener(
      GlobalContextEventTypes.closeAllGlobalAlert,
      () => {
        this.setState({ popupProps: null, alertMessage: null });
      }
    );
  }

  private createCloseAlertFunction = (close?: Function) => {
    return () => {
      close && close();
      this.setState({ popupProps: null, alertMessage: null });
    };
  };
}

export default withGlobalContext(GlobalAlert);
