import React from "react";
import cx from "classnames";
import { useField } from "formik";

import "./RadioFormik.scss";

interface IRadioFormikProps {
  name: string;
  value: any;
  disabled?: boolean;
  className?: string;
  label?: string;
  white?: boolean;
  testId?: string;
  onChange?: (value: string) => void;
}

export const RadioFormik: React.FC<IRadioFormikProps> = ({
  name,
  value,
  disabled,
  className,
  label,
  white,
  testId,
  onChange,
}) => {
  const [field] = useField(name);

  return (
    <div
      className={cx("c-radio-formik", {
        [className as string]: Boolean(className),
        "c-radio-formik__disabled": disabled,
      })}
    >
      <label className="c-radio-formik__container">
        <input
          {...field}
          onChange={(e) => {
            field.onChange(e);
            onChange && onChange(e.target.value);
          }}
          value={value}
          type="radio"
          className="c-radio-formik__input"
          checked={field.value == value}
          data-testid={testId}
        />
        <span className="c-radio-formik__content">
          <span
            className={`c-radio-formik__box ${
              white && "c-radio-formik__box--white"
            }`}
          />
          <span className="c-radio-formik__text">{label}</span>
        </span>
      </label>
    </div>
  );
};
