export class WatchedFunction {

  private func: () => any;
  private watchers: Array<() => any> = [];

  constructor(func: () => any) {
    this.func = func;
  }

  public addWatcher = (w: () => any) => {
    this.watchers.push(w);
  }

  public removeWatcher = (w: () => any) => {
    this.watchers = this.watchers.filter(i => i !== w);
  }

  public call = () => {
    this.func();
    for (const w of this.watchers) {
      w();
    }
  }
};
