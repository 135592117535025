/**
 * Validation rules to be used in forms. Add as needed.
 */

export interface IValidity {
  invalid: boolean;
  validationMessage?: string;
}

// maxLength(50, 'Too long'), minLength(10, 'Too short') etc.
export type ValidationFunction = (value: any) => IValidity;

export const required = (validationMessage: string): ValidationFunction => (value:any): IValidity => {
  return performValidation(() => value === undefined || value === null || value === '', validationMessage);
}

export const maxLength = (maxValidLength: number, validationMessage: string): ValidationFunction => (value: string): IValidity => {
  return performValidation(() => maxValidLength < value.length, validationMessage);
}

export const minLength = (minValidLength: number, validationMessage: string): ValidationFunction => (value: string): IValidity => {
  return performValidation(() => minValidLength > value.length, validationMessage);
}

export const maxValue = (maxValidValue: number, validationMessage: string): ValidationFunction => (value: number): IValidity => {
  return performValidation(() => maxValidValue < value, validationMessage);
}

export const minValue = (minValidValue: number, validationMessage: string): ValidationFunction => (value: number): IValidity => {
  return performValidation(() => minValidValue > value, validationMessage);
}

export const pattern = (validPattern: RegExp, validationMessage: string): ValidationFunction => (value: string): IValidity => {
  return performValidation(() => !validPattern.test(value), validationMessage);
}

export const requiredNonEmptyString = (validationMessage: string): ValidationFunction => (value: string): IValidity => {
  return performValidation(() => value === undefined || value === null || value.trim() === '', validationMessage);
};

/**
 * Returns validity status, can be used to create new validation functions - simply pass in a function which returns true/false, with "true" meaning "invalid", and the validation message to display
 */
export const performValidation = (validationFunction: () => boolean, validationMessage: string): IValidity => {
  const validity: IValidity = {
    invalid: validationFunction(),
  }

  if (validity.invalid) {
    validity.validationMessage = validationMessage;
  }

  return validity;
}

export const Patterns = {
  // https://emailregex.com/
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}
