// import MediaInfoLib from "mediainfo.js";
// @ts-ignore

import { IMediaInfoResult } from "../models/MediaInfoModels";

export let MEDIA_INFO_LIB: any = () => {};

interface IFileDetails {
  durationInMs: number;
  format: string;
}

export interface IMediaInfoService {
  init: () => void;
  getFileDetails: (file: File) => Promise<IFileDetails>;
}

export default class MediaInfoService implements IMediaInfoService {
  private mediaInfoLib: any;
  private file: File;

  public init = async () => {
    this.mediaInfoLib = await MEDIA_INFO_LIB({ format: "object" });
  };

  public getFileDetails = async (file: File) => {
    this.file = file;
    try {
      const {
        media: {
          track: [generalOutput],
        },
      }: IMediaInfoResult = await this.mediaInfoLib.analyzeData(
        this.getFileSize,
        this.readFileChunk
      );

      return {
        durationInMs: this.secondsToMilliseconds(generalOutput.Duration),
        format: generalOutput.Format
          ? generalOutput.Format.toLowerCase()
          : "noMediaFormat",
      };
    } catch (error) {
      return { durationInMs: NaN, format: "" };
    }
  };

  private readFileChunk = (chunkSize: number, offset: number) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        if (event.target.error) {
          reject(event.target.error);
        }
        resolve(new Uint8Array(event.target.result));
      };
      reader.readAsArrayBuffer(this.file.slice(offset, offset + chunkSize));
    });
  };

  private secondsToMilliseconds = (seconds: string) => {
    return Math.ceil(Number(seconds) * 1000);
  };

  private getFileSize = () => {
    return this.file.size;
  };
}
