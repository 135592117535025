import React from "react";
import _values from "lodash/values";
import _compact from "lodash/compact";
import { FacetType } from "shared-services";
import { dataTestIds } from "data-testids";
import {
  FiltersFieldNames,
  HeritageEthnicityAndBackground,
  IFilterFieldConfig,
  IFilterValues,
} from "../../types";
import { FilterMode } from "../../../../Molecules/FilterModeSection";
import { facetToCheckboxOption } from "../../../../Functional/converters/facetConverter";
import { mapHeritageAndEthnicityStats } from "../../../../Functional/converters/heritageAndEthnicity";
import { HeritageAndEthnicityFilterControlSection } from "../../../HeritageAndEthnicityFilterControlSection";
import { ICheckboxGroupValue } from "../../../CheckboxGroup/CheckboxGroup";
import {
  GetCheckboxFacetsProps,
  getCheckboxFacetTags,
} from "../../helpers/getCheckboxFacetTags";

const name = FiltersFieldNames.HeritageEthnicityAndBackground;

export const heritageAndEthnicityFilter: IFilterFieldConfig<IFilterValues> = {
  name,
  facetNames: [FacetType.HeritageAndEthnicity, FacetType.EthnicBackground],
  defaultValue: {
    [HeritageEthnicityAndBackground.HeritageAndEthnicityFilterMode]:
      FilterMode.Any,
    [HeritageEthnicityAndBackground.HeritageAndEthnicityInfo]: "",
    [HeritageEthnicityAndBackground.EthnicBackground]: {},
    [HeritageEthnicityAndBackground.HeritageAndEthnicity]: {},
  },
  dataTestId: dataTestIds.agent["SeekingRepresentation.Filters.genderFilter"],
  getFilterFiledProps: ({
    t,
    facets,
    stats,
    values,
    setFilterValues,
    filterDrawerValues,
    setFilterDrawerValues,
  }) => {
    const mapStatsFunction = mapHeritageAndEthnicityStats(
      stats?.heritageAndEthnicity
    );
    const heritageOptions = facets[FacetType.HeritageAndEthnicity]
      .map(facetToCheckboxOption)
      .map(mapStatsFunction);
    const backgroundOptions = facets[FacetType.EthnicBackground]
      .map(facetToCheckboxOption)
      .map(mapStatsFunction);
    const heritageEthnicityFilterDrawerValue = filterDrawerValues[name];
    const heritageEthnicityValue = values[name];

    const setFacetField = (
      field:
        | HeritageEthnicityAndBackground.HeritageAndEthnicity
        | HeritageEthnicityAndBackground.EthnicBackground,
      value: ICheckboxGroupValue
    ) => {
      setFilterDrawerValues({
        ...filterDrawerValues,
        [name]: {
          ...heritageEthnicityFilterDrawerValue,
          [field]: {
            ...heritageEthnicityFilterDrawerValue[field],
            ...value,
          },
        },
      });
    };

    const setModeOrInfo = (
      field:
        | HeritageEthnicityAndBackground.HeritageAndEthnicityFilterMode
        | HeritageEthnicityAndBackground.HeritageAndEthnicityInfo,
      value: FilterMode | string
    ) => {
      setFilterDrawerValues({
        ...filterDrawerValues,
        [name]: {
          ...heritageEthnicityFilterDrawerValue,
          [field]: value,
        },
      });
    };

    const getTagText: GetCheckboxFacetsProps["getText"] = (name, tagName) =>
      t(`common:search.filters.tagLabel.${name}`, { tagName });

    const backgroundTags = getCheckboxFacetTags({
      name,
      fieldValue: values[name].ethnicBackground,
      fieldFacets: facets[FacetType.EthnicBackground],
      getText: getTagText,
      getOnClick: (name, fieldValue, value) => () => {
        setFilterValues({
          ...values,
          [name]: {
            ...heritageEthnicityValue,
            [HeritageEthnicityAndBackground.EthnicBackground]: {
              ...fieldValue,
              [value]: false,
            },
          },
        });
      },
    });
    const heritageAndEthnicityTags = getCheckboxFacetTags({
      name,
      fieldValue: values[name].heritageAndEthnicity,
      fieldFacets: facets[FacetType.HeritageAndEthnicity],
      getText: getTagText,
      getOnClick: (name, fieldValue, value) => () => {
        setFilterValues({
          ...values,
          [name]: {
            ...heritageEthnicityValue,
            [HeritageEthnicityAndBackground.HeritageAndEthnicity]: {
              ...fieldValue,
              [value]: false,
            },
          },
        });
      },
    });

    const heritageAndEthnicityInfo =
      heritageEthnicityValue[
        HeritageEthnicityAndBackground.HeritageAndEthnicityInfo
      ];
    const heritageAndEthnicityInfoTag = heritageAndEthnicityInfo && {
      id: `${name}-info`,
      text: t(`common:search.filters.tagLabel.${name}`, {
        tagName: heritageAndEthnicityInfo,
      }),
      onClick: () => {
        setFilterValues({
          ...values,
          [name]: {
            ...heritageEthnicityValue,
            [HeritageEthnicityAndBackground.HeritageAndEthnicityInfo]: "",
          },
        });
      },
    };

    const isHnESelected = [
      ..._values({
        ...heritageEthnicityValue[
          HeritageEthnicityAndBackground.EthnicBackground
        ],
        ...heritageEthnicityValue[
          HeritageEthnicityAndBackground.HeritageAndEthnicity
        ],
      }),
      heritageAndEthnicityInfo,
    ].some(Boolean);

    return {
      tags: _compact([
        ...backgroundTags,
        ...heritageAndEthnicityTags,
        heritageAndEthnicityInfoTag,
      ]),
      filterDrawerConfig: {
        id: name,
        title: t("common:heritage.ethnicity"),
        selected: isHnESelected,
        renderFilterContent: () => (
          <HeritageAndEthnicityFilterControlSection
            ethnicBackgroundConfig={{
              name: HeritageEthnicityAndBackground.EthnicBackground,
              options: backgroundOptions,
            }}
            heritageAndEthnicityConfig={{
              name: HeritageEthnicityAndBackground.HeritageAndEthnicity,
              options: heritageOptions,
            }}
            ethnicBackgroundValue={
              heritageEthnicityFilterDrawerValue.ethnicBackground
            }
            heritageAndEthnicityValues={
              heritageEthnicityFilterDrawerValue.heritageAndEthnicity
            }
            filterModeDefaultValue={
              heritageEthnicityFilterDrawerValue.heritageAndEthnicityFilterMode
            }
            handleEthnicBackground={(value) =>
              setFacetField(
                HeritageEthnicityAndBackground.EthnicBackground,
                value
              )
            }
            handleHeritageAndEthnicity={(value) =>
              setFacetField(
                HeritageEthnicityAndBackground.HeritageAndEthnicity,
                value
              )
            }
            handleFilterMode={(value) =>
              setModeOrInfo(
                HeritageEthnicityAndBackground.HeritageAndEthnicityFilterMode,
                value
              )
            }
            heritageAndEthnicityInfo={
              heritageEthnicityFilterDrawerValue.heritageAndEthnicityInfo ?? ""
            }
            handleHeritageAndEthnicityInfo={(value) => {
              setModeOrInfo(
                HeritageEthnicityAndBackground.HeritageAndEthnicityInfo,
                value
              );
            }}
          />
        ),
      },
    };
  },
};
