import React from "react";

import {
  ICapacityBarProps,
  ICapacityBarContent,
  CapacityBar,
} from "mediaModules/media/components/CapacityBar";
import { MediaStorageSpace } from "mediaModules/media/models";

import "./MediaStorageContainer.scss";

export interface IMediaStorageContainerProps {
  storageSpace: MediaStorageSpace;
  storedCapacityBarContent: ICapacityBarContent;
  displayedCapacityBarContent: ICapacityBarContent;
  isLoading?: boolean;
}

export const MediaStorageContainer: React.FC<IMediaStorageContainerProps> = ({
  storageSpace: {
    allowedStorageSpace: { maxAllowedStoreInSec, maxAllowedDisplayInSec },
    usedStorageSpace: { usedStoreValueInSec, usedDisplayValueInSec },
  },
  storedCapacityBarContent,
  displayedCapacityBarContent,
  isLoading = false,
}) => {
  const capacityBars: ICapacityBarProps[] = [
    {
      icon: "icon-folder",
      usedValue: usedStoreValueInSec,
      entireValue: maxAllowedStoreInSec,
      content: storedCapacityBarContent,
      isLoading,
    },
    {
      icon: "icon-profile",
      usedValue: usedDisplayValueInSec,
      entireValue: maxAllowedDisplayInSec,
      content: displayedCapacityBarContent,
      isLoading,
    },
  ];

  return (
    <div className="g-col-md-12 g-col-lg-12 capacity-bar-container">
      {capacityBars.map((bar, index) => (
        <CapacityBar
          key={`${bar.icon}${index}`}
          icon={bar.icon}
          entireValue={bar.entireValue}
          usedValue={bar.usedValue}
          content={bar.content}
          isLoading={bar.isLoading}
        />
      ))}
    </div>
  );
};
