import React from "react";

export const MainPage = React.lazy(() =>
  import("pages/MainPage" /* webpackChunkName: 'page-main' */).then(
    (module) => ({ default: module.MainPage })
  )
);
export const EditCreditPage = React.lazy(() =>
  import(
    "pages/EditCreditPage" /* webpackChunkName: 'page-edit-credit' */
  ).then((module) => ({
    default: module.EditCreditPage,
  }))
);
export const BasicInfoPage = React.lazy(() =>
  import("pages/BasicInfoPage" /* webpackChunkName: 'page-basic-info' */).then(
    (module) => ({
      default: module.BasicInfoPage,
    })
  )
);
export const CreditsPage = React.lazy(() =>
  import("pages/CreditsPage" /* webpackChunkName: 'page-credits' */).then(
    (module) => ({ default: module.CreditsPage })
  )
);
export const TrainingPage = React.lazy(() =>
  import("pages/TrainingPage" /* webpackChunkName: 'page-training' */).then(
    (module) => ({
      default: module.TrainingPage,
    })
  )
);
export const AboutMePage = React.lazy(() =>
  import("pages/AboutMePage" /* webpackChunkName: 'page-aboutme' */).then(
    (module) => ({ default: module.AboutMePage })
  )
);
export const SkillsPage = React.lazy(() =>
  import("pages/SkillsPage" /* webpackChunkName: 'page-skills' */).then(
    (module) => ({ default: module.SkillsPage })
  )
);
export const AgenciesPage = React.lazy(() =>
  import("pages/AgenciesPage" /* webpackChunkName: 'page-agencies' */).then(
    (module) => ({
      default: module.AgenciesPage,
    })
  )
);
export const AddAgencyPage = React.lazy(() =>
  import("pages/AddAgencyPage" /* webpackChunkName: 'page-add-agency' */).then(
    (module) => ({
      default: module.AddAgencyPage,
    })
  )
);
export const AddNewAgencyPage = React.lazy(() =>
  import(
    "pages/AddNewAgencyPage" /* webpackChunkName: 'page-add-new-agency' */
  ).then((module) => ({
    default: module.AddNewAgencyPage,
  }))
);
export const AgenciesHelpPage = React.lazy(() =>
  import(
    "pages/AgenciesHelpPage" /* webpackChunkName: 'page-agencies-help' */
  ).then((module) => ({
    default: module.AgenciesHelpPage,
  }))
);
export const SocialMediaPage = React.lazy(() =>
  import(
    "pages/SocialMediaPage" /* webpackChunkName: 'page-social-media' */
  ).then((module) => ({
    default: module.SocialMediaPage,
  }))
);
export const PageNotFound = React.lazy(() =>
  import("pages/PageNotFound" /* webpackChunkName: 'page-not-found' */).then(
    (module) => ({
      default: module.PageNotFound,
    })
  )
);
export const GalleryPage = React.lazy(() =>
  import("pages/GalleryPage" /* webpackChunkName: 'page-gallery' */).then(
    (module) => ({ default: module.GalleryPage })
  )
);
export const LinkToProfilePage = React.lazy(() =>
  import(
    "pages/LinkToProfilePage" /* webpackChunkName: 'page-link-to-profile' */
  ).then((module) => ({
    default: module.LinkToProfilePage,
  }))
);
export const ProfileNotFoundPage = React.lazy(() =>
  import(
    "pages/ProfileNotFoundPage" /* webpackChunkName: 'page-profile-not-found' */
  ).then((module) => ({
    default: module.ProfileNotFoundPage,
  }))
);
export const AudioPage = React.lazy(() =>
  import("mediaModules/media/" /* webpackChunkName: 'titanium-media' */).then(
    (module) => ({ default: module.AudioPage })
  )
);
export const VideoPage = React.lazy(() =>
  import("mediaModules/media/" /* webpackChunkName: 'titanium-media' */).then(
    (module) => ({ default: module.VideoPage })
  )
);
export const EditAudioPage = React.lazy(() =>
  import("mediaModules/media/" /* webpackChunkName: 'titanium-media' */).then(
    (module) => ({ default: module.EditAudioPage })
  )
);
export const EditVideoPage = React.lazy(() =>
  import("mediaModules/media/" /* webpackChunkName: 'titanium-media' */).then(
    (module) => ({ default: module.EditVideoPage })
  )
);
