import React from "react";
import { IGlobalContextProps } from "shared/contexts/GlobalContext";
import { FullScreenSpinner } from "shared/modules/Common/FullScreenSpinner";
import { GlobalEventTypes } from "contexts/GlobalContext";

interface IWithGlobalLoader {
  showLoader: boolean;
}

export default function withGlobalLoader<P extends IGlobalContextProps>(
  WrappedComponent: React.ComponentClass<P> | React.FC<P>
): typeof React.Component {
  return class extends React.Component<P, IWithGlobalLoader> {
    public readonly state: Readonly<IWithGlobalLoader> = { showLoader: true };

    constructor(props: P) {
      super(props);
      this.initGlobalLoader();
    }

    public render() {
      const { showLoader } = this.state;
      return (
        <React.Fragment>
          <WrappedComponent {...this.props} />
          {showLoader && <FullScreenSpinner />}
        </React.Fragment>
      );
    }

    private initGlobalLoader() {
      this.props.globalContext.addListener(
        GlobalEventTypes.makeVisibleGlobalSpinner,
        (isVisible = false) => {
          this.setState({ showLoader: isVisible });
        }
      );
    }
  };
}
