import React from "react";
import { FlowRouteContext, FlowContext } from "./FlowContext";

export default class FlowContextProvider extends React.Component<any, any> {
  private flowContext: FlowContext = new FlowContext("", null);

  public render() {
    return (
      <FlowRouteContext.Provider value={this.flowContext}>
        {this.props.children}
      </FlowRouteContext.Provider>
    );
  }
}
