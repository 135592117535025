import React, { FC } from "react";
import { useField } from "formik";
import { useFormikScrollToError } from "shared-hooks";
import RichTextArea, {
  IRichTextAreaProps,
} from "../../../Molecules/RichTextArea/RichTextArea";
import { ERROR, InputWrapper } from "../../InputsCommon/InputWrapper";
import "./styles.scss";

interface Props
  extends Pick<IRichTextAreaProps, "label" | "maxLength" | "annex"> {
  name: string;
}

const RichTextAreaFormik: FC<Props> = ({ name, label, maxLength, annex }) => {
  const [field, meta] = useField(name);
  const { fieldRef, containerElementRef } = useFormikScrollToError<
    any,
    HTMLDivElement
  >(name);

  const invalid = meta.touched && meta.error ? ERROR : undefined;

  return (
    <div ref={containerElementRef}>
      <InputWrapper invalid={invalid} validationMesssage={meta.error}>
        <RichTextArea
          label={label}
          formikValue={field.value}
          formikOnChange={field.onChange(field.name)}
          maxLength={maxLength}
          annex={annex}
          textAreaRef={fieldRef}
        />
      </InputWrapper>
    </div>
  );
};

export default RichTextAreaFormik;
