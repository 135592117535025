export function parseQuery(query: string) {
  const queryObject: { [key: string]: string } = {};

  if (query.length > 1) {
    const pairs = query.substring(1).split('&');
    pairs.forEach(pair => {
      const keyValues = pair.split('=');
      const key = keyValues.shift();
      const value = keyValues.join('=');
      if (key) {
        queryObject[key] = decodeURIComponent(value);
      }
    });
  }

  return queryObject;
}

export function parseToUrlQuery(object: any) {
  return '?' + Object.keys(object)
    .map(key => {
      let urlPart = '';
      if (object[key] != null && object[key] !== '') {
        if (Array.isArray(object[key])) {
          urlPart = parseArrayToUrlQueryPart(key, object[key]);
        } else {
          urlPart = `${key}=${encodeURIComponent(object[key])}`;
        }
      }
      return urlPart;
    })
    .filter(urlPart => !!urlPart)
    .join('&');
}

function parseArrayToUrlQueryPart(key: any, array: any[]) {
  return array
    .map(element => element != null && element !== '' ? `${key}=${encodeURIComponent(element)}` : '')
    .filter(urlPart => !!urlPart)
    .join('&');
}
