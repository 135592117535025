export const contactPerformerModal = {
  formHeader: "Contact:",
  subject: "A Spotlight casting director has sent you a direct message",
  additionalText:
    "You can use this form to contact performers directly. Your name and email will be included in the message and you will also get a copy of the email you send.",
  consentTextLabel:
    "I confirm that I consent to the performer receiving my email address.",
  success: "Your email has been sent, and you will receive a copy",
  failed: "Request error",
  emailValidation:
    "Please add a valid email address in here so the performer can reply directly to you",
  contactButton: "Contact Performer",
};
