import React from "react";
import { NotificationToast } from "component-library";

import {
  ControlsContainer,
  DirectionType,
} from "mediaModules/media/components/ControlsContainer";
import { Showreels } from "../Showreels";
import {
  MediaAsset,
  MediaAssetType,
  MediaStorageSpace,
  TranscodeStatus,
} from "mediaModules/media/models";
import { IEditVideoListFormContent } from "./EditVideoListFormContent";
import { Logger } from "common";
import { MediaStorageContainer } from "mediaModules/media/components/MediaStorageContainer";
import { GAEvents } from "mediaModules/media/components/GAEvents/GAEvents";

import "./EditVideoListForm.scss";

export interface IEditVideoListFormProps {
  content: IEditVideoListFormContent;
  showreelList: MediaAsset[];
  editShowreel: (showreel?: MediaAsset) => void;
  playbackVideo: (showreel: MediaAsset) => void;
  hideShowreels: (showreels?: MediaAsset[]) => Promise<void>;
  deleteShowreels: (showreels: MediaAsset[]) => Promise<void>;
  changeMediaAssetsOrder: (
    id: string,
    oldSortOrder: number,
    newSortOrder: number
  ) => Promise<void>;
  addNewShowreel: () => void;
  downloadShowreel: (showreel?: MediaAsset) => void;
  isPatching: boolean;
  storageSpace: MediaStorageSpace;
  isMediaMetadataLoading: boolean;
  areMediaObjectsLoading: boolean;
}

export interface IEditVideoListFormState {
  showSuccessfulNotification: boolean;
  selectedShowreels: MediaAsset[];
}

export class EditVideoListForm extends React.Component<
  IEditVideoListFormProps,
  IEditVideoListFormState
> {
  public readonly state: Readonly<IEditVideoListFormState> = {
    showSuccessfulNotification: false,
    selectedShowreels: [],
  };

  public componentDidUpdate(prevProps: IEditVideoListFormProps) {
    if (this.props.showreelList !== prevProps.showreelList) {
      this.updateSelectedShowreels(this.state.selectedShowreels);
    }
  }

  public render() {
    const {
      content,
      playbackVideo,
      showreelList,
      addNewShowreel,
      editShowreel,
      isPatching,
      storageSpace,
      isMediaMetadataLoading,
      areMediaObjectsLoading,
    } = this.props;
    const { showSuccessfulNotification, selectedShowreels } = this.state;

    return (
      <React.Fragment>
        <div className="c-edit-video-list-form">
          <div className="g-inner-grid-12">
            <MediaStorageContainer
              storageSpace={storageSpace}
              storedCapacityBarContent={content.capacityStore}
              displayedCapacityBarContent={content.capacityDisplay}
              isLoading={areMediaObjectsLoading}
            />
          </div>

          <Showreels
            showreelsList={showreelList}
            setSelectedShowreels={this.setSelectedShowreels}
            content={content.showreels}
            playbackVideo={playbackVideo}
            addNewShowreel={addNewShowreel}
            selectedShowreels={selectedShowreels}
            editShowreel={editShowreel}
            isMediaMetadataLoading={isMediaMetadataLoading}
            areMediaObjectsLoading={areMediaObjectsLoading}
            sortCompleted={this.changeMediaAssetsOrder}
            disableReordering={isPatching}
          />

          <ControlsContainer
            content={content.controls}
            hideShowreel={this.hideShowreels}
            moveShowreel={this.moveShowreel}
            deleteShowreel={this.deleteShowreels}
            isBlocked={isPatching}
            editShowreel={this.editShowreel}
            selectedShowreels={selectedShowreels}
            downloadShowreel={this.downloadShowreel}
          />

          <NotificationToast
            className="c-edit-video-list-form__notification"
            stickToComponent={false}
            handleHide={this.hideSuccessfulNotification}
            text={content.successfulDeleteToast}
            show={showSuccessfulNotification}
          />
        </div>
      </React.Fragment>
    );
  }

  private hideSuccessfulNotification = () =>
    this.setState({ showSuccessfulNotification: false });

  private deleteShowreels = async () => {
    GAEvents.editMediaAssets.trackDeleteClick(MediaAssetType.Video);

    const { selectedShowreels } = this.state;

    try {
      await this.props.deleteShowreels(selectedShowreels);
      this.setState({
        showSuccessfulNotification: true,
        selectedShowreels: [],
      });
    } catch (error) {
      Logger.appendLog(error);
    }
  };

  private hideShowreels = async () => {
    GAEvents.editMediaAssets.trackHideClick(MediaAssetType.Video);

    const { selectedShowreels } = this.state;

    await this.props.hideShowreels(selectedShowreels);
    this.setState({ selectedShowreels: [] });
  };

  private editShowreel = () => {
    GAEvents.editMediaAssets.trackEditClick(MediaAssetType.Video);
    this.props.editShowreel(this.state.selectedShowreels[0]);
  };

  private moveShowreel = async (direction: DirectionType) => {
    const { showreelList } = this.props;
    const selectedShowreel = this.state.selectedShowreels[0];
    let updatedSortOrder = selectedShowreel.sortOrder;

    if (selectedShowreel) {
      const selectedShowreelIndex = showreelList.findIndex(
        (showreel) => showreel.id === selectedShowreel.id
      );

      if (
        selectedShowreelIndex < showreelList.length - 1 &&
        direction === DirectionType.down
      ) {
        updatedSortOrder = selectedShowreelIndex + 1;
        GAEvents.editMediaAssets.trackMoveDownClick(MediaAssetType.Video);
        await this.props.changeMediaAssetsOrder(
          selectedShowreel.id,
          selectedShowreelIndex,
          updatedSortOrder
        );
      }

      if (selectedShowreelIndex > 0 && direction === DirectionType.up) {
        updatedSortOrder = selectedShowreelIndex - 1;
        GAEvents.editMediaAssets.trackMoveUpClick(MediaAssetType.Video);
        await this.props.changeMediaAssetsOrder(
          selectedShowreel.id,
          selectedShowreelIndex,
          updatedSortOrder
        );
      }
    }

    if (updatedSortOrder !== selectedShowreel.sortOrder) {
      const updatedShowreel = {
        ...selectedShowreel,
        sortOrder: updatedSortOrder,
      };
      this.setState({ selectedShowreels: [updatedShowreel] });
    }
  };

  private changeMediaAssetsOrder = async (
    oldIndex: number,
    newIndex: number
  ) => {
    const { showreelList, changeMediaAssetsOrder } = this.props;
    const { id } = showreelList[oldIndex];

    await changeMediaAssetsOrder(id, oldIndex, newIndex);

    this.setState({ selectedShowreels: [] });
  };

  private setSelectedShowreels = (selectedShowreels: MediaAsset[]) =>
    this.setState({ selectedShowreels });

  private updateSelectedShowreels = (selectedShowreels: MediaAsset[]) => {
    const isProcessingShowreel = (showreel: MediaAsset) =>
      showreel.transcodeStatus === TranscodeStatus.Processing ||
      showreel.transcodeStatus === TranscodeStatus.Awaiting;

    const isUpdatingNeeded = selectedShowreels.some((showreel) =>
      isProcessingShowreel(showreel)
    );

    if (isUpdatingNeeded) {
      const updatedSelectedShowreels = selectedShowreels.reduce(
        (acc, selectedShowreel) => {
          const updatedSelectedShowreel = this.props.showreelList.find(
            (showreel) => showreel.id === selectedShowreel.id
          );
          return updatedSelectedShowreel
            ? [...acc, updatedSelectedShowreel]
            : acc;
        },
        [] as MediaAsset[]
      );

      this.setSelectedShowreels(updatedSelectedShowreels);
    }
  };

  private downloadShowreel = () => {
    GAEvents.editMediaAssets.trackDownloadClick(MediaAssetType.Video);
    this.props.downloadShowreel(this.state.selectedShowreels[0]);
  };
}
