import React from "react";
import * as ReactGA from "react-ga";
import { RouteComponentProps } from "react-router-dom";
import { config } from "config";

export default function withGoogleAnalytics<P extends RouteComponentProps>(
  WrappedComponent: React.ComponentClass<P> | React.FC<P>
): typeof React.Component {
  return class extends React.Component<P> {
    public render() {
      return <WrappedComponent {...this.props} />;
    }

    public componentDidMount() {
      this.initGA();
    }

    private initGA = () => {
      ReactGA.initialize(
        config.googleAnalytics.trackingCode,
        config.googleAnalytics
      );
      this.trackPageView(this.props.location.pathname);

      this.props.history.listen((location) =>
        this.trackPageView(location.pathname)
      );
    };

    private trackPageView = (pathname: string) => {
      ReactGA.pageview(
        pathname,
        [config.googleAnalytics.gaOptions.name],
        "Spotlight - Photo Tool"
      );
    };
  };
}
