import { IAddPhotoTexts } from "component-library";
import { IGalleryValidationContent } from "./GalleryValidation";
import { AlertConfigBuilder } from "shared/utils/alert-config-builder";

export interface ICantDeletePhotoAlert {
  cantDeleteAlertTitle: string;
  cantDeleteDescription: string;
  cantDeleteClosePopup: string;
  cantDeleteCloseButton: string;
}

export interface IDeletePhotoConfirmation {
  deletePhotoTitle: string;
  deletePhotoDescription: string;
  deleteClosePopup: string;
  deletePhotoButton: string;
  cancelDeletePopup: string;
}

export interface IGalleryContent {
  galleryPhotoLoadingError: string;
  galleryTitle: string;
  galleryEdit: string;
  editSubtitle: string;
  editHelp: string;
  galleryClose: string;
  imageAlt: string;
  hiddenPhoto: string;
  selectPhoto: string;
  moveup: string;
  moveupAria: string;
  movedown: string;
  movedownAria: string;
  deletePhotos: string;
  deletePhotosAria: string;
  editPhoto: string;
  editPhotoAria: string;
  hidePhotos: string;
  hidePhotosAria: string;
  addPhoto: IAddPhotoTexts;
  deletePhotoConfirmation: IDeletePhotoConfirmation;
  cantDeletePhotoAlert: ICantDeletePhotoAlert;
  tooManyPhotosAlerts: IGalleryValidationContent;
  editHelpLink: string;
  galleryHelpAdvice: {
    title: string;
    description: string;
    close: string;
  };
}

export const getEditingPhotoErrorSettings = (content: ICantDeletePhotoAlert) =>
  new AlertConfigBuilder()
    .initBuildEntity()
    .setDefaultContent({
      title: content.cantDeleteAlertTitle,
      description: content.cantDeleteDescription,
      closeButtonText: content.cantDeleteCloseButton,
      closePopupButtonText: content.cantDeleteClosePopup,
    })
    .build();

export const getHelpAndAdviceSettings = (content: IGalleryContent) =>
  new AlertConfigBuilder()
    .initBuildEntity()
    .setDefaultContent({
      title: content.galleryHelpAdvice.title,
      description: content.galleryHelpAdvice.description,
      closeButtonText: content.galleryHelpAdvice.close,
    })
    .build();
