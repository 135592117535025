import { PhotoContainer } from "flows/Common/PhotoContainer";
import React from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  debounce,
  Helper,
  IAutocompleteItem,
  IconTextLink,
  IGroupValidationResult,
  Input,
  InputGroup,
  ITooltipConfig,
  maxLength,
  required,
  TextArea,
  WatchedFunction,
} from "component-library";

import { GlobalAlertMessage } from "shared/modules/Common/GlobalAlert";
import { AlertConfigBuilder } from "shared/utils/alert-config-builder";

import { PhotoEdit, Photographer } from "models";

import { IEditCreditsFormContent } from "./EditCreditsFormContent";

import "./EditCreditsForm.scss";

const TOOLTIP_CONFIG: ITooltipConfig = {
  minimalTopDistance: 50,
};

export interface IEditCreditsFormProps {
  content: IEditCreditsFormContent;
  photo: PhotoEdit;
  save: (photo: PhotoEdit) => void;
  getPhotographer: (name: string) => Promise<Photographer>;
  cancel: () => void;
  showHelpPopup: (message: GlobalAlertMessage) => void;
}

const VALIDATE = new WatchedFunction(() => undefined);

declare type PhotoForm = Pick<PhotoEdit, "photographer" | "description">;

interface IEditCreditsFormState {
  photo: PhotoEdit;
  form: {
    [key in keyof PhotoForm]: string;
  };
  validationData: {
    [key in keyof PhotoForm]: {
      invalid: boolean;
      validationMessage: string;
    };
  };
  formSubmitted: boolean;
  displayAutocomplete: boolean;
  autocompleteList: IAutocompleteItem[];
}

export default class EditCreditsForm extends React.Component<
  IEditCreditsFormProps,
  IEditCreditsFormState
> {
  private containerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private headerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private formRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private imageContainerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();

  constructor(props: IEditCreditsFormProps) {
    super(props);
    window.addEventListener("resize", this.adaptImageContainerDebounce, false);
    window.addEventListener(
      "orientationchange",
      this.adaptImageContainerDebounce,
      false
    );

    const defaultValidationState = {
      invalid: false,
      validationMessage: "",
    };

    const { photographer = "", description = "" } = props.photo;

    this.state = {
      photo: { ...props.photo },
      form: { photographer, description },
      validationData: {
        photographer: defaultValidationState,
        description: defaultValidationState,
      },
      formSubmitted: false,
      autocompleteList: [],
      displayAutocomplete: false,
    };
  }

  public render() {
    const formConfig = this.configForm();

    const { content, photo } = this.props;

    return (
      <div className="c-edit-credits-form" ref={this.containerRef}>
        <div className="c-edit-credits-form__header" ref={this.headerRef}>
          {content.header}
        </div>
        <div
          className="c-edit-credits-form__photo"
          ref={this.imageContainerRef}
        >
          {photo && (
            <PhotoContainer photo={photo} texts={{ hiddenOverlayTitle: "" }} />
          )}
        </div>
        <div className="c-edit-credits-form__form" ref={this.formRef}>
          <InputGroup
            inputConfigs={formConfig}
            validationDone={this.validationPerformed}
            triggerValidation={VALIDATE}
            variant="tertiary"
          >
            <div className="c-edit-credits-form__photographer-name">
              {this.renderPhotographerInput()}
            </div>
            <div className="c-edit-credits-form__description">
              {this.renderDescriptionInput()}
            </div>
            <div className="c-edit-credits-form__main-photo">
              <Checkbox
                htmlId="editCreditsFormIsMainPhoto"
                name="isMainPhoto"
                label={this.props.content.mainPhotoLabel}
                ariaLabel={this.props.content.mainPhotoLabel}
                valueChanged={this.setMainPhoto}
                value="thing"
              />
            </div>
          </InputGroup>
        </div>
        <div className="c-edit-credits-form__button-panel">
          {this.renderButtons()}
        </div>
      </div>
    );
  }

  public componentDidMount() {
    this.adaptImageContainer();
  }

  private renderDescriptionInput = () => {
    const {
      content: { description: descriptionLabel, tooltips },
    } = this.props;
    const {
      form,
      validationData: { description: descriptionValidation },
      formSubmitted,
    } = this.state;

    return (
      <TextArea
        id="description"
        name="description"
        label={descriptionLabel}
        annex={
          <Helper
            {...{
              tooltip: {
                texts: tooltips.description,
                config: TOOLTIP_CONFIG,
              },
            }}
          />
        }
        value={form.description}
        valueChanged={this.inputChanged("description")}
        invalid={descriptionValidation.invalid}
        isFormSubmitted={formSubmitted}
        validationMessage={descriptionValidation.validationMessage}
      />
    );
  };

  private renderPhotographerInput = () => {
    const {
      content: { photographerName, tooltips },
    } = this.props;
    const {
      form,
      validationData: { photographer: photographerValidation },
      formSubmitted,
      autocompleteList,
      displayAutocomplete,
    } = this.state;

    return (
      <Input
        id="photographer"
        name="photographer"
        type="text"
        label={photographerName}
        annex={
          <Helper
            {...{
              tooltip: {
                texts: tooltips.photographerName,
                config: TOOLTIP_CONFIG,
              },
            }}
          />
        }
        value={form.photographer}
        valueChanged={this.inputChanged("photographer")}
        invalid={photographerValidation.invalid}
        isFormSubmitted={formSubmitted}
        validationMessage={photographerValidation.validationMessage}
      >
        <Autocomplete
          list={autocompleteList}
          formatName={this.formatName}
          display={displayAutocomplete}
          selectItem={this.selectPhotographer}
        />
      </Input>
    );
  };

  private renderButtons = () => {
    const { content, cancel } = this.props;

    return (
      <React.Fragment>
        <div className="g-hidden-sm g-hidden-md">
          <IconTextLink
            variant="quaternary"
            text={content.helper.link?.text || ""}
            handleClick={this.showHelpAndAdvice}
            iconName="icon-lhnhelp"
          />
        </div>
        <div className="c-edit-credits-form__buttons">
          <div className="c-edit-credits-form__cancel">
            <Button type="secondary" text={content.cancel} onClick={cancel} />
          </div>
          <div className="c-edit-credits-form__continue">
            <Button type="primary" text={content.save} onClick={this.save} />
          </div>
        </div>
      </React.Fragment>
    );
  };

  private setMainPhoto = (isMainPhoto: boolean) =>
    this.setState({ photo: { ...this.state.photo, isMainPhoto } });

  private configForm = () => {
    const { photographer: photographerErrors, description: descriptionErrors } =
      this.props.content.errorMessages;
    const { photographer, description } = this.state.form;
    return [
      {
        name: "photographer",
        value: photographer,
        validators: [
          required(photographerErrors.required),
          maxLength(50, photographerErrors.maxLength),
        ],
      },
      {
        name: "description",
        value: description,
        validators: [maxLength(250, descriptionErrors.maxLength)],
      },
    ];
  };

  private inputChanged = (name: keyof PhotoForm) => {
    const state = { ...this.state };

    return (value: string) => {
      if (name === "photographer") {
        if (value.trim().length > 2) {
          this.loadPhotographersDebounced(value.trim());
        } else {
          state.autocompleteList = [];
          state.displayAutocomplete = false;
        }
      }

      state.form[name] = value;
      this.setState(state, VALIDATE.call);
    };
  };

  private loadPhotographers = async (value: string) => {
    const photographers = await this.props.getPhotographer(value);

    const autocompleteList: IAutocompleteItem[] = photographers.names.map(
      (item) => ({ name: item, data: item })
    );

    this.setState({
      autocompleteList,
      displayAutocomplete: Boolean(autocompleteList.length),
    });
  };

  private loadPhotographersDebounced = debounce(this.loadPhotographers, 300);

  private validationPerformed = (result: IGroupValidationResult) => {
    const validationData = {
      ...this.state.validationData,
      ...result.validationData,
    };

    this.setState({ validationData });
  };

  public componentWillUnmount() {
    window.removeEventListener("resize", this.adaptImageContainer, false);
    window.removeEventListener(
      "orientationchange",
      this.adaptImageContainer,
      false
    );
  }

  private save = () => {
    this.setState({ formSubmitted: true });

    const { photo, form, validationData } = this.state;
    const invalidForm = Object.keys(validationData).some(
      (property) => validationData[property].invalid
    );

    if (!invalidForm) {
      photo.photographer = form.photographer;
      photo.description = form.description;

      this.props.save(photo);
    }
  };

  private adaptImageContainer = () => {
    const form = this.formRef.current;
    const imageContainer = this.imageContainerRef.current;
    const container = this.containerRef.current;
    const header = this.headerRef.current;

    if (imageContainer && container && form && header) {
      const containerStyles = window.getComputedStyle(container, null);
      const containerPaddings =
        parseFloat(containerStyles.getPropertyValue("padding-top")) +
        parseFloat(containerStyles.getPropertyValue("padding-bottom"));
      const occupiedHeight =
        form.offsetHeight + header.offsetHeight + containerPaddings;

      imageContainer.style.maxHeight = `calc(100vh - ${occupiedHeight}px)`;
    }
  };

  private adaptImageContainerDebounce = debounce(this.adaptImageContainer, 300);

  private formatName = (item: IAutocompleteItem) => {
    const { photographer } = this.state.form;
    const indexStart = item.name
      .toLowerCase()
      .indexOf(photographer.toLowerCase());

    let name = item.name;

    if (indexStart >= 0) {
      const matchPart = item.name.substring(
        indexStart,
        indexStart + photographer.length
      );
      name = item.name.replace(matchPart, matchPart.bold());
    }

    return name;
  };

  private selectPhotographer = (item: IAutocompleteItem) => {
    const { form } = this.state;
    form.photographer = item.name;

    this.setState({ form, displayAutocomplete: false }, VALIDATE.call);
  };

  private showHelpAndAdvice = () => {
    const { helpAdvice } = this.props.content;

    const setting = new AlertConfigBuilder()
      .initBuildEntity()
      .setDefaultContent({
        closeButtonText: helpAdvice.close,
        description: helpAdvice.description,
        title: helpAdvice.title,
      })
      .setCloseButton({ name: helpAdvice.close })
      .build();

    this.props.showHelpPopup(setting);
  };
}
