import { IOption } from "../../../generics/interfaces";
import { IFacet } from "shared-services";
import { IDropdownCheckboxValueItemConfig } from "../../Molecules/DropdownCheckbox/DropdownCheckboxValue";

export function optionToFacet(option: { value: unknown; name: string }) {
  return { name: option.name, id: option.value };
}

export function facetToOption(facet: IFacet): IOption<number> {
  return {
    name: facet.name,
    value: facet.id,
  };
}

export function facetToCheckboxOption(
  facet: IFacet
): IDropdownCheckboxValueItemConfig {
  return {
    label: facet.name,
    value: String(facet.id),
    selectedLabel: "",
  };
}
