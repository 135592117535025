import { MediaAsset } from "mediaModules/media/models";
import React from "react";
import {
  formatTime,
  handleEnterPress,
  TextEllipsis,
  transformMillisecToSec,
} from "component-library";

import "./TrackViewer.scss";
import { IExternalMedia } from "shared-types";

export interface ITrackViewerProps {
  title: string;
  audioModel: MediaAsset | IExternalMedia;
  playback: (audio: MediaAsset | IExternalMedia) => void;
  titleMaxLines?: number;
}

export class TrackViewer extends React.Component<ITrackViewerProps> {
  public render() {
    const {
      audioModel: { isMediaObjectLoading },
    } = this.props;

    return (
      <div className="c-audio-viewer">
        {isMediaObjectLoading
          ? this.renderLoadingMediaObject()
          : this.renderLoadedMediaObject()}
      </div>
    );
  }

  private renderLoadingMediaObject = () => {
    return (
      <div className="c-audio-viewer__preview c-audio-viewer__preview--loading">
        <div className="c-audio-viewer__icon" />
        <div className="c-audio-viewer__info">
          <div className="c-audio-viewer__title">{this.renderTitle()}</div>
          <div className="c-audio-viewer__duration">{""}</div>
        </div>
      </div>
    );
  };

  private renderLoadedMediaObject = () => {
    const {
      audioModel: { durationInMs = 0 },
      playback,
    } = this.props;
    const formattedAudioTime = formatTime(
      transformMillisecToSec(durationInMs),
      ":"
    );
    const action = playback.bind(this, this.props.audioModel);

    return (
      <div className="c-audio-viewer__preview">
        <div
          className="c-audio-viewer__icon"
          onClick={action}
          tabIndex={0}
          onKeyPress={handleEnterPress(action)}
          role="button"
        >
          <span className="icon-playsmall" aria-label="Play" />
        </div>
        <div className="c-audio-viewer__info">
          <div className="c-audio-viewer__title">{this.renderTitle()}</div>
          <div className="c-audio-viewer__duration">{formattedAudioTime}</div>
        </div>
      </div>
    );
  };

  private renderTitle = () => {
    const { title, titleMaxLines } = this.props;
    return titleMaxLines ? (
      <TextEllipsis text={title} maxLine={titleMaxLines} basedOn="letters" />
    ) : (
      title
    );
  };
}

export default TrackViewer;
