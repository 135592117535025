import React from "react";

import { IControlsContainerContent } from "./ControlsContainerContent";
import { ButtonPanel } from "mediaModules/media/components/ButtonPanel";
import { MediaAsset, TranscodeStatus } from "mediaModules/media/models";

import "./ControlsContainer.scss";
import { IActionButtonProps } from "component-library/src/components/Atoms/ActionButton/ActionButton";

export enum ControlIcons {
  moveUp = "arrowup",
  moveDown = "arrowdown",
  edit = "edit",
  delete = "delete",
  hide = "hide",
  download = "download1",
}

export enum DirectionType {
  up = "up",
  down = "down",
}

export interface IControlsConfig {
  isMultipleMode: boolean;
  hasBrokenItem: boolean;
  hasProcessingItem: boolean;
}

export interface IControlsContainerProps {
  isBlocked: boolean;
  content: IControlsContainerContent;
  hideShowreel: () => void;
  moveShowreel: (direction: DirectionType) => void;
  deleteShowreel: () => void;
  editShowreel: () => void;
  downloadShowreel: () => void;
  selectedShowreels: MediaAsset[];
}

export const ControlsContainer: React.FC<IControlsContainerProps> = (props) => {
  const {
    content,
    isBlocked,
    hideShowreel,
    moveShowreel,
    deleteShowreel,
    editShowreel,
    downloadShowreel,
    selectedShowreels,
  } = props;

  const { isMultipleMode, hasBrokenItem, hasProcessingItem } =
    selectedShowreels.reduce(
      (config: IControlsConfig, { transcodeStatus }) => {
        if (transcodeStatus === TranscodeStatus.Error) {
          config.hasBrokenItem = true;
        }

        if (
          transcodeStatus === TranscodeStatus.Processing ||
          transcodeStatus === TranscodeStatus.Awaiting
        ) {
          config.hasProcessingItem = true;
        }

        return config;
      },
      {
        isMultipleMode: selectedShowreels.length > 1,
        hasBrokenItem: false,
        hasProcessingItem: false,
      }
    );

  const isControlDisabled = (type: ControlIcons) => {
    if (!selectedShowreels.length || isBlocked) {
      return true;
    }

    if (type !== ControlIcons.delete && hasProcessingItem) {
      return true;
    }

    if (
      type === ControlIcons.moveUp ||
      type === ControlIcons.moveDown ||
      type === ControlIcons.edit ||
      type === ControlIcons.download
    ) {
      return isMultipleMode || hasBrokenItem;
    }

    return false;
  };

  const buttons: (IActionButtonProps & { key: string | number })[] = [
    {
      key: ControlIcons.moveUp,
      icon: ControlIcons.moveUp,
      label: content.moveUp,
      onClick: () => moveShowreel(DirectionType.up),
      disabled: isControlDisabled(ControlIcons.moveUp),
    },
    {
      key: ControlIcons.moveDown,
      icon: ControlIcons.moveDown,
      label: content.moveDown,
      onClick: () => moveShowreel(DirectionType.down),
      disabled: isControlDisabled(ControlIcons.moveDown),
    },
    {
      key: ControlIcons.edit,
      icon: ControlIcons.edit,
      label: content.edit,
      onClick: editShowreel,
      disabled: isControlDisabled(ControlIcons.edit),
    },
    {
      key: ControlIcons.delete,
      icon: ControlIcons.delete,
      label: content.delete,
      onClick: deleteShowreel,
      disabled: isControlDisabled(ControlIcons.delete),
    },
    {
      key: ControlIcons.hide,
      icon: ControlIcons.hide,
      label: content.hide,
      onClick: hideShowreel,
      disabled: isControlDisabled(ControlIcons.hide),
    },
    {
      key: ControlIcons.download,
      icon: ControlIcons.download,
      label: content.download,
      onClick: downloadShowreel,
      disabled: isControlDisabled(ControlIcons.download),
    },
  ];

  return (
    <div className="c-controls-container g-bg-secondary">
      <ButtonPanel buttons={buttons} />
    </div>
  );
};
