import React from "react";
import { ITooltipContent } from "component-library";

import { moveArrayElement } from "shared/utils/array";

import { IMultiSelectItem } from "./MultiSelectItem";
import { MultiSelectAutocomplete } from "./MultiSelectAutocomplete";
import { MultiSelectListItems } from "./MultiSelectListItems";

import "./MultiSelectList.scss";
import { MultiSelectDropdown } from "./MultiSelectDropdown";

export interface IMultiSelectListContent {
  label: string;
  placeholder: string;
  tooltip?: ITooltipContent;
}

interface ICommonProps<TValue> {
  name: string;
  selectedItems: IMultiSelectItem<TValue>[];
  handleChanges: (items: IMultiSelectItem<TValue>[]) => void;
  isDisabled?: boolean;
  content: IMultiSelectListContent;
  invalid?: boolean;
  validationMessage?: string;
  hideMoveControls?: boolean;
}

interface IAutocompleteType<TValue> extends ICommonProps<TValue> {
  delayLoadTime?: number;
  list?: never;
  type: "autocomplete";
  loadData?: (value: string) => Promise<IMultiSelectItem<TValue>[]>;
}

interface IDropdownType<TValue> extends ICommonProps<TValue> {
  list: IMultiSelectItem<TValue>[];
  type: "dropdown";
  delayLoadTime?: never;
  loadData?: never;
}

export type MultiSelectListProps<TValue> =
  | IAutocompleteType<TValue>
  | IDropdownType<TValue>;

export default class MultiSelectList<TValue> extends React.Component<
  MultiSelectListProps<TValue>
> {
  public static defaultProps = {
    isDisabled: false,
    delayLoadTime: 0,
  };

  public render() {
    const { selectedItems, hideMoveControls } = this.props;

    return (
      <div className="c-multi-select-list">
        <div className="c-multi-select-list__input">{this.renderInput()}</div>
        <MultiSelectListItems
          hideMoveControls={hideMoveControls}
          selectedItems={selectedItems}
          moveElementDown={this.moveElementDown}
          moveElementUp={this.moveElementUp}
          removeItem={this.removeItem}
        />
      </div>
    );
  }

  private renderInput = () => {
    const {
      type,
      isDisabled,
      content,
      name,
      delayLoadTime,
      loadData,
      selectedItems,
      invalid,
      validationMessage,
      list,
    } = this.props;

    let input: React.ReactNode = null;
    if (!isDisabled) {
      if (type === "autocomplete") {
        input = (
          <MultiSelectAutocomplete
            content={content}
            name={name}
            invalid={invalid}
            validationMessage={validationMessage}
            loadData={loadData || (() => Promise.resolve([]))}
            delayLoadTime={delayLoadTime}
            selectItem={this.selectItem}
          />
        );
      } else if (type === "dropdown") {
        input = (
          <MultiSelectDropdown
            content={content}
            name={name}
            invalid={invalid}
            validationMessage={validationMessage}
            list={list || []}
            selectedItems={selectedItems}
            selectItem={this.selectItem}
          />
        );
      }
    }

    return input;
  };

  private selectItem = (item: IMultiSelectItem<TValue>) => {
    const { selectedItems, handleChanges } = this.props;

    handleChanges([...selectedItems, item]);
  };

  private moveElementUp = (value: TValue) => {
    const { selectedItems, handleChanges } = this.props;

    const fromIndex = selectedItems.findIndex((item) => item.value === value);

    if (fromIndex > 0) {
      const updatedList = moveArrayElement(
        selectedItems,
        fromIndex,
        fromIndex - 1
      );
      handleChanges(updatedList);
    }
  };

  private moveElementDown = (value: TValue) => {
    const { selectedItems, handleChanges } = this.props;
    const fromIndex = selectedItems.findIndex((item) => item.value === value);

    if (fromIndex < selectedItems.length - 1) {
      const updatedList = moveArrayElement(
        selectedItems,
        fromIndex,
        fromIndex + 1
      );
      handleChanges(updatedList);
    }
  };

  private removeItem = (value: TValue) => {
    const { selectedItems, handleChanges } = this.props;

    const updatedList = selectedItems.filter((item) => item.value !== value);

    handleChanges(updatedList);
  };
}
