import React from "react";

export interface ITextDividerProps {
  text?: string;
  separator: string;
  wrapper?: "span";
}

export const TextDivider: React.FC<ITextDividerProps> = ({
  wrapper = "span",
  separator,
  text,
}) => {
  const Wrapper = wrapper as any;

  const wrapperFn = (text: string, index: number) => (
    <Wrapper key={text + index}>{text}</Wrapper>
  );

  let splitText: React.ReactNode = text;

  if (text && Boolean(text.trim())) {
    splitText = text
      .split(separator)
      .filter(Boolean)
      .map((textString, index, array) => {
        let textStringComponent = wrapperFn(textString + separator, index);

        if (array.length - 1 === index) {
          textStringComponent = wrapperFn(textString, index);
        }

        return textStringComponent;
      });
  }

  if (text && text.charAt(0) === separator) {
    splitText = (
      <React.Fragment>
        {wrapperFn(separator, -1)}
        {splitText}
      </React.Fragment>
    );
  }

  return <React.Fragment>{splitText}</React.Fragment>;
};
