import React from "react";

import { Omit } from "shared/utils/types";

export interface IFlowContext {
  flowName?: string;
  flowData?: string;
  changeContext: (flowName: string, flowData?: any) => void;
  subscribe: (func: (flowContext: IFlowContext) => void) => void;
  unsubscribe: (func: (flowContext: IFlowContext) => void) => void;
}

export class FlowContext implements IFlowContext {
  private _subscribers: any[] = [];
  private _flowName: string;
  private _data: any;

  public get flowName() {
    return this._flowName;
  }

  public get flowData() {
    return this._data;
  }

  constructor(flowName: string, data: any) {
    this._flowName = flowName;
    this._data = data;
  }

  public subscribe = (func: (flowContext: IFlowContext) => void) => {
    this._subscribers.push(func);
  };

  public unsubscribe = (func: (flowContext: IFlowContext) => void) => {
    if (this._subscribers.indexOf(func) > -1) {
      this._subscribers.splice(this._subscribers.indexOf(func), 1);
    }
  };

  public changeContext = (flowName: string, flowData?: any) => {
    this._data = flowData;
    this._flowName = flowName;
    this._subscribers.forEach((subscriber) => {
      subscriber(this);
    });
  };
}

export interface IFlowContextProps {
  flowContext: IFlowContext;
}

export const FlowRouteContext = React.createContext(new FlowContext("", null));

export const FlowRouteContextConsumer = FlowRouteContext.Consumer;

export function withFlowContext<P extends IFlowContextProps>(
  Component: React.ComponentClass<P> | React.FC<P>
): React.FC<Omit<P, keyof IFlowContextProps>> {
  return function BoundComponent(props: Omit<P, keyof IFlowContextProps>) {
    return (
      <FlowRouteContextConsumer>
        {(value) => {
          const newProps = {
            ...props,
            flowContext: value,
          } as any as P;

          return <Component {...newProps} />;
        }}
      </FlowRouteContextConsumer>
    );
  };
}
