export default class Credit {
  public id: string;
  public name: string;
  public dateFromFormat?: string;
  public dateFrom?: Date;
  public dateToFormat?: string;
  public dateTo?: Date;
  public typeId: number;
  public role?: string;
  public company?: string;
  public venue?: string;
  public director?: string;
  public highlighted: boolean;
  public position: number;
}
