import React from "react";
import diff from "json-patch-gen";
import { RouteComponentProps } from "react-router-dom";
import { transformMillisecToSec } from "component-library";
import clone from "clone";

import { GlobalEventTypes } from "contexts/GlobalContext";
import { IGlobalContextProps } from "shared/contexts/GlobalContext";
import { Logger } from "common";
import mediaService from "mediaModules/media/services/MediaService";
import {
  MediaAsset,
  MediaAssetType,
  MediaObject,
  MediaStorageSpace,
  TranscodeStatus,
  MediaMetadata,
} from "../models";

interface IWithMediaAssetsProps
  extends IGlobalContextProps,
    RouteComponentProps,
    IWithMediaAssetTypeProps {}

interface IWithMediaDataState {
  mediaAssets: MediaAsset[];
  storageSpace: MediaStorageSpace;
  timeoutIds: ReturnType<typeof setTimeout>[];
  isMediaMetadataLoading: boolean;
  areMediaObjectsLoading: boolean;
  isPatching: boolean;
}

export interface IWithMediaDataInjectedProps {
  mediaAssets: MediaAsset[];
  storageSpace: MediaStorageSpace;
  isMediaMetadataLoading: boolean;
  areMediaObjectsLoading: boolean;
  removeMediaAssets: (mediaAssets: MediaAsset[]) => Promise<void>;
  hideMediaAssets: (
    mediaAssets: MediaAsset[],
    displayLimitReachedCallback: () => void
  ) => Promise<void>;
  changeMediaAssetsOrder: (
    id: string,
    oldSortOrder: number,
    newSortOrder: number
  ) => Promise<void>;
  isPatching: boolean;
}

const PROCESSING_MEDIA_REQUEST_TIMEOUT = 5000;

export default function withMediaData<P extends IWithMediaAssetsProps>(
  WrappedComponent: React.ComponentClass<P> | React.FC<P>
): typeof React.Component {
  return class extends React.Component<P, IWithMediaDataState> {
    constructor(props) {
      super(props);

      this.getAllowedStorageSpace = this.getAllowedStorageSpace.bind(this);
    }
    public readonly state: Readonly<IWithMediaDataState> = {
      mediaAssets: [],
      storageSpace: {
        allowedStorageSpace: {
          maxAllowedStoreInSec: 0,
          maxAllowedDisplayInSec: 0,
        },
        usedStorageSpace: {
          usedDisplayValueInSec: 0,
          usedStoreValueInSec: 0,
        },
      },
      isMediaMetadataLoading: true,
      areMediaObjectsLoading: true,
      timeoutIds: [],
      isPatching: false,
    };
    private mounted: boolean;

    public async componentDidMount() {
      this.mounted = true;
      await this.getAllowedStorageSpace();
      await this.getMediaMetadata();
      await this.getMediaAssets();
    }

    public async componentWillUnmount() {
      this.mounted = false;
      this.state.timeoutIds.forEach((id) => clearTimeout(id));
    }

    private async getAllowedStorageSpace() {
      try {
        const allowedStorageSpace = await mediaService.getAllowedStorageSpace(
          this.props.mediaAssetType
        );

        if (this.mounted) {
          this.setState({
            storageSpace: { ...this.state.storageSpace, allowedStorageSpace },
          });
        }
      } catch (error) {
        Logger.appendLog(error);
      }
    }

    private getMediaMetadata = async () => {
      try {
        const mediaMetadataItems = await mediaService.getMediaMetadata(
          this.props.mediaAssetType
        );
        const mediaAssetsPreload = mediaMetadataItems.map((item) =>
          mediaService.createMediaAssetFromMeta(item)
        );

        if (this.mounted) {
          this.setState({
            mediaAssets: mediaAssetsPreload,
            isMediaMetadataLoading: false,
          });
        }
      } catch (error) {
        Logger.appendLog(error);
      }
    };

    private getMediaAssets = async () => {
      const { mediaAssets } = this.state;
      const mediaAssetsExist = this.isArrayNotEmpty(mediaAssets);

      if (mediaAssetsExist) {
        for (let { mediaInfoId } of mediaAssets) {
          this.pollMediaObject(mediaInfoId);
        }
      } else {
        if (this.mounted) {
          this.setState({ areMediaObjectsLoading: false });
        }
      }
    };

    private pollMediaObject = async (
      mediaInfoId: string,
      timeoutId?: ReturnType<typeof setTimeout>
    ) => {
      const mediaMetadataToPoll = this.state.mediaAssets.find(
        ({ mediaInfoId: id }) => id === mediaInfoId
      );

      if (mediaMetadataToPoll) {
        const mediaObject = await mediaService.getMediaObject(
          mediaInfoId,
          this.closePopup
        );

        if (
          this.isProcessingItem(mediaObject.transcodeStatus) &&
          this.mounted
        ) {
          const newTimeoutId = setTimeout(async () => {
            await this.pollMediaObject(mediaInfoId, newTimeoutId);
          }, PROCESSING_MEDIA_REQUEST_TIMEOUT);

          this.setState({
            timeoutIds: [
              ...this.state.timeoutIds.filter((id) => id !== timeoutId),
              newTimeoutId,
            ],
          });
        }

        this.updateMediaAssets(mediaObject);
      }
    };

    private updateMediaAssets = (mediaObject: MediaObject) => {
      if (this.mounted) {
        const updatedMediaAssets = this.state.mediaAssets.map((mediaAsset) =>
          mediaAsset.mediaInfoId === mediaObject.id
            ? mediaService.mapUpdatedMediaObject(mediaAsset, mediaObject)
            : mediaAsset
        );

        const usedStorageSpace = this.getUsedStorageSpace(updatedMediaAssets);
        const areMediaObjectsLoading = updatedMediaAssets.some(
          (item) => item.isMediaObjectLoading
        );

        this.setState({
          mediaAssets: updatedMediaAssets,
          storageSpace: { ...this.state.storageSpace, usedStorageSpace },
          areMediaObjectsLoading,
        });
      }
    };

    private closePopup = () =>
      this.props.globalContext.notifyListener(
        GlobalEventTypes.closeAllGlobalAlert
      );

    private isProcessingItem = (transcodeStatus: TranscodeStatus) => {
      return (
        transcodeStatus === TranscodeStatus.Awaiting ||
        transcodeStatus === TranscodeStatus.Processing
      );
    };

    private removeMediaAssets = async (assetsToRemove: MediaAsset[]) => {
      this.setState({ isPatching: true });

      try {
        const mediaAssets = this.state.mediaAssets.filter(
          (mediaAsset) => !assetsToRemove.some(({ id }) => mediaAsset.id === id)
        );
        const usedStorageSpace = this.getUsedStorageSpace(mediaAssets);

        this.setState(
          {
            mediaAssets,
            storageSpace: { ...this.state.storageSpace, usedStorageSpace },
          },
          async () =>
            await this.commitRemovingMediaAssets(assetsToRemove, mediaAssets)
        );
      } catch (error) {
        Logger.appendLog(error);
        this.setState({ isPatching: false });
      }

      this.closePopup();
    };

    private commitRemovingMediaAssets = async (
      assetsToRemove: MediaAsset[],
      mediaAssets: MediaAsset[]
    ) => {
      for (const { id, mediaInfoId } of assetsToRemove) {
        try {
          await mediaService.deleteMediaAsset(id, mediaInfoId, this.closePopup);
        } catch (error) {
          Logger.appendLog("Error: Deleting process has been failed", error);
        }
      }

      try {
        const metadata = await mediaService.getMediaMetadata(
          this.props.mediaAssetType
        );
        const mediaAssetsUpdated = await mediaService.updateMediaAssetsMetadata(
          metadata,
          mediaAssets
        );
        const usedStorageSpace = this.getUsedStorageSpace(mediaAssetsUpdated);
        this.setState({
          mediaAssets: mediaAssetsUpdated,
          storageSpace: { ...this.state.storageSpace, usedStorageSpace },
        });
      } catch (error) {
        Logger.appendLog(
          "Error: Getting media assests has been failed while deleting",
          error
        );
      }

      this.setState({ isPatching: false });
    };

    private unhidePreCheck = (mediaAssetsToUpdate: MediaAsset[]) => {
      const {
        storageSpace: { usedStorageSpace, allowedStorageSpace },
        mediaAssets,
      } = this.state;

      //if a performer has a single media item, it could be made visible whether it's within limit or not
      if (
        mediaAssetsToUpdate.length === 1 &&
        mediaAssets.length === 1 &&
        mediaAssets[0].id === mediaAssetsToUpdate[0].id
      ) {
        return {
          allItemsAreHidden: mediaAssets[0].visible === false,
          visibilityCanBeChanged: true,
        };
      }

      const { allItemsAreHidden, totalItemsDurationInSec } =
        mediaAssetsToUpdate.reduce(
          (
            acc: {
              allItemsAreHidden: boolean;
              totalItemsDurationInSec: number;
            },
            { visible, durationInMs }: MediaAsset
          ) => {
            return {
              allItemsAreHidden: acc.allItemsAreHidden && !visible,
              totalItemsDurationInSec:
                acc.totalItemsDurationInSec +
                transformMillisecToSec(durationInMs),
            };
          },
          { allItemsAreHidden: true, totalItemsDurationInSec: 0 }
        );

      const durationInSecAfterUnhide =
        totalItemsDurationInSec + usedStorageSpace.usedDisplayValueInSec;
      const visibilityCanBeChanged = allItemsAreHidden
        ? durationInSecAfterUnhide <= allowedStorageSpace.maxAllowedDisplayInSec
        : true;

      return { allItemsAreHidden, visibilityCanBeChanged };
    };

    private hideMediaAssets = async (
      mediaAssets: MediaAsset[],
      displayLimitReachedCallback: () => void
    ) => {
      this.setState({ isPatching: true });

      const { allItemsAreHidden, visibilityCanBeChanged } =
        this.unhidePreCheck(mediaAssets);

      if (allItemsAreHidden && !visibilityCanBeChanged) {
        this.setState({ isPatching: false });
        displayLimitReachedCallback();
      } else {
        try {
          const patchesToApply: { difference: any; id: string }[] = [];
          for (const { id, visible } of mediaAssets) {
            const difference = diff(
              { visible: String(visible) },
              { visible: String(allItemsAreHidden) }
            );

            if (difference.length) {
              patchesToApply.push({ difference, id });
            }
          }

          //handle all patch requests together
          const patchesToApplyExist = this.isArrayNotEmpty(patchesToApply);
          if (patchesToApplyExist) {
            const mediaAssets = this.getHideMediaAssetsResult(
              this.state.mediaAssets,
              patchesToApply.map(({ id }) => id),
              allItemsAreHidden
            );
            const usedStorageSpace = this.getUsedStorageSpace(mediaAssets);
            this.setState(
              {
                mediaAssets,
                storageSpace: { ...this.state.storageSpace, usedStorageSpace },
              },
              async () =>
                await this.commitUpdatingMediaMetadata(
                  patchesToApply,
                  mediaAssets
                )
            );
          } else {
            this.setState({ isPatching: false });
          }
        } catch (error) {
          Logger.appendLog(error);
          this.setState({ isPatching: false });
        }
      }
    };

    private getHideMediaAssetsResult = (
      mediaAssets: MediaAsset[],
      ids: string[],
      visible: boolean
    ): MediaAsset[] => {
      if (mediaAssets.length) {
        const newMediaAssets: MediaAsset[] = clone(mediaAssets);

        for (const id of ids) {
          const assetToChangeVisibilityIndex = newMediaAssets.findIndex(
            (asset) => asset.id === id
          );
          newMediaAssets[assetToChangeVisibilityIndex].visible = visible;
        }

        return newMediaAssets;
      }

      return [];
    };

    private changeMediaAssetsOrder = async (
      id: string,
      oldIndex: number,
      newIndex: number
    ) => {
      this.setState({ isPatching: true });

      try {
        const difference = diff(
          { sortOrder: String(oldIndex) },
          { sortOrder: String(newIndex) }
        );

        if (difference.length) {
          const mediaAssets = this.getMoveMediaAssestsResult(
            this.state.mediaAssets,
            oldIndex,
            newIndex
          );
          this.setState(
            { mediaAssets },
            async () =>
              await this.commitUpdatingMediaMetadata(
                [{ difference, id }],
                mediaAssets
              )
          );
        } else {
          this.setState({ isPatching: false });
        }
      } catch (error) {
        Logger.appendLog(error);
        this.setState({ isPatching: false });
      }
    };

    private getMoveMediaAssestsResult = (
      mediaAssets: MediaAsset[],
      oldIndex: number,
      newIndex: number
    ): MediaAsset[] => {
      if (mediaAssets.length) {
        const assetToMove = mediaAssets[oldIndex];

        const newMediaAssets: MediaAsset[] = clone(mediaAssets);

        newMediaAssets.splice(oldIndex, 1);
        newMediaAssets.splice(newIndex, 0, assetToMove);
        return newMediaAssets;
      }

      return [];
    };

    private commitUpdatingMediaMetadata = async (
      differences: { difference: any; id: string }[],
      mediaAssets: MediaAsset[]
    ) => {
      let metadata: MediaMetadata[] = [];

      for (const { difference, id } of differences) {
        try {
          metadata = await mediaService.updateMediaMetadata(
            id,
            difference,
            this.props.mediaAssetType
          );
        } catch (error) {
          Logger.appendLog("Error: Patch request failed", error);
          try {
            metadata = await mediaService.getMediaMetadata(
              this.props.mediaAssetType
            );
          } catch (error) {
            Logger.appendLog(
              "Error: Getting media assests has been failed while patching",
              error
            );
          }
        }
      }

      if (metadata.length) {
        const mediaAssetsUpdated = await mediaService.updateMediaAssetsMetadata(
          metadata,
          mediaAssets
        );
        const usedStorageSpace = this.getUsedStorageSpace(mediaAssetsUpdated);
        this.setState({
          mediaAssets: mediaAssetsUpdated,
          storageSpace: { ...this.state.storageSpace, usedStorageSpace },
        });
      } else {
        Logger.appendLog("Error: Patching process has been failed");
      }

      this.setState({ isPatching: false });
    };

    private getUsedStorageSpace = (mediaAssets: MediaAsset[]) => {
      const visibleMediaAssets = mediaAssets.filter(({ visible }) => visible);
      const reducer = (acc: number, { durationInMs }: MediaAsset) =>
        acc + transformMillisecToSec(durationInMs);

      return {
        usedDisplayValueInSec: visibleMediaAssets.reduce(reducer, 0),
        usedStoreValueInSec: mediaAssets.reduce(reducer, 0),
      };
    };

    private isArrayNotEmpty = (array: any[]) =>
      array !== undefined && array.length > 0;

    public render() {
      const {
        mediaAssets,
        storageSpace,
        isMediaMetadataLoading,
        areMediaObjectsLoading,
        isPatching,
      } = this.state;

      return (
        <WrappedComponent
          mediaAssets={mediaAssets}
          storageSpace={storageSpace}
          isMediaMetadataLoading={isMediaMetadataLoading}
          areMediaObjectsLoading={areMediaObjectsLoading}
          removeMediaAssets={this.removeMediaAssets}
          hideMediaAssets={this.hideMediaAssets}
          changeMediaAssetsOrder={this.changeMediaAssetsOrder}
          isPatching={isPatching}
          {...this.props}
        />
      );
    }
  };
}

interface IWithMediaAssetTypeProps {
  mediaAssetType: MediaAssetType;
}

export const withMediaAssetType =
  (mediaAssetType: MediaAssetType) =>
  <P extends IWithMediaAssetTypeProps>(
    Component: React.ComponentClass<P> | React.FC<P>
  ) => {
    return function BoundComponent(props) {
      return <Component {...props} mediaAssetType={mediaAssetType} />;
    };
  };
