export default class InternalError {
  private _errorMessage;
  private _errorType;
  private _baseError;

  constructor(errorMessage: string, errorType?: string, baseError?: any) {
    this._errorMessage = errorMessage;
    this._errorType = errorType;
    this._baseError = baseError;
  }

  public get errorMessage() {
    return this._errorMessage;
  }

  public get errorType() {
    return this._errorType;
  }

  public get baseError() {
    return this._baseError;
  }
}