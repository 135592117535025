import React from "react";
import { RangeSlider } from "component-library";

import "./ContrastSetting.scss";

export interface IContrastState {
  contrast: number;
}

export interface IContrastSettingProps {
  minValue: number;
  maxValue: number;
  step: number;
  value: number;
  texts: {
    minValueName: string;
    maxValueName: string;
  };
  changeValue: (value: IContrastState) => void;
  disabled: boolean;
}

interface IContrastSettingState {
  value: number;
}

export class ContrastSetting extends React.Component<
  IContrastSettingProps,
  IContrastSettingState
> {
  constructor(props: IContrastSettingProps) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  public render() {
    return (
      <div className="c-contrast-setting">
        <RangeSlider
          disabled={this.props.disabled}
          texts={this.props.texts}
          value={this.props.value}
          step={this.props.step}
          maxValue={this.props.maxValue}
          minValue={this.props.minValue}
          onChange={this.changeValue}
        />
      </div>
    );
  }

  public componentWillReceiveProps(nextProps: IContrastSettingProps) {
    this.setState({ value: nextProps.value });
  }

  public changeValue = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    this.props.changeValue({ contrast: parseFloat(event.target.value) });
  };
}
