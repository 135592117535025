import React from "react";
import { useField } from "formik";
import { useFormikScrollToError } from "shared-hooks";
import { ERROR, InputWrapper } from "../../InputsCommon/InputWrapper";
import {
  DatePickerUniversal,
  IDatePickerUniversalProps,
} from "../../../Organisms/DatePickerUniversal/DatePickerUniversal";

interface IDatePickerUniversalFormikProps extends IDatePickerUniversalProps {
  name: string;
}

const DatePickerUniversalFormik = ({
  name,
  ...props
}: IDatePickerUniversalFormikProps) => {
  const [field, meta, helpers] = useField(name);
  const { fieldRef, containerElementRef } = useFormikScrollToError<
    any,
    HTMLDivElement
  >(name, true);

  const invalid = meta.touched && meta.error ? ERROR : undefined;

  return (
    <div ref={containerElementRef} className={props.className}>
      <InputWrapper invalid={invalid} validationMesssage={meta.error}>
        <DatePickerUniversal
          {...field}
          {...props}
          ref={fieldRef}
          valueChanged={(value) => helpers.setValue(value, true)}
        />
      </InputWrapper>
    </div>
  );
};

export default DatePickerUniversalFormik;
