import * as braze from "@braze/web-sdk";
import { MemberGroups } from "shared-types";

type MemberGroupNames = MemberGroups | "any";

// Routes that should NOT show in-app message
const NO_IN_APP_MSG_ROUTES = ["checkout"];

export type InitializeBrazeProps = {
  memberGroupFromToken: MemberGroupNames;
  portalId: string;
  brazeConfig: {
    apiKey: string;
    baseUrl: string;
  };
};

const initBraze = (brazeProps: InitializeBrazeProps): boolean => {
  const { memberGroupFromToken, portalId, brazeConfig } = brazeProps;
  // check MemberGroup(audience:subaudience) Braze should be initialized for
  if (!getShouldBrazeBeInitForMemberGroup(memberGroupFromToken)) {
    return false;
  }

  const isBrazeInitialized = braze.initialize(brazeConfig.apiKey, {
    baseUrl: brazeConfig.baseUrl,
    enableLogging: process.env.NODE_ENV === "development",
    doNotLoadFontAwesome: true,
    allowUserSuppliedJavascript: true,
    devicePropertyAllowlist: [
      braze.DeviceProperties.LANGUAGE,
      braze.DeviceProperties.BROWSER,
      braze.DeviceProperties.OS,
      braze.DeviceProperties.TIME_ZONE,
    ],
  });

  if (!getIsThisRouteShouldNotShowInAppMsg()) {
    braze.automaticallyShowInAppMessages();
  }

  if (portalId) {
    braze.changeUser(portalId);
  }

  braze.openSession();

  return isBrazeInitialized;
};

/* Helper Methods */

const getShouldBrazeBeInitForMemberGroup = (
  memberGroupFromToken: MemberGroupNames
): boolean => {
  if (!memberGroupFromToken) return false;
  if (memberGroupFromToken === "any") return true;

  return [
    MemberGroups.performer,
    MemberGroups.yPerformer,
    MemberGroups.graduatePerformer,
    MemberGroups.castingDirector,
    MemberGroups.agent,
  ].includes(memberGroupFromToken);
};

const getIsThisRouteShouldNotShowInAppMsg = () => {
  const currentPath = window.location.pathname;

  return currentPath
    .split("/")
    .filter(Boolean)
    .some((route) => NO_IN_APP_MSG_ROUTES.includes(route));
};

export { initBraze };
