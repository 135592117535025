import { ToolState } from './ToolState';

export class HistoryRecord {
  public toolState: ToolState;
  public cropperState: any;
  public cropperBoxState: any;
  public toolId: string;
  public image?: any;
  public verticalPivot: number;
  public horizontalPivot: number;
}
