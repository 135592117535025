import {
  IAlertMessageProps,
  IHelperProps,
  IPopupProps,
} from "component-library";

export function getHelpAndAdvice(content: any) {
  const alertProps: IAlertMessageProps = {
    icon: undefined,
    texts: {
      title: content.title,
      description: content.description,
    },
    buttons: [],
  };

  const popupProps: IPopupProps = {
    width: { lg: 4, md: 6 },
    close: () => undefined,
    priority: "high",
    texts: { closePopup: content.close },
  };

  const setting = {
    popupProps: popupProps,
    alertMessageProps: alertProps,
    closeButton: { name: content.close, type: "primary" },
  };

  return setting;
}

export interface IViewGalleryPhotoContent {
  header: string;
  close: string;
  save: string;
  editButton: string;
  deleteButton: string;
  hideButton: string;
  hiddenOverlayTitle: string;
  profilePictureCheckboxName: string;
  helper: IHelperProps;
  helpAdvice: {
    title: string;
    description: string;
    close: string;
  };
}
