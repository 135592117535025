import { GlobalStorage } from "app/generics";

import { AuthClaimTypes, ClaimTypes, UserClaimTypes } from "./claim-types";

const AUTH_CLAIM_CONTAINER = "AUTH_CLAIM_CONTAINER";
const USER_CLAIM_CONTAINER = "CLAIM_CONTAINER";

export class UserManager {
  private userProfile = undefined;
  public set profile(profile: any) {
    this.userProfile = profile;
  }

  public get profile() {
    return this.userProfile;
  }

  public get userId() {
    return this.getUserClaim(UserClaimTypes.userId) || "";
  }

  public set artistRef(artistRef: string | undefined) {
    artistRef !== undefined &&
      this.updateAuthClaim(AuthClaimTypes.artistRef, artistRef);
  }

  public get artistRef() {
    return this.getAuthClaim(AuthClaimTypes.artistRef);
  }

  public getClaim = (claimName: ClaimTypes) => this.getUserClaim(claimName);

  public hasClaimValue = (claimName: ClaimTypes) =>
    Boolean(this.getClaim(claimName));

  public getClaimWithDefault = (
    claimName: ClaimTypes,
    defaultValue: string = ""
  ) => this.getUserClaim(claimName) || defaultValue;

  public setClaim = (claimName: UserClaimTypes, value: string) =>
    this.updateUserClaim(claimName, value);

  public setClaims = (
    claims: { claimName: UserClaimTypes; value: string }[] = []
  ) =>
    claims.forEach(({ claimName, value }) => this.setClaim(claimName, value));

  public removeUserClaims = () => GlobalStorage.remove(USER_CLAIM_CONTAINER);

  private updateUserClaim = (claimName: UserClaimTypes, value: string) =>
    this.updateClaimInStore(USER_CLAIM_CONTAINER, { [claimName]: value });

  private getUserClaim = (claimName: ClaimTypes): string | undefined =>
    this.getClaimFromStore(USER_CLAIM_CONTAINER, claimName);

  private updateAuthClaim = (claimName: AuthClaimTypes, value: string) =>
    this.updateClaimInStore(AUTH_CLAIM_CONTAINER, { [claimName]: value });

  private getAuthClaim = (claimName: AuthClaimTypes): string | undefined =>
    this.getClaimFromStore(AUTH_CLAIM_CONTAINER, claimName);

  private updateClaimInStore = (
    place: string,
    claim: { [key: string]: string }
  ) => GlobalStorage.updateValue(place, claim);

  private getClaimFromStore(
    place: string,
    claimName: ClaimTypes
  ): string | undefined {
    const container = GlobalStorage.getValue<any>(place);

    return container && container[claimName];
  }
}

export default new UserManager();
