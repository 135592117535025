import { IPerformerSearchResult } from "component-library";
import { Performer, PerformersSearchResponse } from "../models";

import { HttpClient } from "./HttpClient";
import { getSearchAndFiltersApiUrl, getSiteRootUrl } from "./UrlBuilder";
import { HttpOperationError } from "shared/utils/exceptions";

export type IPerformerStatsResponse = {
  profileId: string;
  startDate: Date;
  endDate: Date;
  publicViews: number;
  selfTapes: number;
  uniqueVisitors: number;
  castingSearches: number;
  agentSearches: number;
  performerSearches: number;
  shortlists: number;
};

export class PerformersService {
  private httpClient: HttpClient = new HttpClient();

  public async getProfileStats(): Promise<IPerformerStatsResponse> {
    const requestUrl = getSearchAndFiltersApiUrl("/stats");

    try {
      const response = await this.httpClient.post<IPerformerStatsResponse>(
        requestUrl,
        { body: "{}" }
      );
      return response.object;
    } catch (error) {
      throw new HttpOperationError(
        requestUrl,
        "Error Getting Profile Stats:",
        error
      );
    }
  }

  public async searchPerformers(
    searchTerm: string
  ): Promise<IPerformerSearchResult[]> {
    const requestUrl = getSearchAndFiltersApiUrl("/search");

    const requestInfo = {
      skip: 0,
      take: 11,
      performerName: searchTerm,
      orderBy: "Relevance",
    };

    const requestParams = { body: JSON.stringify(requestInfo) };

    const response = await this.httpClient.post<PerformersSearchResponse>(
      requestUrl,
      requestParams
    );

    const result = response.object.profiles.map(
      (item: Performer): IPerformerSearchResult => ({
        url: getSiteRootUrl(`${item.viewPin}`),
        name: item.forename,
        surname: item.surname,
        type: item.performerType,
      })
    );

    return result;
  }
}

export default new PerformersService();
