export const profile = {
  "MainPage.AvailabilityCalendarForm.AvailabilityCalendarForm.datePicker":
    "availability-calendar-datepicker",
  "MainPage.AvailabilityCalendarForm.AvailabilityCalendarForm.titleInput":
    "availability-calendar-title-input",
  "MainPage.RowGallery.RowGallery.editButton": "RowGallery_EditButton",
  "MainPage.RowGallery.RowGallery.photo": "RowGallery_Photo",
  "ProfileStatsPage.HelpTip.helpTipContent": "help-tip-content",
  "ProfileStatsPage.StatCard.totalViewsCount": "total-views-count",
  "LinkToProfilePage.CopyLinkButtonsSection.copyButton": "copy-button",
  "MultiSelectList.MultiSelectListItems.wrapper": "multi-select-list-items",
  "EditBasicInfo.BasicInfoForm.Nationality.nationalitiesField":
    "nationalities-field",
  "Accordion.AccordionItem.expandable-row": "expandable-row",
  "SkillsGrid.SkillsGrid-data-row": "skills-grid-row",
  "Skills.SkillsForm.skillContainer": "skill-container",
  "LinkToProfile.ProfileLink.profileLink": "profile-link",
  "LinkToProfile.CopyProfileLink.button": "profile-link-copy-button",
};
