export interface ICancelablePromise<TType = any> {
  promise: Promise<TType>;
  cancel: () => void;
  isCanceled: () => boolean;
}

export function makeCancelable<TType>(promise: Promise<TType>): ICancelablePromise<TType> {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise<TType>((resolve, reject) => {
    promise.then(
      (result: TType) => hasCanceled_ ? reject({ isCanceled: true, result }) : resolve(result),
      (error) => hasCanceled_ ? reject({ isCanceled: true, error }) : reject(error)
    );
  });

  return {
    promise: wrappedPromise,
    isCanceled: () => hasCanceled_,
    cancel: () => {
      hasCanceled_ = true;
    },
  };
};
