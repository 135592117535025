export type Validator = () => boolean | string;

export class ChangesTrackingService {
  private poolValidators = new Map<string, Validator>();

  public dropValidators(registeredValidatorIds: string[]) {
    registeredValidatorIds
      .forEach((key) => this.poolValidators.delete(key));
  }

  public dropAllValidators() {
    Array.from(this.poolValidators.keys())
      .forEach((key) => this.poolValidators.delete(key));
  }

  public setValidator(id: string, validator: Validator) {
    this.poolValidators.set(id, validator);
  }

  public getValidationMessage = () => {
    const results = Array.from(this.poolValidators.values())
      .map((validator) => validator())
      .filter(Boolean);

    return results[0] || false;
  }
}
