import { ITooltipContent } from "component-library";

export interface IEditAudioFormContent {
  audioTitleFieldLabel: string;
  audioTitleRequired: string;
  audioTitleFieldTooltip: IAudioTitleFieldTooltipContent;
  downloadButton: string;
}

export interface IAudioTitleFieldTooltipContent {
  header: string;
  content: string;
  closeButton: string;
  tooltipName: string;
}

export const getTooltipProps = (content: ITooltipContent) => {
  return {
    texts: content,
    config: {
      minimalTopDistance: 50,
    },
  };
};
