import React from "react";
import FlowContextProvider from "./FlowContextProvider";

export default function withFlowContextProvider<
  P extends JSX.IntrinsicAttributes
>(WrappedComponent: React.ComponentClass<P> | React.FC<P>) {
  return (props: P) => (
    <FlowContextProvider>
      <WrappedComponent {...props} />
    </FlowContextProvider>
  );
}
