import React from "react";
import {
  HALManager,
  HALResources,
  UserClaimTypes,
  UserManager,
  UserRoles,
} from "core";
import jwt_decode from "jwt-decode";
import { RouteComponentProps } from "react-router-dom";
import { authorizationService } from "shared-auth";

import { IProfileViewResponse } from "models";

import ProfileService from "services/ProfileService";
import { IGlobalContextProps } from "shared/contexts/GlobalContext";
import { GlobalEventTypes } from "contexts/GlobalContext";

const VIEW_PIN_PATTERN = /\d{4}-\d{4}-\d{4}\/?$/;
const VIEW_PIN_PATTERN_WITHOUT_DELIMITERS = /\d{4}\d{4}\d{4}\/?$/;

declare type WithAnonymousAccess = RouteComponentProps & IGlobalContextProps;

interface IWithOIdcAuthenticationState {
  showContent: boolean;
}

export default function withAnonymousAccess<P extends WithAnonymousAccess>(
  WrappedComponent: React.ComponentClass<P> | React.FC<P>
): typeof React.Component {
  return class extends React.Component<P, IWithOIdcAuthenticationState> {
    public state: Readonly<IWithOIdcAuthenticationState> = {
      showContent: false,
    };

    constructor(props: P) {
      super(props);
      this.resetUserData();
    }

    public render() {
      const { showContent } = this.state;

      return showContent ? <WrappedComponent {...this.props} /> : null;
    }

    public async componentDidMount() {
      try {
        await this.loadUserProfile();
      } catch (error) {
        this.setState({ showContent: true });
        UserManager.setClaim(UserClaimTypes.role, UserRoles.anonymous);
      }
    }

    private loadUserProfile = async () => {
      const { history } = this.props;
      const viewPin = this.getViewPin();

      if (viewPin) {
        let profile;
        try {
          profile = await ProfileService.getProfileByViewPin(viewPin);
        } catch (error) {
          if (error.message === "Profile was not found") {
            // remove used claims
            this.resetUserData();
            // Force anonymous user
            UserManager.setClaim(UserClaimTypes.role, UserRoles.anonymous);
            // stop global spinner
            this.props.globalContext.notifyListener(
              GlobalEventTypes.makeVisibleGlobalSpinner,
              false
            );
            this.setState({ showContent: true });
            // redirect to profile not found page
            return history.replace("/profilenotfound");
          }
        }

        const { forename, surname, id, artistType, artistRef } =
          profile as IProfileViewResponse;

        UserManager.profile = profile;

        const isSignedIn = await ProfileService.verifySignInSession();

        if (isSignedIn) {
          const userToken = authorizationService.getUserToken();

          if (authorizationService.getUserToken()) {
            const userRole = this.getUserRole(userToken);
            UserManager.setClaim(UserClaimTypes.role, userRole);
          }
        } else {
          UserManager.setClaim(UserClaimTypes.role, UserRoles.anonymous);
        }

        UserManager.setClaims([
          {
            claimName: UserClaimTypes.viewPin,
            value: viewPin,
          },
          {
            claimName: UserClaimTypes.userId,
            value: id,
          },
          {
            claimName: UserClaimTypes.artistType,
            value: artistType.toString(),
          },
        ]);

        UserManager.artistRef = artistRef;
        UserManager.setClaim(
          UserClaimTypes.profileFullName,
          `${forename} ${surname}`
        );

        HALManager.setHalFor(
          HALResources.getGallery,
          HALManager.getGalleryByProfileIdUrl(id)
        );
        HALManager.setHalFor(
          HALResources.getMainPhoto,
          HALManager.getMainPhotoByProfileIdUrl(id)
        );
      }

      this.setState({ showContent: true });
    };

    private getUserRole = (token: string): UserRoles => {
      const { casting_id, agent_id, artist_id } = jwt_decode<{
        casting_id: string;
        agent_id: string;
        artist_id: string;
      }>(token);
      let result = UserRoles.anonymous;

      // Not a complete user role mapping. `cognito_groups` field excluded as we are unable to check that Spotlight stuff authenticated in legacy
      if (artist_id) {
        result = UserRoles.performer;
      } else if (agent_id) {
        result = UserRoles.agent;
      } else if (casting_id) {
        result = UserRoles.castingDirector;
      }

      return result;
    };

    private getViewPin = (): string | undefined => {
      const { history } = this.props;
      let viewPin: string | undefined;
      const currentPathname = window.location.pathname;

      const viewPinMatchWithoutDelimiters = currentPathname.match(
        VIEW_PIN_PATTERN_WITHOUT_DELIMITERS
      );
      const viewPinMatch = currentPathname.match(VIEW_PIN_PATTERN);

      if (viewPinMatchWithoutDelimiters) {
        const viewPinWithoutDelimiters =
          viewPinMatchWithoutDelimiters.toString();
        viewPin = this.addDelimitersToViewPin(viewPinWithoutDelimiters);
        history.replace(
          currentPathname.replace(viewPinWithoutDelimiters, viewPin)
        );
      } else if (viewPinMatch) {
        viewPin = viewPinMatch.toString();
      }

      return viewPin;
    };

    private addDelimitersToViewPin = (rawViewPin: string): string => {
      const viewPinParts: string[] = [];

      for (let i = 0; i < rawViewPin.length; i += 4) {
        viewPinParts.push(rawViewPin.substring(i, i + 4));
      }

      return viewPinParts.join("-");
    };

    private resetUserData = () => UserManager.removeUserClaims();
  };
}
