import React from "react";
import { Button } from "component-library";

import { IChangeMainPhotoViewContent } from "./ChangeMainPhotoViewContent";
import { PhotoSet, PHOTOSET_MODES } from "sections/GallerySection/Gallery";

import { Photo } from "models";

import "./ChangeMainPhotoView.scss";

export interface IChangeMainPhotoViewProps {
  photos: Photo[];
  content: IChangeMainPhotoViewContent;
  changeMainPhoto: (photo: Photo) => void;
  cancel: () => void;
  addPhoto: (photo: Photo) => void;
  error?: string;
}

interface IChangeMainPhotoViewState {
  selectedPhoto?: Photo;
}

export default class ChangeMainPhotoView extends React.Component<
  IChangeMainPhotoViewProps,
  IChangeMainPhotoViewState
> {
  public readonly state: Readonly<IChangeMainPhotoViewState> = {};

  public render() {
    const photoSetProps = this.getPhotoSetProps();

    const { content, error, cancel } = this.props;
    const { selectedPhoto } = this.state;

    return (
      <div className="c-change-main-photo-view">
        <div className="c-change-main-photo-view__header">{content.header}</div>
        <div className="c-change-main-photo-view__description">
          {content.description}
        </div>
        <div className="c-change-main-photo-view__gallery">
          <PhotoSet {...photoSetProps} error={error} />
        </div>
        <div className="c-change-main-photo-view__buttons">
          <div className="c-change-main-photo-view__button">
            <Button type="secondary" text={content.cancel} onClick={cancel} />
          </div>
          <div className="c-change-main-photo-view__button">
            <Button
              type="primary"
              disabled={!Boolean(selectedPhoto)}
              text={content.continue}
              onClick={this.continue}
            />
          </div>
        </div>
      </div>
    );
  }

  private setPhoto = (selectedPhoto: Photo) => this.setState({ selectedPhoto });

  private continue = () => {
    const { selectedPhoto } = this.state;

    if (selectedPhoto) {
      this.props.changeMainPhoto(selectedPhoto);
    }
  };

  private getPhotoSetProps() {
    const photoSetProps = {
      photos: this.props.photos,
      photoClicked: this.setPhoto,
      photoSelected: this.setPhoto,
      photoAdded: this.photoAdded,
      mode: PHOTOSET_MODES.singleSelect,
      texts: this.props.content.photoSetTexts,
      addPhotoTexts: this.props.content.addPhoto,
    };

    photoSetProps.photos.forEach((element) => {
      element.isHidden = false;
    });

    return photoSetProps;
  }

  private photoAdded = (img: HTMLImageElement) => {
    const photo = new Photo();
    photo.url = img.src;

    this.props.addPhoto(photo);
  };
}
