import React from "react";
import { IPopupProps, Spinner } from "component-library";

import { FlowStep } from "./FlowStep";

export interface IPopupFlowBaseComponentState {
  currentStep: string;
}

export default class PopupFlowBaseComponent<
  TFlowBaseComponentProps,
  TFlowBaseComponentState extends IPopupFlowBaseComponentState
> extends React.Component<TFlowBaseComponentProps, TFlowBaseComponentState> {
  protected flowSteps: FlowStep[];

  constructor(props: TFlowBaseComponentProps) {
    super(props);
  }

  protected createPopupConfiguration(
    popupSize: any,
    texts: any,
    closeFunction: () => void,
    className?: string,
    priority = "high"
  ): any {
    return popupSize
      ? ({
          width: popupSize,
          close: closeFunction,
          priority: priority,
          texts: texts,
          className: className,
        } as IPopupProps)
      : null;
  }

  protected getCurrentStep = (): any => {
    const step = this.flowSteps.find(
      (step) => step.name === this.state.currentStep
    );
    if (step) {
      return step.component(step.settings);
    }

    return null;
  };

  protected getCurrentPopupConfiguration = (): IPopupProps | any => {
    const step = this.flowSteps.find(
      (step) => step.name === this.state.currentStep
    );
    if (step) {
      return step.popupSettings;
    }

    return null;
  };

  protected moveToStep = (newState: string) => {
    this.setState({ currentStep: newState });
  };

  protected getLoader = () => {
    return (
      <div className="spinner-bg">
        <Spinner />
      </div>
    );
  };
}
