import { useCallback, useContext } from "react";
import _get from "lodash/get";
import { TranslationContext } from "./TranslationContext";

export type TFunctionDeprecated = (key: string, params?: any) => string;

export const useTranslation = (): TFunctionDeprecated => {
  const translations = useContext(TranslationContext);

  return useCallback(
    (key: string) => {
      return _get(translations, key, key);
    },
    [translations]
  );
};
