import { labels } from "./labels";
import { contactPerformerModal } from "./contactPerformerModal";
import { buttons } from "./buttons";
import { placeholders } from "./placeholders";
import { validation } from "./validation";
import { tooltips } from "./tooltips";
import { earlyApplyFlag } from "./earlyApplyFlag";
import { pagination } from "./pagination";

export const common = {
  labels,
  buttons,
  placeholders,
  validation,
  contactPerformerModal,
  tooltips,
  earlyApplyFlag,
  pagination,
};
