import React from "react";
import { NotificationToast } from "component-library";

import { IAudioPageFormContent } from "./AudioPageFormContent";
import {
  MediaAsset,
  MediaStorageSpace,
  MediaAssetType,
  TranscodeStatus,
} from "mediaModules/media/models";
import {
  ControlsContainer,
  DirectionType,
} from "mediaModules/media/components/ControlsContainer";
import { ReorderableMediaAssets } from "../ReorderableMediaAssets";
import { Logger } from "common";
import { MediaStorageContainer } from "mediaModules/media/components/MediaStorageContainer";
import { GAEvents } from "mediaModules/media/components/GAEvents";

import "./AudioPageForm.scss";

export interface IAudioPageFormProps {
  content: IAudioPageFormContent;
  mediaAssets: MediaAsset[];
  editMediaAsset: (mediaAsset?: MediaAsset) => void;
  playback: (mediaAsset: MediaAsset) => void;
  hideMediaAssets: (mediaAssets?: MediaAsset[]) => Promise<void>;
  deleteMediaAssets: (mediaAssets: MediaAsset[]) => Promise<void>;
  changeMediaAssetsOrder: (
    id: string,
    oldSortOrder: number,
    newSortOrder: number
  ) => Promise<void>;
  addNewMediaAsset: () => void;
  downloadMediaAsset: (mediaAsset?: MediaAsset) => void;
  isBlocked: boolean;
  storageSpace: MediaStorageSpace;
  isMediaMetadataLoading: boolean;
  areMediaObjectsLoading: boolean;
}

interface IAudioPageFormState {
  showSuccessfulNotification: boolean;
  selectedMediaAssets: MediaAsset[];
  disableReordering: boolean;
}

export class AudioPageForm extends React.Component<
  IAudioPageFormProps,
  IAudioPageFormState
> {
  public readonly state: Readonly<IAudioPageFormState> = {
    showSuccessfulNotification: false,
    selectedMediaAssets: [],
    disableReordering: false,
  };

  public componentDidUpdate(prevProps: IAudioPageFormProps) {
    if (this.props.mediaAssets !== prevProps.mediaAssets) {
      this.updateSelectedMediaAssets(this.state.selectedMediaAssets);
    }
  }

  render() {
    const {
      content,
      playback,
      mediaAssets,
      addNewMediaAsset,
      isBlocked,
      storageSpace,
      isMediaMetadataLoading,
      areMediaObjectsLoading,
    } = this.props;
    const { selectedMediaAssets, showSuccessfulNotification } = this.state;

    return (
      <div className="c-audio-page-form">
        <div className="g-inner-grid-12">
          <MediaStorageContainer
            storageSpace={storageSpace}
            storedCapacityBarContent={content.capacity1}
            displayedCapacityBarContent={content.capacity2}
            isLoading={areMediaObjectsLoading}
          />
        </div>

        <ReorderableMediaAssets
          mediaAssets={mediaAssets}
          content={content.audio}
          setSelectedMediaAssets={this.setSelectedMediaAssets}
          playback={playback}
          addNewMediaAsset={addNewMediaAsset}
          selectedMediaAssets={selectedMediaAssets}
          editMediaAsset={this.props.editMediaAsset}
          isMediaMetadataLoading={isMediaMetadataLoading}
          areMediaObjectsLoading={areMediaObjectsLoading}
          sortCompleted={this.changeMediaAssetsOrder}
          disableReordering={isBlocked}
        />

        <ControlsContainer
          content={content.controls}
          hideShowreel={this.hideMediaAssets}
          moveShowreel={this.moveMediaAsset}
          deleteShowreel={this.deleteMediaAssets}
          isBlocked={isBlocked}
          editShowreel={this.editMediaAsset}
          selectedShowreels={selectedMediaAssets}
          downloadShowreel={this.downloadMediaAsset}
        />

        <NotificationToast
          className="c-audio-page-form__notification"
          stickToComponent={false}
          handleHide={this.hideSuccessfulNotification}
          text={content.successfulDeleteToast}
          show={showSuccessfulNotification}
        />
      </div>
    );
  }

  private hideSuccessfulNotification = () =>
    this.setState({ showSuccessfulNotification: false });

  private deleteMediaAssets = async () => {
    GAEvents.editMediaAssets.trackDeleteClick(MediaAssetType.Audio);

    const { selectedMediaAssets } = this.state;

    try {
      await this.props.deleteMediaAssets(selectedMediaAssets);
      this.setState({
        showSuccessfulNotification: true,
        selectedMediaAssets: [],
      });
    } catch (error) {
      Logger.appendLog(error);
    }
  };

  private hideMediaAssets = async () => {
    GAEvents.editMediaAssets.trackHideClick(MediaAssetType.Audio);

    const { selectedMediaAssets } = this.state;

    await this.props.hideMediaAssets(selectedMediaAssets);
    this.setState({ selectedMediaAssets: [] });
  };

  private editMediaAsset = () => {
    GAEvents.editMediaAssets.trackEditClick(MediaAssetType.Audio);
    this.props.editMediaAsset(this.state.selectedMediaAssets[0]);
  };

  private moveMediaAsset = async (direction: DirectionType) => {
    const { mediaAssets } = this.props;
    const selectedMediaAsset = this.state.selectedMediaAssets[0];
    let updatedSortOrder = selectedMediaAsset.sortOrder;

    if (selectedMediaAsset) {
      const selectedMediaAssetIndex = mediaAssets.findIndex(
        (mediaAsset) => mediaAsset.id === selectedMediaAsset.id
      );

      if (
        selectedMediaAssetIndex < mediaAssets.length - 1 &&
        direction === DirectionType.down
      ) {
        updatedSortOrder = selectedMediaAssetIndex + 1;
        GAEvents.editMediaAssets.trackMoveDownClick(MediaAssetType.Audio);
        await this.props.changeMediaAssetsOrder(
          selectedMediaAsset.id,
          selectedMediaAssetIndex,
          updatedSortOrder
        );
      }

      if (selectedMediaAssetIndex > 0 && direction === DirectionType.up) {
        updatedSortOrder = selectedMediaAssetIndex - 1;
        GAEvents.editMediaAssets.trackMoveUpClick(MediaAssetType.Audio);
        await this.props.changeMediaAssetsOrder(
          selectedMediaAsset.id,
          selectedMediaAssetIndex,
          updatedSortOrder
        );
      }
    }

    if (updatedSortOrder !== selectedMediaAsset.sortOrder) {
      const updatedMediaAsset = {
        ...selectedMediaAsset,
        sortOrder: updatedSortOrder,
      };
      this.setState({ selectedMediaAssets: [updatedMediaAsset] });
    }
  };

  private changeMediaAssetsOrder = async (
    oldIndex: number,
    newIndex: number
  ) => {
    const { mediaAssets, changeMediaAssetsOrder } = this.props;
    const { id } = mediaAssets[oldIndex];

    await changeMediaAssetsOrder(id, oldIndex, newIndex);

    this.setState({ selectedMediaAssets: [] });
  };

  private setSelectedMediaAssets = (selectedMediaAssets: MediaAsset[]) =>
    this.setState({ selectedMediaAssets });

  private updateSelectedMediaAssets = (selectedMediaAssets: MediaAsset[]) => {
    const isProcessingMediaAsset = (showreel: MediaAsset) =>
      showreel.transcodeStatus === TranscodeStatus.Processing ||
      showreel.transcodeStatus === TranscodeStatus.Awaiting;

    const isUpdatingNeeded = selectedMediaAssets.some((showreel) =>
      isProcessingMediaAsset(showreel)
    );

    if (isUpdatingNeeded) {
      const updatedSelectedMediaAssets = selectedMediaAssets.reduce(
        (acc, selectedMediaAsset) => {
          const updatedSelectedMediaAsset = this.props.mediaAssets.find(
            (mediaAsset) => mediaAsset.id === selectedMediaAsset.id
          );
          return updatedSelectedMediaAsset
            ? [...acc, updatedSelectedMediaAsset]
            : acc;
        },
        [] as MediaAsset[]
      );

      this.setSelectedMediaAssets(updatedSelectedMediaAssets);
    }
  };

  private downloadMediaAsset = () => {
    GAEvents.editMediaAssets.trackDownloadClick(MediaAssetType.Audio);
    this.props.downloadMediaAsset(this.state.selectedMediaAssets[0]);
  };
}
