import React from "react";
import {
  Helper,
  AlertMessage,
  IAlertMessageProps,
  Popup,
  ActionButton,
} from "component-library";
import clone from "clone";
import diff from "json-patch-gen";

import { PhotoSet, PHOTOSET_MODES, IPhotoSetTexts } from ".";
import {
  IGalleryContent,
  getHelpAndAdviceSettings,
  getEditingPhotoErrorSettings,
} from "./GalleryContent";
import { FLOW_TYPES } from "components/flows";

import { PhotoService } from "services/PhotoService";

import { IFlowContextProps, withFlowContext } from "contexts/FlowContext";
import { GlobalEventTypes } from "contexts/GlobalContext";
import { IErrorNotificationData } from "shared/modules/Common/GlobalAlert";
import {
  withGlobalContext,
  IGlobalContextProps,
} from "shared/contexts/GlobalContext";
import { withLog, ILogContextProps } from "shared/contexts/LoggerContext";

import { BasePhoto } from "models";

import "./Gallery.scss";
import { LOG_DOMAIN, LOG_ACTION_DICTIONARY } from "app/store/logger-dictionary";

export interface IGalleryProps extends ILogContextProps {
  content: IGalleryContent;
  photos: BasePhoto[];
  photoClicked: (photo: BasePhoto) => void;
  addPhoto: (photo: BasePhoto) => void;
  reloadPhotos: () => Promise<void>;
  error?: string;
}

interface IGalleryState {
  photos: BasePhoto[];
  selectedPhotos: BasePhoto[];
  currentlySelectedPhoto: BasePhoto | null;
  isDeletePhotoAttempted: boolean;
  photosWithErrorsIDs: { [key: string]: boolean };
}

export type IGalleryComponentProps = IGalleryProps &
  IFlowContextProps &
  IGlobalContextProps;

export class Gallery extends React.Component<
  IGalleryComponentProps,
  IGalleryState
> {
  private isPatching = false;
  private photoService: PhotoService = new PhotoService();

  constructor(props: IGalleryComponentProps) {
    super(props);

    this.state = {
      selectedPhotos: [],
      photos: [...props.photos],
      currentlySelectedPhoto: null,
      isDeletePhotoAttempted: false,
      photosWithErrorsIDs: {},
    };
  }

  public render() {
    const { content } = this.props;

    const photosetContainerAttrs = {
      tabIndex: -1,
      onKeyDown: this.movePhotoByKeyboard,
    };

    return (
      <div className="c-gallery">
        <div className="g-content-area">
          <div className="g-col-lg-12">
            {this.renderHeader()}
            <div
              className="c-gallery__photoset-container"
              {...photosetContainerAttrs}
            >
              {this.renderPhotoSet(content)}
            </div>
          </div>
        </div>
        {this.renderActions(content)}
        {this.state.isDeletePhotoAttempted &&
          this.showDeletePhotoConfirmation()}
      </div>
    );
  }

  public componentWillReceiveProps(nextProps: IGalleryComponentProps) {
    if (nextProps.photos !== this.props.photos) {
      const selectedPhotos = this.state.selectedPhotos.filter((selectedPhoto) =>
        nextProps.photos.find((photo) => photo.id === selectedPhoto.id)
      );

      // reset entire photoSet upon new array of photos being loaded
      this.setState({
        photos: [...nextProps.photos],
        photosWithErrorsIDs: {},
        selectedPhotos,
      });
    }
  }

  public renderHeader = () => {
    return (
      <React.Fragment>
        <div className="c-gallery__header">
          <h3>{this.props.content.galleryTitle}</h3>
        </div>
        <div className="c-gallery__edit-hint">
          <div className="c-gallery__edit-hint-text" role="alert">
            {this.props.content.editSubtitle}
          </div>
          <div className="c-gallery__edit-hint-help">
            <Helper
              link={{
                url: this.props.content.editHelpLink,
                text: this.props.content.editHelp,
              }}
              click={this.showHelpAndAdvice}
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  public renderPhotoSet = (content: IGalleryContent) => {
    const texts: IPhotoSetTexts = {
      imageAlt: content.imageAlt,
      hiddenPhoto: content.hiddenPhoto,
      selectPhoto: content.selectPhoto,
      tooManyPhotosAlerts: content.tooManyPhotosAlerts,
    };

    const { logger, photoClicked } = this.props;

    const { addPhotoAction, openPhotoAction } = LOG_ACTION_DICTIONARY.gallery;

    const props = {
      photos: this.state.photos,
      error: this.props.error,
      texts,
      photoClicked: logger.wrapActionFunction(photoClicked, openPhotoAction),
      photoError: this.handlePhotoLoadingError,
    };

    let photoSetProps = {
      ...props,
      mode: PHOTOSET_MODES.multiSelect,
      photoSelected: (
        selectedPhotos: BasePhoto[],
        currentlySelectedPhoto: BasePhoto | null
      ) => {
        this.setState({ selectedPhotos, currentlySelectedPhoto });
      },
      sortCompleted: this.updateMovedPhotosState,
      selectedPhotos: this.state.selectedPhotos,
      addPhotoTexts: content.addPhoto,
      photoAdded: logger.wrapActionFunction(this.photoAdded, addPhotoAction),
    };

    return <PhotoSet {...photoSetProps} />;
  };

  public renderActions = (content: IGalleryContent) => {
    const { selectedPhotos, currentlySelectedPhoto, photosWithErrorsIDs } =
      this.state;
    let actions: JSX.Element | null = null;

    let isSingleActionDisabled = true;
    let isMultipleActionDisabled = true;
    let disableHideAction = false;

    if (selectedPhotos) {
      if (selectedPhotos.length == 1) {
        isSingleActionDisabled = false;
      }
      if (selectedPhotos.length >= 1) {
        isMultipleActionDisabled = false;
      }

      const isEditActionDisabled =
        isSingleActionDisabled ||
        (!!currentlySelectedPhoto &&
          photosWithErrorsIDs[currentlySelectedPhoto.id]);

      disableHideAction = selectedPhotos.some((x) => photosWithErrorsIDs[x.id]);

      const isHideActionDisabled =
        isMultipleActionDisabled || disableHideAction;

      const { logger } = this.props;

      const {
        movePhotoUpAction,
        editPhotoAction,
        deletePhotosAction,
        hidePhotosAction,
        movePhotoDownAction,
      } = LOG_ACTION_DICTIONARY.gallery;

      actions = (
        <div className="c-gallery__edit-action-list g-col-lg-12">
          <ActionButton
            onClick={logger.wrapActionFunction(
              this.movePhotoUp,
              movePhotoUpAction
            )}
            icon="arrowup"
            disabled={isSingleActionDisabled}
            label={content.moveup}
            ariaLabel={content.moveupAria}
          />
          <ActionButton
            onClick={logger.wrapActionFunction(
              this.movePhotoDown,
              movePhotoDownAction
            )}
            icon="arrowdown"
            disabled={isSingleActionDisabled}
            label={content.movedown}
            ariaLabel={content.movedownAria}
          />
          <ActionButton
            onClick={logger.wrapActionFunction(this.editPhoto, editPhotoAction)}
            icon="edit"
            disabled={isEditActionDisabled}
            label={content.editPhoto}
            ariaLabel={content.editPhotoAria}
          />
          <ActionButton
            onClick={logger.wrapActionFunction(
              this.attemptDeletePhoto,
              deletePhotosAction
            )}
            icon="delete"
            disabled={isMultipleActionDisabled}
            label={content.deletePhotos}
            ariaLabel={content.deletePhotosAria}
          />
          <ActionButton
            onClick={logger.wrapActionFunction(
              this.updateHiddenPhotosState,
              hidePhotosAction
            )}
            icon="hide"
            disabled={isHideActionDisabled}
            label={content.hidePhotos}
            ariaLabel={content.hidePhotosAria}
          />
        </div>
      );
    }

    return (
      <div className={"c-gallery__actions edit"}>
        <div className="g-content-area g-bg-tertiary">{actions}</div>
      </div>
    );
  };

  public editPhoto = () => {
    if (!this.isPatching) {
      const currentlySelectedPhoto = this.state.currentlySelectedPhoto;
      if (currentlySelectedPhoto && !currentlySelectedPhoto.isPreviouslyMain) {
        this.props.flowContext.changeContext(FLOW_TYPES.editPhoto, {
          photo: currentlySelectedPhoto,
        });
      } else if (
        currentlySelectedPhoto &&
        currentlySelectedPhoto.isPreviouslyMain
      ) {
        const settings = getEditingPhotoErrorSettings(
          this.props.content.cantDeletePhotoAlert
        );
        this.props.globalContext.notifyListener(
          GlobalEventTypes.notifyingGlobalAlert,
          settings
        );
      }
    }
  };

  private showDeletePhotoConfirmation = () => {
    const {
      content: { deletePhotoConfirmation },
    } = this.props;
    const { selectedPhotos } = this.state;

    const DELETE_ALERT_PROPS: IAlertMessageProps = {
      icon: "notice",
      texts: {
        title: deletePhotoConfirmation.deletePhotoTitle,
        description: deletePhotoConfirmation.deletePhotoDescription,
      },
      buttons: [
        {
          name: deletePhotoConfirmation.deletePhotoButton,
          type: "primary",
          click: this.updateDeletedPhotosState(selectedPhotos),
        },
        {
          name: deletePhotoConfirmation.cancelDeletePopup,
          type: "secondary",
          click: this.cancelDeletePhoto,
        },
      ],
    };

    return (
      <Popup
        width={{ lg: 4, md: 6 }}
        close={this.cancelDeletePhoto}
        priority="high"
        texts={{ closePopup: deletePhotoConfirmation.deleteClosePopup }}
      >
        <AlertMessage {...DELETE_ALERT_PROPS} />
      </Popup>
    );
  };

  private attemptDeletePhoto = () => {
    if (!this.isPatching) {
      if (
        this.state.selectedPhotos.findIndex(
          (photo) => photo.isPreviouslyMain
        ) === -1
      ) {
        this.setState({ isDeletePhotoAttempted: true });
      } else {
        const settings = getEditingPhotoErrorSettings(
          this.props.content.cantDeletePhotoAlert
        );
        this.props.globalContext.notifyListener(
          GlobalEventTypes.notifyingGlobalAlert,
          settings
        );
      }
    }
  };

  private cancelDeletePhoto = () =>
    this.setState({ isDeletePhotoAttempted: false });

  private movePhotoByKeyboard = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    const { key } = event;
    const { logger } = this.props;

    logger.appendAction(LOG_ACTION_DICTIONARY.gallery.keyPressAction, {
      context: { key },
    });

    if (
      key === "Up" ||
      key === "ArrowUp" ||
      key === "Left" ||
      key === "ArrowLeft"
    ) {
      event.stopPropagation();
      event.preventDefault();
      return this.movePhotoUp();
    }
    if (
      key === "Down" ||
      key === "ArrowDown" ||
      key === "Right" ||
      key === "ArrowRight"
    ) {
      event.stopPropagation();
      event.preventDefault();
      return this.movePhotoDown();
    }
    return null;
  };

  private movePhotoUp = () => !this.isPatching && this.movePhoto(-1);

  private movePhotoDown = () => !this.isPatching && this.movePhoto(1);

  private movePhoto = (bySpaces: 1 | -1) => {
    if (this.state.photos && this.state.selectedPhotos.length === 1) {
      const photo = this.state.selectedPhotos[0];
      const oldIndex = this.state.photos.findIndex(
        (item) => item.id === photo.id
      );
      let newIndex = oldIndex + bySpaces;
      this.updateMovedPhotosState(oldIndex, newIndex);
    }
  };

  private updateMovedPhotosState = (oldIndex: number, newIndex: number) => {
    if (!this.isPatching) {
      this.isPatching = true;

      if (newIndex === -1) {
        newIndex = this.state.photos.length - 1;
      } else {
        newIndex = newIndex % this.state.photos.length;
      }

      const photos = this.getMovePhotoResult(
        this.state.photos,
        oldIndex,
        newIndex
      );

      this.setState({ photos }, async () => {
        try {
          const patchDocument = {
            op: "move",
            from: "/photos/" + oldIndex,
            path: "/photos/" + newIndex,
          };
          try {
            const photos = await this.photoService.patchGallery([
              patchDocument,
            ]);
            this.applyResponsePhotos(photos);
          } catch (error) {
            const { logger } = this.props;
            logger.appendError(
              "Error: Patching process has been failed",
              error,
              { patchDocument }
            );

            try {
              const photos = await this.photoService.getGallery();
              this.applyResponsePhotos(photos);
            } catch (error) {
              logger.appendError(
                "Error: Getting photo, has been failed. (Patch error)",
                error,
                { patchDocument }
              );
            }
          }
        } catch (error) {
          const errorData: IErrorNotificationData = {
            errorType: error.status,
            error,
          };
          this.props.globalContext.notifyListener(
            GlobalEventTypes.errorNotification,
            errorData
          );
        }
      });
    }
  };

  private getMovePhotoResult = (
    photos: BasePhoto[],
    oldIndex: number,
    newIndex: number
  ): BasePhoto[] => {
    if (photos.length) {
      const photoToMove = photos[oldIndex];

      const newPhotos: BasePhoto[] = clone(photos);

      newPhotos.splice(oldIndex, 1);
      newPhotos.splice(newIndex, 0, photoToMove);
      return newPhotos;
    }
    return [];
  };

  private updateDeletedPhotosState = (selectedPhotos: BasePhoto[]) => () => {
    if (selectedPhotos.length) {
      this.deletePhotos(selectedPhotos);
      return this.setState({
        selectedPhotos: [],
        currentlySelectedPhoto: null,
        isDeletePhotoAttempted: false,
      });
    }

    return this.setState({ isDeletePhotoAttempted: false });
  };

  private deletePhotos = async (selectedPhotos: BasePhoto[] = []) => {
    const { logger, globalContext } = this.props;
    const { photos } = this.state;

    logger.appendInfo("Started: Deleting photos", {
      selectedPhotos: selectedPhotos.map(({ id, url }) => ({ id, url })),
      photos: photos.map(({ id, url }) => ({ id, url })),
    });

    globalContext.notifyListener(
      GlobalEventTypes.makeVisibleGlobalSpinner,
      true
    );

    const deleteCalls: Promise<any>[] = selectedPhotos
      .filter((selectedPhoto) =>
        photos.some((photo) => photo.id === selectedPhoto.id)
      )
      .map((selectedPhoto) => this.photoService.deletePhoto(selectedPhoto));

    try {
      await Promise.all(deleteCalls);
      logger.appendInfo(`Finished: Deleting of ${deleteCalls.length} photos`);
    } catch (error) {
      logger.appendError(
        "Error: Deleting photo process has been failed ",
        error,
        { deletedPhoto: selectedPhotos }
      );
      const errorData: IErrorNotificationData = {
        errorType: error.status,
        error,
      };
      this.props.globalContext.notifyListener(
        GlobalEventTypes.errorNotification,
        errorData
      );
    } finally {
      await this.props.reloadPhotos();
      globalContext.notifyListener(
        GlobalEventTypes.makeVisibleGlobalSpinner,
        false
      );
    }
  };

  private updateHiddenPhotosState = async () => {
    if (this.state.selectedPhotos.length && !this.isPatching) {
      this.isPatching = true;
      const oldPhotos = clone(this.state.photos);
      const photos = this.hidePhotos(this.state.selectedPhotos);

      const changes = diff({ photos: oldPhotos }, { photos });

      try {
        const patchedPhotos = await this.photoService.patchGallery(changes);

        try {
          this.applyResponsePhotos(patchedPhotos);
        } catch (error) {
          this.isPatching = false;
          this.runGalleryReloading();
          throw error;
        }
      } catch (error) {
        const { logger } = this.props;
        logger.appendError(
          "Error: Hiding photo process has been failed ",
          error,
          { hiddenPhotos: changes }
        );

        const errorData: IErrorNotificationData = {
          errorType: error.status,
          error,
        };
        this.props.globalContext.notifyListener(
          GlobalEventTypes.errorNotification,
          errorData
        );
      }
    }
  };

  private hidePhotos = (selectedPhotos: BasePhoto[]): BasePhoto[] => {
    let hide = false;

    for (let i = 0; i < selectedPhotos.length; i++) {
      const selectedPhoto = this.state.photos.find(
        (x) => x.id === selectedPhotos[i].id
      );
      if (selectedPhoto && !selectedPhoto.isHidden) {
        hide = true;
        break;
      }
    }

    const photos = this.state.photos;

    selectedPhotos.forEach((photo) => {
      const index = photos.findIndex((p) => p.id === photo.id);
      if (index > -1) {
        photos[index].isHidden = hide;
      }
    });

    return photos;
  };

  private applyResponsePhotos = (photos: BasePhoto[]) => {
    const selectedPhotos: BasePhoto[] = this.state.selectedPhotos;
    const newSelectedPhotos: BasePhoto[] = [];

    selectedPhotos.forEach((photo) => {
      const index = photos.findIndex((p) => p.id === photo.id);

      if (index > -1) {
        newSelectedPhotos.push(photos[index]);
      }
    });

    this.setState({ photos, selectedPhotos: newSelectedPhotos }, () => {
      this.isPatching = false;
      this.runGalleryReloading(photos);
    });
  };

  private runGalleryReloading = (photos?: BasePhoto[]) =>
    this.props.globalContext.notifyListener(GlobalEventTypes.updateGallery, {
      photos: photos,
    });

  private photoAdded = (img: HTMLImageElement) => {
    const photo: BasePhoto = new BasePhoto();
    photo.url = img.src;
    this.props.addPhoto(photo);
  };

  private handlePhotoLoadingError = (photo: BasePhoto) => {
    const { logger } = this.props;
    logger.appendError("Error: Photo loading has been failed", new Error(), {
      photo,
    });
    const photosWithErrors = { ...this.state.photosWithErrorsIDs };
    photosWithErrors[photo.id] = true;
    this.setState({ photosWithErrorsIDs: photosWithErrors });
  };

  private showHelpAndAdvice = () => {
    const setting = getHelpAndAdviceSettings(this.props.content);
    this.props.globalContext.notifyListener(
      GlobalEventTypes.notifyingGlobalAlert,
      setting
    );
  };
}

export default withGlobalContext(
  withFlowContext(withLog(LOG_DOMAIN.gallery)(Gallery))
);
