export enum MeasurementTypes {
  Height = 'Height',
  Weight = 'Weight',
  Chest = 'Chest',
  Waist = 'Waist',
  Hips = 'Hips',
  InsideLeg = 'InsideLeg',
  InsideArm = 'InsideArm',
  Collar = 'Collar',
  Hat = 'Hat',
  Shoes = 'Shoes',
  Dress = 'Dress',
  LowerVocalNote = 'LowerVocalNote',
  UpperVocalNote = 'UpperVocalNote',
}
