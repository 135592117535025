export class PromiseContainer<TEntity> {
  private _promise: Promise<TEntity>;
  private _resolve: (value?: TEntity | PromiseLike<TEntity> | undefined) => void;
  private _reject: (reason?: any) => void;

  public get promise(): Promise<TEntity> {
    return this._promise;
  };

  public set promise(promise: Promise<TEntity>) {
    this._promise = promise;
  };

  public get resolve(): (value?: TEntity | PromiseLike<TEntity> | undefined) => void {
    return this._resolve;
  };

  public get reject(): (reason?: any) => void {
    return this._reject;
  };

  public setPromise(promise?: Promise<TEntity>): void {
    if (promise) {
      const valve = this.initValve();
      this._promise = promise.then(() => valve);
    } else {
      this._promise = this.initValve();
    }
  }

  private initValve(): Promise<TEntity> {
    return new Promise<TEntity>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }
}