export const labels = {
  yourName: "Your name:",
  yourEmail: "Your email:",
  yourMessage: "Your message:",
  yourContactNumberOptional: "Your contact number (optional):",
  companyName: "Company name:",
  companyWebsiteSocialMedia: "Company website/social media:",
  youMayAttach5Files: "You may attach up to 5 files",
  address1: "Address 1:",
  address2Optional: "Address 2 (optional):",
  city: "City:",
  country: "Country:",
  stateProvince: "State/Province:",
  zipPostCode: "Zip/Post code:",
};
