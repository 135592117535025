import React, { FC } from "react";
import { authorizationService } from "shared-auth";
import { FullPageSpinner } from "shared/modules/Common/FullPageSpinner";

const Logout: FC = () => {
  authorizationService.signOutRedirect();

  return <FullPageSpinner />;
};

export default Logout;
