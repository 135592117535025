import { FacetListItem } from "../Facets";
import Measurement from "../Measurements/Measurement";

import BasicInfoLocation from "./BasicInfoLocation";

export default class BasicInfo {
  public profileId: string;
  public isPregnant: boolean;
  public pregnantUntil?: Date;
  public hasTwin: boolean;
  public makeUp?: string;
  public age?: string;
  public playingAgeFrom: number;
  public playingAgeTo: number;
  public isVisibleActualAge: boolean;
  public vocalAgeFrom?: number;
  public vocalAgeTo?: number;
  public measurements: Measurement[];
  public locations: BasicInfoLocation[];
  public facets: FacetListItem[];
  public hasDisability: boolean;
  public isVisibleDisability: boolean;
  public disabilityInfo: string;
  public genderInfo: string;
  public isVisibleGender: boolean;
  public isLicenseRequired?: boolean;
  public isVisibleHeritageAndEthnicity?: boolean;
  public isVisibleVisasAndPermits?: boolean;
  public heritageAndEthnicityInfo?: string;
  public hasPremiumCalendar?: string;
}
