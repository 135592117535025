import { GlobalStorage } from "app/generics";

export enum HALResources {
  getProfile = "HAL_GET_PROFILE",
  getGallery = "HAL_GET_GALLERY",
  patchGallery = "HAL_PATCH_GALLERY",
  getMainPhoto = "HAL_GET_MAIN_PHOTO",
  getPhotographers = "HAL_GET_PHOTOGRAPHERS",
}

const HAL_CLAIM_CONTAINER = "HAL_CLAIM_CONTAINER";

export class HALManager {
  public getProfileUrl = () => this.getHalFor(HALResources.getProfile);

  public getProfileByProfileIdUrl = (profileId: string) =>
    `/profiles/${profileId}`;

  public getGalleryUrl = () => this.getHalFor(HALResources.getGallery);

  public getGalleryByProfileIdUrl = (profileId: string) =>
    `/profiles/${profileId}/gallery`;

  public updateGalleryUrl = () => this.getHalFor(HALResources.patchGallery);

  public updateGalleryByProfileIdUrl = (profileId: string) =>
    `/profiles/${profileId}/gallery`;

  public getMainPhotoUrl = () => this.getHalFor(HALResources.getMainPhoto);

  public getMainPhotoByProfileIdUrl = (profileId: string) =>
    `/profiles/${profileId}/mainphoto`;

  public getPhotographersUrl = (photographerName: string) =>
    `/profiles/photographers/?namePart=${encodeURIComponent(photographerName)}`;

  public getProfileByViewPinUrl = (viewPin: string) =>
    `/profiles/view/${viewPin}`;

  //#region Skills

  public getSkillsUrl = (userId: string) => `/profiles/${userId}/skills`;

  public addSkillUrl = (userId: string) => `/profiles/${userId}/skills`;

  public updateSkillUrl = (userId: string, skillId: string) =>
    `/profiles/${userId}/skills/${skillId}`;

  public deleteSkillUrl = (userId: string, skillId: string) =>
    `/profiles/${userId}/skills/${skillId}`;

  //#endregion

  //#region SocialMedia

  public getSocialMediaUrl = (userId: string) => `/profiles/${userId}/social`;

  public updateSocialMediaUrl = (userId: string) =>
    `/profiles/${userId}/social`;

  //#endregion

  //#region AboutMe

  public getAboutMeUrl = (userId: string) => `/profiles/${userId}/aboutme`;

  public updateAboutMeUrl = (userId: string) => `/profiles/${userId}/aboutme`;

  ////#endregion

  //#region Credits

  public getCreditsUrl = (userId: string) => `/profiles/${userId}/credits`;

  public getCreditTypesUrl = () => `/profiles/productiontypes`;

  public patchCreditsUrl = (userId: string) => `/profiles/${userId}/credits`;

  public addCreditUrl = (userId: string) => `/profiles/${userId}/credits`;

  public updateCreditUrl = (userId: string, creditId: string) =>
    `/profiles/${userId}/credits/${creditId}`;

  public deleteCreditUrl = (userId: string, creditId: string) =>
    `/profiles/${userId}/credits/${creditId}`;

  ////#endregion

  //#region Headings

  public getHeadingsUrl = (userId: string) =>
    `/profiles/${userId}/creditheadings`;

  public patchHeadingsUrl = (userId: string) =>
    `/profiles/${userId}/creditheadings`;

  ////#endregion

  //#region Facets

  public getFacetCategoriesUrl = () => `/facets/categories`;

  ////#endregion

  //#region Training

  public getTrainingUrl = (userId: string) => `/profiles/${userId}/training`;

  public updateTrainingUrl = (userId: string) => `/profiles/${userId}/training`;

  ////#endregion

  //#region Profiles

  public shareProfile = (userId: string) => `/profiles/${userId}/share`;

  public getSettingsUrl = (userId: string) => `/profiles/${userId}/settings`;

  public patchSettingsUrl = (userId: string) => `/profiles/${userId}/settings`;

  public getBasicInfoUrl = (userId: string) => `/profiles/${userId}/basicinfo`;

  public patchBasicInfoUrl = (userId: string) =>
    `/profiles/${userId}/basicinfo`;

  public getFilmmakersSsoLinkUrl = (profileId: string) =>
    `/profiles/${profileId}/filmmakers-sso-link`;

  ////#endregion

  //#region Training

  public postAgencyUrl = () => "/agencies";

  public searchAgencyUrl = () => `/agencies`;

  public addAgencyUrl = (artistRef: string) =>
    `/performers/${artistRef}/agents`;

  public getAgencyUrl = (artistRef: string) =>
    `/performers/${artistRef}/agents`;

  public deleteAgencyUrl = (artistRef: string, agentRef: string) =>
    `/performers/${artistRef}/agents/${agentRef}`;

  public patchAgencyListUrl = (artistRef: string) =>
    `/performers/${artistRef}/agents`;

  public postLookingForRepUrl = (artistRef: string) =>
    `/performers/${artistRef}/setislookingforrep`;

  ////#endregion

  //#region Media

  public getMediaUrl = (profileId: string) => `/profiles/${profileId}/media`;

  public getMediaByIdUrl = (profileId: string, mediaId: string) =>
    `/profiles/${profileId}/media/${mediaId}`;

  public deleteMediaUrl = (profileId: string, mediaId: string) =>
    `/profiles/${profileId}/media/${mediaId}`;

  public patchMediaUrl = (profileId: string, mediaId: string) =>
    `/profiles/${profileId}/media/${mediaId}`;

  public postMediaUrl = (profileId: string) => `/profiles/${profileId}/media`;

  public getMediaObjectByIdUrl = (id: string) => `/media/${id}`;

  public deleteMediaObjectByIdUrl = (id: string) => `/media/${id}`;

  public getMediaObjectUrl = () => `/media`;

  public getMediaAllocationsUrl = (profileId: string) =>
    `/profiles/${profileId}/media/allocation`;

  ////#endregion

  // #region Shortlist

  public getShortListUrl = () => "/shortlists";

  public postShortListUrl = () => `/shortlists`;

  public postPerformerShortListUrl = (shortlistId: number) =>
    `/shortlists/${shortlistId}/performers`;

  ////#endregion

  // #region Casting

  public getCastingNotesUrl = (profileId: string, castingId: string) =>
    `/profiles/${profileId}/notes/${castingId}`;

  public putCastingNotesUrl = (profileId: string, castingId: string) =>
    `/profiles/${profileId}/notes/${castingId}`;

  ////#endregion

  // Availability Calendar

  public getAvailabilityCalendarUrl = (profileId: string) =>
    `/profiles/${profileId}/unavailability`;

  public postAvailabilityCalendarUrl = (profileId: string) =>
    `/profiles/${profileId}/unavailability`;

  public deleteAvailabilityCalendarUrl = (profileId: string, itemId: string) =>
    `/profiles/${profileId}/unavailability/${itemId}`;

  public setHalFor(
    resource: HALResources,
    value: string,
    uniquePrefix: string = ""
  ) {
    const halPair = { [resource + uniquePrefix]: value };
    GlobalStorage.updateValue(HAL_CLAIM_CONTAINER, halPair);
  }

  private getHalFor(resource: HALResources, uniquePrefix: string = "") {
    const halContainer = GlobalStorage.getValue<any>(HAL_CLAIM_CONTAINER);
    return halContainer[resource + uniquePrefix];
  }
}

export default new HALManager();
