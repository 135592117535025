import React from "react";
import { useEffect, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuid } from "uuid";
import cx from "classnames";
import { InputWrapper } from "component-library";

import { FileUploadState, IFileUpload, IFileUploadComplete } from "./Models";
import { TitaniumFileUploader } from "./TitaniumFileUploader";
import { IFileUploadService } from "./Services/TitaniumUploadService";
import { IFilePreviewTexts } from "./Components/FilePreview";
import { MediaAssetType } from "mediaModules/media/models";
import { GAEvents } from "mediaModules/media/components/GAEvents";
import MediaInfoService from "./Services/MediaInfoService";
import { ShimmeringStub } from "mediaModules/media/components/ShimmeringStub";

import "./TitaniumUploader.scss";
import { getVideoDurationInMs } from "../utils/media-utils";

export interface ITitaniumUploaderTextProps extends IFilePreviewTexts {
  dropzone: string;
  dropzoneMobile: string;
  uploadError: string;
  formatValidationError: string;
  removeButton: string;
  extensionError: string;
}

export interface IUploaderProps {
  uploadService: IFileUploadService;
  mediaType: MediaAssetType;
  accept?: string;
  onCompleteSuccess?: (
    fileUpload: IFileUploadComplete,
    durationInMs: number
  ) => void;
  onUploadStart?: (fileName?: string) => void;
  onFileRemove?: (id: string, callback: () => void) => Promise<void>;
  multiUpload?: boolean;
  validationMessage?: string;
  invalid?: boolean;
  preUploadValidation?: (durationInMs: number) => boolean;
}

export type ITitaniumUploaderProps = IUploaderProps &
  ITitaniumUploaderTextProps;

export const TitaniumUploader: React.FunctionComponent<
  ITitaniumUploaderProps
> = (props) => {
  const mediaInfoService: MediaInfoService = new MediaInfoService();

  const {
    onCompleteSuccess,
    onFileRemove,
    uploadService,
    multiUpload = true,
    onUploadStart,
    invalid,
    validationMessage,
    accept = "*",
  } = props;
  const [fileUploadList, setFileUploadList] = useState<IFileUpload[]>([]);
  const [validationData, setValidationData] = useState({
    invalid,
    validationMessage,
  });
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    initMediaInfoLib();
  }, []);

  useEffect(() => {
    setValidationData({ invalid, validationMessage });
  }, [invalid, validationMessage]);

  const initMediaInfoLib = async () => {
    setLoading(true);
    await mediaInfoService.init();
    setLoading(false);
  };

  const handleDropAccepted = async (files: File[]): Promise<void> => {
    const uList = await Promise.all(
      Array.from(files).map(async (file) => {
        const { format } = await mediaInfoService.getFileDetails(file);
        const durationInMs = await getVideoDurationInMs(file);

        const mimeType = file.type
          ? file.type
          : `${props.mediaType}/${format}`.toLowerCase();

        return {
          id: uuid(),
          uploadState: FileUploadState.NotStarted,
          percentComplete: 0,
          file,
          mimeType,
          ...(isNaN(durationInMs) ? {} : { durationInMs }),
        };
      })
    );

    const newUploadList = [...fileUploadList, ...uList];
    setFileUploadList(newUploadList);
  };

  const onDrop = useCallback((acceptedFiles) => {
    handleDropAccepted(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleUploadSuccess = (
    fileUploadCompleteInfo: IFileUploadComplete,
    durationInMs: number
  ) => {
    if (onCompleteSuccess) {
      onCompleteSuccess(fileUploadCompleteInfo, durationInMs);
    }
  };

  const handleUploadError = (validationMessage) => {
    GAEvents.addMediaAsset.trackFailedUploadMessage(props.mediaType);
    setValidationData({ invalid: true, validationMessage });
  };

  const handleUploadRetry = () => {
    setValidationData({ invalid: false, validationMessage: "" });
  };

  const handleUploadStart = (fileName?: string) => {
    if (onUploadStart) {
      onUploadStart(fileName);
    }
  };

  const handlePreUpload = ({ durationInMs }: IFileUpload) => {
    if (props.preUploadValidation && durationInMs) {
      return props.preUploadValidation(durationInMs);
    }

    return true;
  };

  const handleRemoveFile = async (id) => {
    const updatedFileUploadList = fileUploadList.filter(
      (file) => file.id !== id
    );
    if (onFileRemove) {
      setValidationData({ invalid: false, validationMessage: "" });
      await onFileRemove(id, () => {
        setFileUploadList(updatedFileUploadList);
      });
    }
  };

  const renderDropzone = () => {
    const isDropzoneHidden = !multiUpload && fileUploadList.length > 0;

    if (isDropzoneHidden) {
      return null;
    }

    return (
      <div
        className={cx("c-file-uploader__dropzone", {
          "c-file-uploader__dropzone--error": validationData.invalid,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} multiple={multiUpload} accept={accept} />
        <>
          {" "}
          <div className="c-file-uploader__text">{props.dropzone}</div>
          <div className="c-file-uploader__mobile-text">
            {props.dropzoneMobile}
          </div>
        </>
      </div>
    );
  };

  return (
    <div
      className={cx("c-file-uploader", {
        "c-file-uploader--error": validationData.invalid,
      })}
    >
      <InputWrapper
        validationMesssage={validationData.validationMessage}
        invalid={validationData.invalid ? "error" : undefined}
      >
        {isLoading ? (
          <ShimmeringStub>
            <div className="c-file-uploader__loading" />
          </ShimmeringStub>
        ) : (
          renderDropzone()
        )}

        <div
          className={cx("c-file-uploader__files", {
            "c-file-uploader__files--error":
              validationData.invalid && fileUploadList.length,
          })}
        >
          {fileUploadList.length < 1 ? null : (
            <ul>
              {fileUploadList.map((file) => (
                <li key={file.id}>
                  <TitaniumFileUploader
                    {...props}
                    uploadService={uploadService}
                    mediaType={props.mediaType}
                    onUploadStart={handleUploadStart}
                    onComplete={handleUploadSuccess}
                    onFileRemove={handleRemoveFile}
                    onUploadError={handleUploadError}
                    onUploadRetry={handleUploadRetry}
                    onPreUpload={handlePreUpload}
                    key={file.id}
                    preloadedFile={file}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </InputWrapper>
    </div>
  );
};
