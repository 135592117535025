import React from "react";
import { BreadcrumbBar, IBreadcrumbBarItem } from "component-library";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getLocalUrl } from "common";

import { UnregisterCallback } from "history";
import { breadcrumbsFallbackTranslations } from "app/store/fallbackTranslations";
import { UserRoleManager } from "app/core";
import { UserManager, UserClaimTypes } from "core";

export interface IBreadcrumbsContent {
  editCreditsPageName?: string;
  contactsPageName?: string;
  videoPageName?: string;
  audioPageName?: string;
  agentHomePageUrl?: string;
  performerHomePageUrl?: string;
  spotlightHomePageUrl?: string;
  homePageName?: string;
  usernamePostfix?: string;
}
export const IBreadcrumbsContentKeys = [
  "editCreditsPageName",
  "contactsPageName",
  "videoPageName",
  "audioPageName",
  "agentHomePageUrl",
  "performerHomePageUrl",
  "spotlightHomePageUrl",
  "homePageName",
  "usernamePostfix",
];

export interface IBreadcrumbsProps extends RouteComponentProps {
  content: IBreadcrumbsContent;
  isOnlyHomeLink?: boolean;
}

declare type BreadcrumbItem = {
  key: string;
  name: string;
  url?: string;
  children?: BreadcrumbItem[];
  handleClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

export class Breadcrumbs extends React.Component<IBreadcrumbsProps, any> {
  private unregisterCallback: UnregisterCallback;

  public render() {
    const links = [...this.breadCrumbsHead(), ...this.buildBreadCrumbsSet()];
    if (this.props.isOnlyHomeLink) {
      return (
        <BreadcrumbBar
          items={[
            {
              text:
                this.props.content.homePageName ||
                breadcrumbsFallbackTranslations.myAccountPageTitle,
              url: this.getHomePageUrl(),
            },
          ]}
        />
      );
    }
    return <BreadcrumbBar items={links} />;
  }

  public componentWillUnmount() {
    this.unregisterCallback && this.unregisterCallback();
  }

  private buildBreadCrumbsSet = () => {
    const url = this.props.location.pathname;
    const urlParts = url.split("/").filter(Boolean);
    urlParts.pop();

    return this.parseUrlToCrumbs(urlParts, 0, this.getRoutes()).filter(
      (item) => item.text
    );
  };

  private breadCrumbsHead = () => {
    const { content } = this.props;

    const profileName = UserManager.getClaim(UserClaimTypes.profileFullName);

    const head: IBreadcrumbBarItem[] = [
      {
        text:
          content.homePageName ||
          breadcrumbsFallbackTranslations.myAccountPageTitle,
        url: this.getHomePageUrl(),
      },
      {
        text: `${profileName} ${content.usernamePostfix}`,
        url: getLocalUrl(),
        handleClick: (e) =>
          void e.preventDefault() || this.props.history.push(getLocalUrl()),
      },
    ];

    return head;
  };

  private parseUrlToCrumbs = (
    urlParts: string[],
    depth: number,
    dictionary: BreadcrumbItem[],
    crumbs: IBreadcrumbBarItem[] = []
  ): IBreadcrumbBarItem[] => {
    const currentPart = urlParts[depth];

    if (currentPart) {
      const items = dictionary.filter((item) => {
        return (
          item.key === currentPart ||
          (item.key === currentPart + "/*" && depth + 1 < urlParts.length)
        );
      });

      if (items.length === 1) {
        depth++;
        const selectedRoute = items[0];

        crumbs.push({
          text: selectedRoute.name,
          url: selectedRoute.url ? selectedRoute.url : "",
          handleClick: selectedRoute.handleClick,
        });

        if (urlParts.length > depth && selectedRoute.children) {
          crumbs = this.parseUrlToCrumbs(
            urlParts,
            depth,
            selectedRoute.children,
            crumbs
          );
        }
      } else if (urlParts.length > depth + 1 && items.length > 0) {
        const selectedRoute = items.find(
          (item) => item.key === currentPart + "/*"
        );

        if (selectedRoute && selectedRoute.children) {
          depth += 2;
          crumbs.push({
            text: selectedRoute.name,
            url: selectedRoute.url ? selectedRoute.url : "",
            handleClick: selectedRoute.handleClick,
          });
          crumbs = this.parseUrlToCrumbs(
            urlParts,
            depth,
            selectedRoute.children,
            crumbs
          );
        }
      } else if (items.length === 0) {
        const wildcardSection = dictionary.find((item) => item.key === "*");
        if (wildcardSection && wildcardSection.children) {
          crumbs = this.parseUrlToCrumbs(
            urlParts,
            ++depth,
            wildcardSection.children,
            crumbs
          );
        }
      }
    }

    return crumbs;
  };

  private getHomePageUrl = () => {
    const { content } = this.props;

    let url = "";
    const userRole = (UserRoleManager.getUserRole() || "").toLowerCase();

    switch (userRole) {
      case "spotlight":
        url = content.spotlightHomePageUrl || "/myhome";
        break;
      case "agent":
        url = content.agentHomePageUrl || "/myhome";
        break;
      case "performer":
        url = content.performerHomePageUrl || "/profile";
        break;
    }

    return url;
  };

  private handleClick = (url?: string) => {
    const { history } = this.props;

    return (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      if (url) {
        const distention = getLocalUrl(url);
        if (this.props.location.pathname !== distention) {
          history.push(distention);
        }
      }
    };
  };

  private getRoutes = (): BreadcrumbItem[] => {
    const { content } = this.props;

    return [
      {
        key: "*",
        url: "",
        name: "",
        children: [
          {
            key: "credits",
            name:
              content.editCreditsPageName ||
              breadcrumbsFallbackTranslations.creditsPageTitle,
            handleClick: this.handleClick("credits"),
          },
          {
            key: "credit",
            handleClick: this.handleClick("credits"),
            name:
              content.editCreditsPageName ||
              breadcrumbsFallbackTranslations.creditsPageTitle,
          },
          {
            key: "contact",
            handleClick: this.handleClick("contacts"),
            name:
              content.contactsPageName ||
              breadcrumbsFallbackTranslations.contactsPageTitle,
          },
          {
            key: "media",
            name: "",
            children: [
              {
                key: "video",
                handleClick: this.handleClick("media/video"),
                name:
                  content.videoPageName ||
                  breadcrumbsFallbackTranslations.videoPageTitle,
              },
              {
                key: "audio",
                handleClick: this.handleClick("media/audio"),
                name:
                  content.audioPageName ||
                  breadcrumbsFallbackTranslations.audioPageTitle,
              },
            ],
          },
        ],
      },
    ];
  };
}

export default withRouter(Breadcrumbs);
