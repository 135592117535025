import React from "react";
import { Spinner } from "component-library";

import "./FullScreenSpinner.scss";

export const FullScreenSpinner: React.FC = () => {
  return (
    <div className="c-full-screen-spinner">
      <Spinner />
    </div>
  );
};
