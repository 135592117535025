export const LOG_DOMAIN = {
  uploadingPhoto: 'UPLOADING_PHOTO',
  mainPhotoSection: 'MAIN_PHOTO_SECTION',
  galleryPhotoSection: 'GALLERY_PHOTO_SECTION',
  changeMainPhoto: 'CHANGE_MAIN_PHOTO',
  masterPage: 'MASTER_PAGE',
  mainPhoto: 'MAIN_PHOTO',
  galleryPhoto: 'GALLERY_PHOTO',
  gallery: 'GALLERY',
  photoEdit: 'PHOTO_EDIT',
  basicInfo: 'BASIC_INFO',
  editCredit: 'EDIT_CREDIT',
  creditList: 'CREDIT_LIST',
  addNewAgency: 'ADD_NEW_AGENCY',
  addAgency: 'ADD_AGENCY',
  agencies: 'AGENCIES',
  galleryPage: 'GALLERY_PAGE',
  agenciesHelp: 'AGENCIES_HELP',
  mainPage: 'MAIN_PAGE',
  linkToProfilePage: 'LINK_TO_PROFILE',
}

export const LOG_ACTION_DICTIONARY = {
  galleryPage: {
    closeGalleryPageAction: 'GALLERY_PAGE_CLOSE_GALLERY_PAGE',
    showHelpAndAdviceAction:'GALLERY_PAGE_SHOW_HELP_AND_ADVICE',
    openMainPhotoViewerAction:'GALLERY_PAGE_OPEN_MAIN_PHOTO_VIEWER',
    openGalleryPhotoViewerAction:'GALLERY_PAGE_OPEN_GALLERY_PHOTO_VIEWER',
    openAddPhotoFlowAction:'GALLERY_PAGE_OPEN_ADD_PHOTO_FLOW',
    loadGalleryPhotosAction:'GALLERY_PAGE_LOAD_GALLERY_PHOTOS',
  },
  agencies: {
    addAgencyAction: 'AGENCIES_ADD_AGENCY',
    redirectToCVAction: 'AGENCIES_RETURN_TO_CV',
    closeFormAction: 'AGENCIES_CLOSE_FORM',
    deleteAgencyAction: 'AGENCIES_DELETE_AGENCY',
    closeNewAgencyWarningAction: 'AGENCIES_CLOSE_NEW_AGENCY_WARNING',
  },
  agenciesHelp:{
    closeFormAction: 'AGENCIES_HELP_CLOSE_FORM',
    addAgent: 'AGENCIES_HELP_ADD_AGENCY',
    cancelMembershipAction: 'AGENCIES_HELP_CANCEL_MEMBERSHIP'
  },
  addAgency: {
    closeFormAction: 'ADD_AGENCY_CLOSE_FORM',
    cancelAddingAction: 'ADD_AGENCY_CANCEL_ADDING',
    tryCancelAddingAction: 'ADD_AGENCY_TRY_CANCEL_ADDING',
    addAgencyAction: 'ADD_AGENCY_ADD_AGENCY',
    addNewAction: 'ADD_AGENCY_ADD_NEW_FORM',
    selectAgencyAction: 'ADD_AGENCY_SELECT_Agency',
  },
  addNewAgency: {
    closeFormAction: 'ADD_NEW_AGENCY_CLOSE_FORM',
    tryCancelAddingAction: 'ADD_NEW_AGENCY_TRY_CANCEL_ADDING',
    saveFormAction: 'ADD_NEW_AGENCY_SAVE_FORM',
    continueAddingAction: 'ADD_NEW_AGENCY_CONTINUE_ADDING',
    cancelEditingAction: 'ADD_NEW_AGENCY_CANCEL_EDITING',
  },
  creditList: {
    deleteCreditAction: 'CREDIT_LIST_DELETE',
    reorderListAction: 'CREDIT_LIST_REORDER',
    saveHeadingsAction: 'CREDIT_LIST_SAVE_HEADINGS',
    redirectToCVAction: 'CREDIT_LIST_REDIRECT_TO_CV',
  },
  editCredit: {
    closeFormAction: 'EDIT_CREDIT_CLOSE',
    saveFormAction: 'EDIT_CREDIT_SAVE',
    continueFormAction: 'EDIT_CREDIT_CONTINUE_EDITING',
    cancelSavingAction: 'EDIT_CREDIT_CANCEL_SAVE',
  },
  gallery: {
    movePhotoUpAction: 'GALLERY_MOVE_PHOTO_UP',
    movePhotoDownAction: 'GALLERY_MOVE_PHOTO_DOWN',
    editPhotoAction: 'GALLERY_EDIT_PHOTO',
    deletePhotosAction: 'GALLERY_DELETE_PHOTOS',
    hidePhotosAction: 'GALLERY_HIDE_PHOTOS',
    keyPressAction: 'GALLERY_KEY_PRESS',
    addPhotoAction: 'GALLERY_ADD_PHOTO',
    openPhotoAction: 'GALLERY_OPEN_PHOTO'
  },
  mainPhotoSection: {
    openPhotoAction: 'MAIN_PHOTO_SECTION_OPEN_PHOTO',
    editPhotoAction: 'MAIN_PHOTO_SECTION_EDIT_PHOTO',
  },
  photoEdit: {
    closePopupAction: 'PHOTO_EDIT_CLOSE',
    photoEditorActions: {
      savePhoto: 'PHOTO_EDIT_PHOTO_EDITOR_SAVE_PHOTO',
      cancelEditing: 'PHOTO_EDIT_PHOTO_EDITOR_CANCEL_EDITING',
      showHelp: 'PHOTO_EDIT_PHOTO_EDITOR_SHOW_HELP',
    },
    cancelEditingActions: {
      backToPhotoEditing: 'PHOTO_EDIT_CANCEL_EDITING_BACK_TO_PHOTO',
      close: 'PHOTO_EDIT_CANCEL_EDITING_CLOSE',
      savePhoto: 'PHOTO_EDIT_CANCEL_EDITING_SAVE_PHOTO',
    },
    savePhotoActions: {
      backToPhotoEditing: 'PHOTO_EDIT_SAVE_PHOTO_BACK_TO_PHOTO',
      close: 'PHOTO_EDIT_SAVE_PHOTO_CLOSE',
      savePhoto: 'PHOTO_EDIT_SAVE_PHOTO_SAVE_PHOTO',
    },
    errorActions: {
      backToPhotoEditing: 'PHOTO_EDIT_ERROR_BACK_TO_PHOTO',
    },
  },
  galleryPhoto: {
    closePopupAction: 'GALLERY_PHOTO_CLOSE',
    viewPhotoActions: {
      replacePhoto: 'GALLERY_PHOTO_VIEW_PHOTO_REPLACE_PHOTO',
      editPhoto: 'GALLERY_PHOTO_VIEW_PHOTO_EDIT_PHOTO',
      close: 'GALLERY_PHOTO_VIEW_PHOTO_CLOSE',
      showHelp: 'GALLERY_PHOTO_VIEW_PHOTO_SHOW_HELP',
      deletePhoto: 'GALLERY_PHOTO_VIEW_PHOTO_DELETE_PHOTO',
      savePhoto: 'GALLERY_PHOTO_VIEW_PHOTO_SAVE_PHOTO',
    },
    deletePhotoActions: {
      deletePhoto: 'GALLERY_PHOTO_DELETE_PHOTO_DELETE_PHOTO',
      backToView: 'GALLERY_PHOTO_BACK_TO_VIEW_BACK_TO_VIEW',
    }
  },
  mainPhoto: {
    closePopupAction: 'MAIN_PHOTO_CLOSE',
    viewPhotoActions: {
      replacePhoto: 'MAIN_PHOTO_VIEW_PHOTO_REPLACE_PHOTO',
      editPhoto: 'MAIN_PHOTO_VIEW_PHOTO_EDIT_PHOTO',
      close: 'MAIN_PHOTO_VIEW_PHOTO_CLOSE',
      showHelp: 'MAIN_PHOTO_VIEW_PHOTO_SHOW_HELP',
    }
  },
  changeMainPhoto: {
    closePopupAction: 'CHANGE_MAIN_PHOTO_CLOSE',
    galleryActions: {
      cancel: 'CHANGE_MAIN_PHOTO_GALLERY_CANCEL',
      changeMainPhoto: 'CHANGE_MAIN_PHOTO_GALLERY_CHANGE_MAIN_PHOTO',
      addPhoto: 'CHANGE_MAIN_PHOTO_GALLERY_ADD_PHOTO',
    },
    confirmActions: {
      cancel: 'CHANGE_MAIN_PHOTO_CONFIRM_CANCEL',
      save: 'CHANGE_MAIN_PHOTO_CONFIRM_SAVE',
    }
  },
  uploadingPhoto: {
    closePopupAction: 'UPLOADING_PHOTO_CLOSE_POPUP',
    photoToolActions: {
      save: 'UPLOADING_PHOTO_TOOL_SAVE',
      cancel: 'UPLOADING_PHOTO_TOOL_CANCEL',
    },
    cancelPhotoActions: {
      discard: 'UPLOADING_PHOTO_CANCEL_PHOTO_DISCARD',
      backToEdit: 'UPLOADING_PHOTO_CANCEL_PHOTO_BACK_TO_EDIT',
      save: 'UPLOADING_PHOTO_CANCEL_PHOTO_SAVE',
    },
    confirmPhotoActions: {
      discard: 'UPLOADING_PHOTO_CONFIRM_PHOTO_DISCARD',
      backToEdit: 'UPLOADING_PHOTO_CONFIRM_PHOTO_BACK_TO_EDIT',
      continue: 'UPLOADING_PHOTO_CONFIRM_PHOTO_CONTINUE',
    },
    photoCreditsFormActions: {
      save: 'UPLOADING_PHOTO_PHOTO_FORM_SAVE',
      cancel: 'UPLOADING_PHOTO_PHOTO_FORM_CANCEL',
    },
    photoErrorActions: {
      backToEdit: 'UPLOADING_PHOTO_PHOTO_ERROR_BACK_TO_EDIT',
    }
  }
}
