import diff from "json-patch-gen";
import clone from "clone";

export interface AddOperation {
  op: "add";
  path: string;
  value: any;
}

export interface RemoveOperation {
  op: "remove";
  path: string;
}

export interface ReplaceOperation {
  op: "replace";
  path: string;
  value: any;
}

export interface MoveOperation {
  op: "move";
  from: string;
  path: string;
}

export interface CopyOperation {
  op: "copy";
  from: string;
  path: string;
}

export interface TestOperation {
  op: "test";
  path: string;
  value: any;
}

export declare type JsonPatchOperation =
  | AddOperation
  | RemoveOperation
  | ReplaceOperation
  | MoveOperation
  | CopyOperation
  | TestOperation;

export class ObjectPath {
  public static from(path: ReadonlyArray<string | number>): ObjectPath {
    return new ObjectPath(path);
  }

  constructor(private readonly path: ReadonlyArray<string | number>) {}

  public get(obj: any): any {
    this.ensurePathValid(obj);

    let curr = obj;
    for (const part of this.path) {
      curr = curr[part];
    }

    return curr;
  }

  public toString(separator: string = "/") {
    return this.path.join(separator);
  }

  public isEmpty(): boolean {
    return this.path.length === 0;
  }

  private ensurePathValid(obj: any, mustExists: boolean = true): void {
    let curr = obj;

    for (const part of this.path) {
      if (Array.isArray(curr)) {
        if (
          typeof part === "number" &&
          Math.trunc(part) === part &&
          (curr.length > part || !mustExists)
        ) {
          curr = curr[part];
        } else {
          throw new Error("Path invalid");
        }
      } else {
        if ((typeof curr === "object" && part in curr) || !mustExists) {
          curr = curr[part];
        } else {
          throw new Error("Path invalid");
        }
      }
    }
  }
}

export interface IOrderPatchOptions {
  equals: (left: any, right: any) => boolean;
  path: ObjectPath;
}

export class JsonPatchOrderGeneratorWrapper {
  public generate(
    fromObj: any,
    toObj: any,
    options: IOrderPatchOptions[] = []
  ): JsonPatchOperation[] {
    const ops: JsonPatchOperation[] = [];

    let fromCopy = clone(fromObj);

    for (const { path, equals } of options) {
      if (
        !Array.isArray(path.get(fromCopy)) ||
        !Array.isArray(path.get(toObj))
      ) {
        throw new Error(
          '"from" and "to" objects must be array to use ordering'
        );
      }

      const removeOps = this.generateRemoveOperation(
        path.get(fromCopy),
        path.get(toObj),
        equals
      ).map((op) => ({
        op: op.op,
        path: `${path.isEmpty() ? "" : "/"}${path}${op.path}`,
      }));
      ops.push(...removeOps);

      const addOps = this.generateAddOperation(
        path.get(fromCopy),
        path.get(toObj),
        equals
      ).map((op) => ({
        op: op.op,
        path: `${path.isEmpty() ? "" : "/"}${path}${op.path}`,
        value: op.value,
      }));
      ops.push(...addOps);

      const orderOps = this.generateOrderPatch(
        path.get(fromCopy),
        path.get(toObj),
        equals
      ).map((op) => ({
        op: op.op,
        from: `${path.isEmpty() ? "" : "/"}${path}${op.from}`,
        path: `${path.isEmpty() ? "" : "/"}${path}${op.path}`,
      }));
      ops.push(...orderOps);
    }

    ops.push(...diff(fromCopy, toObj));

    return ops;
  }

  private generateOrderPatch<TObj>(
    fromArray: TObj[],
    toArray: TObj[],
    equals: (left: TObj, right: TObj) => boolean
  ): MoveOperation[] {
    const ops: MoveOperation[] = [];

    for (let toIndex = 0; toIndex < toArray.length; toIndex += 1) {
      const fromIndex = fromArray.findIndex((obj) =>
        equals(obj, toArray[toIndex])
      );

      if (fromIndex === -1) {
        continue;
      }

      if (fromIndex === toIndex) {
        continue;
      }

      const op: MoveOperation = {
        op: "move",
        from: `/${fromIndex}`,
        path: `/${toIndex}`,
      };

      ops.push(op);

      const movedItem = fromArray.splice(fromIndex, 1)[0];
      fromArray.splice(toIndex, 0, movedItem);
    }

    return ops;
  }

  private generateRemoveOperation<TObj>(
    fromArray: TObj[],
    toArray: TObj[],
    equals: (left: TObj, right: TObj) => boolean
  ): RemoveOperation[] {
    const ops: RemoveOperation[] = [];

    for (let fromIndex = fromArray.length - 1; fromIndex >= 0; fromIndex--) {
      const item = fromArray[fromIndex];

      const toIndex = toArray.findIndex((obj) => equals(obj, item));

      if (toIndex > -1) {
        continue;
      }

      const op: RemoveOperation = {
        op: "remove",
        path: `/${fromIndex}`,
      };

      ops.push(op);

      fromArray.splice(fromIndex, 1);
    }

    return ops;
  }

  private generateAddOperation<TObj>(
    fromArray: TObj[],
    toArray: TObj[],
    equals: (left: TObj, right: TObj) => boolean
  ): AddOperation[] {
    const ops: AddOperation[] = [];

    for (let toIndex = 0; toIndex < toArray.length; toIndex += 1) {
      const fromIndex = fromArray.findIndex((obj) =>
        equals(obj, toArray[toIndex])
      );

      if (fromIndex > -1) {
        continue;
      }

      const op: AddOperation = {
        op: "add",
        path: `/${toIndex}`,
        value: clone(toArray[toIndex]),
      };

      ops.push(op);

      fromArray.splice(toIndex, 0, toArray[toIndex]);
    }

    return ops;
  }
}
