export interface IFetchInterceptor {
  request?(request: IRequestObject): Promise<IRequestObject> | IRequestObject;
  requestError?(error: any): Promise<any>;
  response?(response: Response): Response | Promise<Response>;
  responseError?(error: any): Promise<any>;
}

export interface IRequestObject {
  url: string;
  config?: RequestInit;
};

export class FetchInterceptor {
  private static requestChain: { request?: (request: IRequestObject) => Promise<IRequestObject> | IRequestObject, requestError?: (error: any) => Promise<any> }[] = [];
  private static responseChain: { response?: (response: Response) => Response | Promise<Response>, responseError?: (error: any) => Promise<any> }[] = [];
  private static readonly originalFetch = window.fetch && window.fetch.bind(window);

  public static register(interceptor: IFetchInterceptor) {

    if (interceptor.request || interceptor.requestError) {
      FetchInterceptor.requestChain.push({ request: interceptor.request, requestError: interceptor.requestError })
    }

    if (interceptor.response || interceptor.responseError) {
      FetchInterceptor.responseChain.push({ response: interceptor.response, responseError: interceptor.responseError })
    }

    const newFetch = (url: string, config: RequestInit) => {
      let fetchRequest = Promise.resolve({ url: url, config: config });

      FetchInterceptor.requestChain.forEach((listeners) => {
        fetchRequest = fetchRequest.then((request: IRequestObject) => listeners.request ? listeners.request(request) : Promise.resolve(request), listeners.requestError);
      });

      let fetchResponse = fetchRequest.then((request: IRequestObject) => FetchInterceptor.originalFetch(request.url, request.config))

      FetchInterceptor.responseChain.forEach((listeners) => {
        fetchResponse = fetchResponse.then(listeners.response, listeners.responseError);
      });

      return fetchResponse;
    }

    window.fetch = newFetch;
  }
}
