import React from "react";
import {
  ActionButton,
  Button,
  Checkbox,
  debounce,
  Helper,
  Stub,
} from "component-library";

import { GlobalAlertMessage } from "shared/modules/Common/GlobalAlert";

import { Photo } from "models";

import { PhotoContainer } from "components/flows/Common/PhotoContainer";

import {
  getHelpAndAdvice,
  IViewGalleryPhotoContent,
} from "./GalleryPhotoViewContent";

import "./GalleryPhotoView.scss";

export interface IGalleryPhotoViewProps {
  photo?: Photo;
  close: () => void;
  showHelpPopup: (message: GlobalAlertMessage) => void;
  savePhoto: (photo: Photo, isMainPhoto: boolean) => void;
  editPhoto: () => void;
  deletePhoto: () => void;
  error?: string;
  content: IViewGalleryPhotoContent;
}

interface IGalleryPhotoViewState {
  photo?: Photo;
  isPhotoHidden: boolean;
  isMakeMainPhotoChecked: boolean;
  photoHasLoaded: boolean;
  hasError: boolean;
}

export default class GalleryPhotoView extends React.Component<
  IGalleryPhotoViewProps,
  IGalleryPhotoViewState
> {
  private containerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private headerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private toolBarRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private imageContainerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();

  constructor(props: IGalleryPhotoViewProps) {
    super(props);

    window.addEventListener("resize", this.adaptImageContainerDebounced, false);
    window.addEventListener(
      "orientationchange",
      this.adaptImageContainerDebounced,
      false
    );

    this.state = {
      photo: props.photo,
      isPhotoHidden: Boolean(props.photo && props.photo.isHidden),
      isMakeMainPhotoChecked: false,
      photoHasLoaded: false,
      hasError: false,
    };
  }

  public render() {
    const { content, error } = this.props;
    const { isPhotoHidden } = this.state;

    let photo = this.props.photo;

    if (photo) {
      photo = { ...photo, isHidden: isPhotoHidden };
    }

    return (
      <div ref={this.containerRef} className="c-gallery-photo-view">
        <div ref={this.headerRef} className="c-gallery-photo-view__title">
          {this.props.content.header}
        </div>
        <div
          ref={this.imageContainerRef}
          className="c-gallery-photo-view__image-container"
        >
          {!error ? (
            <PhotoContainer
              photo={photo}
              texts={{ hiddenOverlayTitle: content.hiddenOverlayTitle }}
              loadComplete={this.loadComplete}
              errorHandler={this.errorHandler}
            />
          ) : (
            <Stub text={error} />
          )}
        </div>
        <div ref={this.toolBarRef} className="c-gallery-photo-view__tools">
          {this.renderToolBar()}
          <div className="c-gallery-photo-view__additional-panel">
            <Checkbox
              htmlId="galleryPhotoViewCheckbox"
              name=""
              value=""
              valueChanged={this.changeMainPhotoFlag}
              label={content.profilePictureCheckboxName}
              ariaLabel={content.profilePictureCheckboxName}
            />
          </div>
          {this.renderButtons()}
        </div>
      </div>
    );
  }

  public static getDerivedStateFromProps(
    nextProps: IGalleryPhotoViewProps,
    prevState: IGalleryPhotoViewState
  ) {
    let stateUpdates: Partial<IGalleryPhotoViewState> = {};

    if (nextProps.photo !== prevState.photo) {
      stateUpdates = {
        photo: nextProps.photo,
        isPhotoHidden: Boolean(nextProps.photo && nextProps.photo.isHidden),
      };
    }

    return stateUpdates;
  }

  public componentWillUnmount() {
    window.addEventListener("resize", this.adaptImageContainerDebounced, false);
    window.addEventListener(
      "orientationchange",
      this.adaptImageContainerDebounced,
      false
    );
  }

  private renderToolBar = () => {
    const { error, content, deletePhoto, editPhoto } = this.props;
    const { photoHasLoaded, isMakeMainPhotoChecked, isPhotoHidden } =
      this.state;

    const isEditButtonDisabled =
      isPhotoHidden || Boolean(error) || !photoHasLoaded;
    const isDeleteButtonDisabled = isMakeMainPhotoChecked;
    const isHideButtonDisabled =
      isMakeMainPhotoChecked || Boolean(error) || !photoHasLoaded;

    return (
      <div className="c-gallery-photo-view__tool-bar">
        <ActionButton
          onClick={editPhoto}
          label={content.editButton}
          ariaLabel={content.editButton}
          icon="edit"
          disabled={Boolean(isEditButtonDisabled)}
          variant="toolbar"
        />
        <ActionButton
          onClick={deletePhoto}
          label={content.deleteButton}
          ariaLabel={content.deleteButton}
          icon="delete"
          disabled={Boolean(isDeleteButtonDisabled)}
          variant="toolbar"
        />
        <ActionButton
          onClick={this.toggleHidePhoto}
          label={content.hideButton}
          ariaLabel={content.hideButton}
          icon="hide"
          disabled={Boolean(isHideButtonDisabled)}
          variant="toolbar"
        />
      </div>
    );
  };

  private renderButtons = () => {
    const { photo, error, content, close } = this.props;
    const { photoHasLoaded } = this.state;

    const isButtonDisabled =
      Boolean(error) || !Boolean(photo) || !photoHasLoaded;

    return (
      <div className="c-gallery-photo-view__buttons-container">
        <div className="c-gallery-photo-view__help g-hidden-sm g-hidden-md">
          <Helper {...content.helper} click={this.showHelpAndAdvice} />
        </div>
        <div className="c-gallery-photo-view__buttons">
          <div className="c-gallery-photo-view__button">
            <Button type="secondary" text={content.close} onClick={close} />
          </div>
          <div className="c-gallery-photo-view__button">
            <Button
              type="primary"
              disabled={isButtonDisabled}
              text={content.save}
              onClick={this.save}
            />
          </div>
        </div>
      </div>
    );
  };

  private loadComplete = () => this.setState({ photoHasLoaded: true });

  private errorHandler = () => this.setState({ hasError: true });

  private changeMainPhotoFlag = (isMakeMainPhotoChecked: boolean) => {
    let { isPhotoHidden } = this.state;

    if (isMakeMainPhotoChecked) {
      isPhotoHidden = false;
    }

    this.setState({ isMakeMainPhotoChecked, isPhotoHidden });
  };

  private toggleHidePhoto = () =>
    this.setState({ isPhotoHidden: !this.state.isPhotoHidden });

  private save = () => {
    const { isPhotoHidden, isMakeMainPhotoChecked } = this.state;
    const { photo, savePhoto } = this.props;

    if (photo) {
      savePhoto(
        {
          ...photo,
          isHidden: isPhotoHidden,
        },
        isMakeMainPhotoChecked
      );
    }
  };

  private showHelpAndAdvice = () =>
    this.props.showHelpPopup(getHelpAndAdvice(this.props.content.helpAdvice));

  private adaptImageContainer = () => {
    const toolBar = this.toolBarRef.current;
    const imageContainer = this.imageContainerRef.current;
    const container = this.containerRef.current;
    const header = this.headerRef.current;

    if (imageContainer && container && toolBar && header) {
      const containerStyles = window.getComputedStyle(container, null);
      const containerPaddings =
        parseFloat(containerStyles.getPropertyValue("padding-top")) +
        parseFloat(containerStyles.getPropertyValue("padding-bottom"));
      const occupiedHeight =
        toolBar.offsetHeight + header.offsetHeight + containerPaddings;

      imageContainer.style.maxHeight = `calc(100vh - ${occupiedHeight}px)`;
    }
  };

  private adaptImageContainerDebounced = debounce(
    this.adaptImageContainer,
    300
  );
}
