import {
  withLDConsumer,
  AllFlagsLDClient,
} from "launchdarkly-react-client-sdk";

export { default as LaunchDarklyProvider } from "./LaunchDarklyProvider";
export { default as useLaunchDarklyFlags } from "./useLaunchDarklyFlags";
// to be consistent re-exporting withLDConsumer, AllFlagsLDClient
export const withLaunchDarklyConsumerHOC = withLDConsumer;
export type LaunchDarklyClientWithFlags = AllFlagsLDClient;
export { FEATURE_FLAGS } from "./utils";
