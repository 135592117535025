import React from "react";

import { FlowsFactory } from "flows";
import {
  withGlobalContext,
  IGlobalContextProps,
} from "shared/contexts/GlobalContext";
import { PopupPlaceholder } from "components/common/PopupPlaceholder";

export interface ICommonSectionProps extends IGlobalContextProps {}

export class CommonSection extends React.Component<ICommonSectionProps> {
  public render() {
    return (
      <React.Fragment>
        <FlowsFactory />
        <PopupPlaceholder />
      </React.Fragment>
    );
  }
}

export default withGlobalContext(CommonSection);
