import React from "react";
import { Stub } from "component-library";

import { Overlay } from "components/common/Overlay";

import { getImageUrl } from "services/UrlBuilder";

import { BasePhoto, ImageLocation } from "models";

import "./Photo.scss";

export interface IPhotoProps {
  photo: BasePhoto;
  texts: {
    imageAlt: string;
    hiddenPhoto: string;
  };
  onClick: (photo: BasePhoto) => any;
  onError?: (photo: BasePhoto) => void;
}

interface IPhotoState {
  hasError: boolean;
}

export default class Photo extends React.Component<IPhotoProps, IPhotoState> {
  public constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  public render() {
    return (
      <a className="c-photo edit" onClick={this.onClick} tabIndex={0}>
        {this.renderImage()}
        {this.renderPhotoIdOverlay()}
        {this.props.photo.isHidden ? (
          <Overlay title={this.props.texts.hiddenPhoto} icon="icon-hide" />
        ) : null}
      </a>
    );
  }

  private renderPhotoIdOverlay = () => {
    const [, photoId] = this.props.photo.userFriendlyId.split("-");
    return <div className="c-photo__photo-id-overlay">ID:{photoId}</div>;
  };

  private renderImage = () => {
    const imageUrl = getImageUrl(
      this.props.photo.url,
      ImageLocation.GalleryPhoto
    );

    return !this.state.hasError ? (
      <img
        src={imageUrl}
        alt={this.props.texts.imageAlt}
        onError={this.showImagePlaceholder}
      />
    ) : (
      <Stub />
    );
  };

  private onClick = () => this.props.onClick(this.props.photo);

  private showImagePlaceholder = () =>
    this.setState(
      { hasError: true },
      () => this.props.onError && this.props.onError(this.props.photo)
    );
}
