import { History } from './History';
import { IPhotoModel } from './PhotoTool';
import { ToolState } from './ToolState';

export class PhotoToolState {
  public history: History;
  public image: IPhotoModel;
  public builtImage: IPhotoModel;
  public toolState: ToolState;
}
