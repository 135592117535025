import React from "react";
import {
  Checkbox,
  formatTime,
  transformMillisecToSec,
} from "component-library";

import {
  MediaAsset,
  TranscodeStatus,
  MediaAssetType,
} from "mediaModules/media/models";
import { GAEvents } from "mediaModules/media/components/GAEvents";
import { ShimmeringStub } from "mediaModules/media/components/ShimmeringStub";

import "./AudioItem.scss";

export interface IAudioItemProps {
  mediaAsset: MediaAsset;
  hiddenLabel: string;
  processingLabel: string;
  errorLabel: string;
  checkMediaAsset: (value: boolean) => void;
  playback: (mediaAsset: MediaAsset) => void;
  selectedMediaAsset: MediaAsset[];
  editMediaAsset: (mediaAsset?: MediaAsset) => void;
}

export const AudioItem: React.FC<IAudioItemProps> = (props) => {
  const {
    mediaAsset,
    playback,
    hiddenLabel,
    processingLabel,
    errorLabel,
    selectedMediaAsset,
    checkMediaAsset,
    editMediaAsset,
  } = props;

  const durationString = formatTime(
    transformMillisecToSec(mediaAsset.durationInMs),
    ":"
  );
  const isSelected = selectedMediaAsset.some(
    (item) => item.id === mediaAsset.id
  );

  const renderCheckbox = () => {
    return (
      <div className="c-audio-item__checkbox">
        <Checkbox
          htmlId="audioItemCheckbox"
          name={mediaAsset.title}
          ariaLabel={mediaAsset.title}
          valueChanged={checkMediaAsset}
          value={mediaAsset.title}
          checked={isSelected}
        />
      </div>
    );
  };

  const renderLoadingItem = () => {
    return (
      <div className="c-audio-item c-audio-item--loading">
        <ShimmeringStub>
          <div className="c-audio-item__info">
            <div className="c-audio-item__info-title">{mediaAsset.title}</div>
          </div>
        </ShimmeringStub>
      </div>
    );
  };

  const renderProcessingItem = () => {
    return (
      <div className="c-audio-item c-audio-item--processing">
        <div className="c-audio-item__status">
          <div className="c-audio-item__status-icon icon-processing" />
          <span className="c-audio-item__status-text">{processingLabel}</span>
        </div>
        <div className="c-audio-item__info">
          <div className="c-audio-item__info-title">{mediaAsset.title}</div>
        </div>
        {renderCheckbox()}
      </div>
    );
  };

  const renderErrorItem = () => {
    return (
      <div className="c-audio-item c-audio-item--error">
        <div className="c-audio-item__status">
          <div
            className="c-audio-item__status-icon icon-alarm"
            aria-label="Audio error"
          />
          <span className="c-audio-item__status-text">{errorLabel}</span>
        </div>
        <div className="c-audio-item__info">
          <div className="c-audio-item__info-title">{mediaAsset.title}</div>
        </div>
        {renderCheckbox()}
      </div>
    );
  };

  const renderHiddenItem = () => {
    return (
      <div className="c-audio-item c-audio-item--hidden">
        <div
          className="c-audio-item__status"
          onClick={() => playback(mediaAsset)}
        >
          <div className="c-audio-item__status-icon icon-hide" />
          <span className="c-audio-item__status-text">{hiddenLabel}</span>
        </div>
        <div
          className="c-audio-item__info"
          onClick={() => handleEditClick(mediaAsset)}
        >
          <div className="c-audio-item__info-title">{mediaAsset.title}</div>
          <div className="c-audio-item__info-duration">{durationString}</div>
        </div>
        {renderCheckbox()}
      </div>
    );
  };

  const renderCompleteItem = () => {
    return (
      <div className="c-audio-item c-audio-item--complete">
        <div
          className="c-audio-item__status"
          onClick={() => playback(mediaAsset)}
        >
          <div
            className="c-audio-item__status-icon icon-playsmall"
            aria-label="Play"
          />
        </div>
        <div
          className="c-audio-item__info"
          onClick={() => handleEditClick(mediaAsset)}
        >
          <div className="c-audio-item__info-title">{mediaAsset.title}</div>
          <div className="c-audio-item__info-duration">{durationString}</div>
        </div>
        {renderCheckbox()}
      </div>
    );
  };

  const renderMediaAsset = () => {
    switch (mediaAsset.transcodeStatus) {
      case TranscodeStatus.Awaiting:
      case TranscodeStatus.Processing:
        return renderProcessingItem();
      case TranscodeStatus.Error:
        return renderErrorItem();
      case TranscodeStatus.Complete:
        return mediaAsset.visible ? renderCompleteItem() : renderHiddenItem();
      default:
        return null;
    }
  };

  const handleEditClick = (mediaAsset?: MediaAsset) => {
    GAEvents.editMediaAssets.trackEditFromTitleClick(MediaAssetType.Audio);
    editMediaAsset(mediaAsset);
  };

  return mediaAsset.isMediaObjectLoading
    ? renderLoadingItem()
    : renderMediaAsset();
};
