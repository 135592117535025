import React from "react";
import DOMPurify from "dompurify";

import { SpaceBar } from "./SpaceBar";

import "./CapacityBar.scss";

export interface ICapacityBarContent {
  infoLabelFirst: string;
  infoLabelSecond: string;
  usedLabel: string;
  remainingLabel: string;
  secLabel: string;
  minLabel: string;
  loading: string;
}

export interface ICapacityBarProps {
  icon: string;
  entireValue: number;
  usedValue: number;
  content: ICapacityBarContent;
  isLoading?: boolean;
}

export class CapacityBar extends React.Component<ICapacityBarProps> {
  public render() {
    const { icon, content, usedValue, entireValue, isLoading } = this.props;
    const entireValueLabel = Math.round(entireValue / 60);

    return (
      <div className="c-capacity-bar g-col-sm-12 g-col-md-12 g-col-lg-12">
        <div className={`c-capacity-bar__icon ${icon}`} />
        <div className="c-capacity-bar__info-container">
          <div className="c-capacity-bar__label-container">
            <span
              className={`c-capacity-bar__icon c-capacity-bar__icon--small-screen ${icon}`}
            />
            {isLoading ? (
              <span className="c-capacity-bar__info-label--loading" />
            ) : (
              <span
                className="c-capacity-bar__info-label"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    `${content.infoLabelFirst} ${entireValueLabel} ${content.infoLabelSecond}`
                  ),
                }}
              />
            )}
          </div>
          <SpaceBar
            isLoading={isLoading}
            usedValue={usedValue}
            entireValue={entireValue}
            content={content}
          />
        </div>
      </div>
    );
  }
}
