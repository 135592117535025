import { ISectionContainerProps } from "component-library";

import { IEditVideoListFormContent } from "./components/EditVideoListForm/EditVideoListFormContent";
import { AlertConfigBuilder } from "shared/utils/alert-config-builder";

export interface IDisplayLimitAlert {
  displayLimitAlertTitle: string;
  displayLimitAlertDescription: string;
  displayLimitAlertCloseButton: string;
}

export interface IVideoPageContent {
  deletePopup: {
    deletePopupCloseButtonText: string;
    deletePopupDescription: string;
    deletePopupTitle: string;
    deletePopupContinueButtonText: string;
  };
  copyrightPopup: {
    copyrightPopupCloseButtonText: string;
    copyrightPopupDescription: string;
    copyrightPopupTitle: string;
    copyrightPopupContinueButtonText: string;
  };
  header: string;
  closeButton: string;
  cancelButton: string;
  addButton: string;
  addLink: string;
  agentCVLink: string;
  performerCVLink: string;
  notificationMessage: string;
  notificationErrorMessage: string;
  editUrl: string;
  displayLimitAlert: IDisplayLimitAlert;
  editVideoListForm: IEditVideoListFormContent;
}
export const IVideoPageContentKeys = [
  "deletePopup",
  "copyrightPopup",
  "header",
  "closeButton",
  "cancelButton",
  "addButton",
  "addLink",
  "agentCVLink",
  "performerCVLink",
  "notificationMessage",
  "notificationErrorMessage",
  "editUrl",
  "displayLimitAlert",
  "editVideoListForm",
];

export const getSectionContainerProps = (
  content: IVideoPageContent,
  handleCloseClick: () => void
): ISectionContainerProps => {
  return {
    header: content.header,
    variant: "shadowed",
    controls: [
      {
        text: content.closeButton,
        onClick: handleCloseClick,
      },
    ],
  };
};

export const getAlertMessage = (
  content: IVideoPageContent,
  popupType: PopupTypes,
  handleActionClick: () => void,
  cancelAction: () => void,
  iconType: "warning" | "notice" = "warning"
) => {
  const popupContent = content[popupType];

  return new AlertConfigBuilder()
    .initBuildEntity()
    .setDefaultContent({
      closeButtonText: popupContent[`${popupType}CloseButtonText`],
      description: popupContent[`${popupType}Description`],
      title: popupContent[`${popupType}Title`],
    })
    .setAlertProps({ icon: iconType })
    .setCloseButton({ type: "secondary", click: cancelAction })
    .addButton({
      type: "primary",
      click: handleActionClick,
      name: popupContent[`${popupType}ContinueButtonText`],
    })
    .build();
};

export const getDisplayLimitAlert = (
  content: IDisplayLimitAlert,
  closeAction: () => void
) =>
  new AlertConfigBuilder()
    .initBuildEntity()
    .setDefaultContent({
      title: content.displayLimitAlertTitle,
      description: content.displayLimitAlertDescription,
      closeButtonText: content.displayLimitAlertCloseButton,
    })
    .setCloseButton({ type: "primary", click: closeAction })
    .build();

export enum PopupTypes {
  Delete = "deletePopup",
  Copyright = "copyrightPopup",
}
