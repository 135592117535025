export enum Status {
  Progress,
  Success,
  Failed,
}

export interface IInputFile {
  id: string;
  name: string;
}

export interface IFile extends IInputFile {
  status: Status;
  percent: number;
  nativeFile: File;
}
