export class GlobalStorage {
  public static getValue<TEntity>(name: string): TEntity {
    const jsonString = sessionStorage.getItem(name);

    if (jsonString) {
      return JSON.parse(jsonString) as TEntity
    }

    return {} as TEntity;
  }

  public static updateValue(name: string, object: any): void {
    const jsonString = sessionStorage.getItem(name);

    if (jsonString) {
      let newObject = { ...JSON.parse(jsonString), ...object }
      sessionStorage.setItem(name, JSON.stringify(newObject))
    } else {
      sessionStorage.setItem(name, JSON.stringify(object))
    }
  }

  public static remove(name: string) {
    sessionStorage.removeItem(name);
  }

  public static clear() {
    sessionStorage.clear();
  }
}
