import React from "react";
import { TextEllipsis } from "component-library";

import FileProgressBar from "./FileProgressBar";
import { Status } from "./FileUploadModels";
import { GAEvents } from "mediaModules/media/components/GAEvents";
import { MediaAssetType } from "mediaModules/media/models";

import "./FilePreview.scss";

export interface IFilePreviewTexts {
  uploadError: string;
  removeButton: string;
  retryButton: string;
}

export interface IFilePreviewProps {
  id: string;
  name: string;
  status: Status;
  percent: number;
  texts: IFilePreviewTexts;
  onRemove: (id: string) => Promise<void>;
  onRetry: () => void;
  mediaType: MediaAssetType;
}

class FilePreview extends React.PureComponent<IFilePreviewProps> {
  public render() {
    return (
      <div className="c-file-preview">
        <div className="c-file-preview__name">
          <TextEllipsis text={this.props.name} maxLine={2} />
        </div>
        {this.renderControls()}
      </div>
    );
  }

  private renderControls = () => {
    const {
      status,
      percent,
      mediaType,
      texts: { removeButton, retryButton },
    } = this.props;

    return (
      <div className="c-file-preview__controls">
        {status === Status.Progress && (
          <div className="c-file-preview__progress">
            <FileProgressBar percent={percent} />
          </div>
        )}
        {status === Status.Success && (
          <button
            type="button"
            className="c-file-preview__button icon-delete"
            title={removeButton}
            onClick={async () => await this.handleRemove(mediaType)}
          />
        )}
        {status === Status.Failed && (
          <>
            <button
              type="button"
              className="c-file-preview__button icon-retry"
              title={retryButton}
              onClick={this.retryUpload}
            />
            <button
              type="button"
              className="c-file-preview__button icon-delete"
              title={removeButton}
              onClick={async () => await this.handleRemove(mediaType)}
            />
          </>
        )}
      </div>
    );
  };

  private handleRemove = async (mediaType: MediaAssetType) => {
    GAEvents.addMediaAsset.trackDeleteClick(mediaType);
    await this.props.onRemove(this.props.id);
  };

  private retryUpload = () => {
    this.props.onRetry();
  };
}

export default FilePreview;
