import React from "react";
import { matchPath, RouteComponentProps } from "react-router-dom";
import { authorizationService } from "shared-auth";
import { IGlobalContextProps } from "shared/contexts/GlobalContext";
import { ILogContextProps } from "shared/contexts/LoggerContext";
import {
  ErrorTypes,
  GlobalAlert,
  GlobalAlertContentFactory,
  GlobalAlertMessage,
  IErrorNotificationData,
  IErrorPopupContent,
} from "shared/modules/Common/GlobalAlert";
import { AlertConfigBuilder } from "shared/utils/alert-config-builder";
import { FetchInterceptor } from "shared/utils/http";

import { GlobalEventTypes } from "contexts/GlobalContext";
import { ITranslationContextProps } from "contexts/TranslationContext";
import { IErrorPopupContentKeys } from "shared/modules/Common/GlobalAlert/GlobalAlertContentFactory";

interface ISessionExpiredAlertContent {
  alertTitle: string;
  alertDescription: string;
  closeAlert: string;
  goToMainSiteTitle: string;
  goToMainSiteLink: string;
}
export const ISessionExpiredAlertContentKeys = [
  "alertTitle",
  "alertDescription",
  "closeAlert",
  "goToMainSiteTitle",
  "goToMainSiteLink",
];

interface IWithErrorCatcherState {
  isContentHidden: boolean;
}

declare type WithErrorCatcherProps = IGlobalContextProps &
  ITranslationContextProps &
  ILogContextProps &
  RouteComponentProps;

const excludedUrls: RegExp[] = [
  /http(s|):\/\/([\w\.\-]){0,}\/useractivities/,
  /view\/\d{4}-\d{4}-\d{4}/,
  /shortlists/,
  /notes/,
  /profilenotfound/,
];

export default function withErrorCatcher<P extends WithErrorCatcherProps>(
  WrappedComponent: React.ComponentClass<P> | React.FC<P>
): typeof React.Component {
  return class extends React.Component<P, IWithErrorCatcherState> {
    private readonly alertContentFactory = new GlobalAlertContentFactory();
    public readonly state: Readonly<IWithErrorCatcherState> = {
      isContentHidden: false,
    };

    constructor(props: P) {
      super(props);
      this.initErrorCatcher();
    }

    public render() {
      const { isContentHidden } = this.state;
      return (
        <React.Fragment>
          {!isContentHidden && <WrappedComponent {...this.props} />}
          <GlobalAlert />
        </React.Fragment>
      );
    }

    private initErrorCatcher = () => {
      FetchInterceptor.register({
        response: this.responseHandler,
        responseError: this.responseErrorHandler,
      });
    };

    private responseHandler = async (response: Response) => {
      const { logger } = this.props;

      if (!excludedUrls.some((excluded) => excluded.test(response.url))) {
        if (!response.ok) {
          try {
            await this.generateResponseErrorLog(response);
          } catch (error) {
            logger.appendError(
              "Error: Parsing of negative response failed (responseHandler)",
              error,
              {
                url: response.url,
                status: response.status,
                headers: response.headers,
              }
            );
          }
        }

        const currentRoute = this.props.history.location.pathname;
        const matchProfileNotFound = matchPath(currentRoute, {
          path: "/profilenotfound",
        });

        if (response.status === 401) {
          // skip showing error popup when on error page
          if (matchProfileNotFound) return response;

          this.showErrorPopup(() => this.getUnAuthError());
          this.setState({ isContentHidden: true });
        } else if (response.status >= 400) {
          const error = { errorType: response.status, error: response };
          this.showErrorPopup(error);
        }
      }

      return response;
    };

    private responseErrorHandler = async (response: any) => {
      const { logger } = this.props;
      if (response instanceof Error) {
        logger.appendError(
          "Error: Invalid request/response. (responseErrorHandler)",
          response
        );
      } else {
        logger.appendError(
          "Error: Invalid request/response. (responseErrorHandler)",
          new Error(response.toString())
        );
      }

      throw response;
    };

    private generateResponseErrorLog = async (response: Response) => {
      const responseContent = await response.json();
      const { logger } = this.props;

      const error = new Error(response.status.toString());

      logger.appendError(`Error: Code is ${response.status}`, error, {
        response,
        responseContent,
      });
    };

    private showErrorPopup = (
      errorMessage: IErrorNotificationData | (() => GlobalAlertMessage)
    ) => {
      let message: GlobalAlertMessage;

      const { globalContext } = this.props;

      if (typeof errorMessage === "function") {
        message = errorMessage();
      } else {
        message = this.alertContentFactory.getErrorPopupSettings(
          this.getErrorContent(errorMessage),
          errorMessage
        );
      }

      globalContext.notifyListener(
        GlobalEventTypes.notifyingGlobalAlert,
        message,
        true
      );
    };

    private getUnAuthError = () => {
      const content =
        this.props.translator.createTranslationObject<ISessionExpiredAlertContent>(
          ISessionExpiredAlertContentKeys,
          "sessionExpiredAlert"
        );

      return new AlertConfigBuilder()
        .initBuildEntity()
        .setDefaultContent({
          title: content.alertTitle,
          description: content.alertDescription,
          closeButtonText: content.closeAlert,
        })
        .addButton({
          name: content.goToMainSiteTitle,
          type: "primary",
          click: () =>
            //@ts-ignore
            authorizationService.signinRedirect() ||
            window.location.replace(content.goToMainSiteLink),
        })
        .setCloseButton({
          type: "secondary",
        })
        .build();
    };

    private getErrorContent = (
      errorMessage: IErrorNotificationData
    ): IErrorPopupContent => {
      let errorType =
        ErrorTypes[errorMessage.errorType] ||
        ErrorTypes[ErrorTypes.UnknownError];
      errorType = errorType.replace(/^\w/, (char) => char.toLowerCase());

      const address = `errorList.${errorType}.error`;

      return this.props.translator.createTranslationObject<IErrorPopupContent>(
        IErrorPopupContentKeys,
        address
      );
    };
  };
}
