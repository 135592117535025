export class BaseError extends Error {
  public readonly errorContext: any;
  public readonly parent?: any;

  constructor(message: string, context?: Error | any, parentError?: Error) {
    super(message);

    if (context instanceof Error) {
      this.parent = context && this.createCopyOfError(context);
    } else {
      this.errorContext = context;
    }

    this.parent = parentError && this.createCopyOfError(parentError);
  }

  private createCopyOfError = (error: Error) => {
    let errorCopy: any;
    if (error instanceof Error) {
      const errorProps = Object.getOwnPropertyNames(error);

      errorCopy = errorProps.reduce((errorCopy, prop) => {
        errorCopy[prop] = error[prop];

        return errorCopy;
      }, {})
    }

    return errorCopy;
  }
}