import React from "react";

import "./FileProgressBar.scss";

export interface IProgresBarProps {
  percent: number;
}

class FileProgressBar extends React.PureComponent<IProgresBarProps> {
  public render() {
    return (
      <div className="c-file-progress-bar">
        <div
          className="c-file-progress-bar__filler"
          style={{ width: `${this.props.percent}%` }}
        />
      </div>
    );
  }
}

export default FileProgressBar;
