import { ITooltipContent } from "component-library";

export interface IEditVideoFormContent {
  chooseThumbnail: string;
  videoTitleFieldLabel: string;
  videoTitleFieldRequired: string;
  videoTitleFieldTooltip: IVideoTitleFieldTooltipContent;
  downloadButton: string;
}

export interface IVideoTitleFieldTooltipContent {
  header: string;
  content: string;
  closeButton: string;
  tooltipName: string;
}

export const getTooltipProps = (content: ITooltipContent) => {
  return {
    texts: content,
    config: {
      minimalTopDistance: 50,
    },
  };
};
