import "react-app-polyfill/stable";
import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import { initI18N } from "i18n";
import { Spinner, TranslationContextProvider } from "component-library";
import TagManager from "react-gtm-module";
import App from "./app/App";
import { config } from "config";
import { LaunchDarklyProvider } from "shared-services";

import "./index.css";
import "./app/App.scss";

initI18N(["performer"]);

TagManager.initialize({
  gtmId: config.gtm.gtmId,
  dataLayerName: config.gtm.dataLayerName,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

smoothscroll.polyfill();

ReactDOM.render(
  (() => (
    <BrowserRouter>
      <LaunchDarklyProvider thisUser={null}>
        <QueryClientProvider client={queryClient}>
          <TranslationContextProvider>
            <Suspense fallback={<Spinner />}>
              <Route path="/" component={App} />
            </Suspense>
          </TranslationContextProvider>
        </QueryClientProvider>
      </LaunchDarklyProvider>
    </BrowserRouter>
  ))(),
  document.getElementById("root")
);
