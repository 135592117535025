import React, { FC } from "react";
import { useTranslation } from "../Translations/TranslationContext";
import { IconTextLink } from "../../Atoms/IconTextLink/IconTextLink";

interface Props {
  onClick: (e: any) => void;
  className?: string;
  iconButton?: boolean;
}

const ContactPerformerButton: FC<Props> = ({
  onClick,
  className = "g-alternative-link g-alternative-link--md",
  iconButton = false,
}) => {
  const t = useTranslation();
  return iconButton ? (
    <IconTextLink
      className={className}
      onClick={onClick}
      iconName="icon-messages"
      text={t("common.contactPerformerModal.contactButton")}
      variant="primary"
    />
  ) : (
    <a className={className} onClick={onClick}>
      {t("common.contactPerformerModal.contactButton")}
    </a>
  );
};

export default ContactPerformerButton;
