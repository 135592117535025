import React from "react";
import { authorizationService, UserAttributes } from "shared-auth";
import { UserManager, UserRoleManager, UserRoles } from "app/core";
import { matchPath, RouteComponentProps } from "react-router-dom";

import { IGlobalContextProps } from "shared/contexts/GlobalContext/GlobalContext";
import {
  ErrorTypes,
  IErrorPopupContent,
  IErrorPopupContentKeys,
} from "shared/modules/Common/GlobalAlert/GlobalAlertContentFactory";
import { AlertConfigBuilder } from "shared/utils/alert-config-builder";
import { parseQuery } from "shared/utils/http";

import { GlobalEventTypes } from "contexts/GlobalContext/GlobalEventTypes";
import { ITranslationContextProps } from "contexts/TranslationContext";

declare type withOidcAuthenticationProps = RouteComponentProps &
  IGlobalContextProps &
  ITranslationContextProps;

export default function withOidcAuthentication<
  P extends withOidcAuthenticationProps
>(
  WrappedComponent: React.ComponentClass<P> | React.FC<P>
): typeof React.Component {
  return class extends React.Component<P> {
    private isContentVisible = false;

    constructor(props: P) {
      super(props);
      window.addEventListener("pageshow", this.onPageShowHandler);
      this.initAuthentication();
    }

    public render() {
      return this.isContentVisible && <WrappedComponent {...this.props} />;
    }

    public componentWillUnmount() {
      window.removeEventListener("pageshow", this.onPageShowHandler);
    }

    /** This is needed for Safari. Safari, in an attempt to be a better browser (which it isn't), doesn't always reload a page when you press back. Instead, it will
     * use a stored copy of the page's state. However, this doesn't play nicely if it happens on the /signin-oidc URL. Therefore, if we have a truthy event.persisted in
     * the onPageShowHandler, then we should start the sign in process again
     */
    onPageShowHandler = (event: PageTransitionEvent) => {
      if (event.persisted) {
        if (authorizationService.isSignInUrl()) {
          this.onRedirectedBackToSignInUrl();
        }
      }
    };

    private initAuthentication = () => {
      const { artistref, provider, token } = parseQuery(window.location.search);
      const { history } = this.props;

      if (token || artistref) {
        history.replace(window.location.pathname);
      }

      const currentRoute = this.props.history.location.pathname;
      const matchProfile = matchPath(currentRoute, {
        path: "/:viewPin(\\d{4}-\\d{4}-\\d{4})",
      });

      if (matchProfile) {
        this.isContentVisible = true;
        return;
      }

      if (!UserRoleManager.isInRole(UserRoles.anonymous)) {
        if (!authorizationService.isSignInUrl()) {
          this.handleSignInFlow(provider, artistref);
        } else {
          this.onRedirectedBackToSignInUrl();
        }
      } else {
        this.isContentVisible = true;
      }
    };

    private onRedirectedBackToSignInUrl = async () => {
      try {
        await authorizationService.signinRedirectCallback();
      } catch (e) {
        this.showOidcErrorPopup(e);
      }
    };

    private showOidcErrorPopup = (errorInfo: unknown) => {
      const contentAddress = `errorList.${ErrorTypes[
        ErrorTypes.UnknownError
      ].replace(/^\w/, (char) => char.toLowerCase())}.error`;

      const content =
        this.props.translator.createTranslationObject<IErrorPopupContent>(
          IErrorPopupContentKeys,
          contentAddress
        );
      const altContent =
        this.props.translator.createTranslationObject<IErrorMessageContent>(
          IErrorMessageContentKeys,
          "sessionExpiredAlert"
        );
      const message = new AlertConfigBuilder()
        .initBuildEntity()
        .setDefaultContent({
          title: content.title,
          description: "OidcStateError - " + errorInfo,
          closeButtonText: content.closeButtonText,
        })
        .addButton({
          name: altContent.goToMainSiteTitle,
          type: "primary",
          click: () => window.location.replace(altContent.goToMainSiteLink),
        })
        .build();

      this.props.globalContext.notifyListener(
        GlobalEventTypes.notifyingGlobalAlert,
        message,
        true
      );
    };

    private handleSignInFlow = async (
      provider?: string,
      artistref?: string
    ) => {
      if (authorizationService.isAuthenticated()) {
        if (artistref && UserManager.artistRef !== artistref) {
          UserManager.artistRef = artistref;
        } else if (authorizationService.user) {
          const performerId = authorizationService.getUserAttribute(
            UserAttributes.UserIdPerformer
          );

          if (!UserManager.artistRef && performerId) {
            UserManager.artistRef = performerId;
          }
        }

        this.isContentVisible = true;
      } else {
        UserManager.artistRef = artistref;
        authorizationService.signInRedirect(provider);
      }
    };
  };
}

interface IErrorMessageContent {
  goToMainSiteTitle: string;
  goToMainSiteLink: string;
}
export const IErrorMessageContentKeys = [
  "goToMainSiteTitle",
  "goToMainSiteLink",
];
