import GlobalAlertMessage from "./GlobalAlertMessage";
import { AlertConfigBuilder } from "shared/utils/alert-config-builder";

export interface IErrorPopupContent {
  title: string;
  description: string;
  actionButtonText: string;
  closeButtonText: string;
}
export const IErrorPopupContentKeys = [
  "title",
  "description",
  "actionButtonText",
  "closeButtonText",
];

export enum ErrorTypes {
  UnknownError = 0,
  GeneralError = 1,
  InternalServerError = 500,
  AccessDenyError = 403,
}

export interface IErrorNotificationData {
  errorType: ErrorTypes;
  error?: any;
  errorAction?: () => void;
}

export class GlobalAlertContentFactory {
  public getErrorPopupSettings(
    errorContent: IErrorPopupContent,
    errorData: IErrorNotificationData
  ): GlobalAlertMessage {
    return this.getGlobalAlertMessage(errorContent, errorData);
  }

  private getGlobalAlertMessage(
    content: IErrorPopupContent,
    errorData: IErrorNotificationData
  ) {
    return new AlertConfigBuilder()
      .initBuildEntity()
      .setDefaultContent({
        title: content.title,
        description: content.description,
        closeButtonText: content.closeButtonText,
        closePopupButtonText: content.closeButtonText,
      })
      .setCloseButton({
        name: content.actionButtonText,
        click: errorData.errorAction || this.defaultErrorAction,
      })
      .build();
  }

  private defaultErrorAction() {
    location.reload();
  }
}
