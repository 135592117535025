import React from "react";
import {
  Input,
  ITooltipContent,
  IAutocompleteItem,
  Autocomplete,
  Helper,
  debounce,
} from "component-library";

import { IMultiSelectItem } from "../MultiSelectItem";

interface IMultiSelectAutocompleteContent {
  label: string;
  placeholder: string;
  tooltip?: ITooltipContent;
}

interface IMultiSelectAutocompleteProps<TValue> {
  content: IMultiSelectAutocompleteContent;
  name: string;
  delayLoadTime?: number;
  invalid?: boolean;
  validationMessage?: string;
  selectItem: (value: IMultiSelectItem<TValue>) => void;
  loadData: (value: string) => Promise<IMultiSelectItem<TValue>[]>;
}

interface IMultiSelectAutocompleteState {
  value: string;
  displayAutocomplete: boolean;
  autocompleteList: IAutocompleteItem[];
}

export class MultiSelectAutocomplete<TValue> extends React.Component<
  IMultiSelectAutocompleteProps<TValue>,
  IMultiSelectAutocompleteState
> {
  public readonly state: Readonly<IMultiSelectAutocompleteState> = {
    value: "",
    autocompleteList: [],
    displayAutocomplete: false,
  };

  public render() {
    const { autocompleteList, displayAutocomplete, value } = this.state;
    const { content, name, invalid, validationMessage } = this.props;

    return (
      <Input
        id={name}
        name={name}
        type="text"
        invalid={invalid}
        validationMessage={validationMessage}
        isFormSubmitted
        label={content.label}
        placeholder={content.placeholder}
        value={value}
        valueChanged={this.valueChanged}
        annex={
          content.tooltip && (
            <Helper
              {...{
                tooltip: {
                  texts: content.tooltip,
                  config: {
                    minimalTopDistance: 50,
                  },
                },
              }}
            />
          )
        }
      >
        <Autocomplete
          list={autocompleteList}
          display={displayAutocomplete}
          selectItem={this.selectItem}
        />
      </Input>
    );
  }

  private valueChanged = (value: string) => {
    const state = { ...this.state };
    if (value.length === 0) {
      state.displayAutocomplete = false;
      state.autocompleteList = [];
    }

    state.displayAutocomplete = state.autocompleteList.length > 0;
    state.value = value;

    this.setState(state, this.loadListDebounced);
  };

  private selectItem = ({ name, data }: IAutocompleteItem) => {
    this.setState(
      {
        value: "",
        autocompleteList: [],
        displayAutocomplete: false,
      },
      () => this.props.selectItem({ name, value: data })
    );
  };

  private loadList = async () => {
    const { value: searchString } = this.state;

    if (searchString.length > 0) {
      const autocompleteList = (await this.props.loadData(searchString)).map(
        ({ name, value }) => ({ name, data: value })
      );

      this.setState({
        autocompleteList,
        displayAutocomplete: autocompleteList.length > 0,
      });
    }
  };

  private loadListDebounced = debounce(
    this.loadList,
    this.props.delayLoadTime || 0
  );
}
