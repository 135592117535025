import React from "react";

import { LoggerContext } from "./LoggerProvider";
import { Logger } from "./Logger";
import { LogHub } from "./LogHub";

export interface ILogContextProps {
  logHub: LogHub;
  logger: Logger;
}

const withLog =
  (domain: string) =>
  <P extends ILogContextProps>(
    Component: React.ComponentClass<P> | React.FC<P>
  ) => {
    return class extends React.Component<Omit<P, keyof ILogContextProps>> {
      public render() {
        return (
          <LoggerContext.Consumer>
            {(logHub) => (
              <WithLogConsumer
                childProps={this.props}
                logHub={logHub}
                component={Component}
                domain={domain}
              />
            )}
          </LoggerContext.Consumer>
        );
      }
    };
  };

interface IWithLogConsumerProps {
  logHub: LogHub;
  component: React.ComponentType;
  domain: string;
  childProps: any;
}

class WithLogConsumer extends React.Component<IWithLogConsumerProps> {
  private logger: Logger;

  constructor(props: Readonly<IWithLogConsumerProps>) {
    super(props);

    this.logger = props.logHub.createLoggerInstance(props.domain);
  }

  render() {
    const { component: Component, logHub, childProps } = this.props;
    return <Component {...childProps} logHub={logHub} logger={this.logger} />;
  }

  public componentWillUnmount() {
    this.logger.closeAllGroups();
  }
}

export default withLog;
