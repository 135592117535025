import React, { useEffect, useState } from "react";

import { ContrastSetting } from "../ContrastSetting/ContrastSetting";
import { TiltSetting } from "../TiltSetting/TiltSetting";
import { CropSetting } from "../CropSetting/CropSetting";
import { TOOL_IDS } from "../../PhotoTool";
import { ToolState } from "../../ToolState";
import { ToolSetting } from "./ToolSetting";
import { BrightnessSetting } from "../BrightnessSetting/BrightnessSetting";

export interface IToolSettingsTexts {
  contrast: {
    minValueName: string;
    maxValueName: string;
  };
  brightness: {
    minValueName: string;
    maxValueName: string;
  };
  tilt: {
    minValueName: string;
    maxValueName: string;
  };
  crop: {
    rangeTexts: {
      minValueName: string;
      maxValueName: string;
    };
    applyButton: string;
  };
}

export interface IToolSettingsProps {
  toolState: ToolState;
  changeState: (toolState: ToolState, toolId: string) => void;
  toolId: string;
  disabledTools: boolean;
  texts: IToolSettingsTexts;
  preventCrop?: boolean;
}

const CROP_SETTING_INDEX_POSITION = 3;

const ToolSettings: React.FC<IToolSettingsProps> = (props) => {
  const [toolSettings, setToolSettings] = useState<ToolSetting[]>([]);

  useEffect(() => {
    const getContrastSetting = (configuration: any) => {
      return (
        <ContrastSetting
          value={props.toolState.contrast}
          disabled={props.disabledTools}
          texts={props.texts.contrast}
          {...configuration}
          changeValue={changeSettingValue}
        />
      );
    };

    const getTiltSetting = (configuration: any) => {
      return (
        <TiltSetting
          value={props.toolState.tilt}
          disabled={props.disabledTools}
          texts={props.texts.tilt}
          {...configuration}
          changeValue={changeSettingValue}
        />
      );
    };

    const getCropSetting = (configuration: any) => {
      return (
        <CropSetting
          value={props.toolState.crop.zoom}
          disabled={props.disabledTools}
          texts={props.texts.crop}
          {...configuration}
          changeValue={changeSettingValue}
        />
      );
    };
    const getBrightnessSetting = (configuration: any) => {
      return (
        <BrightnessSetting
          value={props.toolState.brightness}
          disabled={props.disabledTools}
          texts={props.texts.brightness}
          {...configuration}
          changeValue={changeSettingValue}
        />
      );
    };

    setToolSettings([
      {
        id: TOOL_IDS.contrast,
        getToolSetting: getContrastSetting,
        configuration: {
          minValue: 25,
          maxValue: 175,
          step: 1,
        },
      },
      {
        id: TOOL_IDS.brightness,
        getToolSetting: getBrightnessSetting,
        configuration: {
          minValue: 25,
          maxValue: 175,
          step: 1,
        },
      },
      {
        id: TOOL_IDS.tilt,
        getToolSetting: getTiltSetting,
        configuration: {
          minValue: -45,
          maxValue: 45,
          step: 1,
        },
      },
      {
        id: TOOL_IDS.crop,
        getToolSetting: getCropSetting,
        configuration: {
          minValue: 0,
          maxValue: 2,
          step: 0.1,
          preventCrop: props.preventCrop,
        },
      },
    ]);
  }, [props]);

  const getSettingView = (): JSX.Element | null => {
    const tool = toolSettings.find(
      (selectedTool) => selectedTool.id === props.toolId
    );

    if (tool) {
      return tool.getToolSetting(tool.configuration);
    }

    return null;
  };

  const changeSettingValue = (value: any) => {
    props.changeState(value as ToolState, props.toolId);
  };

  return getSettingView();
};

export default ToolSettings;
