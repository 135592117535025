import {
  init,
  setContext,
  setUser,
  captureException,
  // replayIntegration,
} from "@sentry/react";
import config from "../../config";

type DecodedToken = {
  account_id: string;
  artist_id: string;
  client_id: string;
  iss: string;
  auth_time: number;
  name: string;
  userType: string;
  role: string;
} | null;

const ignorableErrors = [
  /invalid_grant/,
  /Frame window timed out/,
  /Unable to send state! Network Error/,
  /Unauthorized/,
  /401 Unauthorized/,
  /Script error/,
  /ChunkLoadError/,
];

const getCustomContext = (decodedToken: DecodedToken) => {
  return {
    accountId: decodedToken?.account_id,
    artistId: decodedToken?.artist_id,
    clientId: decodedToken?.client_id,
    issuer: decodedToken?.iss,
    authTime: decodedToken?.auth_time,
    name: decodedToken?.name,
    userType: decodedToken?.userType,
  };
};

const initSentry = (decodedToken: any, appName: string) => {
  setUser({
    id: decodedToken?.account_id,
    username: decodedToken?.name,
  });

  const isProd = process.env.NODE_ENV === "production";
  const customContext = getCustomContext(decodedToken);

  setContext("LoggedIn User Details", {
    ...customContext,
    appName,
    memberGroups: decodedToken?.["spotlight.MemberGroups"],
  });

  init({
    dsn: config.sentry.dsn,
    environment: process.env.NODE_ENV,
    sampleRate: 0.6,
    // integrations: [replayIntegration()],
    // replaysSessionSampleRate: 0.1,
    // replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      const errorMsg = hint.originalException?.toString();
      if (errorMsg && ignorableErrors.some((regex) => regex.test(errorMsg))) {
        return null;
      }
      return event;
    },
    maxBreadcrumbs: 10,
    tracesSampleRate: 0,
    normalizeDepth: 8,
    enabled: isProd,
  });
};

/**
 * Capture exception event and send to Sentry
 * @param error - Error object
 * @param decodedToken - Decoded token
 * @param tags - Tags
 */
export const captureExceptionEvent = (
  error: any,
  decodedToken: DecodedToken,
  tags: Record<string, string | number> = {}
) => {
  const customContext = getCustomContext(decodedToken);
  captureException(error, {
    tags: {
      ...customContext,
      ...tags,
    },
  });
};

export default initSentry;
