export const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    [
      {
        color: [],
      },
      "link",
    ],
  ],
};

export const formats = [
  "bold",
  "italic",
  "underline",
  "list",
  "bullet",
  "color",
  "link",
];
