import React from "react";

import "./ControlSection.scss";

export interface IControlSectionProps {
  name?: string;
  description?: string | React.ReactNode;
  button?: {
    name: string;
    clickHandler: () => void;
  };
  children?: React.ReactNode;
}

const ControlSection: React.FunctionComponent<IControlSectionProps> = (
  props: IControlSectionProps
) => {
  const handleKeydown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      props.button?.clickHandler();
    }
  };

  return (
    <div className="c-control-section">
      {props.button && (
        <a
          className="g-alternative-link c-control-section__section-action"
          onClick={props.button.clickHandler}
          onKeyDown={handleKeydown}
          role="button"
          tabIndex={0}
        >
          {props.button.name}
        </a>
      )}
      {props.name && (
        <div className="c-control-section__section-name">{props.name}</div>
      )}
      {props.description && (
        <div className="c-control-section__section-description">
          {props.description}
        </div>
      )}
      <div className="c-control-section__section-content">{props.children}</div>
    </div>
  );
};

export default ControlSection;
