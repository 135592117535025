import React from "react";
import { CloseIconSvg } from "component-library";

import "./MediaCloseButton.scss";

export interface IMediaCloseButtonProps {
  closeAction: () => void;
}

export const MediaCloseButton: React.FC<IMediaCloseButtonProps> = ({
  closeAction,
}) => {
  return (
    <button
      type="button"
      onClick={closeAction}
      className="c-media-close-button"
    >
      <CloseIconSvg />
    </button>
  );
};
