import { ISectionContainerProps } from "component-library";

import { INewAudioFormContent } from "./components/NewAudioForm";
import { AlertConfigBuilder } from "shared/utils/alert-config-builder";

export interface IStoreLimitAlert {
  storeLimitAlertTitle: string;
  storeLimitAlertDescription: string;
  storeLimitAlertCloseButton: string;
}

export interface IAddNewAudioPageContent {
  cancelPopup: {
    cancelPopupClosePopupButton: string;
    cancelPopupDescription: string;
    cancelPopupTitle: string;
    cancelPopupContinueEditing: string;
  };
  deletePopup: {
    deletePopupClosePopupButton: string;
    deletePopupDescription: string;
    deletePopupTitle: string;
    deletePopupContinueEditing: string;
  };
  header: string;
  closeButton: string;
  saveButton: string;
  cancelButton: string;
  cvEditorLink: string;
  newAudioForm: INewAudioFormContent;
  storeLimitAlert: IStoreLimitAlert;
}
export const IAddNewAudioPageContentKeys = [
  "cancelPopup",
  "deletePopup",
  "header",
  "closeButton",
  "saveButton",
  "cancelButton",
  "cvEditorLink",
  "newAudioForm",
  "storeLimitAlert",
];

export const getSectionContainerProps = (
  content: IAddNewAudioPageContent,
  handleCloseClick: () => void
): ISectionContainerProps => {
  return {
    header: content.header,
    variant: "shadowed",
    controls: [
      {
        text: content.closeButton,
        onClick: handleCloseClick,
      },
    ],
  };
};

export const getAlertMessage = (
  content: IAddNewAudioPageContent,
  popupType: PopupTypes,
  cancelContinuingAction: () => void,
  continueAction: () => void
) => {
  const popupContent = content[popupType];
  const closeButtonText =
    popupType === PopupTypes.Delete
      ? popupContent["deletePopupClosePopupButton"]
      : popupContent["cancelPopupClosePopupButton"];
  const description =
    popupType === PopupTypes.Delete
      ? popupContent["deletePopupDescription"]
      : popupContent["cancelPopupDescription"];
  const title =
    popupType === PopupTypes.Delete
      ? popupContent["deletePopupTitle"]
      : popupContent["cancelPopupTitle"];
  const continueEditing =
    popupType === PopupTypes.Delete
      ? popupContent["deletePopupContinueEditing"]
      : popupContent["cancelPopupContinueEditing"];

  return new AlertConfigBuilder()
    .initBuildEntity()
    .setDefaultContent({
      closeButtonText: continueEditing,
      description,
      title,
    })
    .setCloseButton({ type: "secondary", click: cancelContinuingAction })
    .addButton({
      type: "primary",
      click: continueAction,
      name: closeButtonText,
    })
    .build();
};

export enum PopupTypes {
  CancelClose = "cancelPopup",
  Delete = "deletePopup",
}

export const getStoreLimitAlert = (
  content: IStoreLimitAlert,
  closeAction: () => void
) =>
  new AlertConfigBuilder()
    .initBuildEntity()
    .setDefaultContent({
      title: content.storeLimitAlertTitle,
      description: content.storeLimitAlertDescription,
      closeButtonText: content.storeLimitAlertCloseButton,
    })
    .setCloseButton({ type: "primary", click: closeAction })
    .build();
