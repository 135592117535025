import { getUmbracoApiUrl } from 'services/UrlBuilder';

import { HttpClient } from '../../services/HttpClient';

import Translator from './Translator';

export class TranslationLibrary {
  private library: any;
  private httpClient: HttpClient;
  private id: string;
  private language: string;

  constructor() {
    this.httpClient = new HttpClient();
  }

  public async initLibrary(language: string) {
    let translation: any = {};
    try {
      const response = await this.httpClient.get(getUmbracoApiUrl(`profile/${language}/profileCommon`));

      translation = response.object;
    } catch (error) {
      throw error;
    } finally {
      this.language = language;
      this.id = Math.random().toString(36).substring(7);
      this.library = this.simplifyTranslationStructure(translation);
    }
  }

  public get currentLocalization() {
    return this.language;
  }

  public get version() {
    return this.id;
  }

  public createTranslationNode(path: string) {
    return new Translator(this.searchByPath(path, this.library), this, path);
  }

  public searchByPath(path: string, library: any) {
    const pathParts: string[] = path.split('.');

    let selectedGroup = library;
    for (const part of pathParts) {
      if (Boolean(selectedGroup) && part in selectedGroup) {
        selectedGroup = selectedGroup[part];
      } else if (Boolean(selectedGroup) && Boolean(selectedGroup.modules) && part in selectedGroup.modules) {
        selectedGroup = selectedGroup.modules[part];
      } else if (part === '') {
        selectedGroup = library;
      } else {
        selectedGroup = {};
        console.warn('unknown translation path: ', path);
      }
    }

    return selectedGroup;
  }

  private simplifyTranslationStructure(translation: any) {
    const translationLibrary: { [key: string]: any } = {};

    for (const property in translation) {
      if (translation.hasOwnProperty(property)) {
        const translationProperty = translation[property];

        if (property === 'children' && Object.keys(translationProperty).length > 0) {
          for (const page in translationProperty) {
            if (translationProperty.hasOwnProperty(page)) {
              translationLibrary[page] = this.simplifyTranslationStructure(translationProperty[page]);
            }
          }
        }

        if (property === 'propertyGroups' && Object.keys(translation[property]).length > 0) {
          translationLibrary['modules'] = this.parseTranslationGroup(translation[property]);
        }
      }
    }

    return translationLibrary;
  }

  private parseTranslationGroup(translationGroup: any) {
    const newTranslationGroup: { [key: string]: any } = {};

    for (const property in translationGroup) {
      if (translationGroup.hasOwnProperty(property)) {
        const translationProperty = translationGroup[property];

        if (translationProperty) {
          let translationSection: { [key: string]: string | any } = {};

          for (const key in translationProperty) {
            if (translationProperty.hasOwnProperty(key) && 'text' in translationProperty[key]) {
              translationSection = { ...translationSection, [key]: translationProperty[key].text };
            }
          }

          newTranslationGroup[property] = translationSection;
        }
      }
    }

    return newTranslationGroup;
  }
}
