import React from "react";
import { Tool } from "./Tool";

import "./ToolBar.scss";

const TOOL_TYPE = {
  tab: "tab",
  button: "button",
};

export interface IToolBarProps {
  tools: Tool[];
  selectTool: (tool: Tool) => void;
  undo: () => void;
  toolId: string;
  undoDisabled: boolean;
  disablePanel: boolean;
  texts: {
    undo: string;
  };
}

export interface IToolBarState {
  activeTool: string;
  undoDisabled: boolean;
}

export class ToolBar extends React.Component<IToolBarProps, IToolBarState> {
  constructor(props: IToolBarProps) {
    super(props);

    const selectedTab = props.tools.find(
      (tool) => tool.controlType === TOOL_TYPE.tab
    );

    this.state = {
      activeTool: selectedTab ? selectedTab.id : props.tools[0].id,
      undoDisabled: true,
    };
  }

  public render() {
    this.getTools();
    return (
      <div
        className={`c-tool-bar ${this.props.disablePanel ? "disabled" : ""}`}
      >
        <div className="c-tool-bar__tool-set">{this.getTools()}</div>
        <div
          className={`c-tool-bar__undo icon-undo ${
            this.state.undoDisabled ? "disabled" : ""
          }`}
          onKeyUp={this.undo}
          onClick={this.undo}
        >
          <div className="c-tool-bar__tool-name">{this.props.texts.undo}</div>
        </div>
      </div>
    );
  }

  public getTools = (): JSX.Element[] => {
    const tools: JSX.Element[] = [];
    this.props.tools.forEach((tool) => {
      tools.push(this.getTool(tool));
    });

    return tools;
  };

  public getTool = (tool: Tool): JSX.Element => {
    const className = `c-tool-bar__tool ${tool.icon} ${
      tool.id === this.state.activeTool && tool.controlType !== TOOL_TYPE.button
        ? " active"
        : ""
    }`;
    return (
      <div
        key={tool.id}
        tabIndex={0}
        className={className}
        onKeyUp={this.handleKeyPress.bind(this, tool)}
        onClick={this.selectTool.bind(this, tool)}
      >
        <div className="c-tool-bar__tool-name">{tool.name}</div>
      </div>
    );
  };

  public componentWillReceiveProps(nextProps: IToolBarProps) {
    if (this.state.activeTool !== nextProps.toolId) {
      this.setState({ activeTool: nextProps.toolId });
    }
    this.setState({ undoDisabled: nextProps.undoDisabled });
  }

  public undo = (): void => {
    if (!this.state.undoDisabled) {
      this.props.undo();
    }
  };

  public selectTool(tool: Tool): void {
    if (this.props.disablePanel && tool.controlType === "button") {
      return;
    }

    this.props.selectTool(tool);

    if (tool.controlType !== "button") {
      this.setState({ activeTool: tool.id });
    }
  }

  public handleKeyPress = (tool: Tool, event: any): void => {
    if (event.key === " " || event.key === "Enter") {
      event.preventDefault();
      this.props.selectTool(tool);
      if (tool.controlType !== "button") {
        this.setState({ activeTool: tool.id });
      }
    }
  };
}
