import React from "react";
import { useEffect, useState } from "react";

import FilePreview from "./Components/FilePreview";
import { Status } from "./Components/FileUploadModels";
import {
  FileUploadState,
  IFileUpload,
  IFileUploadModel,
  IFileUploadComplete,
  IFilePartProgress,
} from "./Models";
import { ITitaniumUploaderProps } from "./TitaniumUploader";
import {
  IFilePartInfo,
  IFileUploadService,
} from "./Services/TitaniumUploadService";
import {
  MediaAssetType,
  SUPPORTED_AUDIO_EXTENSIONS,
  SUPPORTED_VIDEO_EXTENSIONS,
} from "mediaModules/media/models";

import "./TitaniumUploader.scss";

interface IFileUploaderProps {
  uploadService: IFileUploadService;
  mediaType: MediaAssetType;
  preloadedFile: IFileUpload;
  onPreUpload: (preloadedFile: IFileUpload) => boolean;
  onUploadStart?: (fileName?: string) => void;
  onComplete?: (uploadModel: IFileUploadComplete, durationInMs: number) => void;
  onFileRemove?: (id: string) => Promise<void>;
  onUploadError?: (errorMessage: string) => void;
  onUploadRetry?: () => void;
}

export type TitaniumUploaderProps = IFileUploaderProps & ITitaniumUploaderProps;

export const TitaniumFileUploader: React.FunctionComponent<
  TitaniumUploaderProps
> = (props) => {
  const [uploadState, setUploadState] = useState<FileUploadState>(
    props.preloadedFile.uploadState
  );
  const [fileUploadDetails, setFileUploadDetails] = useState<
    IFileUploadModel | undefined
  >(props.preloadedFile.details);
  const [percentCompleted, setPercentCompleted] = useState<number>(0);
  const [fileParts, setFileParts] = useState<IFilePartInfo[] | null>(null);
  const [partProgress, setPartProgress] = useState<IFilePartProgress[]>([]);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    switch (uploadState) {
      case FileUploadState.NotStarted:
        prepareUpload();
        break;
      case FileUploadState.PreparationComplete:
        uploadFiles();
        break;
      case FileUploadState.UploadComplete:
        if (fileUploadDetails) {
          fileUploadDetails.isMultipartUpload
            ? completeMultiPartFileUpload()
            : completeFileUpload();
        }
        break;
      case FileUploadState.Error:
        onUploadError(errorMessage);
    }
  }, [uploadState]);

  const onFileRemove = async (id: string): Promise<void> => {
    if (props.onFileRemove) {
      await props.onFileRemove(id);
    }
  };

  const onUploadRetry = () => {
    if (props.onUploadRetry) {
      props.onUploadRetry();
    }
    setUploadState(FileUploadState.NotStarted);
  };

  const onUploadError = (message) => {
    if (props.onUploadError) {
      props.onUploadError(message);
    }
  };

  const updateMultipartProgress = (ptProgress: IFilePartProgress[]): number => {
    if (ptProgress.length > 0 && fileUploadDetails) {
      const fpProgress: IFilePartProgress = ptProgress.reduce(
        (pVal: IFilePartProgress, cVal: IFilePartProgress) => {
          return {
            total: pVal.total + cVal.total,
            loaded: pVal.loaded + cVal.loaded,
            partNumber: 0,
          };
        }
      );

      return (100 / fileUploadDetails.size) * fpProgress.loaded;
    }
    return 0;
  };

  const handleFilePartProgress = (
    partNumber: number,
    loaded: number,
    total: number
  ) => {
    const idx = partProgress.findIndex((p) => p.partNumber === partNumber);

    const updatedFPProgress = partProgress;
    const updated: IFilePartProgress = { partNumber, loaded, total };
    if (idx > -1) {
      updatedFPProgress.splice(idx, 1, updated);
    } else {
      updatedFPProgress.push(updated);
    }
    setPartProgress(updatedFPProgress);
    setPercentCompleted(updateMultipartProgress(updatedFPProgress));
  };

  const isExtensionValid = () => {
    const extension = getFileExtension(props.preloadedFile.file);
    const supportedTypes =
      props.mediaType === MediaAssetType.Video
        ? SUPPORTED_VIDEO_EXTENSIONS
        : SUPPORTED_AUDIO_EXTENSIONS;

    return supportedTypes.includes(extension);
  };

  const getFileExtension = (file) => {
    return file.name.split(".").pop().toLowerCase();
  };

  const prepareUpload = async () => {
    if (!isExtensionValid()) {
      setErrorMessage(props.extensionError);
      setUploadState(FileUploadState.Error);
      return;
    }

    const isValidToUpload = props.onPreUpload(props.preloadedFile);
    if (!isValidToUpload) {
      setErrorMessage(props.uploadError);
      setUploadState(FileUploadState.Error);
      return;
    }

    if (props.onUploadStart) {
      props.onUploadStart(props.preloadedFile.file?.name);
    }

    await props.uploadService
      .getFileUploadDetails(props.preloadedFile)
      .then((fileUploadResonse) => {
        setFileUploadDetails(fileUploadResonse.value);
        setUploadState(FileUploadState.PreparationComplete);
      })
      .catch((error) => {
        setUploadState(FileUploadState.Error);
      });
  };

  const uploadFiles = async () => {
    if (fileUploadDetails) {
      if (fileUploadDetails.isMultipartUpload) {
        props.uploadService
          .doMultipartFileUploadAsync(
            props.preloadedFile,
            fileUploadDetails,
            handleFilePartProgress
          )
          .then((fpList) => {
            setFileParts(fpList);
            setUploadState(FileUploadState.UploadComplete);
            setPercentCompleted(100);
          })
          .catch((error) => {
            setPercentCompleted(100);
            setUploadState(FileUploadState.Error);
          });
      } else {
        props.uploadService
          .putFile(
            fileUploadDetails.uploadUri,
            props.preloadedFile,
            fileUploadDetails,
            (complete) => {
              setPercentCompleted(complete);
            }
          )
          .then((res) => {
            setPercentCompleted(100);
            setUploadState(FileUploadState.UploadComplete);
          })
          .catch((error) => {
            setPercentCompleted(0);
            setUploadState(FileUploadState.Error);
          });
      }
    }
  };

  const completeMultiPartFileUpload = () => {
    if (fileUploadDetails && fileParts) {
      props.uploadService
        .completeMultipartFileUpload(
          fileUploadDetails,
          props.mediaType,
          fileParts
        )
        .then((response) => {
          if (!response.hasErrors) {
            setUploadState(FileUploadState.Complete);
            if (props.onComplete) {
              props.onComplete(
                response.value,
                props.preloadedFile.durationInMs!
              );
            }
          } else {
            setErrorMessage(props.uploadError);
            setUploadState(FileUploadState.Error);
          }
        })
        .catch((error) => {
          setErrorMessage(props.uploadError);
          setUploadState(FileUploadState.Error);
        });
    }
  };

  const completeFileUpload = () => {
    if (fileUploadDetails) {
      props.uploadService
        .completeFileUpload(fileUploadDetails, props.mediaType)
        .then((response) => {
          if (!response.hasErrors) {
            setUploadState(FileUploadState.Complete);
            if (props.onComplete) {
              props.onComplete(
                response.value,
                props.preloadedFile.durationInMs!
              );
            }
          } else {
            setErrorMessage(props.uploadError);
            setUploadState(FileUploadState.Error);
          }
        })
        .catch((error) => {
          setErrorMessage(props.uploadError);
          setUploadState(FileUploadState.Error);
        });
    }
  };

  const convertUploadStatusToStatus = (status: FileUploadState): Status => {
    switch (status) {
      case FileUploadState.NotStarted:
      case FileUploadState.PreparationComplete:
        return Status.Progress;
      case FileUploadState.Complete:
        return Status.Success;
      case FileUploadState.Error:
        return Status.Failed;
      default:
        return Status.Progress;
    }
  };

  return (
    <FilePreview
      percent={percentCompleted}
      name={props.preloadedFile.file.name}
      id={props.preloadedFile.id}
      status={convertUploadStatusToStatus(uploadState)}
      onRemove={onFileRemove}
      onRetry={onUploadRetry}
      texts={{
        uploadError: props.uploadError,
        removeButton: props.removeButton,
        retryButton: props.retryButton,
      }}
      mediaType={props.mediaType}
    />
  );
};
