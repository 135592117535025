import { MediaFile } from './MediaFile';
import { TranscodeStatus } from './TranscodeStatus'

export class MediaObject {
  ownerId: string;
  transcodeStatus: TranscodeStatus;
  durationInMs: number;
  outputFiles: MediaFile[];
  thumbnails: string[];
  id: string;
  originalFileName: string;
  s3ObjectKey: string;
  size: number;
  mimeType: string;
  created: Date;
  modified: Date;
}
