import React from "react";
import { useTranslation } from "i18n";
import "./CopyrightInfo.scss";

interface ICopyrightInfoProps {
  text: string;
}

export const CopyrightInfo: React.FC<ICopyrightInfoProps> = ({ text }) => {
  const { t } = useTranslation();
  return (
    <div className="c-copyright-info pb-2.5 md:pb-0 ">
      <div className="flex flex-col items-start justify-between md:items-center md:flex-row ">
        <div
          className="p-2.5"
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        <a
          href="javascript:CookieScript.instance.show()"
          className="text-inherit px-2.5 print:hidden"
        >
          {t("common:label.cookieSettings")}
        </a>
      </div>
    </div>
  );
};
