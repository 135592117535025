export const availabilityCalendarFallback = {
  buttonTitle: "Availability Calendar",
  title: "Update availability calendar",
  explanation:
    "This calendar helps you keep track of your schedule and indicates the dates you are not available for work. This information is visible to you (and your agent) only and is not displayed publicly.",
  datePickerLabel: "Enter dates:",
  datePickerPlaceholder: "Select date(s)",
  titleLabel: "Title:",
  tagminCheckboxLabel: "Sync with {image} account",
  tagminCheckboxDescription:
    "By doing so, these dates will reflect on your Tagmin account as well.",
  datesListTitle: "Unavailable dates",
  enterDatesTooltip: {
    header: "Select your date/s",
    content:
      "Use the calendar to select a date or date range to signify your unavailability",
  },
  unavailableListTooltip: {
    header: "Your Unavailability",
    content:
      "These are the dates previously added to signify your unavailability",
  },
  unavailableListEmpty: "No dates have been added.",
  submitCtaButton: "Add dates",
  cancelButton: "Cancel",
  errorPopupTitle: "Error - Please refresh",
  titleMaxLengthMessage: "Title cannot exceed 50 characters",
  tooltipCloseButtonAriaLabel: "Close tooltip",
  tagminComingSoon: "The ability to integrate with {image} is coming soon.",
};

export const heritageAndEthnicityFallback = {
  heritageAndEthnicityTitle: "Heritage & Ethnicity",
  heritageAndEthnicityHeader:
    "To indicate your ethnicity, you may select a broader ethnic background or add specific ethnicities in the fields below. Alternatively, you can choose a combination of both options.",
  heritageAndEthnicityWarningDescription:
    "Are you sure you want to add more than 3 ethnicities? Please ensure that you are only adding the most relevant information.",
  ethnicBackgroundLabel: "Add ethnic background:",
  ethnicBackgroundPlaceholder: "Please select",
  ethnicBackgroundTooltipContent: "Add ethnic background tooltip content",
  specificEthnicitiesLabel: "Add specific ethnicities:",
  specificEthnicitiesPlaceholder: "Please start typing...",
  specificEthnicitiesTooltipContent: "Add specific ethnicities tooltip content",
  heritageAndEthnicityFurtherInformationLabel:
    "Further information (optional):",
  heritageAndEthnicityFurtherInformationTooltipContent:
    "Further information tooltip content",
  heritageAndEthnicityVisibilityLabel:
    "Do you want your ethnicity displayed on your profile?",
  heritageAndEthnicityVisibilityTooltipContent:
    "Do you want your ethnicity displayed on your profile content",
  heritageAndEthnicityVisibilityYesInfo:
    "By selecting 'Yes', this information will be visible on your profile at all times.  Everyone who has a link to your profile will be able to see this information, it will be public.  To restrict visibility choose 'No'.",
  heritageAndEthnicityVisibilityNoInfo:
    "By selecting 'No', this information will only be visible if a casting professional filters their Performer search results by ethnicity.",
  heritageAndEthnicityClose: "Close",
  heritageAndEthnicityContinue: "Continue to add",
  heritageAndEthnicityYes: "Yes",
  heritageAndEthnicityNo: "No",
};

export const profileFallbackTranslations = {
  seekRepresentation: "Seeking representation",
  seekRepresentationToolTip: "Performer is looking for representation",
  contactPerformerAgentEmailSubject:
    "A Spotlight agent has sent you a direct message",
};

export const breadcrumbsFallbackTranslations = {
  myAccountPageTitle: "My account",
  creditsPageTitle: "Credits",
  contactsPageTitle: "Contacts",
  videoPageTitle: "Video",
  audioPageTitle: "Audio",
};
export const profileStatsFallbackTranslations = {
  pageHeader: "Profile insights: 30-Day statistics",
  helpTipTitle: "Help Tips:",
  profileViews: {
    title: "Profile views",
    description:
      "Profile views represent the number of times your unique profile has been viewed by various individuals, including agents, casting professionals, other performers, and individuals in your network.",
    total: "Total unique profile views",
    helpTips: [
      "Update your profile with now and relevant information. <a target='_blank' href='https://www.spotlight.com/news-and-advice/pick-the-best-photo-for-your-spotlight-profile/'>Read article</a>",
      "Promote your profile link with your network. <a target='_blank' href='https://www.spotlight.com/news-and-advice/tips-and-advice/checking-your-social-media-footprint/'>Read article</a>",
    ],
  },
  submissionViews: {
    title: "Submissions",
    description:
      "Submission indicate the number of times you have been suggested for a role, either by yourself or your agent.  This metric reflects the level of consideration you receive for different casting opportunities.",
    total: "Number of role suggestions",
    helpTips: [
      "Stay proactive with casting calls on Spotlight. <a href='#'>Read article</a>",
      "Continuously improve your skills and build your profile. <a href='#'>View Upcoming events</a>",
    ],
  },
  searchResults: {
    title: "Search results",
    description:
      "This section provides insights into how frequently your profile appears in searches by casting directors, agents, and other performers.  These metrics indicate the extent to which your profile aligns with the criteria and preferences of those conducting searches.  The following sub-categories are included:",
    totalViewsCD: "Casting professional searches",
    totalViewsAgent: "Agent searches",
    totalViewsPerformer: "Performer searches",
    total: "Total search results",
    helpTips: [
      "5 ways to optimise your profile. <a target='_blank' href='https://www.spotlight.com/news-and-advice/25-ways-to-raise-your-spotlight-profile/'>View video guide</a>",
      "Using Spotlight’s search filters effectively. <a target='_blank' href='https://www.spotlight.com/news-and-advice/tips-and-advice/tips-to-help-be-found-in-spotlight-search/'>Read article</a>",
    ],
  },
  shortlists: {
    title: "Shortlist",
    description:
      "Being added to short list is no guarantee of work or an audition but means your profile has caught the attention of a casting director.",
    total: "Number of times added to Shortlists",
    helpTips: [
      "Audition preparations and feedback. <a target='_blank' href='https://www.spotlight.com/news-and-advice/interviews-podcasts/preparing-for-auditions-and-self-taping/'>View video guide</a>",
    ],
  },
  lastRefreshed: "Last refreshed:",
};
