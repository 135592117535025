import { Response } from "models";
import { InvalidCastError, HttpOperationError } from "shared/utils/exceptions";

const validateResponse = (response: globalThis.Response) => {
  if (!response.ok) {
    throw response;
  }
  return response;
};

export class HttpClient {
  public get<TEntity>(
    request: string,
    params?: RequestInit
  ): Promise<Response<TEntity>> {
    const requestParams: RequestInit = {
      ...params,
      method: "GET",
    };

    return this.request<TEntity>(request, requestParams);
  }
  public post<TEntity>(
    request: string,
    params?: RequestInit
  ): Promise<Response<TEntity>> {
    const requestParams: RequestInit = {
      ...params,
      method: "POST",
    };

    return this.request<TEntity>(request, requestParams);
  }

  public put<TEntity>(
    request: string,
    params?: RequestInit
  ): Promise<Response<TEntity>> {
    const requestParams: RequestInit = {
      ...params,
      method: "PUT",
    };

    return this.request<TEntity>(request, requestParams);
  }

  public delete(request: string, params?: RequestInit): Promise<Response<any>> {
    const requestParams: RequestInit = {
      ...params,
      method: "DELETE",
    };

    return this.request<any>(request, requestParams);
  }

  public patch<TEntity>(
    request: string,
    params?: RequestInit
  ): Promise<Response<TEntity>> {
    const requestParams: RequestInit = {
      ...params,
      method: "PATCH",
    };

    return this.request<TEntity>(request, requestParams);
  }

  private async request<TEntity>(
    request: string,
    params: RequestInit
  ): Promise<Response<TEntity>> {
    const response = new Response<TEntity>();

    let originalResponse: globalThis.Response;

    try {
      originalResponse = await fetch(request, params);
    } catch (error) {
      throw new HttpOperationError(request, "Request failed", error);
    }

    if (originalResponse) {
      validateResponse(originalResponse);
      response.headers = originalResponse.headers;

      const contentType = originalResponse.headers.get("content-type");
      const isJsonResponse =
        contentType &&
        contentType.includes("application") &&
        contentType.includes("json");

      const responseClone = originalResponse.clone();

      const bodyBlob = await responseClone.clone().blob();
      const isContainsPayload = bodyBlob.size > 0;

      if (isJsonResponse && isContainsPayload) {
        try {
          const responseObject = await responseClone.clone().json();
          response.object = responseObject as TEntity;
        } catch (error) {
          const text = (await responseClone.text()).substr(0, 500);
          throw new InvalidCastError(
            "Invalid json casting",
            { text, request },
            error
          );
        }
      }
    }

    return response;
  }
}
