import React from "react";
import { VideoPlayer } from "component-library";

import { ButtonPanel } from "../ButtonPanel";
import { PropType } from "shared/utils/types";

import "./VideoPlayerContainer.scss";
import { IActionButtonProps } from "component-library/src/components/Atoms/ActionButton/ActionButton";

export interface IVideoPlayerContainerProps {
  playerConfig: PropType<VideoPlayer, "props">;
  controlPanelButtons?: (IActionButtonProps & { key: string | number })[];
}

export const VideoPlayerContainer: React.FC<IVideoPlayerContainerProps> = ({
  playerConfig,
  controlPanelButtons = [],
}) => {
  const [selectedVideo, setSelectedVideo] = React.useState(
    playerConfig.selectedVideo
  );

  const hasPanel = controlPanelButtons.length > 0;

  const containerClasses = `c-video-player-container ${
    hasPanel ? "with-panel" : ""
  }`;

  return (
    <div className={containerClasses}>
      <div className="c-video-player-container--player">
        <VideoPlayer
          {...playerConfig}
          selectedVideo={selectedVideo}
          selectVideo={setSelectedVideo}
        />
      </div>
      {hasPanel && (
        <div className="c-video-player-container--buttons">
          <ButtonPanel buttons={controlPanelButtons} />
        </div>
      )}
    </div>
  );
};
