import { HttpClient } from "services/HttpClient";
import { getTitaniumApiUrl, getApiUrl } from "services/UrlBuilder";
import { HALManager } from "core";
import UserManager from "app/core/user-manager";
import { JsonPatchOperation } from "shared/utils/http/json-patch-generator";
import {
  MediaAllocation,
  MediaAsset,
  MediaAssetType,
  MediaMetadata,
  MediaMetadataPayload,
  MediaObject,
  TranscodeStatus,
  OutputGroupType,
} from "../models";
import { transformMillisecToSec } from "component-library";
import { authorizationService } from "shared-auth";

interface IResponseWrapper<TEntity> {
  value: TEntity;
}

export class MediaService {
  private httpClient: HttpClient = new HttpClient();

  public async getMediaMetadata(assetType?: MediaAssetType) {
    const urlMediaMetadataRequest = getApiUrl(
      HALManager.getMediaUrl(UserManager.userId)
    );
    const { object: mediaMetadata } = await this.httpClient.get<
      MediaMetadata[]
    >(urlMediaMetadataRequest);

    return assetType
      ? mediaMetadata.filter(({ mediaType }) => mediaType === assetType)
      : mediaMetadata;
  }

  public async getMediaAssets(
    assetType: MediaAssetType,
    closeErrorPopup: () => void
  ) {
    const filteredMedia = await this.getMediaMetadata(assetType);

    return Promise.all(
      filteredMedia.map(
        async (item) =>
          await this.getMediaAssetByMetadata(item, closeErrorPopup)
      )
    );
  }

  public async updateMediaAssetsMetadata(
    updatedMetadata: MediaMetadata[],
    mediaAssets: MediaAsset[],
    notFoundCallback?: () => void
  ) {
    return Promise.all(
      updatedMetadata.map(async (updatedMetadata) => {
        const assetToUpdate = mediaAssets.find(
          (asset) => asset.id === updatedMetadata.id
        );
        if (assetToUpdate) {
          return this.mapUpdatedMediaMetadata(assetToUpdate, updatedMetadata);
        } else {
          return await this.getMediaAssetByMetadata(
            updatedMetadata,
            notFoundCallback
          );
        }
      })
    );
  }

  public async getMediaObjectById(mediaInfoId: string) {
    const urlMediaObjectRequest = getTitaniumApiUrl(
      HALManager.getMediaObjectByIdUrl(mediaInfoId)
    );
    const {
      object: { value: mediaObject },
    } = await this.httpClient.get<IResponseWrapper<MediaObject>>(
      urlMediaObjectRequest
    );

    return mediaObject;
  }

  public async getMediaObject(
    mediaInfoId: string,
    notFoundCallback?: () => void
  ): Promise<MediaObject> {
    let mediaObject;
    try {
      mediaObject = await this.getMediaObjectById(mediaInfoId);
    } catch (error) {
      if (error.status === 404) {
        notFoundCallback && notFoundCallback();
      }
      mediaObject = { id: mediaInfoId, transcodeStatus: TranscodeStatus.Error };
    }

    return mediaObject;
  }

  public async getMediaMetadataById(id: string) {
    const urlProfileMediaRequest = getApiUrl(
      HALManager.getMediaByIdUrl(UserManager.userId, id)
    );
    const { object: mediaMetadata } = await this.httpClient.get<MediaMetadata>(
      urlProfileMediaRequest
    );

    return mediaMetadata;
  }

  public async deleteMediaAsset(
    mediaId: string,
    mediaInfoId: string,
    notFoundCallback: () => void
  ) {
    const urlMediaMetadataRequest = getApiUrl(
      HALManager.deleteMediaUrl(UserManager.userId, mediaId)
    );

    try {
      await this.httpClient.delete(urlMediaMetadataRequest);
      await this.deleteMediaObjectById(mediaInfoId);
    } catch (error) {
      if (error.status === 404) {
        notFoundCallback();
      }
    }
  }

  public async deleteMediaObjectById(id: string) {
    const urlMediaObjectRequest = getTitaniumApiUrl(
      HALManager.deleteMediaObjectByIdUrl(id)
    );

    await this.httpClient.delete(urlMediaObjectRequest, {
      headers: {
        Authorization: authorizationService.getAuthorizationHeader(),
        "X-Spotlight-Performer": UserManager.artistRef,
      } as any,
    });
  }

  public async updateMediaMetadata(
    mediaId: string,
    difference: JsonPatchOperation[],
    assetType?: MediaAssetType
  ) {
    const urlMediaMetadataRequest = getApiUrl(
      HALManager.patchMediaUrl(UserManager.userId, mediaId)
    );
    const requestParams = { body: JSON.stringify(difference) };

    const { object: mediaMetadata } = await this.httpClient.patch<
      MediaMetadata[]
    >(urlMediaMetadataRequest, requestParams);

    return assetType
      ? mediaMetadata.filter(({ mediaType }) => mediaType === assetType)
      : mediaMetadata;
  }

  public async createMediaMetadata(
    payload: MediaMetadataPayload
  ): Promise<MediaMetadata> {
    const urlMediaMetadataRequest = getApiUrl(
      HALManager.postMediaUrl(UserManager.userId)
    );
    const requestParams = { body: JSON.stringify(payload) };

    const { object: mediaMetadata } = await this.httpClient.post<MediaMetadata>(
      urlMediaMetadataRequest,
      requestParams
    );

    return mediaMetadata;
  }

  public async getAllowedStorageSpace(mediaType: MediaAssetType) {
    const urlMediaMetadataRequest = getApiUrl(
      HALManager.getMediaAllocationsUrl(UserManager.userId)
    );
    const { object: allocation } = await this.httpClient.get<MediaAllocation>(
      urlMediaMetadataRequest
    );

    return mediaType === MediaAssetType.Audio
      ? {
          maxAllowedStoreInSec: transformMillisecToSec(
            allocation.audio.maxAllowedUpload
          ),
          maxAllowedDisplayInSec: transformMillisecToSec(
            allocation.audio.maxAllowedVisible
          ),
        }
      : {
          maxAllowedStoreInSec: transformMillisecToSec(
            allocation.video.maxAllowedUpload
          ),
          maxAllowedDisplayInSec: transformMillisecToSec(
            allocation.video.maxAllowedVisible
          ),
        };
  }

  public getUsedStorageSpace(mediaAssets: MediaAsset[]) {
    const visibleMediaAssets = mediaAssets.filter(({ visible }) => visible);
    const reducer = (acc: number, { durationInMs }: MediaAsset) =>
      acc + transformMillisecToSec(durationInMs);

    return {
      usedDisplayValueInSec: visibleMediaAssets.reduce(reducer, 0),
      usedStoreValueInSec: mediaAssets.reduce(reducer, 0),
    };
  }

  private async getMediaAssetByMetadata(
    mediaMetadata: MediaMetadata,
    notFoundCallback?: () => void
  ) {
    const mediaObject = await this.getMediaObject(
      mediaMetadata.mediaInfoId,
      notFoundCallback
    );

    return this.mapMediaAsset(mediaMetadata, mediaObject, false);
  }

  public mapUpdatedMediaObject(
    mediaAsset: MediaAsset,
    mediaObject: MediaObject
  ): MediaAsset {
    return this.mapMediaAsset(mediaAsset, mediaObject, false);
  }

  public createMediaAssetFromMeta(mediaMetadata: MediaMetadata): MediaAsset {
    return this.mapMediaAsset(mediaMetadata, new MediaObject(), true);
  }

  private mapMediaAsset(
    mediaMetadata: MediaMetadata | MediaAsset,
    mediaObject: MediaObject,
    isLoading: boolean
  ): MediaAsset {
    const {
      transcodeStatus,
      durationInMs = 0,
      thumbnails = [],
      outputFiles = [],
    } = mediaObject;
    const objectUrls =
      outputFiles.length > 0
        ? outputFiles.map((outputFile) => outputFile.objectUrls[0])
        : ([] as string[]);
    const downloadLink = this.extractDownloadLink(mediaObject);

    return {
      ...mediaMetadata,
      transcodeStatus,
      durationInMs,
      thumbnails,
      downloadLink,
      sources: objectUrls,
      selectedThumbnail: mediaMetadata.selectedThumbnail || 0,
      isMediaObjectLoading: isLoading,
    };
  }

  public mapUpdatedMediaMetadata(
    mediaAsset: MediaAsset,
    mediaMetadata: MediaMetadata
  ): MediaAsset {
    const { title, description, selectedThumbnail, sortOrder, visible } =
      mediaMetadata;

    return {
      ...mediaAsset,
      title: title,
      description: description,
      selectedThumbnail: selectedThumbnail || 0,
      sortOrder: sortOrder,
      visible: visible,
    };
  }

  public filterMediaAssets = (mediaAssets: MediaAsset[]) => {
    return mediaAssets.reduce(
      (acc, mediaAsset) => {
        mediaAsset.mediaType === MediaAssetType.Video
          ? acc.showreels.push(mediaAsset)
          : acc.audioClips.push(mediaAsset);
        return acc;
      },
      { showreels: [] as MediaAsset[], audioClips: [] as MediaAsset[] }
    );
  };

  private extractDownloadLink({ outputFiles = [] }: MediaObject): string {
    const fileTypeOutput = outputFiles.find(
      ({ outputGroupType }) => outputGroupType === OutputGroupType.FILE
    );

    return fileTypeOutput ? fileTypeOutput.objectUrls[0] : "";
  }
}

export default new MediaService();
