import React from "react";

import { ThumbnailBarItem } from "./ThumbnailBarItem";
import { ShimmeringStub } from "mediaModules/media/components/ShimmeringStub";

import "./ThumbnailBar.scss";

export interface IThumbnailBarProps {
  thumbnails: string[];
  selectThumbnail: (thumbnail: number) => void;
  selectedThumbnail: number;
  isLoading?: boolean;
}

export const ThumbnailBar: React.FC<IThumbnailBarProps> = ({
  thumbnails,
  selectedThumbnail,
  selectThumbnail,
  isLoading,
}) => {
  const renderLoadingThumbnails = () => {
    return (
      <div className="g-col-lg-2 g-col-md-3 g-col-sm-6">
        <div className="c-thumbnail-bar-item c-thumbnail-bar-item--loading">
          <ShimmeringStub />
        </div>
      </div>
    );
  };

  const renderLoadedThumbnails = () => {
    return thumbnails.map((thumbnail, index) => (
      <ThumbnailBarItem
        key={thumbnail + index}
        thumbnail={thumbnail}
        index={index}
        selectedThumbnail={selectedThumbnail}
        selectThumbnail={selectThumbnail}
      />
    ));
  };

  return (
    <div className="c-thumbnail-bar g-content-area">
      {isLoading ? renderLoadingThumbnails() : renderLoadedThumbnails()}
    </div>
  );
};
