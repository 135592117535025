import { v4 as uuid } from 'uuid';
import { ILogEntry } from './ILogEntry';
import { Logger } from './Logger';

const UNKNOWN_USER = 'UNKNOWN_USER';

const TAB_SESSION_ID = 'TAB_SESSION_ID';
const BROWSER_SESSION_ID = 'BROWSER_SESSION_ID';

export class LogHub {
  public userId: string;
  public readonly sessionId: string;

  private logListener: (entry: ILogEntry, forcePush?: boolean) => void;
  private loggerPool: Logger[] = [];

  public get tabSessionId() {
    return sessionStorage.getItem(TAB_SESSION_ID) || '';
  }

  public get browserSessionId() {
    return localStorage.getItem(BROWSER_SESSION_ID)|| ''
  }

  constructor(logListener: (entry: ILogEntry) => void, userId: string = UNKNOWN_USER) {
    this.userId = userId;
    this.sessionId = uuid();
    this.logListener = logListener;

    if (!this.tabSessionId) {
      sessionStorage.setItem(TAB_SESSION_ID, uuid());
    }

    if (!this.browserSessionId) {
      localStorage.setItem(BROWSER_SESSION_ID, uuid());
    }
  }

  public createLoggerInstance(domain: string): Logger {
    const logger = new Logger(this, domain);
    this.loggerPool.push(logger);

    return logger;
  }

  public registerEntry(entry: ILogEntry, forcePush: boolean = false) {
    this.logListener(entry, forcePush);
  }

  public killLogger(sessionId: string) {
    this.loggerPool = this.loggerPool.filter((logger) => logger.sessionId !== sessionId);
  }
}
