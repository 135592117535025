import React, { useState } from "react";
import { useTranslation } from "i18n";
import replaceLineBreaks from "../../Functional/helpers/replaceLineBreaks";

interface IShowMoreTextProps extends React.HTMLAttributes<HTMLDivElement> {
  paragraphText: string;
  characterLimit: number;
}

const ShowMoreParagraph = ({
  paragraphText,
  characterLimit,
  ...props
}: IShowMoreTextProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();

  const toggle = () => {
    setIsExpanded(!isExpanded);
  };

  const shouldTruncate = paragraphText.length > characterLimit;
  const displayedText =
    shouldTruncate && !isExpanded
      ? paragraphText.slice(0, characterLimit)
      : paragraphText;
  const actionText = isExpanded
    ? t("common:button.label.hide")
    : t("common:button.label.show");
  const dots = shouldTruncate && !isExpanded;

  const renderDisplayedLines = () => (
    <div
      dangerouslySetInnerHTML={{
        __html: replaceLineBreaks(displayedText || ""),
      }}
    />
  );

  return (
    <div className={props.className}>
      {renderDisplayedLines()}
      {dots && <span>...</span>}
      {shouldTruncate && (
        <a className="break-normal g-alternative-link" onClick={toggle}>
          {" "}
          {actionText}
        </a>
      )}
    </div>
  );
};

export default ShowMoreParagraph;
