import React from "react";
import { ReorderableList, debounce } from "component-library";
import cx from "classnames";

import { IShowreelsContent } from "./ShowreelsContent";
import { ShowreelThumb } from "../ShowreelThumb";
import { MediaAsset } from "mediaModules/media/models";
import { ShimmeringStub } from "mediaModules/media/components/ShimmeringStub";

import "./Showreels.scss";

const MOBILE_TABLET_WIDTH = 1200;

export interface IShowreelsProps {
  showreelsList: MediaAsset[];
  content: IShowreelsContent;
  setSelectedShowreels: (list: MediaAsset[]) => void;
  playbackVideo: (model: MediaAsset) => void;
  addNewShowreel: () => void;
  selectedShowreels: MediaAsset[];
  editShowreel: (showreel?: MediaAsset) => void;
  isMediaMetadataLoading: boolean;
  areMediaObjectsLoading: boolean;
  sortCompleted: (oldIndex: number, newIndex: number) => Promise<void>;
  disableReordering: boolean;
}

interface IShowreelsState {
  disableSorting: boolean;
}

export class Showreels extends React.Component<
  IShowreelsProps,
  IShowreelsState
> {
  private refsList: HTMLDivElement[] = [];

  constructor(props) {
    super(props);
    this.state = {
      disableSorting: false,
    };
  }

  public render() {
    const { showreelsList, isMediaMetadataLoading } = this.props;

    return (
      <div className="c-showreels">
        {isMediaMetadataLoading
          ? this.renderLoadingMediaMetadata()
          : this.renderLoadedMediaMetadata(showreelsList)}
      </div>
    );
  }

  public componentDidMount() {
    window.addEventListener("resize", this.debouncedResizeHandler, false);
    this.debouncedResizeHandler();
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.debouncedResizeHandler, false);
  }

  private renderLoadingMediaMetadata = () => {
    return (
      <div className="g-inner-grid-12 c-showreels__loading">
        <div className="g-col-lg-2 g-col-md-3 g-col-sm-12">
          <div className="c-showreels__loading-thumbnail">
            <ShimmeringStub />
          </div>
          <div className="c-showreels__loading-title">
            <ShimmeringStub />
          </div>
        </div>
      </div>
    );
  };

  private renderLoadedMediaMetadata = (showreelsList) => {
    return (
      <div className="c-showreels-list__wrapper">
        <ReorderableList
          axis="xy"
          list={showreelsList}
          distance={5}
          itemRenderer={this.renderShowreel}
          getUniqueKey={(showreel) => showreel.id}
          immovableTail={this.renderAddShowreel() as any}
          onSortStart={this.startSort}
          onSortEnd={this.sortCompleted}
          disableReordering={this.props.disableReordering}
          shouldCancelStart={() => this.state.disableSorting}
        />
      </div>
    );
  };

  private startSort = () => {
    if (this.refsList.length) {
      this.refsList.forEach((item) =>
        item.classList.remove("c-showreel-thumb--hover")
      );
    }
  };

  private sortCompleted = async ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    await this.props.sortCompleted(oldIndex, newIndex);

    if (this.refsList.length) {
      this.refsList.forEach((item) =>
        item.classList.add("c-showreel-thumb--hover")
      );
    }
  };

  private renderShowreel = (showreel: MediaAsset) => {
    const {
      content: { hiddenTitle, processingTitle, errorTitle },
      playbackVideo,
      selectedShowreels,
      editShowreel,
    } = this.props;

    return (
      <ShowreelThumb
        key={showreel.id}
        hiddenTitle={hiddenTitle}
        processingTitle={processingTitle}
        errorTitle={errorTitle}
        playbackVideo={playbackVideo}
        showreel={showreel}
        selectedShowreels={selectedShowreels}
        editShowreel={editShowreel}
        checkShowreel={this.checkShowreel(showreel)}
        getShowreelElements={this.getShowreelElements}
      />
    );
  };

  private getShowreelElements = (element: HTMLDivElement) => {
    this.refsList.push(element);
    return this.refsList;
  };

  private renderAddShowreel = (): JSX.Element => {
    const { content, addNewShowreel, areMediaObjectsLoading } = this.props;

    return (
      <a
        className={cx("c-showreels__add-showreel", {
          "c-showreels__add-showreel--disabled": areMediaObjectsLoading,
        })}
        onClick={addNewShowreel}
        tabIndex={0}
      >
        <i className="c-showreels__add-icon icon-plus" />
        <span className="c-showreels__add-label">{content.addVideoLabel}</span>
      </a>
    );
  };

  private checkShowreel =
    (selectedShowreel: MediaAsset) =>
    (isChecked: boolean): void => {
      const { selectedShowreels, setSelectedShowreels } = this.props;

      const newSelectedShowreels = [...selectedShowreels];
      const index = newSelectedShowreels.findIndex(
        (item) => item.id === selectedShowreel.id
      );

      isChecked
        ? newSelectedShowreels.push(selectedShowreel)
        : newSelectedShowreels.splice(index, 1);

      setSelectedShowreels(newSelectedShowreels);
    };

  private handleWindowResize = () => {
    const disableSorting = window.innerWidth < MOBILE_TABLET_WIDTH;
    this.setState({ disableSorting });
  };

  private debouncedResizeHandler = debounce(this.handleWindowResize, 300);
}
