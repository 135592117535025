import React from "react";
import {
  Input,
  InputGroup,
  Helper,
  ITooltipContent,
  requiredNonEmptyString,
  WatchedFunction,
  IGroupValidationResult,
} from "component-library";

import { IEditVideoFormContent, getTooltipProps } from "./EditVideoFormContent";
import { ThumbnailBar } from "mediaModules/media/components/ThumbnailBar";
import { ThumbnailVideoViewer } from "../ThumbnailVideoViewer";
import { MediaAsset } from "mediaModules/media/models";
import { ShimmeringStub } from "mediaModules/media/components/ShimmeringStub";

import "./EditVideoForm.scss";

const VALIDATE = new WatchedFunction(() => undefined);
const TITLE_MAX_LENGTH = 255;

export interface IEditVideoFormProps {
  content: IEditVideoFormContent;
  videoModel: MediaAsset;
  changeModel: (videoModel: MediaAsset) => void;
  saveChanges: () => void;
  showMediaPopup: (videoModel: MediaAsset) => void;
  children: (submit: () => void) => React.ReactElement;
  isMediaMetadataLoading: boolean;
}

export interface IEditVideoFormState {
  isFormSubmitted: boolean;
  validationData: {
    title: {
      invalid: boolean;
      validationMessage: string;
    };
  };
}

export class EditVideoForm extends React.Component<
  IEditVideoFormProps,
  IEditVideoFormState
> {
  private isValidationChanged: boolean = false;
  private initialTitleValue: string = this.props.videoModel.title || "";

  public readonly state: Readonly<IEditVideoFormState> = {
    validationData: {
      title: {
        invalid: false,
        validationMessage: "",
      },
    },
    isFormSubmitted: false,
  };

  public render() {
    const { children, isMediaMetadataLoading } = this.props;

    return (
      <React.Fragment>
        {isMediaMetadataLoading
          ? this.renderMetadataLoading()
          : this.renderMediaAsset()}
        {children(this.saveChanges)}
      </React.Fragment>
    );
  }

  private renderMetadataLoading = () => {
    return (
      <div className="g-content-area c-edit-video-form c-edit-video-form__loading">
        <div className="g-col-md-4 g-col-lg-5">
          <div className="c-edit-video-form__loading-thumbnail">
            <div className="c-edit-video-form__loading-thumbnail-cover">
              <ShimmeringStub />
            </div>
          </div>
          <div className="c-edit-video-form__loading-title">
            <ShimmeringStub />
          </div>
        </div>
      </div>
    );
  };

  private renderMediaAsset = () => {
    const { content, videoModel, showMediaPopup } = this.props;
    const { validationData, isFormSubmitted } = this.state;

    const formConfig = this.setFormConfig();

    return (
      <div className="g-content-area c-edit-video-form">
        <div className="g-col-md-4 g-col-lg-5">
          <ThumbnailVideoViewer
            title={this.initialTitleValue}
            videoModel={videoModel}
            playback={showMediaPopup.bind(this, videoModel)}
            cover={videoModel.thumbnails[videoModel.selectedThumbnail]}
          />
        </div>
        <div className="g-col-md-8 g-col-lg-7" />
        <div className="g-col-md-6 g-col-lg-6">
          <InputGroup
            className="c-edit-video-form__control"
            inputConfigs={formConfig}
            validationDone={this.validationPerformed}
            triggerValidation={VALIDATE}
          >
            <Input
              id="title"
              className="c-edit-video-form__input"
              type="text"
              value={videoModel.title}
              name="title"
              label={content.videoTitleFieldLabel}
              invalid={validationData.title.invalid}
              validationMessage={validationData.title.validationMessage}
              isFormSubmitted={isFormSubmitted}
              annex={this.getTitleAnnex(content.videoTitleFieldTooltip)}
              valueChanged={this.changeTitle}
              maxLength={TITLE_MAX_LENGTH}
              disabled={videoModel.isMediaObjectLoading}
            />
          </InputGroup>
        </div>

        <div className="g-col-lg-12">
          <div className="c-edit-video-form__thumbnails">
            <h4 className="c-edit-video-form__thumbnails-heading">
              {content.chooseThumbnail}
            </h4>
            <ThumbnailBar
              thumbnails={videoModel.thumbnails}
              selectThumbnail={this.selectThumbnail}
              selectedThumbnail={videoModel.selectedThumbnail}
              isLoading={videoModel?.isMediaObjectLoading}
            />
          </div>
        </div>
      </div>
    );
  };

  public componentDidUpdate() {
    if (this.isValidationChanged) {
      this.isValidationChanged = false;
      VALIDATE.call();
    }
  }

  private changeTitle = (title: string) => {
    this.isValidationChanged = true;
    this.props.changeModel({ ...this.props.videoModel, title });
    VALIDATE.call();
  };

  private selectThumbnail = (selectedThumbnail: number) => {
    this.props.changeModel({ ...this.props.videoModel, selectedThumbnail });
  };

  private getTitleAnnex = (content: ITooltipContent) => (
    <Helper tooltip={getTooltipProps(content)} />
  );

  private saveChanges = () => {
    this.setState({ isFormSubmitted: true }, () => {
      const { validationData } = this.state;
      const invalidForm = Object.keys(validationData).some(
        (key) => validationData[key].invalid
      );
      if (!invalidForm) {
        this.props.saveChanges();
      }
    });
  };

  private validationPerformed = (result: IGroupValidationResult) => {
    const validationData = {
      ...this.state.validationData,
      ...result.validationData,
    };
    this.setState({ validationData });
  };

  private setFormConfig = () => {
    return [
      {
        name: "title",
        value: this.props.videoModel.title,
        validators: [
          requiredNonEmptyString(this.props.content.videoTitleFieldRequired),
        ],
      },
    ];
  };
}
