import React from "react";

import { ICapacityBarContent } from "mediaModules/media/components/CapacityBar";

import "./SpaceBar.scss";

export interface ISpaceBarProps {
  isLoading?: boolean;
  entireValue: number;
  usedValue: number;
  content: ICapacityBarContent;
}

export const SpaceBar: React.FC<ISpaceBarProps> = ({
  isLoading,
  entireValue,
  usedValue,
  content,
}) => {
  const tapWidth =
    entireValue >= usedValue ? `${(usedValue / entireValue) * 100}%` : "100%";

  const formatCapacityLabel = (entireValue: number, usedValue: number) => {
    return {
      remaining:
        entireValue >= usedValue
          ? `${Math.floor((entireValue - usedValue) / 60)} ${
              content.minLabel
            } ${String(Math.ceil((entireValue - usedValue) % 60)).padStart(
              2,
              "0"
            )} ${content.secLabel} ${content.remainingLabel}`
          : `00 ${content.minLabel} 00 ${content.secLabel} ${content.remainingLabel}`,
      used: `${Math.floor(usedValue / 60)} ${content.minLabel} ${String(
        Math.floor(usedValue % 60)
      ).padStart(2, "0")} ${content.secLabel} ${content.usedLabel}`,
    };
  };
  const { remaining, used } = formatCapacityLabel(entireValue, usedValue);

  const renderLoadingBar = () => {
    return (
      <React.Fragment>
        <div className="c-space-bar__bar-container c-space-bar__bar-container--loading">
          <div className="c-space-bar__entire-value-block c-space-bar__entire-value-block--loading" />
        </div>
        <div className="c-space-bar__loading-container">
          <span className="c-space-bar__loading-content">
            {content.loading}
          </span>
        </div>
      </React.Fragment>
    );
  };

  const renderValuableBar = () => {
    return (
      <React.Fragment>
        <div className="c-space-bar__bar-container">
          <div
            className="c-space-bar__used-value-block"
            style={{ width: tapWidth }}
          />
          <div className="c-space-bar__entire-value-block" />
        </div>
        <div className="c-space-bar__units-container">
          <span className="c-space-bar__used-value-content">{used}</span>
          <span className="c-space-bar__entire-value-content">{remaining}</span>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="c-space-bar">
      {isLoading ? renderLoadingBar() : renderValuableBar()}
    </div>
  );
};
