import { i18n } from "i18n";

export function getPerformersSearchText() {
  return {
    inputPlaceholder: i18n.t("common:nav.search.input.placeholder"),
    noResultsFound: i18n.t("common:nav.search.noResults"),
    showMoreResults: i18n.t("common:nav.search.moreResults"),
    performerTypes: {
      youngPerformerLabel: i18n.t("common:nav.search.label.yp"),
      graduateLabel: i18n.t("common:nav.search.label.grad"),
      performerLabel: i18n.t("common:nav.search.label.performer"),
    },
  };
}
