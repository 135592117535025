import React from "react";
import { authorizationService } from "shared-auth";
import { HALManager, UserManager } from "core";
import { FetchInterceptor, IRequestObject } from "shared/utils/http";

import {
  getApiUrl,
  getLiteAgentApiUrl,
  getSearchAndFiltersApiUrl,
  getShortlistsApiUrl,
} from "services/UrlBuilder";

export default function withIdentification<P>(
  WrappedComponent: React.ComponentClass<P> | React.FC<P>
): typeof React.Component {
  return class extends React.Component<P> {
    constructor(props: Readonly<P>) {
      super(props);
      this.registerFetchInterceptor();
    }

    public render() {
      return <WrappedComponent {...this.props} />;
    }

    private registerFetchInterceptor = () => {
      FetchInterceptor.register({
        request: this.requestHandler,
      });
    };

    private requestHandler = (request: IRequestObject) => {
      const requestUrl = request.url?.toLowerCase();

      const isProfileApiRequest =
        requestUrl.includes(getApiUrl().toLocaleLowerCase()) &&
        !requestUrl.includes(HALManager.getFacetCategoriesUrl());
      const isLiteAgentApi = requestUrl.includes(
        getLiteAgentApiUrl().toLocaleLowerCase()
      );
      const isShortListApi = requestUrl.includes(
        getShortlistsApiUrl().toLocaleLowerCase()
      );
      const isSearchAndFiltersApi = requestUrl.includes(
        getSearchAndFiltersApiUrl().toLocaleLowerCase()
      );

      const useAuthHeaders =
        isProfileApiRequest ||
        isLiteAgentApi ||
        isShortListApi ||
        isSearchAndFiltersApi;

      if (
        authorizationService.isAuthenticated() &&
        request.config &&
        useAuthHeaders
      ) {
        const headers: any = {
          Authorization: authorizationService.getAuthorizationHeader(),
          "Content-Type": "application/json",
        };

        if (isProfileApiRequest) {
          headers["X-Spotlight-Performer"] = UserManager.artistRef;
        }

        request.config.headers = { ...request.config.headers, ...headers };
      } else if (isProfileApiRequest && request.config) {
        request.config.headers = {
          ...request.config.headers,
          ...({
            Authorization: authorizationService.getAuthorizationHeader(),
            "X-Spotlight-Performer": UserManager.artistRef,
            "Content-Type": "application/json",
          } as any),
        };
      }

      return request;
    };
  };
}
