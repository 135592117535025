import React from "react";
import { Copyright, Footer, getFooterLinks } from "component-library";
import { withRouter, RouteComponentProps } from "react-router-dom";

import compose from "shared/utils/compose";
import {
  withGlobalContext,
  IGlobalContextProps,
} from "shared/contexts/GlobalContext";
import {
  withTranslationF,
  ITranslationContextProps,
} from "contexts/TranslationContext";
import { CopyrightInfo } from "components/common/CopyrightInfo";

const VIEW_PIN_PATTERN = /\d{4}-\d{4}-\d{4}\/?$/;

export interface IFooterSectionProps
  extends RouteComponentProps,
    IGlobalContextProps,
    ITranslationContextProps {}
export class FooterSection extends React.Component<IFooterSectionProps> {
  public render() {
    const { match, location, translator } = this.props;

    const copyrightInfo = translator
      .getTranslator("", true)
      .getTranslationFor("common.copyrightInfo");

    let footerComponent: React.ReactNode = null;

    const isProfileRootPage = Boolean(
      `${match.path}`.match(location.pathname.replace(/\//gi, ""))
    );
    const isViewPingPage = Boolean(
      window.location.pathname.match(VIEW_PIN_PATTERN)
    );

    const footer = getFooterLinks();

    if (isProfileRootPage || isViewPingPage) {
      footerComponent = (
        <React.Fragment>
          <CopyrightInfo text={copyrightInfo} />
          <Copyright address={footer.texts.copyrightAddress} />
        </React.Fragment>
      );
    } else {
      footerComponent = (
        <Footer texts={footer.texts} navigationLinks={footer.navigationLinks} />
      );
    }

    return footerComponent;
  }
}

export default compose(
  withGlobalContext,
  withRouter,
  withTranslationF()
)(FooterSection);
