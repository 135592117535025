import React from "react";

import { Omit } from "../../utils/types";

export type SubscriberFunction = (data: any) => void;

export interface IGlobalContext {
  addListener(name: string, func: SubscriberFunction): void;
  removeListener(name: string, func: SubscriberFunction): void;
  notifyListener(name: string, data?: any, delayedStart?: boolean): void;
  removeContainer(name: string): void;
}

export class GlobalContext implements IGlobalContext {
  private eventContainers: EventContainer[] = [];
  private eventPool: { name: string; data: any }[] = [];

  public addListener(name: string, func: SubscriberFunction) {
    const eventContainer: EventContainer | undefined = this.getContainer(name);

    if (eventContainer) {
      eventContainer.addSubscriber(func);
    } else {
      this.createContainer(name, func);
    }

    if (
      this.eventPool.length > 0 &&
      this.eventPool.some((event) => event.name === name)
    ) {
      const newPool: { name: string; data: any }[] = [];

      for (let index = 0; index < this.eventPool.length; index++) {
        const event = this.eventPool[index];
        if (event.name === name) {
          func(event.data);
        } else {
          newPool.push(event);
        }
      }

      this.eventPool = newPool;
    }
  }

  public removeListener(name: string, func: SubscriberFunction) {
    const eventContainer: EventContainer | undefined = this.getContainer(name);
    if (eventContainer) {
      eventContainer.removeSubscriber(func);
    }
  }

  public notifyListener(
    name: string,
    data: any,
    delayedStart: boolean = false
  ) {
    const eventContainer: EventContainer | undefined = this.getContainer(name);
    if (eventContainer) {
      eventContainer.initSubscribers(data);
    } else {
      if (delayedStart) {
        this.eventPool.push({ name, data });
      }
    }
  }

  public removeContainer(name: string) {
    const eventContainer: EventContainer | undefined = this.getContainer(name);
    if (eventContainer) {
      this.eventContainers.splice(
        this.eventContainers.indexOf(eventContainer),
        1
      );
    }
  }

  private getContainer(name: string): EventContainer | undefined {
    return this.eventContainers.find((container) => container.name === name);
  }

  private createContainer(name: string, func: SubscriberFunction) {
    const container = new EventContainer(name);
    container.addSubscriber(func);
    this.eventContainers.push(container);
  }
}

export class EventContainer {
  private subscribers: SubscriberFunction[] = [];

  public readonly name: string;

  constructor(name: string) {
    this.name = name;
  }

  public addSubscriber(func: SubscriberFunction) {
    this.subscribers.push(func);
  }

  public removeSubscriber(func: SubscriberFunction) {
    this.subscribers.splice(this.subscribers.indexOf(func), 1);
  }

  public initSubscribers(data: any) {
    this.subscribers.forEach((subscriber) => {
      subscriber(data);
    });
  }
}

export interface IGlobalContextProps {
  globalContext: IGlobalContext;
}

export const GlobalRouteContext = React.createContext<IGlobalContext>(
  new GlobalContext()
);

export const GlobalRouteContextConsumer = GlobalRouteContext.Consumer;

export function withGlobalContext<P extends IGlobalContextProps>(
  Component: React.ComponentClass<P> | React.FC<P>
): React.FC<Omit<P, keyof IGlobalContextProps>> {
  return function BoundComponent(props: Omit<P, keyof IGlobalContextProps>) {
    return (
      <GlobalRouteContextConsumer>
        {(value) => {
          const newProps = {
            ...props,
            globalContext: value,
          } as any as P;

          return <Component {...newProps} />;
        }}
      </GlobalRouteContextConsumer>
    );
  };
}
