import { LocalizationUtils } from "component-library";

import { Settings, ETagList } from "models";
import { GlobalStorage } from "app/generics";

const SETTING_MANAGER_CONTAINER = "SETTING_MANAGER_CONTAINER";
const ETAG_CONTAINER = "ETAG_CONTAINER";

export class SettingManager {
  public set settings(settings: Partial<Settings>) {
    GlobalStorage.updateValue(SETTING_MANAGER_CONTAINER, settings);
  }

  public get settings() {
    return GlobalStorage.getValue<Settings>(SETTING_MANAGER_CONTAINER);
  }

  public set eTag(eTag: ETagList) {
    GlobalStorage.updateValue(ETAG_CONTAINER, eTag);
  }

  public get eTag() {
    return GlobalStorage.getValue<ETagList>(ETAG_CONTAINER);
  }

  public get localization() {
    const localizationInfo = LocalizationUtils.getUserLocalizationPreference();

    return localizationInfo.locale;
  }

  public get defaultLocalization() {
    return LocalizationUtils.DEFAULT_LOCALE;
  }

  public get language() {
    const localizationInfo = LocalizationUtils.getUserLocalizationPreference();

    return localizationInfo.language;
  }

  public get location() {
    const localizationInfo = LocalizationUtils.getUserLocalizationPreference();

    return localizationInfo.location;
  }
}

export default new SettingManager();
