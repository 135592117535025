import React from "react";
import { useField } from "formik";
import { useFormikScrollToError } from "shared-hooks";

import { InputLabel } from "../../InputsCommon/InputLabel";

import { RadioFormik } from "./RadioFormik";
import { ERROR, InputWrapper } from "../../InputsCommon/InputWrapper";

import "./RadioGroupFormik.scss";

interface IRadioButton {
  value: any;
  label?: string;
  disabled?: boolean;
  buttonClassName?: string;
}

interface IRadioGroupFormikProps {
  name: string;
  label?: string;
  boldLabel?: boolean;
  radioButtons: IRadioButton[];
  className?: string;
  annex?: JSX.Element;
  tooltipComponent?: () => JSX.Element;
  tooltipPosition?: "start" | "end";
  onChange?: (value: string) => void;
}

export const RadioGroupFormik: React.FC<IRadioGroupFormikProps> = ({
  name,
  label,
  boldLabel,
  radioButtons,
  className,
  annex,
  tooltipComponent,
  tooltipPosition,
  onChange,
}) => {
  const [_, meta] = useField(name);
  const invalid = meta.touched && meta.error ? ERROR : undefined;

  const { fieldRef, containerElementRef } = useFormikScrollToError<
    HTMLDivElement,
    HTMLDivElement
  >(name);

  return (
    <div style={{ position: "relative" }}>
      <div
        ref={containerElementRef}
        style={{ position: "absolute", top: "-55px" }}
      />
      <InputWrapper invalid={invalid} validationMesssage={meta.error}>
        <fieldset className="radio-group-formik__container">
          <div
            className={`flex items-baseline ${
              tooltipPosition === "end" ? "justify-between" : "gap-2"
            }`}
          >
            {label && (
              <InputLabel label={label} annex={annex} bold={boldLabel} />
            )}
            {tooltipComponent && tooltipComponent()}
          </div>

          <div className="radio-group-formik__fields" ref={fieldRef}>
            {radioButtons.map(
              ({ value, disabled, label, buttonClassName = "" }) => (
                <RadioFormik
                  key={value}
                  name={name}
                  label={label}
                  value={value}
                  disabled={disabled}
                  className={`${className} ${buttonClassName}`}
                  onChange={onChange}
                  testId={`${name}-radio-button-${label}`}
                />
              )
            )}
          </div>
        </fieldset>
      </InputWrapper>
    </div>
  );
};
