import React from "react";
import { debounce, Spinner, Stub } from "component-library";

import { ImageLocation, Photo } from "models";

import {
  getImageSourcesSettings,
  getImageUrl,
  PHOTO_CONTAINER_WIDTH_LIST,
} from "services/UrlBuilder";

import { Overlay } from "components/common/Overlay";
import { Picture } from "components/common/Picture";

import "./PhotoContainer.scss";

export interface IPhotoContainerProps {
  photo?: Photo;
  texts?: {
    hiddenOverlayTitle: string;
  };
  loadComplete?: () => void;
  errorHandler?: () => void;
}

interface IPhotoContainerState {
  isCreditsVisible: boolean;
  hasError: boolean;
}

const MIN_PHOTO_HEIGHT = 200;

export default class PhotoContainer extends React.Component<
  IPhotoContainerProps,
  IPhotoContainerState
> {
  public readonly state: Readonly<IPhotoContainerState> = {
    isCreditsVisible: false,
    hasError: false,
  };

  private imageRef: React.RefObject<HTMLImageElement> =
    React.createRef<HTMLImageElement>();
  private imageContainerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();

  constructor(props: IPhotoContainerProps) {
    super(props);

    window.addEventListener("resize", this.adaptImageSizeDebounced, false);
    window.addEventListener(
      "orientationchange",
      this.adaptImageSizeDebounced,
      false
    );
  }

  public render() {
    const { photo } = this.props;

    return (
      <div ref={this.imageContainerRef} className="c-photo-view">
        {photo ? this.renderImage() : <Spinner />}
      </div>
    );
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.adaptImageSizeDebounced, false);
    window.removeEventListener(
      "orientationchange",
      this.adaptImageSizeDebounced,
      false
    );
  }

  private renderImage = () => {
    const { photo } = this.props;
    const { hasError } = this.state;
    let view: React.ReactNode = null;

    if (photo && !hasError) {
      const { isCreditsVisible } = this.state;
      const containerClassName = `c-photo-view__image-wrapper ${
        isCreditsVisible ? "active touched" : ""
      }`;
      const imageSourcesSettings = getImageSourcesSettings(
        photo.url,
        PHOTO_CONTAINER_WIDTH_LIST
      );

      view = (
        <div className={containerClassName} onClick={this.toggleCredits}>
          <Picture
            sourcesSettings={imageSourcesSettings}
            imgRef={this.imageRef}
            className="c-photo-view__image"
            src={getImageUrl(photo.url, ImageLocation.ViewPhoto)}
            aria-label={photo.photographer}
            onError={this.handleError}
            onLoad={this.imageLoad}
          />
          {this.renderIdPanel(photo)}
          {this.renderPhotographer(photo)}
          {this.renderHiddenOverlay(photo)}
        </div>
      );
    } else if (hasError) {
      view = <Stub />;
    }

    return view;
  };

  private renderIdPanel = ({ userFriendlyId }: Photo) =>
    userFriendlyId && (
      <div className="c-photo-view__image-id">ID: {userFriendlyId}</div>
    );

  private renderHiddenOverlay = ({ isHidden }: Photo) => {
    const { texts: { hiddenOverlayTitle = "" } = {} } = this.props;

    return isHidden && <Overlay title={hiddenOverlayTitle} icon="icon-hide" />;
  };

  private renderPhotographer = ({ photographer, description }: Photo) => {
    const credits = [photographer, description].filter(Boolean).join(" - ");

    return <div className="c-photo-view__copyright">© {credits}</div>;
  };

  public toggleCredits = () =>
    this.setState({ isCreditsVisible: !this.state.isCreditsVisible });

  private imageLoad = () => {
    this.adaptImageSize();
    this.props.loadComplete && this.props.loadComplete();
  };

  private handleError = () => {
    this.setState({ hasError: true });
    this.props.errorHandler && this.props.errorHandler();
  };

  private adaptImageSize = () => {
    const image = this.imageRef.current;
    const imageContainer = this.imageContainerRef.current;

    if (image && imageContainer) {
      const containerHeight = imageContainer.offsetHeight;
      const containerWidth = imageContainer.offsetWidth;
      image.style.maxHeight = `${
        containerHeight > MIN_PHOTO_HEIGHT ? containerHeight : MIN_PHOTO_HEIGHT
      }px`;
      image.style.maxWidth = `${containerWidth}px`;

      const naturalHeight = image.naturalHeight;
      const naturalWidth = image.naturalWidth;

      const directionImageRelation = naturalHeight / naturalWidth > 1;

      let bindToWidth = false;
      if (directionImageRelation) {
        bindToWidth =
          naturalHeight / naturalWidth > containerHeight / containerWidth;
      } else {
        bindToWidth =
          naturalHeight / naturalWidth < containerHeight / containerWidth;
      }

      if (containerHeight > MIN_PHOTO_HEIGHT) {
        if (directionImageRelation) {
          if (bindToWidth) {
            image.style.minHeight = `${containerHeight}px`;
            image.style.minWidth = `unset`;
          } else {
            image.style.minWidth = `${containerWidth}px`;
            image.style.minHeight = `unset`;
          }
        } else {
          if (bindToWidth) {
            image.style.minWidth = `${containerWidth}px`;
            image.style.minHeight = `unset`;
          } else {
            image.style.minHeight = `${containerHeight}px`;
            image.style.minWidth = `unset`;
          }
        }
      } else {
        image.style.minHeight = `${MIN_PHOTO_HEIGHT}px`;
        image.style.minWidth = `unset`;
      }
    }
  };

  private adaptImageSizeDebounced = debounce(this.adaptImageSize, 300);
}
