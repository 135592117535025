export const performerContactFallbackTranslations = {
  yourMessage: "Your message",
  yourEmail: "Your email",
  emailValidation:
    "Please add a valid email address in here so the performer can reply directly to you",
  yourName: "Your name",
  yourContactNumberOptional: "Your contact number",
  companyName: "Company name",
  companyWebsiteSocialMedia: "Company website or social media",
  maxLengthMessage: "{fieldName} must be at most {length} characters",
  required: "{fieldName} is required",
};
