import React from "react";
import { IAddPhotoTexts } from "component-library";

import { FLOW_TYPES } from "./FlowTypes";
import { MainPhotoFlow, IMainPhotoFlowContent } from "flows/MainPhotoFlow";
import {
  PhotoEditingFlow,
  IPhotoEditingFlowContent,
} from "flows/PhotoEditingFlow";
import {
  GalleryPhotoFlow,
  IGalleryPhotoFlowContent,
} from "flows/GalleryPhotoFlow";
import {
  ChangeMainPhotoFlow,
  IChangeMainPhotoFlowContent,
} from "flows/ChangeMainPhotoFlow";
import {
  UploadingPhotoFlow,
  IUploadingPhotoFlowContent,
} from "flows/UploadingPhotoFlow";

import {
  IFlowContextProps,
  IFlowContext,
  withFlowContext,
} from "contexts/FlowContext";
import {
  Translator,
  withTranslation,
  ITranslationContextProps,
} from "contexts/TranslationContext";

import { IFlowsFactoryContent } from "./FlowFactoryContent";
import { IPhotoToolTexts } from "shared/modules/Common";
import { IGalleryValidationContent } from "sections/GallerySection/Gallery";
import { IChangeMainPhotoFlowContentKeys } from "flows/ChangeMainPhotoFlow/ChangeMainPhotoFlowContent";
import { IGalleryValidationContentKeys } from "sections/GallerySection/Gallery/GalleryValidation";
import { IAddPhotoTextsKeys } from "component-library/src/components/Molecules/AddPhoto/AddPhoto";
import { IGalleryPhotoFlowContentKeys } from "flows/GalleryPhotoFlow/GalleryPhotoFlowContent";
import { IUploadingPhotoFlowContentKeys } from "flows/UploadingPhotoFlow/UploadingPhotoFlowContent";
import { IPhotoEditingFlowContentKeys } from "flows/PhotoEditingFlow/PhotoEditingFlowContent";
import { IPhotoToolTextsKeys } from "shared/modules/Common/PhotoTool/PhotoTool";

export interface IFlowsFactoryProps
  extends IFlowContextProps,
    ITranslationContextProps {}

interface IFlowsFactoryState {
  name?: string;
  data?: any;
  content: IFlowsFactoryContent;
}

export class FlowsFactory extends React.Component<
  IFlowsFactoryProps,
  IFlowsFactoryState
> {
  private container: { name: string; flow: any }[] = [];

  constructor(props: IFlowsFactoryProps) {
    super(props);

    this.state = {
      name: "",
      data: null,
      content: FlowsFactory.mapTranslation(props.translator),
    };

    this.initDictionaryFlow();
    props.flowContext.subscribe(this.trackChanges);
  }

  public componentWillUnmount() {
    this.props.flowContext.unsubscribe(this.trackChanges);
  }

  public render() {
    const { data, name = "" } = this.state;
    return this.renderFlow(name, data);
  }

  private trackChanges = (context: IFlowContext) => {
    this.setState({ name: context.flowName, data: context.flowData });
  };

  private renderEditGalleryPhotoFlow = (data: any) => {
    return (
      <PhotoEditingFlow
        {...{ ...data, content: this.state.content.editGalleryPhotoFlow }}
      />
    );
  };

  private renderEditMainPhotoFlow = (data: any) => {
    return (
      <PhotoEditingFlow
        {...{ ...data, content: this.state.content.editMainPhotoFlow }}
      />
    );
  };

  private renderMainPhotoFlow = (data: any) => {
    return (
      <MainPhotoFlow
        {...{ ...data, content: this.state.content.viewMainPhotoFlow }}
      />
    );
  };

  private renderGalleryPhotoFlow = (data: any) => {
    return (
      <GalleryPhotoFlow
        {...{ ...data, content: this.state.content.viewGalleryPhotoFlow }}
      />
    );
  };

  private renderChangeMainPhotoFlow = (data: any) => {
    return (
      <ChangeMainPhotoFlow
        {...{ ...data, content: this.state.content.changeMainPhoto }}
      />
    );
  };

  private renderUploadingPhotoFlow = (data: any) => {
    return (
      <UploadingPhotoFlow
        {...{ ...data, content: this.state.content.uploadPhotoFlow }}
      />
    );
  };

  private renderFlow = (name: string, data: any): JSX.Element => {
    let flow: JSX.Element = <React.Fragment />;
    const flowContainer = this.container.find(
      (element) => element.name === name
    );

    if (flowContainer) {
      flow = flowContainer.flow(data);
    }

    return flow;
  };

  private initDictionaryFlow() {
    this.container.push({
      name: FLOW_TYPES.uploadingPhoto,
      flow: this.renderUploadingPhotoFlow,
    });
    this.container.push({
      name: FLOW_TYPES.editPhoto,
      flow: this.renderEditGalleryPhotoFlow,
    });
    this.container.push({
      name: FLOW_TYPES.editMainPhoto,
      flow: this.renderEditMainPhotoFlow,
    });
    this.container.push({
      name: FLOW_TYPES.viewMainPhoto,
      flow: this.renderMainPhotoFlow,
    });
    this.container.push({
      name: FLOW_TYPES.viewGalleryPhoto,
      flow: this.renderGalleryPhotoFlow,
    });
    this.container.push({
      name: FLOW_TYPES.changeMainPhotoFlow,
      flow: this.renderChangeMainPhotoFlow,
    });
  }

  public static getDerivedStateFromProps(
    nextProps: IFlowsFactoryProps,
    prevState: IFlowsFactoryState
  ) {
    let stateUpdates = {};

    if (
      nextProps.translator.version !==
      nextProps.translator.getTranslationVersion(
        prevState.content.changeMainPhoto
      )
    ) {
      const content = FlowsFactory.mapTranslation(nextProps.translator);

      stateUpdates = { content };
    }

    return stateUpdates;
  }

  private static mapTranslation = (
    translator: Translator
  ): IFlowsFactoryContent => {
    const changeMainPhoto: IChangeMainPhotoFlowContent =
      translator.compileTranslationObject<IChangeMainPhotoFlowContent>(
        IChangeMainPhotoFlowContentKeys,
        "common",
        "changeMainPhotoFlow"
      );

    changeMainPhoto.photoGalleryTexts.photoSetTexts.tooManyPhotosAlerts =
      translator.createTranslationObject<IGalleryValidationContent>(
        IGalleryValidationContentKeys,
        "changeMainPhotoFlow.tooManyPhotosAlerts"
      );

    changeMainPhoto.photoGalleryTexts.addPhoto =
      translator.createTranslationObject<IAddPhotoTexts>(
        IAddPhotoTextsKeys,
        "changeMainPhotoFlow.addPhoto"
      );

    const viewMainPhotoFlowResult =
      translator.getTranslationFor("viewMainPhotoFlow")?.modules;
    const viewMainPhotoFlow: IMainPhotoFlowContent = {
      ...viewMainPhotoFlowResult?.common,
      viewMainPhoto: {
        ...viewMainPhotoFlowResult?.viewMainPhoto,
        help: {
          link: viewMainPhotoFlowResult?.viewMainPhoto?.help_link_url || "",
          text: viewMainPhotoFlowResult?.viewMainPhoto?.help_link_text || "",
        },
      },
    };

    const content: IFlowsFactoryContent = {
      changeMainPhoto,
      viewMainPhotoFlow,
      viewGalleryPhotoFlow:
        translator.compileTranslationObject<IGalleryPhotoFlowContent>(
          IGalleryPhotoFlowContentKeys,
          "common",
          "viewGalleryPhotoFlow"
        ),
      uploadPhotoFlow: {
        ...translator.compileTranslationObject<IUploadingPhotoFlowContent>(
          IUploadingPhotoFlowContentKeys,
          "common",
          "uploadPhotoFlow"
        ),
        photoEditFlow:
          translator.compileTranslationObject<IPhotoEditingFlowContent>(
            IPhotoEditingFlowContentKeys,
            "common",
            "addPhotoFlow"
          ),
      },
      editGalleryPhotoFlow:
        translator.compileTranslationObject<IPhotoEditingFlowContent>(
          IPhotoEditingFlowContentKeys,
          "common",
          "editGalleryPhotoFlow"
        ),
      editMainPhotoFlow:
        translator.compileTranslationObject<IPhotoEditingFlowContent>(
          IPhotoEditingFlowContentKeys,
          "common",
          "editMainPhotoFlow"
        ),
    };

    content.editGalleryPhotoFlow.editPhoto.photoTool =
      translator.createTranslationObject<IPhotoToolTexts>(
        IPhotoToolTextsKeys,
        "editGalleryPhotoFlow.photoTool"
      );

    content.editMainPhotoFlow.editPhoto.photoTool =
      translator.createTranslationObject<IPhotoToolTexts>(
        IPhotoToolTextsKeys,
        "editMainPhotoFlow.photoTool"
      );

    content.uploadPhotoFlow.photoEditFlow.editPhoto.photoTool =
      translator.createTranslationObject<IPhotoToolTexts>(
        IPhotoToolTextsKeys,
        "addPhotoFlow.photoTool"
      );

    return content;
  };
}

export default withTranslation(
  withFlowContext(FlowsFactory),
  "profileMainPage"
);
