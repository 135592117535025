import { GlobalAlertMessage } from "shared/modules/Common";
import { IPopupProps, IAlertMessageProps } from "component-library";

declare type CloseButton = { name: string; type: string; click?: () => void };
declare type Content = {
  title: string;
  description: string;
  closeButtonText: string;
  closePopupButtonText?: string;
};

const DEFAULT_SIZES = {
  medium: { lg: 4, md: 6 },
  large: { lg: 8, md: 10 },
};

export class AlertConfigBuilder {
  private alertMessage: GlobalAlertMessage;

  public initBuildEntity(): AlertConfigBuilder {
    this.alertMessage = new GlobalAlertMessage();

    this.alertMessage.popupProps = this.getDefaultPopupProps();
    this.alertMessage.alertMessageProps = this.getDefaultAlertProps();
    this.alertMessage.closeButton = this.getDefaultCloseButton();

    return this;
  }

  public build() {
    return this.alertMessage;
  }

  public setCustomContent(content: React.ReactNode) {
    this.alertMessage.children = content;

    return this;
  }

  public setSize(size: keyof typeof DEFAULT_SIZES): AlertConfigBuilder {
    this.alertMessage.popupProps.width = DEFAULT_SIZES[size];

    return this;
  }

  public addButton(button: any): AlertConfigBuilder {
    this.alertMessage.alertMessageProps.buttons.push(button);

    return this;
  }

  public setDefaultContent(content: Content): AlertConfigBuilder {
    this.alertMessage.alertMessageProps.texts.title = content.title;
    this.alertMessage.alertMessageProps.texts.description = content.description;
    this.alertMessage.closeButton.name = content.closeButtonText;
    // @ts-ignore
    this.alertMessage.popupProps.texts.closePopup =
      content.closePopupButtonText || content.closeButtonText;
    return this;
  }

  public setPopupProps<K extends keyof IPopupProps>(
    overrides?: Pick<IPopupProps, K>
  ): AlertConfigBuilder {
    this.alertMessage.popupProps = {
      ...this.alertMessage.popupProps,
      ...overrides,
    };

    return this;
  }

  public setCloseButton<K extends keyof CloseButton>(
    overrides?: Pick<CloseButton, K>
  ): AlertConfigBuilder {
    this.alertMessage.closeButton = {
      ...this.alertMessage.closeButton,
      ...overrides,
    };

    return this;
  }

  public setAlertProps<K extends keyof IAlertMessageProps>(
    overrides?: Pick<IAlertMessageProps, K>
  ): AlertConfigBuilder {
    this.alertMessage.alertMessageProps = {
      ...this.alertMessage.alertMessageProps,
      ...overrides,
    };

    return this;
  }

  private getDefaultPopupProps(): IPopupProps {
    return {
      width: DEFAULT_SIZES.medium,
      close: () => undefined,
      priority: "high",
      texts: { closePopup: "" },
    };
  }

  private getDefaultCloseButton() {
    return {
      name: "",
      type: "primary",
      click: () => undefined,
    };
  }

  private getDefaultAlertProps(): IAlertMessageProps {
    return {
      icon: "warning",
      texts: {
        title: "",
        description: "",
      },
      buttons: [],
    };
  }
}
