import React from "react";
import { RangeSlider } from "component-library";

import "./CropSetting.scss";

export interface ICropState {
  crop: {
    zoom: number;
    isApplyCrop?: boolean;
  };
}

export interface ICropSettingProps {
  minValue: number;
  maxValue: number;
  step: number;
  value: number;
  texts: {
    rangeTexts: {
      minValueName: string;
      maxValueName: string;
    };
    applyButton: string;
  };
  changeValue: (value: ICropState) => void;
  disabled: boolean;
  preventCrop: boolean;
}

interface ICropSettingState {
  value: number;
}

export class CropSetting extends React.Component<
  ICropSettingProps,
  ICropSettingState
> {
  constructor(props: ICropSettingProps) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  public render() {
    return (
      <div className="c-crop-setting">
        <div className="c-crop-setting__zoom">
          <RangeSlider
            disabled={this.props.disabled}
            texts={this.props.texts.rangeTexts}
            value={this.state.value}
            step={this.props.step}
            maxValue={this.props.maxValue}
            minValue={this.props.minValue}
            onChange={this.changeZoomValue}
          />
        </div>
        <div
          className={`c-crop-setting__buttons ${
            this.props.disabled || this.props.preventCrop ? "disabled" : ""
          }`}
        >
          <a
            onClick={this.applyCrop}
            role="button"
            className="c-crop-setting__button g-tertiary-link"
            tabIndex={0}
          >
            {this.props.texts.applyButton}
          </a>
        </div>
      </div>
    );
  }

  public componentWillReceiveProps(nextProps: ICropSettingProps) {
    if (nextProps.value !== undefined) {
      this.setState({ value: nextProps.value });
    }
  }

  private changeZoomValue = (
    event: React.ChangeEvent<HTMLInputElement> | any
  ) => {
    this.props.changeValue({ crop: { zoom: parseFloat(event.target.value) } });
  };

  private applyCrop = () => {
    if (!this.props.disabled) {
      this.props.changeValue({ crop: { isApplyCrop: true, zoom: 0 } });
    }
  };
}
