import React from "react";

import { GlobalContext, GlobalRouteContext } from "./GlobalContext";

export function withGlobalContextProvider<P>(
  WrappedComponent: React.ComponentClass<P> | React.FC<P>,
  globalContext = new GlobalContext()
) {
  return (props: P) => (
    <GlobalRouteContext.Provider value={globalContext}>
      <WrappedComponent {...props} globalContext={globalContext} />
    </GlobalRouteContext.Provider>
  );
}
