import { UserTypes } from "shared-types";
import UserManager from "./user-manager";
import { UserClaimTypes } from "./claim-types";

export class UserRoleManager {
  public isInRole(role: string): boolean {
    const currentRole = UserManager.getClaim(UserClaimTypes.role) || "";

    return (
      Boolean(currentRole) && currentRole.toLowerCase() === role.toLowerCase()
    );
  }

  public getUserRole = () => UserManager.getClaim(UserClaimTypes.role);

  public isUserType = (userType: UserTypes) =>
    +(UserManager.getClaim(UserClaimTypes.artistType) || NaN) === userType;

  public isUserTypes = (...userTypes: UserTypes[]) =>
    userTypes.some(
      (userType) =>
        +(UserManager.getClaim(UserClaimTypes.artistType) || NaN) === userType
    );

  public getUserType = () =>
    +(UserManager.getClaim(UserClaimTypes.artistType) || NaN);
}

export default new UserRoleManager();
