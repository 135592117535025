import React from "react";

import { LogHub } from "./LogHub";
import { ILogContextProps } from "./LoggerConsumer";
import { Logger } from "./Logger";
import { ILogEntry } from "./ILogEntry";
import { LogLevel } from "./LogLevel";
import { debounce } from "component-library";

export const LoggerContext = React.createContext<LogHub>(
  new LogHub(() => Promise.resolve())
);

declare type LogHubSettings = {
  url: string;
  domain: string;
  userId: () => string;
  userInfo: () => any;
};

const MAX_FAILED_ATTEMPTS_AMOUNT = 15;

const withLogHub =
  ({ url, domain, userId, userInfo }: LogHubSettings) =>
  <P extends ILogContextProps>(
    Component: React.ComponentClass<P> | React.FC<P>
  ) => {
    return class extends React.Component<Exclude<P, ILogContextProps>> {
      private failedAttempts: number = 0;
      private logHub: LogHub;
      private logger: Logger;
      private logEntryPool: ILogEntry[] = [];
      private spareEntryPool: ILogEntry[] = [];
      private isMainPoolBlocked = false;

      constructor(props: Readonly<Exclude<P, ILogContextProps>>) {
        super(props);

        this.logHub = new LogHub(this.logListener, userId());
        this.logger = this.logHub.createLoggerInstance(domain);

        window.addEventListener("beforeunload", this.saveLogLocally);
        window.addEventListener("error", this.globalErrorListener);

        this.pushLocalLog();
      }

      render() {
        return (
          <LoggerContext.Provider value={this.logHub}>
            <Component
              {...this.props}
              logHub={this.logHub}
              logger={this.logger}
            />
          </LoggerContext.Provider>
        );
      }

      public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.logger.appendError(
          "Fatal Error: Component - LogHubProvider",
          error,
          { context: { errorInfo } }
        );
      }

      private globalErrorListener = (event: ErrorEvent) => {
        this.logger.appendError(
          "Error: global error event catcher",
          event.error,
          { context: { event } }
        );
      };

      private logListener = (entry: ILogEntry, forcePush: boolean = false) => {
        if (!this.isMainPoolBlocked) {
          this.logEntryPool.push(entry);
          if (
            entry.level === LogLevel.error ||
            this.logEntryPool.length > 30 ||
            forcePush
          ) {
            this.emergencySending(this.logEntryPool);
            this.logEntryPool = [];
          } else {
            this.sendLogsDebounce();
          }
        } else {
          this.spareEntryPool.push(entry);
        }
      };

      private sendLogs = async () => {
        if (this.logEntryPool.length > 0 && !this.isMainPoolBlocked) {
          this.isMainPoolBlocked = true;

          await this.emergencySending(this.logEntryPool);

          this.logEntryPool = [...this.spareEntryPool];
          this.spareEntryPool = [];
          this.isMainPoolBlocked = false;

          if (this.logEntryPool.length > 0) {
            this.sendLogsDebounce();
          }
        }

        this.isMainPoolBlocked = false;
      };

      private sendLogsDebounce = debounce(this.sendLogs, 5000);

      private emergencySending = async (logEntryPool: ILogEntry[]) => {
        let list: { [P in keyof ILogEntry]?: any }[] = logEntryPool.map(
          (entry) => ({
            ...entry,
            context: JSON.stringify({ ...entry.context, userInfo: userInfo() }),
            trigger: JSON.stringify(entry.trigger),
          })
        );

        try {
          const response = await fetch(url, {
            body: JSON.stringify(list),
            method: "POST",
          });
          if (response.status >= 400) {
            throw response;
          }
        } catch (error) {
          this.failedAttempts++;
          return new Promise<void>((resolve, reject) => {
            if (this.failedAttempts <= MAX_FAILED_ATTEMPTS_AMOUNT) {
              setTimeout(async () => {
                await this.emergencySending(logEntryPool);
                resolve();
              }, 5000 + this.failedAttempts * 500);
            } else {
              this.saveLogLocally();
              reject();
            }
          });
        }
      };

      private saveLogLocally = () => {
        this.isMainPoolBlocked = true;
        const pool = [...this.logEntryPool, ...this.spareEntryPool];
        if (pool.length > 0) {
          localStorage.setItem("LOG_POOL", JSON.stringify(pool));
        }
      };

      private pushLocalLog = async () => {
        const pool: ILogEntry[] = JSON.parse(
          localStorage.getItem("LOG_POOL") || "[]"
        );

        if (pool.length > 0) {
          await this.emergencySending(pool);
          localStorage.removeItem("LOG_POOL");
        }
      };
    };
  };

export default withLogHub;
