import { ISectionContainerProps } from "component-library";

import { IEditAudioFormContent } from "./components/EditAudioForm/EditAudioFormContent";

export interface IEditAudioPageContent {
  header: string;
  closeButton: string;
  saveButton: string;
  cancelButton: string;
  cancelPopup: {
    cancelPopupCloseButton: string;
    cancelPopupDescription: string;
    cancelPopupTitle: string;
    cancelPopupContinueEditingButton: string;
  };
  editAudioForm: IEditAudioFormContent;
}
export const IEditAudioPageContentKeys = [
  "header",
  "closeButton",
  "saveButton",
  "cancelButton",
  "cancelPopup",
  "editAudioForm",
];

export const getSectionContainerProps = (
  content: IEditAudioPageContent,
  handleCloseClick: () => void = () => undefined
): ISectionContainerProps => {
  return {
    header: content.header,
    variant: "shadowed",
    controls: [
      {
        text: content.closeButton,
        onClick: handleCloseClick,
      },
    ],
  };
};
