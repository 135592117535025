import { MediaAssetType } from "./MediaAssetType";
import { TranscodeStatus } from "./TranscodeStatus";

export class MediaAsset {
  id: string;
  visible: boolean;
  sortOrder: number;
  mediaInfoId: string;
  mediaType: MediaAssetType;
  title: string;
  transcodeStatus: TranscodeStatus;
  durationInMs: number;
  sources: string[];
  selectedThumbnail: number;
  thumbnails: string[];
  description: string;
  isMediaObjectLoading?: boolean;
  downloadLink: string;
}
