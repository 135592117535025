import React from "react";
import { ActionButton } from "component-library";

import "./ButtonPanel.scss";
import { IActionButtonProps } from "component-library/src/components/Atoms/ActionButton/ActionButton";

export interface IButtonPanelProps {
  buttons: (IActionButtonProps & { key: string | number })[];
}

export const ButtonPanel: React.FC<IButtonPanelProps> = ({ buttons }) => {
  return (
    <div className="c-button-panel">
      {buttons.map((button) => (
        <div className="c-button-panel__element">
          <ActionButton {...button} />
        </div>
      ))}
    </div>
  );
};
