import React from "react";
import { ReorderableList, debounce } from "component-library";
import cx from "classnames";

import { IReorderableMediaAssetsContent } from "./ReorderableMediaAssetsContent";
import { AudioItem } from "../AudioItem";
import { MediaAsset } from "mediaModules/media/models";
import { ShimmeringStub } from "mediaModules/media/components/ShimmeringStub";

import "./ReorderableMediaAssets.scss";

const MOBILE_TABLET_WIDTH = 1200;

export interface IReorderableMediaAssetsProps {
  mediaAssets: MediaAsset[];
  content: IReorderableMediaAssetsContent;
  setSelectedMediaAssets: (mediaAssets: MediaAsset[]) => void;
  playback: (mediaAsset: MediaAsset) => void;
  addNewMediaAsset: () => void;
  selectedMediaAssets: MediaAsset[];
  editMediaAsset: (mediaAsset?: MediaAsset) => void;
  isMediaMetadataLoading: boolean;
  areMediaObjectsLoading: boolean;
  sortCompleted: (oldIndex: number, newIndex: number) => Promise<void>;
  disableReordering: boolean;
}

interface IReorderableMediaAssetsState {
  disableSorting: boolean;
}

export class ReorderableMediaAssets extends React.Component<
  IReorderableMediaAssetsProps,
  IReorderableMediaAssetsState
> {
  constructor(props) {
    super(props);
    this.state = {
      disableSorting: false,
    };
  }

  public render() {
    const { mediaAssets, isMediaMetadataLoading } = this.props;

    return (
      <div className="c-reorderable-media-assets">
        {isMediaMetadataLoading
          ? this.renderLoadingMediaMetadata()
          : this.renderLoadedMediaMetadata(mediaAssets)}
      </div>
    );
  }

  public componentDidMount() {
    window.addEventListener("resize", this.debouncedResizeHandler, false);
    this.debouncedResizeHandler();
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.debouncedResizeHandler, false);
  }

  private renderLoadingMediaMetadata = () => {
    return (
      <div className="g-inner-grid-12">
        <div className="c-reorderable-media-assets--loading g-col-lg-4 g-col-md-4 g-col-sm-12">
          <ShimmeringStub />
        </div>
      </div>
    );
  };

  private renderLoadedMediaMetadata = (mediaAssets: MediaAsset[]) => {
    return (
      <div className="c-media-assets__wrapper">
        <ReorderableList
          axis="xy"
          list={mediaAssets}
          distance={5}
          itemRenderer={this.renderAudioItem}
          onSortEnd={this.sortCompleted}
          getUniqueKey={(mediaAsset) => mediaAsset.id}
          immovableTail={this.renderAddMediaAsset() as any}
          disableReordering={this.props.disableReordering}
          shouldCancelStart={() => this.state.disableSorting}
        />
      </div>
    );
  };

  private sortCompleted = async ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => await this.props.sortCompleted(oldIndex, newIndex);

  private renderAudioItem = (mediaAsset: MediaAsset) => {
    const {
      content: { hiddenLabel, processingLabel, errorLabel },
      playback,
      selectedMediaAssets,
      editMediaAsset,
    } = this.props;

    return (
      <AudioItem
        key={mediaAsset.id}
        hiddenLabel={hiddenLabel}
        processingLabel={processingLabel}
        errorLabel={errorLabel}
        playback={playback}
        mediaAsset={mediaAsset}
        selectedMediaAsset={selectedMediaAssets}
        checkMediaAsset={this.checkMediaAsset(mediaAsset)}
        editMediaAsset={editMediaAsset}
      />
    );
  };

  private renderAddMediaAsset = (): JSX.Element => {
    const { content, addNewMediaAsset, areMediaObjectsLoading } = this.props;

    return (
      <a
        className={cx("c-reorderable-media-assets__add-new-item", {
          "c-reorderable-media-assets__add-new-item--disabled":
            areMediaObjectsLoading,
        })}
        onClick={addNewMediaAsset}
        tabIndex={0}
      >
        <i className="c-reorderable-media-assets__add-icon icon-plus" />
        <span className="c-reorderable-media-assets__add-label">
          {content.addNewAudioLabel}
        </span>
      </a>
    );
  };

  private checkMediaAsset =
    (selectedMediaAsset: MediaAsset) =>
    (isChecked: boolean): void => {
      const { selectedMediaAssets, setSelectedMediaAssets } = this.props;

      const newSelectedMediaAssets = [...selectedMediaAssets];
      const index = newSelectedMediaAssets.findIndex(
        (item) => item.id === selectedMediaAsset.id
      );

      isChecked
        ? newSelectedMediaAssets.push(selectedMediaAsset)
        : newSelectedMediaAssets.splice(index, 1);

      setSelectedMediaAssets(newSelectedMediaAssets);
    };

  private handleWindowResize = () => {
    const disableSorting = window.innerWidth < MOBILE_TABLET_WIDTH;
    this.setState({ disableSorting });
  };

  private debouncedResizeHandler = debounce(this.handleWindowResize, 300);
}
