import React from "react";

export interface IThumbnailBarItemProps {
  thumbnail: string;
  index: number;
  selectedThumbnail: number;
  selectThumbnail: (thumbnail: number) => void;
}

const KEYBOARD_KEY_ENTER = "Enter";

export const ThumbnailBarItem: React.FC<IThumbnailBarItemProps> = ({
  thumbnail,
  index,
  selectedThumbnail,
  selectThumbnail,
}) => {
  const handleKeyPress = (event: React.KeyboardEvent): void => {
    event.preventDefault();

    if (event.key === KEYBOARD_KEY_ENTER) {
      selectThumbnail(index);
    }
  };
  const isSelected = index === selectedThumbnail;

  return (
    <div
      className={`c-thumbnail-bar-item ${
        isSelected ? "c-thumbnail-bar-item--selected" : ""
      } g-col-lg-2 g-col-md-3 g-col-sm-6`}
      onClick={() => selectThumbnail(index)}
      onKeyUp={handleKeyPress}
      tabIndex={0}
    >
      <div className="c-thumbnail-bar-item__layer">
        {isSelected && <span className="icon-check1" />}
      </div>
      <img
        className="c-thumbnail-bar-item__image"
        src={thumbnail}
        alt={thumbnail}
      />
    </div>
  );
};
