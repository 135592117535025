export enum UserClaimTypes {
  role = "USER_ROLE",
  userId = "USER_ID",
  profileFullName = "PROFILE_FULL_NAME",
  artistType = "ARTIST_TYPE",
  viewPin = "VIEW_PIN",
  isGenderVisible = "IS_GENDER_VISIBLE",
  isDisabilitiesVisible = "IS_DISABILITIES_VISIBLE",
}

export enum AuthClaimTypes {
  artistRef = "ARTIST_REF",
}

export declare type ClaimTypes = AuthClaimTypes | UserClaimTypes;
