import { IHelperProps } from "component-library";
import { AlertConfigBuilder } from "shared/utils/alert-config-builder";

export interface IMainPhotoViewContent {
  header: string;
  close: string;
  editButton: string;
  replaceButton: string;
  help: IHelperProps;
  helpAdvice: {
    title: string;
    description: string;
    close: string;
  };
}

export function getHelpAndAdvice(content: any) {
  return new AlertConfigBuilder()
    .initBuildEntity()
    .setDefaultContent({
      title: content.title,
      description: content.description,
      closeButtonText: content.close,
    })
    .build();
}
