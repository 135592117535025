import React from "react";
import {
  required,
  maxLength,
  TextArea,
  Input,
  IInputConfig,
  IAlertMessageProps,
} from "component-library";

import { config } from "config";
import { GlobalAlertMessage } from "shared/modules/Common/GlobalAlert";

export type PhotoFormType = {
  photographer: string;
  description: string;
};

export const EDIT_PHOTO_FORM: PhotoFormType = {
  photographer: "photographer",
  description: "description",
};

function getValidationFunction() {
  return {
    photographer: [
      required(CONTENT.errors.photographer.required),
      maxLength(50, CONTENT.errors.photographer.maxLength),
    ],
    description: [maxLength(250, CONTENT.errors.description.maxLength)],
  };
}

export interface IFormState {
  form: { [T in keyof PhotoFormType]: string };
  validationData: {
    [T in keyof PhotoFormType]: {
      invalid: boolean;
      validationMessage: string;
    };
  };
  formSubmitted: boolean;
}

export class InputBuilder {
  constructor(private inputChanged: (name: string) => (event: any) => void) {}

  public getInput(name: string, formState: IFormState, children: any) {
    return (
      <Input
        id={name}
        name={name}
        type="text"
        label={CONTENT.texts[name]}
        value={formState.form[name]}
        valueChanged={this.inputChanged(name)}
        invalid={formState.validationData[name].invalid}
        isFormSubmitted={formState.formSubmitted}
        validationMessage={formState.validationData[name].validationMessage}
      >
        {children}
      </Input>
    );
  }

  public getTextArea(name: string, formState: IFormState) {
    return (
      <TextArea
        name={name}
        label={CONTENT.texts[name]}
        value={formState.form[name]}
        valueChanged={this.inputChanged(name)}
        invalid={formState.validationData[name].invalid}
        isFormSubmitted={formState.formSubmitted}
        validationMessage={formState.validationData[name].validationMessage}
      />
    );
  }

  public isFormValid(state: IFormState) {
    return !Object.keys(state.validationData).some(
      (key) => state.validationData[key].invalid
    );
  }
}
export function getInitialFormState(state: any, initialValue?: PhotoFormType) {
  state.form = {};

  Object.keys(EDIT_PHOTO_FORM).forEach((key) => {
    state.form[key] = (initialValue && initialValue[key]) || "";
  });

  state.validationData = {};

  Object.keys(EDIT_PHOTO_FORM).forEach((key) => {
    state.validationData[key] = {
      invalid: false,
      validationMessage: "",
    };
  });

  state.formSubmitted = false;

  return state;
}

export function getFormConfiguration(initialValue?: PhotoFormType) {
  const config: IInputConfig[] = [];

  const validationFunctions = getValidationFunction();

  Object.keys(EDIT_PHOTO_FORM).forEach((key) => {
    config.push({
      name: key,
      value: (initialValue && initialValue[key]) || "",
      validators: validationFunctions[key],
    });
  });

  return config;
}

export function getErrorResolutionPopup(): GlobalAlertMessage {
  return {
    popupProps: {
      width: { lg: 4, md: 6 },
      close: close,
      priority: "high",
      texts: { closePopup: "" },
    },
    alertMessageProps: {
      icon: "warning",
      texts: {
        title: CONTENT.errors.errorResolutionHeader,
        description: CONTENT.errors.errorResolution,
      },
      buttons: [],
    } as IAlertMessageProps,
    closeButton: { name: CONTENT.texts.close, type: "primary" },
  };
}

export function calculateHeight(
  buttonsContainer: HTMLDivElement,
  header: HTMLDivElement,
  container: HTMLDivElement,
  occupiedHeightValue: number
) {
  const containerStyles = window.getComputedStyle(container, null);
  const containerPaddings =
    parseFloat(containerStyles.getPropertyValue("padding-top")) +
    parseFloat(containerStyles.getPropertyValue("padding-bottom"));
  const tools = container.getElementsByClassName("c-photo-tool__tools")[0];
  const toolHeight = tools ? tools.clientHeight : 0;

  const occupiedHeight =
    buttonsContainer.offsetHeight +
    header.offsetHeight +
    containerPaddings +
    toolHeight +
    document.body.clientHeight -
    container.clientHeight;
  const minPhotoHeight = Math.max(
    window.innerHeight - occupiedHeight,
    MIN_PHOTO_HEIGHT
  );
  if (occupiedHeightValue !== occupiedHeight) {
    return { occupiedHeight, minPhotoHeight };
  } else {
    return { minPhotoHeight, occupiedHeight: occupiedHeightValue };
  }
}

export function cropAreaValidation(event: CustomEvent, cropper: any) {
  const isValidImageResolution =
    event.detail.width >= config.photoValidation.minResolution.width &&
    event.detail.height >= config.photoValidation.minResolution.height;

  return isValidImageResolution
    ? { isValid: true, message: "" }
    : { isValid: false, message: CONTENT.errors.errorResolution };
}

export const MIN_PHOTO_HEIGHT = 200;

export const CONTENT = {
  texts: {
    photographer: "Photographer:",
    description: "Description:",
    save: "Save",
    cancel: "Cancel",
    close: "Close",
  },
  errors: {
    photographer: {
      required: "Photographer is required",
      maxLength: "The maximum length of the field is 50 characters",
    },
    description: {
      maxLength: "The maximum length of the field is 250 characters",
    },
    errorResolution: "Photo should be at least 500 x 500 px",
    errorResolutionHeader: "Invalid resolution",
  },
  photoEditor: {
    photoEditor: {
      header: "Edit image",
      save: "Continue",
      cancel: "Cancel",
      toolNames: {
        contrast: "Contrast",
        crop: "Crop",
        tilt: "Tilt",
        rotate: "Rotate",
        undo: "Undo",
        brightness: "Brightness",
      },
    },
    tools: {
      contrast: {
        minValueName: "Darker",
        maxValueName: "Lighter",
      },
      tilt: {
        minValueName: "Tilt left",
        maxValueName: "Tilt right",
      },
      brightness: {
        minValueName: "Lighter",
        maxValueName: "Darker",
      },
      crop: {
        rangeTexts: {
          minValueName: "Zoom out",
          maxValueName: "Zoom in",
        },
        applyButton: "Apply crop",
      },
    },
  },
  denyDeleting: {
    icon: "warning",
    texts: {
      title: `Can't delete this photo`,
      description: `It used to be profile's main photo. Once new main photo is approved, you will be able to delete this one`,
    },
    buttons: [],
    focusOnOpen: true,
  },
};
