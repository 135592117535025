import React from "react";
import {
  FiltersFieldNames,
  IFilterFieldConfig,
  IFilterValues,
} from "../../types";
import {
  PLAYING_AGE_MAX,
  PLAYING_AGE_MIN,
} from "../../../../../../../../apps/agents/src/shared/constants";
import { RangeSelectionDropdown } from "../../../../Molecules/RangeSelectionDropdown/RangeSelectionDropdown";
import RangeSelection from "../../../../Molecules/RangeSelection";

export const playingAgeFilter: IFilterFieldConfig<IFilterValues> = {
  name: FiltersFieldNames.PlayingAge,
  defaultValue: {},
  modifiers: { showFilterControl: true },
  getFilterFiledProps: ({
    values,
    filterDrawerValues,
    setFilterValues,
    setFilterDrawerValues,
    modifiers,
    t,
  }) => {
    const playingAgeFilterTexts = {
      topmostDropdownPlaceholder: t("common:label.playingAge"),
      lowerDropdownPlaceholder: t("common:label.select"),
      lowerDropdownLabel: t("common:label.from"),
      upperDropdownPlaceholder: t("common:label.select"),
      upperDropdownLabel: t("common:label.to"),
    };

    const filterControlComponent = modifiers?.showFilterControl ? (
      <RangeSelectionDropdown
        minimumRangeValue={PLAYING_AGE_MIN}
        maximumRangeValue={PLAYING_AGE_MAX}
        value={values[FiltersFieldNames.PlayingAge]}
        content={playingAgeFilterTexts}
        getValueRepresentation={(value) => `${value} ${t("common:yr")}`}
        valueChanged={(value) => {
          setFilterValues({
            [FiltersFieldNames.PlayingAge]: value,
          });
        }}
        key={FiltersFieldNames.PlayingAge}
      />
    ) : null;

    const filterDrawerConfig = {
      title: t("common:label.playingAge"),
      id: FiltersFieldNames.PlayingAge,
      selected: Boolean(
        filterDrawerValues[FiltersFieldNames.PlayingAge].upperRangeValue ||
          filterDrawerValues[FiltersFieldNames.PlayingAge].lowerRangeValue
      ),
      renderFilterContent: () => (
        <RangeSelection
          value={filterDrawerValues[FiltersFieldNames.PlayingAge]}
          content={playingAgeFilterTexts}
          getValueRepresentation={(value) => `${value} ${t("common:yr")}`}
          minimumRangeValue={PLAYING_AGE_MIN}
          maximumRangeValue={PLAYING_AGE_MAX}
          valueChanged={(value) => {
            setFilterDrawerValues({
              [FiltersFieldNames.PlayingAge]: value,
            });
          }}
        />
      ),
    };

    const playingAgeValue = Object(values[FiltersFieldNames.PlayingAge]);

    const playingAgeOverUnder = playingAgeValue.lowerRangeValue
      ? t("common:search.filters.tagLabel.playingAge.over", {
          value: playingAgeValue?.lowerRangeValue,
        })
      : t("common:search.filters.tagLabel.playingAge.under", {
          value: playingAgeValue.upperRangeValue,
        });
    const playingAgeText =
      playingAgeValue.lowerRangeValue && playingAgeValue.upperRangeValue
        ? t("common:search.filters.tagLabel.playingAge.range", {
            lower: playingAgeValue.lowerRangeValue,
            upper: playingAgeValue.upperRangeValue,
          })
        : playingAgeOverUnder;

    const tags =
      playingAgeValue.lowerRangeValue || playingAgeValue.upperRangeValue
        ? [
            {
              id: `${FiltersFieldNames.PlayingAge}`,
              text: t("common:search.filters.tagLabel.playingAge", {
                playingAge: playingAgeText,
              }),
              onClick: () => {
                setFilterValues({
                  [FiltersFieldNames.PlayingAge]: {},
                });
              },
            },
          ]
        : [];

    return {
      tags,
      filterDrawerConfig,
      filterControlComponent,
    };
  },
};
