import { IAlertMessageProps, AddPhoto } from "component-library";
import React from "react";

import { GlobalEventTypes } from "contexts/GlobalContext";
import { IGlobalContext } from "shared/contexts/GlobalContext";

interface ITooManyPhotosWarningContent {
  title: string;
  description: string;
  ignoreButton: string;
  cancelButton: string;
}

interface ITooManyPhotosErrorContent {
  title: string;
  description: string;
  closeButton: string;
}

export interface IGalleryValidationContent {
  warning: ITooManyPhotosWarningContent;
  error: ITooManyPhotosErrorContent;
  closeTooManyButton: string;
}
export const IGalleryValidationContentKeys = [
  "warning",
  "error",
  "closeTooManyButton",
];

export default class GalleryValidation {
  private showUploadPhotoWarning = (
    close: () => void,
    addPhotoRef: React.RefObject<AddPhoto>,
    content: IGalleryValidationContent
  ) => {
    const alertProps: IAlertMessageProps = {
      icon: "notice",
      texts: {
        title: content.warning.title,
        description: content.warning.description,
      },
      buttons: [
        {
          name: content.warning.ignoreButton,
          type: "primary",
          click: () => {
            if (addPhotoRef.current) {
              close();
              addPhotoRef.current.showExplorer();
            }
          },
        },
      ],
    };

    const popupProps = {
      width: { lg: 4, md: 6 },
      close: close,
      priority: "high",
      texts: { closePopup: content.closeTooManyButton },
    };

    return {
      popupProps: popupProps,
      alertMessageProps: alertProps,
      closeButton: { name: content.warning.cancelButton, type: "secondary" },
    };
  };

  private showUploadingPhotoError = (content: IGalleryValidationContent) => {
    const alertProps: IAlertMessageProps = {
      icon: "warning",
      texts: {
        title: content.error.title,
        description: content.error.description,
      },
      buttons: [],
    };

    const popupProps = {
      width: { lg: 4, md: 6 },
      close: () => undefined,
      priority: "high",
      texts: { closePopup: content.closeTooManyButton },
    };

    return {
      popupProps: popupProps,
      alertMessageProps: alertProps,
      closeButton: { name: content.error.closeButton, type: "primary" },
    };
  };

  public preUploadPhotoValidation = (
    photoNumber: number,
    globalContext: IGlobalContext,
    addPhotoRef: React.RefObject<AddPhoto>,
    close: () => void,
    content: IGalleryValidationContent
  ) => {
    if (photoNumber >= 5 && photoNumber < 14) {
      globalContext.notifyListener(
        GlobalEventTypes.notifyingGlobalAlert,
        this.showUploadPhotoWarning(close, addPhotoRef, content)
      );
    } else if (photoNumber >= 14) {
      globalContext.notifyListener(
        GlobalEventTypes.notifyingGlobalAlert,
        this.showUploadingPhotoError(content)
      );
    } else {
      if (addPhotoRef.current) {
        addPhotoRef.current.showExplorer();
      }
    }
  };
}
