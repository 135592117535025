export const moveArrayElement = <T>(array: T[], fromIndex: number, toIndex: number): T[] => {
  const arrayCopy = [...array];

  if(toIndex < array.length && toIndex >= 0){
    const element = arrayCopy[fromIndex];
    arrayCopy.splice(fromIndex, 1);
    arrayCopy.splice(toIndex, 0, element);
  }

  return arrayCopy;
}