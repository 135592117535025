import React from "react";
import { IPopupProps as IBasePopupProps, Popup } from "component-library";

import {
  IGlobalContextProps,
  withGlobalContext,
} from "shared/contexts/GlobalContext";

import { GlobalEventTypes } from "contexts/GlobalContext";

import "./PopupPlaceholder.scss";

export declare type IPopupProps = IBasePopupProps & {
  isTransparent?: boolean;
};

export interface IPopupPlaceholderProps extends IGlobalContextProps {}

interface IPopupPlaceholderState {
  popupProps: IPopupProps;
  content: React.ReactNode;
}

export class PopupPlaceholder extends React.Component<
  IPopupPlaceholderProps,
  IPopupPlaceholderState
> {
  public readonly defaultPopupProps: Readonly<IPopupProps> = {
    hideCloseButton: false,
    texts: { closePopup: "" },
    width: { lg: 8, md: 10 },
    isTransparent: false,
    priority: "high",
    close: () =>
      this.showPopup({ content: null, popupProps: this.defaultPopupProps }),
  };

  public readonly state: Readonly<IPopupPlaceholderState> = {
    popupProps: this.defaultPopupProps,
    content: null,
  };

  public componentDidMount() {
    this.props.globalContext.addListener(
      GlobalEventTypes.showPopup,
      this.showPopup
    );
  }

  public componentWillUnmount() {
    this.props.globalContext.removeListener(
      GlobalEventTypes.showPopup,
      this.showPopup
    );
  }

  public render() {
    const {
      content,
      popupProps: { isTransparent, ...popupProps },
    } = this.state;

    const containerClasses = `c-popup-placeholder ${
      isTransparent ? "c-popup-placeholder--transparent" : ""
    }`;

    return (
      <div className={containerClasses}>
        {content && <Popup {...popupProps}>{content}</Popup>}
      </div>
    );
  }

  private showPopup = ({
    content,
    popupProps = {},
  }: {
    content: React.ReactNode;
    popupProps: Partial<IPopupProps>;
  }) =>
    this.setState({
      content,
      popupProps: { ...this.defaultPopupProps, ...popupProps },
    });
}

export default withGlobalContext(PopupPlaceholder);
