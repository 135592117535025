export const replaceAll = (
  pattern: RegExp | string,
  searchValue: string,
  replaceValue: ((match: string) => string) | string
) => {
  const regex = new RegExp(pattern, 'gi');
  const matches = Array.from(searchValue.match(regex) || []);
  const replaceValueFunction = typeof replaceValue === 'function' ? replaceValue : () => replaceValue;

  let result = '';
  let matchEndLastIndex = 0;

  for (const match of matches) {
    const fromIndex = searchValue.indexOf(match, matchEndLastIndex) + match.length;

    result += searchValue.substring(matchEndLastIndex, fromIndex).replace(match, replaceValueFunction(match));

    matchEndLastIndex = fromIndex;
  }

  return result + searchValue.substr(matchEndLastIndex);
};

/** Forces exhaustive checks of switch statements. Use it by calling checkTypeScriptNever(x) in your switch statement's default case. It will then cause
 * a compile error if all cases of the switch statement variable are not handled.
 */
export function checkTypeScriptNever(_x: never): never {
  return undefined as never;
}
