import { TFunction } from "i18n";
import { FacetType, IFacet } from "shared-services";
import { IStat } from "shared-types";
import { FilterDrawerMediaFilterNames } from "../FilterDrawerMediaFilter";
import { IFilterTagProps } from "../../Atoms/FilterTag/FilterTag";
import { IFilterDrawerItem } from "../FilterControl/FilterDrawer/FilterDrawer";
import { IRangeSelectionValue } from "../../Molecules/RangeSelectionDropdown/RangeSelectionDropdown";
import { IOption } from "../../../generics/interfaces";
import { ICheckboxGroupValue } from "../CheckboxGroup/CheckboxGroup";
import { FilterMode } from "../../Molecules/FilterModeSection";

export enum FiltersFieldNames {
  Gender = "gender",
  PlayingAge = "playingAge",
  Locations = "locationId",
  Media = "media",
  HeritageEthnicityAndBackground = "heritageEthnicityAndBackground",
}

export enum HeritageEthnicityAndBackground {
  HeritageAndEthnicity = "heritageAndEthnicity",
  EthnicBackground = "ethnicBackground",
  HeritageAndEthnicityFilterMode = "heritageAndEthnicityFilterMode",
  HeritageAndEthnicityInfo = "heritageAndEthnicityInfo",
}

export type SetFilterValues<T> = (values: Partial<T>) => void;
export type Modifiers = { showFilterControl?: boolean } & Record<
  string,
  unknown
>;

export interface IGetFilterFieldConfig<T> {
  name: FiltersFieldNames;
  facetNames?: FacetType[];
  values: T;
  filterDrawerValues: T;
  setFilterValues: SetFilterValues<T>;
  setFilterDrawerValues: SetFilterValues<T>;
  facets: Record<FacetType, IFacet[]>;
  stats?: IFilterStats;
  dataTestId?: string;
  modifiers?: Modifiers;
  t: TFunction;
}

export interface IFilterFieldConfig<T> {
  name: FiltersFieldNames;
  facetNames?: FacetType[];
  getFilterFiledProps: (props: IGetFilterFieldConfig<T>) => {
    tags: IFilterTagProps[];
    filterDrawerConfig: IFilterDrawerItem;
    filterControlComponent?: JSX.Element | null;
  };
  defaultValue: unknown;
  dataTestId?: string;
  modifiers?: Modifiers;
}

export type FilterConfig<T> = IFilterFieldConfig<T>[];

export interface IFilterValues {
  [FiltersFieldNames.Gender]: ICheckboxGroupValue;
  [FiltersFieldNames.PlayingAge]: IRangeSelectionValue;
  [FiltersFieldNames.Locations]: IOption<string>[];
  [FiltersFieldNames.Media]: {
    [FilterDrawerMediaFilterNames.HasShowreels]: boolean;
    [FilterDrawerMediaFilterNames.HasAudioClips]: boolean;
  };
  [FiltersFieldNames.HeritageEthnicityAndBackground]: {
    [HeritageEthnicityAndBackground.HeritageAndEthnicityInfo]?: string;
    [HeritageEthnicityAndBackground.HeritageAndEthnicity]: ICheckboxGroupValue;
    [HeritageEthnicityAndBackground.EthnicBackground]: ICheckboxGroupValue;
    [HeritageEthnicityAndBackground.HeritageAndEthnicityFilterMode]: FilterMode;
  };
}

export interface IFilterStats {
  performersWithShowReelsCount?: number;
  performersWithAudioClipsCount?: number;
  heritageAndEthnicity?: Array<IStat<number>>;
}
