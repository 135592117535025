import { GlobalContextEventTypes } from "shared/contexts/GlobalContext";

export class GlobalEventTypes extends GlobalContextEventTypes {
  public static readonly changeMainPhoto = "CHANGE_MAIN_PHOTO";
  public static readonly uploadNewPhoto = "UPLOAD_NEW_PHOTO";
  public static readonly updateMainPhoto = "UPDATE_MAIN_PHOTO";
  public static readonly updateGallery = "UPDATE_GALLERY";
  public static readonly showInformationDialog = "INFORMATION_DIALOG";
  public static readonly runDiscovery = "RUN_DISCOVERY";
  public static readonly runProfilePageDiscovery = "RUN_PROFILE_PAGE_DISCOVERY";
  public static readonly runCreditPageDiscovery = "RUN_CREDIT_PAGE_DISCOVERY";
  public static readonly runBasicInfoPageDiscovery =
    "RUN_BASICINFO_PAGE_DISCOVERY";
  public static readonly errorNotification = "ERROR_NOTIFICATION";
  public static readonly httpErrorHandling = "HTTP_ERROR_HANDLING";
  public static readonly reloadLocalization = "RELOAD-LOCALIZATION";
  public static readonly updateLocalization = "UPDATE-LOCALIZATION";
  public static readonly makeVisibleGlobalSpinner =
    "MAKE_VISIBLE_GLOBAL_SPINNER";
  public static readonly loadedBasicContent = "LOADED_BASIC_CONTENT";
  public static readonly showPopup = "SHOW_POPUP";
  public static readonly runProfilePrinting = "RUN_PROFILE_PRINTING";
  public static readonly shareProfileViaEmail = "SHARE_PROFILE_VIA_EMAIL";
  public static readonly performerContact = "PERFORMER_CONTACT";
}
