import { useQuery } from "react-query";
import { fetchWithFallback } from "translations";
import { common } from "../fallback/common";
import { config } from "../../../../config";
import { LocalizationUtils } from "../../../../index";

const QUERY_COMMON_TRANSLATIONS = "QUERY_COMMON_TRANSLATIONS";

export const useGetCommonTranslations = () => {
  const locale = LocalizationUtils.getUserLocalizationPreference().locale;
  const { data, ...query } = useQuery(
    [QUERY_COMMON_TRANSLATIONS, locale],
    async () => {
      return await fetchWithFallback(
        () =>
          fetch(
            `${config.umbracoApiUrl}/umbraco/api/content/home-page/common/common-translations?culture=${locale}`
          ).then((res) => res.json()),
        common
      );
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  return {
    data: data || common,
    ...query,
  };
};
