import { TOOL_IDS } from './PhotoTool';
import { ToolState } from './ToolState';
import { HistoryRecord } from './HistoryRecord';

export class History {

  private history: HistoryRecord[] = [];
  private initialState: HistoryRecord;
  private readonly availableCropOperationNumber = 1;

  constructor(initialRecord: HistoryRecord) {
    this.initialState = initialRecord
  }

  public addRecord(record: HistoryRecord): void {
    this.history.push(record);
    if (record.toolId === TOOL_IDS.crop) {
      this.removeCropAction();
    }
  }

  public get isEmpty(): boolean {
    return this.history.length === 0;
  }

  public get initialRecord(): HistoryRecord {
    return this.initialState;
  }

  public revert(): HistoryRecord {
    let previous: HistoryRecord = new HistoryRecord();

    if (this.history.length !== 0) {
      this.history.pop();
    }

    if (this.history.length !== 0) {
      const record = this.history[this.history.length - 1];
      previous = record ? record : new HistoryRecord();
    } else {
      previous = this.initialState
    }
    return previous;
  }

  public getHistoryCopy(): HistoryRecord[] {
    return this.history.map((record) => Object.assign(new HistoryRecord(), record));
  }

  public getLastRecord(): HistoryRecord {
    return this.history.length > 0 ? this.history[this.history.length - 1] : this.initialState;
  }

  public getLastKnownRecord(expression: (record: HistoryRecord) => boolean): HistoryRecord {
    for (let index = this.history.length - 1; index >= 0; index--) {
      const record = this.history[index];
      if (expression(record)) {
        return record;
      }
    }

    return this.initialState;
  }

  public getLastToolRecord(expression: (toolState: ToolState) => boolean): HistoryRecord | null {
    for (let index = this.history.length - 1; index >= 0; index--) {
      const record = this.history[index];
      if (expression(record.toolState)) {
        return record;
      }
    }

    return null;
  }

  public removeAllByToolId(toolId: string) {
    for (let index = this.history.length - 1; index >= 0; index--) {
      const records = this.history[index];
      if (records.toolId === toolId) {
        this.history.splice(index, 1);
      }
    }
  }

  private removeCropAction(): void {
    let currentCropOperationCounter = 0;

    for (let index = this.history.length - 1; index >= 0; index--) {
      const cropRecords = this.history[index];
      if (cropRecords.toolId === TOOL_IDS.crop && cropRecords.toolState.crop.isApplyCrop) {
        if (currentCropOperationCounter > this.availableCropOperationNumber) {
          this.history.splice(index, 1);
        }
        currentCropOperationCounter++;
      }
    }
  }
}
