import React, { useState } from "react";
import { useField } from "formik";
import { ErrorCode, FileError } from "react-dropzone";
import { useFormikScrollToError } from "shared-hooks";
import {
  DropzoneUploader,
  IDropzoneUploaderFile,
  RecordWithActionControls,
} from "../../../..";
import { ActionButton } from "../../ActionButton/ActionButton";
import { InputLabel } from "../../InputsCommon/InputLabel";
import {
  DropzoneInvalidModal,
  IDropzoneInvalidModalTexts,
} from "./DropzoneInvalidModal";
import {
  areDimensionsValid,
  ErrorCodes,
  FileWithErrors,
  ReactDropzoneErrorMap,
} from "./Dropzone.helpers";

export interface IImageDimensionValidations {
  maxWidth?: number;
  maxHeight?: number;
  minWidth?: number;
  minHeight?: number;
}

export interface IDropzoneFormikProps {
  name: string;
  label?: string;
  boldLabel?: boolean;
  placeholder?: string;
  onDrop?: (files: IDropzoneUploaderFile[]) => Promise<void> | void;
  onDelete?: (fileId: string) => void;
  customFileList?: (values: IDropzoneUploaderFile[]) => JSX.Element | null;
  acceptedFileTypes?: string[];
  onDropzoneReject?: (errors: FileWithErrors[]) => void;
  minSize?: number;
  maxSize?: number;
  multiple?: boolean;
  maxFiles?: number;
  imageDimensions?: IImageDimensionValidations;
  hideErrorModal?: boolean;
  errorModalTexts?: IDropzoneInvalidModalTexts;
  errorButtonId?: string;
}

export const DropzoneFormik: React.FC<IDropzoneFormikProps> = ({
  name,
  label,
  boldLabel,
  placeholder,
  onDrop,
  onDelete,
  customFileList,
  acceptedFileTypes,
  onDropzoneReject,
  minSize,
  maxSize,
  imageDimensions,
  multiple,
  maxFiles,
  hideErrorModal,
  errorModalTexts,
  errorButtonId,
}) => {
  const { containerElementRef, fieldRef } = useFormikScrollToError<
    HTMLDivElement,
    HTMLDivElement
  >(name);

  const [field, meta, helpers] = useField<IDropzoneUploaderFile[]>(name);
  const [fileErrors, setFileErrors] = useState<ErrorCodes[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const onDropAccepted = async (acceptedFiles: IDropzoneUploaderFile[]) => {
    helpers.setTouched(true);

    // validate image sizes here if needed
    const dimensionsError = await areDimensionsValid(
      acceptedFiles,
      imageDimensions
    );
    const isDimensionsError = dimensionsError !== true;

    if (isDimensionsError) {
      setFileErrors([dimensionsError]);
      return;
    }

    if (
      multiple &&
      maxFiles &&
      field.value.length + acceptedFiles.length > maxFiles
    ) {
      setFileErrors([ErrorCodes.TooManyFiles]);
      return;
    }

    try {
      setLoading(true);

      if (onDrop) await onDrop(acceptedFiles);

      helpers.setValue([...field.value, ...acceptedFiles]);
    } catch (e) {
      setFileErrors([ErrorCodes.UploadError]);
    } finally {
      setLoading(false);
    }
  };

  const onDropRejected = (rejectedFiles: IDropzoneUploaderFile[]) => {
    const rejects = rejectedFiles as {
      file: File;
      id: string;
      errors: FileError[];
    }[];

    const filesWithErrors: FileWithErrors[] = rejects.map((rejectedFile) => {
      const errors = rejectedFile.errors;
      const errorCodes = errors.map((error) => {
        return ReactDropzoneErrorMap.get(error.code as ErrorCode)!;
      });

      return {
        ...rejectedFile,
        errors: errorCodes,
      };
    });

    const errorCodes = filesWithErrors.flatMap(({ errors }) => errors);
    const uniqueErrors = Array.from(new Set(errorCodes));

    if (!hideErrorModal) {
      setFileErrors(uniqueErrors);
    }
    onDropzoneReject?.(filesWithErrors);
  };

  const onDeleteFile = (fileId: string) => {
    if (onDelete) {
      onDelete(fileId);
    } else {
      const updatedFiles = field.value?.filter(({ id }) => id !== fileId);
      helpers.setValue(updatedFiles);
    }
  };

  const showMultipleDropzone = maxFiles ? field.value.length < maxFiles : true;
  const showDropzone = multiple
    ? showMultipleDropzone
    : field.value.length === 0;

  return (
    <>
      {fileErrors && fileErrors.length > 0 && (
        <DropzoneInvalidModal
          errors={fileErrors}
          onClose={() => setFileErrors(undefined)}
          supportedFileTypes={acceptedFileTypes}
          maxSize={maxSize}
          minSize={minSize}
          maxFiles={maxFiles}
          imageDimensions={imageDimensions}
          texts={errorModalTexts}
          errorButtonId={errorButtonId}
        />
      )}
      <div ref={containerElementRef} className="relative">
        {label && <InputLabel htmlFor={name} label={label} bold={boldLabel} />}

        {showDropzone && (
          <div ref={fieldRef}>
            <DropzoneUploader
              texts={{
                dropzoneLabel: placeholder || "",
              }}
              invalid={meta.touched && Boolean(meta.error)}
              validationMessage={meta.error}
              onDropAcceptedFiles={onDropAccepted}
              onDropRejectedFiles={onDropRejected}
              accept={acceptedFileTypes}
              minSize={minSize}
              maxSize={maxSize}
              maxFiles={maxFiles}
              multiple={multiple}
              loading={loading}
            />
          </div>
        )}

        {customFileList
          ? customFileList(field.value)
          : field.value.length > 0 && (
              <div className="g-bg-secondary" style={{ marginBottom: 46 }}>
                {field.value.map((file) => (
                  <RecordWithActionControls
                    key={file.id}
                    title={file.file.name}
                    controls={[
                      <ActionButton
                        key={file.id}
                        icon="delete"
                        onClick={() => onDeleteFile(file.id)}
                        size="small"
                        disabled={loading}
                      />,
                    ]}
                  />
                ))}
              </div>
            )}
      </div>
    </>
  );
};
