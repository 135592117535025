export const SYSTEM_CALLS = [Symbol.toPrimitive, '$$typeof', Symbol.iterator, Symbol.toStringTag, 'entries'];

export const getDummyProxy = (returnedValue: string | number | null | undefined) => {
  const proxy: any = new Proxy(new String(), {
    get: (target, prop, receiver) => {
      if ('toString' === prop) {
        return () => returnedValue;
      }

      if (prop in target) {
        return Reflect.get(target, prop, receiver);
      } else {
        if (Symbol.toPrimitive === prop) {
          return () => returnedValue;
        }

        return SYSTEM_CALLS.some(name => name === prop) ? undefined : getDummyProxy(returnedValue);
      }
    }
  })

  return proxy;
}
