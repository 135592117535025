import React from "react";
import DOMPurify from "dompurify";
import {
  Helper,
  IGroupValidationResult,
  Input,
  InputGroup,
  ITooltipContent,
  required,
  requiredNonEmptyString,
  WatchedFunction,
} from "component-library";

import { INewAudioFormContent } from "./NewAudioFormContent";
import { UploaderMapper } from "mediaModules/media/components/UploaderWrapper";
import {
  MediaAssetType,
  MediaMetadataPayload,
  MediaStorageSpace,
  SUPPORTED_AUDIO_EXTENSIONS,
} from "mediaModules/media/models";
import { IFileUploadComplete } from "mediaModules/media/TitaniumUploader/Models";
import { MediaStorageContainer } from "mediaModules/media/components/MediaStorageContainer";

import "./NewAudioForm.scss";

export const VALIDATE = new WatchedFunction(() => undefined);
const TITLE_MAX_LENGTH = 255;
const MIN_TOP_ANNEX_DISTANCE = 50;
const UPLOADER_ACCEPT_EXTENSIONS = SUPPORTED_AUDIO_EXTENSIONS.map(
  (ext) => `.${ext}`
).join(",");

export interface INewAudioFormProps {
  content: INewAudioFormContent;
  metadataPayload: MediaMetadataPayload;
  updateMetadata: (metadataPayload: MediaMetadataPayload) => void;
  showDeletePopup: (
    metadataPayload: MediaMetadataPayload,
    callback: () => void
  ) => Promise<void>;
  saveChanges: () => void;
  children: (submit: () => void) => React.ReactElement;
  storageSpace: MediaStorageSpace;
  disableSaveButton: (isFileUploading: boolean) => void;
  preUploadValidation?: (durationInMs: number) => boolean;
}

export interface INewAudioFormState {
  title: string;
  mediaInfo: string;
  isFormSubmitted: boolean;
  validationData: {
    title: {
      invalid: boolean;
      validationMessage: string;
    };
    mediaInfo: {
      invalid: boolean;
      validationMessage: string;
    };
  };
}

export class NewAudioForm extends React.Component<
  INewAudioFormProps,
  INewAudioFormState
> {
  public readonly state: Readonly<INewAudioFormState> = {
    title: "",
    mediaInfo: "",
    validationData: {
      title: {
        invalid: false,
        validationMessage: "",
      },
      mediaInfo: {
        invalid: false,
        validationMessage: "",
      },
    },
    isFormSubmitted: false,
  };

  private isValidationChanged: boolean;

  public render() {
    const { content, children, storageSpace, preUploadValidation } = this.props;
    const { validationData, isFormSubmitted, title } = this.state;

    const config = this.configForm();
    return (
      <React.Fragment>
        <div className="g-content-area c-new-audio-form">
          <MediaStorageContainer
            storageSpace={storageSpace}
            storedCapacityBarContent={content.capacity1}
            displayedCapacityBarContent={content.capacity2}
          />
          <div className="g-col-md-8 g-col-lg-8">
            {this.renderDescription()}
          </div>
          <div className="g-col-md-6 g-col-lg-6">
            <InputGroup
              className="c-new-audio-form__control"
              inputConfigs={config}
              validationDone={this.validationPerformed}
              triggerValidation={VALIDATE}
            >
              <UploaderMapper
                content={content.uploader}
                fileUploaded={this.fileUploaded}
                fileRemoved={this.fileRemoved}
                uploadStarted={this.uploadStarted}
                mediaType={MediaAssetType.Audio}
                multiUpload={false}
                validationMessage={validationData.mediaInfo.validationMessage}
                invalid={isFormSubmitted && validationData.mediaInfo.invalid}
                preUploadValidation={preUploadValidation}
                accept={UPLOADER_ACCEPT_EXTENSIONS}
              />
              <Input
                id="title"
                type="text"
                value={title}
                name="title"
                label={content.titleLabel}
                valueChanged={this.changeTitle}
                invalid={validationData.title.invalid}
                validationMessage={validationData.title.validationMessage}
                annex={this.getAnnex(content.titleTooltip)}
                isFormSubmitted={isFormSubmitted}
                maxLength={TITLE_MAX_LENGTH}
              />
            </InputGroup>
          </div>
        </div>
        {children(this.saveChanges)}
      </React.Fragment>
    );
  }

  public componentDidUpdate() {
    if (this.isValidationChanged) {
      this.isValidationChanged = false;
      VALIDATE.call();
    }
  }

  private renderDescription = () => {
    const { content } = this.props;

    return (
      <div
        className="c-new-audio-form__description"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(content.formDescription),
        }}
      />
    );
  };

  private changeTitle = (title: string) => {
    this.isValidationChanged = true;
    this.setState({ title }, () => {
      VALIDATE.call();
      this.props.updateMetadata({ ...this.props.metadataPayload, title });
    });
  };

  private saveChanges = () => {
    this.setState({ isFormSubmitted: true }, () => {
      const { validationData } = this.state;
      const invalidForm = Object.keys(validationData).some(
        (key) => validationData[key].invalid
      );
      if (!invalidForm) {
        this.props.saveChanges();
      }
    });
  };

  private getAnnex = (tooltipContent: ITooltipContent) => (
    <Helper
      {...{
        tooltip: {
          texts: tooltipContent,
          config: {
            minimalTopDistance: MIN_TOP_ANNEX_DISTANCE,
          },
        },
      }}
    />
  );

  private fileUploaded = (
    uploadedFile: IFileUploadComplete,
    durationInMs: number
  ) => {
    const { updateMetadata, disableSaveButton } = this.props;

    disableSaveButton(false);

    updateMetadata({
      title: this.state.title,
      mediaInfoId: uploadedFile.id,
      mediaType: MediaAssetType.Audio,
      visible: false,
      durationInMs,
    });
  };

  private fileRemoved = async (id, callback) => {
    await this.props.showDeletePopup(
      {
        ...new MediaMetadataPayload(),
        title: this.state.title,
      },
      () => this.handleFileRemoved(callback)
    );
  };

  private handleFileRemoved = (callback) => {
    this.triggerMediaValidationChange("");
    callback();
  };

  private uploadStarted = (fileName: string) => {
    this.props.disableSaveButton(true);

    this.triggerMediaValidationChange(fileName);
  };

  private triggerMediaValidationChange = (value: string) => {
    this.isValidationChanged = true;
    this.setState(
      {
        mediaInfo: value,
      },
      () => {
        VALIDATE.call();
      }
    );
  };

  private validationPerformed = (result: IGroupValidationResult) => {
    const validationData = {
      ...this.state.validationData,
      ...result.validationData,
    };
    this.setState({ validationData });
  };

  private configForm = () => {
    const { content } = this.props;

    return [
      {
        name: "title",
        value: this.state.title,
        validators: [requiredNonEmptyString(content.titleRequiredError)],
      },
      {
        name: "mediaInfo",
        value: this.state.mediaInfo,
        validators: [required(content.mediaRequiredError)],
      },
    ];
  };
}
