import {
  IHelperProps,
  IAlertMessageProps,
  IPopupProps,
} from "component-library";
import { IPhotoToolTexts } from "shared/modules/Common";

export interface IPhotoEditorContent extends IPhotoEditorAlerMessageContent {
  header: string;
  savePhotoButton: string;
  cancelEditingButton: string;
  photoTool: IPhotoToolTexts;
  helper: IHelperProps;
  helpAdvice: {
    title: string;
    description: string;
    close: string;
  };
}

export interface IPhotoEditorAlerMessageContent {
  resolutionErrorHeader: string;
  resolutionError: string;
  closeErrorButton: string;
}

export const getAlertMessage = (
  content: IPhotoEditorContent | IPhotoEditorAlerMessageContent
) => {
  const alertProps: IAlertMessageProps = {
    icon: "warning",
    texts: {
      title: content.resolutionErrorHeader,
      description: content.resolutionError,
    },
    buttons: [],
  };

  const popupProps: IPopupProps = {
    width: { lg: 4, md: 6 },
    close: () => undefined,
    priority: "high",
    texts: { closePopup: "" },
  };

  const errorPopup = {
    popupProps: popupProps,
    alertMessageProps: alertProps,
    closeButton: { name: content.closeErrorButton, type: "primary" },
  };

  return errorPopup;
};

export function getHelpAndAdvice(content: any) {
  const alertProps: IAlertMessageProps = {
    icon: undefined,
    texts: {
      title: content.title,
      description: content.description,
    },
    buttons: [],
  };

  const popupProps: IPopupProps = {
    width: { lg: 4, md: 6 },
    close: () => undefined,
    priority: "high",
    texts: { closePopup: content.close },
  };

  const setting = {
    popupProps: popupProps,
    alertMessageProps: alertProps,
    closeButton: { name: content.close, type: "primary" },
  };

  return setting;
}
