import React from "react";
import { ChangesTrackingService } from "./ChangesTrackingService";
import { RouteComponentProps } from "react-router-dom";

import { Action, Location } from "history";

export const ChangesTrackerContext = React.createContext(
  new ChangesTrackingService()
);

declare type WithChangesTracker = RouteComponentProps;

export default function withChangesTracker<P extends WithChangesTracker>(
  WrappedComponent: React.ComponentClass<P> | React.FC<P>
): typeof React.Component {
  return class extends React.Component<P> {
    private changesTrackingService = new ChangesTrackingService();
    private unblock?: () => void;

    public render() {
      return (
        <ChangesTrackerContext.Provider value={this.changesTrackingService}>
          <WrappedComponent {...this.props} />
        </ChangesTrackerContext.Provider>
      );
    }

    public async componentDidMount() {
      try {
        window.addEventListener("beforeunload", this.preventClosing);
        this.unblock = this.props.history.block(this.blockHistoryChange);
      } catch (e) {
        console.error(e);
      }
    }

    public async componentWillUnmount() {
      try {
        window.removeEventListener("beforeunload", this.preventClosing);

        if (this.unblock) {
          this.unblock();
        }
      } catch (e) {
        console.error(e);
      }
    }

    private preventClosing = (event: BeforeUnloadEvent) => {
      const result = this.changesTrackingService.getValidationMessage();

      if (result) {
        event.preventDefault();
        event.returnValue = result;
      }
    };

    private blockHistoryChange = (location: Location, action: Action) => {
      const pathChanged = this.props.location.pathname !== location.pathname;

      let message: string | undefined;

      if (pathChanged) {
        const isBlocking = this.changesTrackingService.getValidationMessage();

        if (Boolean(isBlocking)) {
          message = isBlocking.toString();
        }
      }

      return message;
    };
  };
}
