import { IPhotoEditorContent } from "../Common/PhotoEditor";
import { IAlertMessageProps } from "component-library";

export interface ISavePhotoDialog {
  saveTitle: string;
  saveDescription: string;
  closeSavePopup: string;
  continueButton: string;
  backToEditButton: string;
  discardButton: string;
  dontShowAgain: string;
}

export interface ICancelEditingPhotoDialog {
  cancelTitle: string;
  cancelDescription: string;
  closeCancalPopup: string;
  discardChangesButton: string;
  saveButton: string;
  rejectCancellation: string;
}

export interface IErrorEditingPhotoDialog {
  errorTitle: string;
  closeErrorPopup: string;
  commonError: string;
  imageSizeError: string;
  backToPhotoTool: string;
}

export interface IPhotoEditingFlowContent {
  closePopup: string;
  editPhoto: IPhotoEditorContent;
  saveDialog: ISavePhotoDialog;
  cancelDialog: ICancelEditingPhotoDialog;
  errorDialog: IErrorEditingPhotoDialog;
  generalError: string;
  photoLoadingError: string;
}
export const IPhotoEditingFlowContentKeys = [
  "closePopup",
  "editPhoto",
  "saveDialog",
  "cancelDialog",
  "errorDialog",
  "generalError",
  "photoLoadingError",
];

declare type SaveDialogSettings = {
  content: ISavePhotoDialog;
  close: () => void;
  editPhoto: () => void;
  save: () => void;
};

export const getSaveDialogSettings = (
  setting: SaveDialogSettings
): IAlertMessageProps => {
  const { content, close, save, editPhoto } = setting;

  return {
    icon: "notice",
    texts: {
      title: content.saveTitle,
      description: content.saveDescription,
    },
    buttons: [
      { name: content.continueButton, type: "primary", click: save },
      { name: content.backToEditButton, type: "secondary", click: editPhoto },
      { name: content.discardButton, type: "secondary", click: close },
    ],
    focusOnOpen: true,
  };
};

declare type CancelDialogSettings = {
  content: ICancelEditingPhotoDialog;
  close: () => void;
  editPhoto: () => void;
  save: () => void;
};

export const getCancelDialogSettings = (
  setting: CancelDialogSettings
): IAlertMessageProps => {
  const { content, close, save, editPhoto } = setting;
  return {
    icon: "notice",
    texts: {
      title: content.cancelTitle,
      description: content.cancelDescription,
    },
    buttons: [
      { name: content.discardChangesButton, type: "primary", click: close },
      { name: content.rejectCancellation, type: "secondary", click: editPhoto },
      { name: content.saveButton, type: "secondary", click: save },
    ],
    focusOnOpen: true,
  };
};

declare type ErrorDialogSettings = {
  content: IErrorEditingPhotoDialog;
  editPhoto: () => void;
};

export const getErrorDialogSettings = (
  setting: ErrorDialogSettings
): IAlertMessageProps => {
  const { content, editPhoto } = setting;

  return {
    icon: "warning",
    texts: {
      title: content.errorTitle,
      description: "",
    },
    buttons: [
      { name: content.backToPhotoTool, type: "primary", click: editPhoto },
    ],
    focusOnOpen: true,
  };
};
