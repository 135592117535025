export interface ISortArrayConfig<T> {
  expression: (data: T) => number | string | Date;
  order: 'asc' | 'desc';
}

export function sortArray<T>(array: T[], sortingConfig: ISortArrayConfig<T>[]): T[] {
  var arrayCopy = [...array];

  return arrayCopy.sort((left, right) => {
    let sortResult = 0;
    sortingConfig.forEach(config => {
      if (sortResult === 0) {
        const leftData = config.expression(left);
        const rightData = config.expression(right);
        const orderMultiplier = config.order === 'desc' ? -1 : 1;

        const intermediateResult = leftData > rightData ? 1 : leftData < rightData ? -1 : 0;
        sortResult = orderMultiplier * intermediateResult;
      }
    });

    return sortResult;
  });
}

export function setOrderingArray<T, K>(array: T[], keys: K[], property: (element: T) => K): T[] {
  var arrayCopy = [...array];

  return keys.map(key => arrayCopy.find(element => property(element) === key)).filter(Boolean) as T[];
}
