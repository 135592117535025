import { BaseError } from './base-error';

export class HttpOperationError extends BaseError {
  public readonly url: string;
  public readonly name = 'HttpOperationError';

  constructor(url: string, message: string, context?: Error | any, parentError?: Error) {
    super(message, context, parentError);
    this.url = url;
  }
}