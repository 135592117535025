import React from "react";

import { ReactComponent as CloseIcon } from "../../../images/svg/icons/close-oval.svg";

import "./CloseIconSvg.scss";

const DEFAULT_SIZE = 25;

export interface ICloseIconSvgProps {
  size?: number;
}

export const CloseIconSvg: React.FC<ICloseIconSvgProps> = ({
  size = DEFAULT_SIZE,
}) => {
  return (
    <CloseIcon
      height={size}
      width={size}
      aria-label="close"
      tabIndex={0}
      className="c-close-icon"
    />
  );
};
