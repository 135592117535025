import React from "react";

import "./Overlay.scss";

export enum OverlayType {
  Dark,
  Light,
}

export interface IOverlayProps {
  title: string;
  icon: string;
  overlayType?: OverlayType;
}

const Overlay: React.FC<IOverlayProps> = ({
  title,
  icon,
  overlayType = OverlayType.Dark,
}: IOverlayProps) => {
  return (
    <div
      className={`c-overlay ${
        overlayType === OverlayType.Dark
          ? "c-overlay--dark"
          : "c-overlay--light"
      }`}
    >
      <div className="c-overlay__container">
        <div className={`c-overlay__icon ${icon}`} />
        <div className="c-overlay__title">{title}</div>
      </div>
    </div>
  );
};

export default Overlay;
