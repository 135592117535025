declare const __CONFIG__: {
  oidc: {
    authority: string;
    clientId: string;
    scope: string;
  };
  frontEndUrl: string;
  portalApiLogoutUrl: string;
  whoAmIUrl: string;
  braze: {
    baseUrl: string;
    apiKey: string;
  };
};

export default {
  ...__CONFIG__,
};
