import * as ReactGA from "react-ga";

import { config } from "config";
import { MediaAssetType } from "mediaModules/media/models";

export class GAEvents {
  public static trackPageLoad = (
    label: string,
    mediaAssetType: MediaAssetType,
    isCreateCategory: boolean = false
  ) => {
    const category =
      (isCreateCategory ? "Create" : "Edit") +
      `-${GAEvents.getCategoryName(mediaAssetType)}`;

    return GAEvents.sendEvent(label, category, "page loaded");
  };

  public static editMediaAssets = {
    trackCloseClick(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `edit-${mediaAssetType}${
          mediaAssetType === MediaAssetType.Video ? "s" : ""
        }-close-click`,
        `Edit-${GAEvents.getCategoryName(mediaAssetType)}`,
        "click"
      );
    },
    trackMoveUpClick(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `move-${mediaAssetType}-up-click`,
        `Edit-${GAEvents.getCategoryName(mediaAssetType)}`,
        "click"
      );
    },
    trackMoveDownClick(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `move-${mediaAssetType}-down-click`,
        `Edit-${GAEvents.getCategoryName(mediaAssetType)}`,
        "click"
      );
    },
    trackEditClick(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `edit-${mediaAssetType}-click`,
        `Edit-${GAEvents.getCategoryName(mediaAssetType)}`,
        "click"
      );
    },
    trackEditFromTitleClick(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `title-${mediaAssetType}-click`,
        `Edit-${GAEvents.getCategoryName(mediaAssetType)}`,
        "click"
      );
    },
    trackDeleteClick(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `delete-${mediaAssetType}-click`,
        `Edit-${GAEvents.getCategoryName(mediaAssetType)}`,
        "click"
      );
    },
    trackHideClick(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `hide-${mediaAssetType}-click`,
        `Edit-${GAEvents.getCategoryName(mediaAssetType)}`,
        "click"
      );
    },
    trackDownloadClick(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `download-${mediaAssetType}-click`,
        `Edit-${GAEvents.getCategoryName(mediaAssetType)}`,
        "click"
      );
    },
    trackAddClick(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `add-${mediaAssetType}-click`,
        `Edit-${GAEvents.getCategoryName(mediaAssetType)}`,
        "click"
      );
    },
    playerControls: {
      trackEditClick(mediaAssetType: MediaAssetType) {
        GAEvents.sendEvent(
          `edit-${mediaAssetType}-player-click`,
          `Edit-${GAEvents.getCategoryName(mediaAssetType)}`,
          "click"
        );
      },
      trackDeleteClick(mediaAssetType: MediaAssetType) {
        GAEvents.sendEvent(
          `delete-${mediaAssetType}-player-click`,
          `Edit-${GAEvents.getCategoryName(mediaAssetType)}`,
          "click"
        );
      },
      trackHideClick(mediaAssetType: MediaAssetType) {
        GAEvents.sendEvent(
          `hide-${mediaAssetType}-player-click`,
          `Edit-${GAEvents.getCategoryName(mediaAssetType)}`,
          "click"
        );
      },
      trackDownloadClick(mediaAssetType: MediaAssetType) {
        GAEvents.sendEvent(
          `download-${mediaAssetType}-player-click`,
          `Edit-${GAEvents.getCategoryName(mediaAssetType)}`,
          "click"
        );
      },
    },
  };

  public static editMediaAsset = {
    trackCloseClick(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `edit-individual-${mediaAssetType}-close-click`,
        `Edit-${GAEvents.getCategoryName(mediaAssetType)}`,
        "click"
      );
    },
    trackCancelCloseClick(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `edit-individual-${mediaAssetType}-cancel&close-click`,
        `Edit-${GAEvents.getCategoryName(mediaAssetType)}`,
        "click"
      );
    },
    trackSaveClick(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `edit-individual-${mediaAssetType}-save-click`,
        `Edit-${GAEvents.getCategoryName(mediaAssetType)}`,
        "click"
      );
    },
    trackDownloadClick(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `edit-individual-${mediaAssetType}-download-click`,
        `Edit-${GAEvents.getCategoryName(mediaAssetType)}`,
        "click"
      );
    },
  };

  public static addMediaAsset = {
    trackCloseClick(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `add-individual-${mediaAssetType}-close-click`,
        `Create-${GAEvents.getCategoryName(mediaAssetType)}`,
        "click"
      );
    },
    trackCancelCloseClick(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `add-individual-${mediaAssetType}-cancel&close-click`,
        `Create-${GAEvents.getCategoryName(mediaAssetType)}`,
        "click"
      );
    },
    trackSaveClick(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `add-individual-${mediaAssetType}-save-click`,
        `Create-${GAEvents.getCategoryName(mediaAssetType)}`,
        "click"
      );
    },
    trackDeleteClick(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `delete-individual-${mediaAssetType}-click`,
        `Create-${GAEvents.getCategoryName(mediaAssetType)}`,
        "click"
      );
    },
    trackOverlimitMessage(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `add-individual-${mediaAssetType}-ovetlimit-message`,
        `Create-${GAEvents.getCategoryName(mediaAssetType)}`,
        "message"
      );
    },
    trackFailedUploadMessage(mediaAssetType: MediaAssetType) {
      GAEvents.sendEvent(
        `add-individual-${mediaAssetType}-failedupload-message`,
        `Create-${GAEvents.getCategoryName(mediaAssetType)}`,
        "message"
      );
    },
  };

  private static sendEvent = (
    label: string,
    category: string,
    action: string
  ) =>
    ReactGA.event({ label, category, action }, [
      config.googleAnalytics.gaOptions.name,
    ]);

  private static getCategoryName = (mediaAssetType: MediaAssetType) =>
    mediaAssetType === MediaAssetType.Audio ? "Audioreel" : "Showreel";
}
