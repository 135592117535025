import { ISectionContainerProps } from "component-library";

import { INewVideoFormContent } from "./components/NewVideoForm";
import { AlertConfigBuilder } from "shared/utils/alert-config-builder";

export interface IStoreLimitAlert {
  storeLimitAlertTitle: string;
  storeLimitAlertDescription: string;
  storeLimitAlertCloseButton: string;
}

export interface IAddNewVideoPageContent {
  cancelPopup: {
    cancelPopupClosePopupButton: string;
    cancelPopupDescription: string;
    cancelPopupTitle: string;
    cancelPopupContinueEditing: string;
  };
  deletePopup: {
    deletePopupClosePopupButton: string;
    deletePopupDescription: string;
    deletePopupTitle: string;
    deletePopupContinueEditing: string;
  };
  header: string;
  closeButton: string;
  saveButton: string;
  cancelButton: string;
  cvEditorLink: string;
  newVideoForm: INewVideoFormContent;
  storeLimitAlert: IStoreLimitAlert;
}
export const IAddNewVideoPageContentKeys = [
  "cancelPopup",
  "deletePopup",
  "header",
  "closeButton",
  "saveButton",
  "cancelButton",
  "cvEditorLink",
  "newVideoForm",
  "storeLimitAlert",
];

export const getSectionContainerProps = (
  content: IAddNewVideoPageContent,
  handleCloseClick: () => void
): ISectionContainerProps => {
  return {
    header: content.header,
    variant: "shadowed",
    controls: [
      {
        text: content.closeButton,
        onClick: handleCloseClick,
      },
    ],
  };
};

export const getAlertMessage = (
  content: IAddNewVideoPageContent,
  popupType: PopupTypes,
  cancelContinuingAction: () => void,
  continueAction: () => void
) => {
  const popupContent = content[popupType];

  return new AlertConfigBuilder()
    .initBuildEntity()
    .setDefaultContent({
      closeButtonText: popupContent[`${popupType}ContinueEditing`],
      description: popupContent[`${popupType}Description`],
      title: popupContent[`${popupType}Title`],
    })
    .setCloseButton({ type: "secondary", click: cancelContinuingAction })
    .addButton({
      type: "primary",
      click: continueAction,
      name: popupContent[`${popupType}ClosePopupButton`],
    })
    .build();
};

export enum PopupTypes {
  CancelClose = "cancelPopup",
  Delete = "deletePopup",
}

export const getStoreLimitAlert = (
  content: IStoreLimitAlert,
  closeAction: () => void
) =>
  new AlertConfigBuilder()
    .initBuildEntity()
    .setDefaultContent({
      title: content.storeLimitAlertTitle,
      description: content.storeLimitAlertDescription,
      closeButtonText: content.storeLimitAlertCloseButton,
    })
    .setCloseButton({ type: "primary", click: closeAction })
    .build();
