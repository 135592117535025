import React from "react";
import { RangeSlider } from "component-library";

import "./TiltSetting.scss";

export interface ITiltState {
  tilt: number;
}

export interface ITiltSettingProps {
  minValue: number;
  maxValue: number;
  step: number;
  value: number;
  texts: {
    minValueName: string;
    maxValueName: string;
  };
  changeValue: (value: ITiltState) => void;
  disabled: boolean;
}

interface ITiltSettingState {
  value: number;
}

export class TiltSetting extends React.Component<
  ITiltSettingProps,
  ITiltSettingState
> {
  constructor(props: ITiltSettingProps) {
    super(props);
    this.state = {
      value: props.value,
    };
  }

  public render() {
    return (
      <div className="c-tilt-setting">
        <RangeSlider
          disabled={this.props.disabled}
          texts={this.props.texts}
          value={this.state.value}
          step={this.props.step}
          maxValue={this.props.maxValue}
          minValue={this.props.minValue}
          onChange={this.changeValue}
        />
      </div>
    );
  }

  public componentWillReceiveProps(nextProps: ITiltSettingProps) {
    this.setState({ value: nextProps.value });
  }

  public changeValue = (event: React.ChangeEvent<HTMLInputElement> | any) => {
    this.props.changeValue({ tilt: parseFloat(event.target.value) });
  };
}
