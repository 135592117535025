import React from "react";
import { Spinner } from "component-library";

import "./FullPageSpinner.scss";

export const FullPageSpinner: React.FC = () => {
  return (
    <div className="c-full-page-spinner__container">
      <div className="c-full-page-spinner__spinner">
        <Spinner />
      </div>
    </div>
  );
};
