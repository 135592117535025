import React from "react";

import { IMultiSelectItem } from "../MultiSelectItem";

export interface IMultiSelectListItemProps<TValue> {
  item: IMultiSelectItem<TValue>;
  isUpMovable: boolean;
  isDownMovable: boolean;
  hideMoveControls?: boolean;
  moveElementUp: (id: any) => void;
  moveElementDown: (id: any) => void;
  removeItem: (id: any) => void;
}

export class MultiSelectListItem<TValue> extends React.Component<
  IMultiSelectListItemProps<TValue>
> {
  public render() {
    const {
      item: { name },
    } = this.props;

    return (
      <div className="c-multi-select-list__selected-item" key={name}>
        <div className="c-multi-select-list__selected-item-name">{name}</div>
        {this.renderButtons()}
      </div>
    );
  }

  private renderButtons = () => {
    const { isUpMovable, isDownMovable, hideMoveControls } = this.props;

    const actionStyle = "c-multi-select-list__selected-item-action";
    const arrowDownStyles = `${actionStyle} icon-arrowdown ${
      isDownMovable ? "" : "disable"
    }`;
    const arrowUpStyles = `${actionStyle} icon-arrowup ${
      isUpMovable ? "" : "disable"
    }`;

    return (
      <React.Fragment>
        {!hideMoveControls && (
          <div
            className={arrowDownStyles}
            onKeyPress={(e) => e.key === "Enter" && this.moveDownHandler()}
            onClick={this.moveDownHandler}
            tabIndex={isDownMovable ? 0 : -1}
            role="button"
            aria-label="Move down"
          />
        )}
        {!hideMoveControls && (
          <div
            className={arrowUpStyles}
            onKeyPress={(e) => e.key === "Enter" && this.moveUpHandler()}
            onClick={this.moveUpHandler}
            tabIndex={isUpMovable ? 0 : -1}
            role="button"
            aria-label="Move up"
          />
        )}
        <div
          className="c-multi-select-list__selected-item-action icon-delete"
          onKeyPress={(e) => e.key === "Enter" && this.removeHandler()}
          onClick={this.removeHandler}
          tabIndex={0}
          role="button"
          aria-label="Delete"
        />
      </React.Fragment>
    );
  };

  private moveUpHandler = () => {
    const {
      moveElementUp,
      isUpMovable,
      item: { value },
    } = this.props;
    if (isUpMovable) {
      moveElementUp(value);
    }
  };

  private moveDownHandler = () => {
    const {
      moveElementDown,
      isDownMovable,
      item: { value },
    } = this.props;
    if (isDownMovable) {
      moveElementDown(value);
    }
  };

  private removeHandler = () => {
    const {
      removeItem,
      item: { value },
    } = this.props;
    removeItem(value);
  };
}
