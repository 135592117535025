import React from "react";

import {
  TitaniumUploader,
  ITitaniumUploaderProps,
} from "mediaModules/media/TitaniumUploader/TitaniumUploader";
import { TitaniumUploadService } from "mediaModules/media/TitaniumUploader/Services/TitaniumUploadService";
import { config } from "config";
import { IFileUploadComplete } from "mediaModules/media/TitaniumUploader/Models";
import { MediaAssetType } from "mediaModules/media/models";

export interface IUploaderContent {
  dropzone: string;
  dropzoneMobile: string;
  uploadFileError: string;
  formatValidationError: string;
  removeFileButton: string;
  retryUploadButton: string;
}

interface IUploaderMapperProps {
  content: IUploaderContent;
  mediaType: MediaAssetType;
  fileUploaded?: (
    fileUpload: IFileUploadComplete,
    durationInMs: number
  ) => void;
  fileRemoved?: (id: string, callback: () => void) => Promise<void>;
  uploadStarted?: (fileName?: string) => void;
  multiUpload?: boolean;
  validationMessage?: string;
  invalid?: boolean;
  preUploadValidation?: (durationInMs: number) => boolean;
  accept?: string;
}

const TITANIUM_SERVICE = new TitaniumUploadService(config.mediaApiRoot);

export const UploaderMapper: React.FC<IUploaderMapperProps> = (props) => {
  const {
    content,
    mediaType,
    fileUploaded,
    fileRemoved,
    uploadStarted,
    multiUpload,
    validationMessage,
    invalid,
    preUploadValidation,
    accept,
  } = props;

  const uploaderProps: ITitaniumUploaderProps = {
    dropzone: content.dropzone,
    dropzoneMobile: content.dropzoneMobile,
    uploadError: content.uploadFileError,
    formatValidationError: content.formatValidationError,
    removeButton: content.removeFileButton,
    retryButton: content.retryUploadButton,
    extensionError: content.formatValidationError,
    mediaType: mediaType,
    uploadService: TITANIUM_SERVICE,
    onCompleteSuccess: fileUploaded,
    onFileRemove: fileRemoved,
    onUploadStart: uploadStarted,
    multiUpload,
    validationMessage,
    invalid,
    preUploadValidation,
    accept,
  };

  return <TitaniumUploader {...uploaderProps} />;
};
