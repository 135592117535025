export const getVideoDurationInMs = (file: File) => {
  return new Promise<number>((resolve) => {
    var vid = document.createElement("video");
    const fileURL = URL.createObjectURL(file);
    vid.src = fileURL;

    vid.ondurationchange = function () {
      // @ts-ignore
      resolve((this.duration * 1000).toFixed(0));
    };
  });
};
