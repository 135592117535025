import React from "react";
import { AudioPlayer } from "component-library";

import { PropType } from "shared/utils/types";
import { ButtonPanel } from "../ButtonPanel";
import { MediaCloseButton } from "../MediaCloseButton";

import "./AudioPlayerContainer.scss";
import { IActionButtonProps } from "component-library/src/components/Atoms/ActionButton/ActionButton";

export interface IAudioPlayerContainerProps {
  playerConfig: PropType<AudioPlayer, "props">;
  closeAction: () => void;
  controlPanelButtons?: (IActionButtonProps & { key: string | number })[];
}

export const AudioPlayerContainer: React.FC<IAudioPlayerContainerProps> = ({
  playerConfig,
  closeAction,
  controlPanelButtons = [],
}) => {
  const hasPanel = controlPanelButtons.length > 0;

  const containerClasses = `c-audio-player-container ${
    hasPanel ? "with-panel" : ""
  }`;

  return (
    <div className={containerClasses}>
      <div className="c-audio-player-container__player">
        <AudioPlayer {...playerConfig} />
        <div className="c-audio-player-container__close-btn">
          <MediaCloseButton closeAction={closeAction} />
        </div>
      </div>
      {hasPanel && (
        <div className="c-audio-player-container__buttons g-bg-secondary">
          <ButtonPanel buttons={controlPanelButtons} />
        </div>
      )}
    </div>
  );
};
