import React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { UserManager, UserClaimTypes, AuthClaimTypes } from "app/core";
import { omitProps } from "shared/utils/object";

export enum ResolveRouteAction {
  allow = 1,
  deny,
}

export interface IRouteRule {
  claim: UserClaimTypes | AuthClaimTypes;
  action?: ResolveRouteAction;
  values?: any[];
}

export interface IRouteResolverProps extends RouteProps {
  rules?: IRouteRule[];
  negativeRouteResolver?: string | (() => void);
}

export const RouteResolver: React.FC<IRouteResolverProps> = (
  props: IRouteResolverProps
) => {
  const { rules = [], negativeRouteResolver } = props;

  const routeProps = omitProps(props, ["rules", "negativeRouteResolver"]);

  const hasRestriction =
    rules.length > 0 && rules.some((rule) => !hasAccess(rule));

  let result: JSX.Element | null = null;
  if (!hasRestriction) {
    result = <Route {...routeProps} />;
  } else {
    if (typeof negativeRouteResolver === "string") {
      result = <Redirect to={negativeRouteResolver} />;
    } else {
      negativeRouteResolver && negativeRouteResolver();
    }
  }

  return result;
};

const hasAccess = (rule: IRouteRule) => {
  const { action = ResolveRouteAction.allow, values } = rule;

  let result = false;
  const userProperty = UserManager.getClaim(rule.claim);

  if (values && userProperty) {
    result = values.some(
      (value) =>
        value.toString().toLowerCase() === userProperty.toString().toLowerCase()
    );
  } else {
    result = Boolean(userProperty);
  }

  return action === ResolveRouteAction.allow ? result : !result;
};

export default RouteResolver;
