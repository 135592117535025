import { IChangeMainPhotoViewContent } from "./ChangeMainPhotoView";
import { IAlertMessageProps } from "component-library";

export interface IConfirmChangeMainPhotoDialog {
  confirmTitle: string;
  confirmDescription: string;
  continueConfirmButton: string;
  cancelConfirmButton: string;
  closeConfirmPopup: string;
}

export interface IChangeMainPhotoFlowContent {
  photoGalleryTexts: IChangeMainPhotoViewContent;
  confirmationDialog: IConfirmChangeMainPhotoDialog;
  photoLoadingError: string;
}

export const IChangeMainPhotoFlowContentKeys = [
  "photoGalleryTexts",
  "confirmationDialog",
  "photoLoadingError",
];

declare type ConfirmationDialogSettings = {
  confirmationDialog: IConfirmChangeMainPhotoDialog;
  save: () => void;
  close: () => void;
};

export const getConfirmationDialogSettings = (
  setting: ConfirmationDialogSettings
) => {
  const { confirmationDialog, save, close } = setting;

  const labels = confirmationDialog;
  const config: IAlertMessageProps = {
    icon: "notice",
    focusOnOpen: true,
    buttons: [],
    texts: {
      title: labels.confirmTitle,
      description: labels.confirmDescription,
    },
  };

  config.buttons = [
    { name: labels.continueConfirmButton, type: "primary", click: save },
    { name: labels.cancelConfirmButton, type: "secondary", click: close },
  ];

  return config;
};
