import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { IGlobalContextProps } from "shared/contexts/GlobalContext";
import { parseQuery, parseToUrlQuery } from "shared/utils/http";

import { GlobalEventTypes } from "contexts/GlobalContext";

declare type WithQueryActionHandlingProps = RouteComponentProps &
  IGlobalContextProps;

enum ProfileAction {
  print = "print",
  email = "email",
}

export default function withQueryActionHandling<
  P extends WithQueryActionHandlingProps
>(
  WrappedComponent: React.ComponentClass<P> | React.FC<P>
): typeof React.Component {
  return class extends React.Component<P> {
    public render() {
      return (
        <React.Fragment>
          <WrappedComponent {...this.props} />
        </React.Fragment>
      );
    }

    public componentDidMount() {
      this.triggerActionFromQueryParameter();
    }

    private triggerActionFromQueryParameter = () => {
      const {
        location: { search, pathname },
        history,
      } = this.props;

      const { action, ...queryObject } = parseQuery(search);

      if (action) {
        switch (action) {
          case ProfileAction.email:
            this.props.globalContext.notifyListener(
              GlobalEventTypes.shareProfileViaEmail,
              {},
              true
            );
            history.replace(pathname + parseToUrlQuery(queryObject));
        }
      }
    };
  };
}
