import { ShimmeringStub } from "mediaModules/media/components/ShimmeringStub";
import { MediaAsset } from "mediaModules/media/models";
import React from "react";
import {
  formatTime,
  handleEnterPress,
  TextEllipsis,
  transformMillisecToSec,
} from "component-library";

import "./ThumbnailVideoViewer.scss";
import { IExternalMedia } from "shared-types";

export interface IThumbnailVideoViewerProps {
  title: string;
  videoModel: MediaAsset | IExternalMedia;
  playback: (video: MediaAsset | IExternalMedia) => void;
  cover: string;
  titleMaxLines?: number;
}

export const THUMBNAIL_VIDEO_VIEWER_POPUP_PROPS = {
  hideCloseButton: true,
  fullHeight: true,
  className: "c-thumbnail-video-viewer__popup",
};

export class ThumbnailVideoViewer extends React.Component<IThumbnailVideoViewerProps> {
  public render() {
    const {
      videoModel: { durationInMs = 0, isMediaObjectLoading },
      playback,
      cover,
      title,
      titleMaxLines,
    } = this.props;
    const formattedVideoTime = formatTime(
      transformMillisecToSec(durationInMs),
      ":"
    );
    const action = playback.bind(this, this.props.videoModel);

    return (
      <div className="c-thumbnail-video-viewer">
        <div className="c-thumbnail-video-viewer__preview">
          {isMediaObjectLoading ? (
            <div className="c-thumbnail-video-viewer__loading">
              <div className="c-thumbnail-video-viewer__loading-cover">
                <ShimmeringStub />
              </div>
            </div>
          ) : (
            <div
              className="c-thumbnail-video-viewer__cover"
              onClick={action}
              tabIndex={0}
              onKeyPress={handleEnterPress(action)}
            >
              <img src={cover} alt={title} />
              <span className="icon-playsmall" aria-label="Play" />
            </div>
          )}
          <div className="c-thumbnail-video-viewer__title">
            {titleMaxLines ? (
              <TextEllipsis
                text={title}
                maxLine={titleMaxLines}
                basedOn="letters"
              />
            ) : (
              title
            )}
          </div>
          <div className="c-thumbnail-video-viewer__duration">
            {isMediaObjectLoading ? "" : formattedVideoTime}
          </div>
        </div>
      </div>
    );
  }
}

export default ThumbnailVideoViewer;
