import { ICropState } from './components/CropSetting/CropSetting';
import { ITiltState } from './components/TiltSetting/TiltSetting';
import { IContrastState } from './components/ContrastSetting/ContrastSetting';

export class ToolState implements ICropState, ITiltState, IContrastState {
  public contrast: number;
  public tilt: number;
  public brightness: number;
  public rotate: number;
  public crop: {
    zoom: number;
    isApplyCrop?: boolean;
  }
}
