import { SettingManager } from "app/core";
import React from "react";

import { IGlobalContextProps } from "shared/contexts/GlobalContext";
import { ILogContextProps } from "shared/contexts/LoggerContext";

import { GlobalEventTypes } from "contexts/GlobalContext";
import {
  TranslationLibrary,
  TranslationProvider,
} from "contexts/TranslationContext";

interface IWithTranslationState {
  isLoaded: boolean;
}

declare type WithTranslationProps = IGlobalContextProps & ILogContextProps;

export default function withTranslation<P extends WithTranslationProps>(
  WrappedComponent: React.ComponentClass<P> | React.FC<P>
): typeof React.Component {
  return class extends React.Component<P, IWithTranslationState> {
    public readonly state: Readonly<IWithTranslationState> = {
      isLoaded: false,
    };
    private translation = new TranslationLibrary();

    public render() {
      const { isLoaded } = this.state;

      return (
        <TranslationProvider value={this.translation}>
          {isLoaded && <WrappedComponent {...this.props} />}
        </TranslationProvider>
      );
    }

    public componentDidMount() {
      this.loadLocalization();
      this.props.globalContext.addListener(
        GlobalEventTypes.reloadLocalization,
        this.loadLocalization
      );
    }

    private loadLocalization = async () => {
      const { globalContext, logger } = this.props;

      if (
        this.translation.currentLocalization !== SettingManager.localization
      ) {
        try {
          await this.translation.initLibrary(
            SettingManager.localization || SettingManager.defaultLocalization
          );
        } catch (error) {
          const localization =
            SettingManager.localization || SettingManager.defaultLocalization;
          logger.appendError("Error: Main Content Translation", error, {
            localization,
          });
        }

        globalContext.notifyListener(GlobalEventTypes.updateLocalization, {
          library: this.translation,
        });
        this.setState({ isLoaded: true });
      }
    };
  };
}
