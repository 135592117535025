import * as piexif from 'piexifjs';

import { InvalidOperationError } from '../exceptions';

export default class ImageMetadataManager {
  private exifData: any = undefined;
  private readonly ifd0SectionName = '0th';

  public saveImageBlobMetadata = async (blob: Blob) => {
    if (blob.type === 'image/jpeg') {
      try {
        const dataUrl = await this.convertBlobToDataUrl(blob);

        const loadedEXIFData = piexif.load(dataUrl);
        const ifd0Section = {
          [piexif.ImageIFD.Artist]: loadedEXIFData[this.ifd0SectionName][piexif.ImageIFD.Artist],
          [piexif.ImageIFD.Copyright]: loadedEXIFData[this.ifd0SectionName][piexif.ImageIFD.Copyright],
          [piexif.ImageIFD.XPAuthor]: loadedEXIFData[this.ifd0SectionName][piexif.ImageIFD.XPAuthor],
        }

        Object.keys(ifd0Section).forEach(key => ifd0Section[key] === undefined && delete ifd0Section[key])

        this.exifData = { [this.ifd0SectionName]: ifd0Section };

      } catch (error) {
        throw new InvalidOperationError('Error during metadata extraction', error);
      }
    }
  };

  public insertMetadataToImageDataUrl = (dataUrl: string): string => {
    let resultDataUrl = dataUrl;
    const dataUrlType = this.getDataUrlMimeType(dataUrl);

    if (dataUrlType === 'image/jpeg') {
      try {
        const exifBytes = piexif.dump(this.exifData);
        resultDataUrl = piexif.insert(exifBytes, dataUrl);
      } catch (error) {
        throw new InvalidOperationError('Error during metadata insertion', error);
      }
    }

    return resultDataUrl;
  };

  private convertBlobToDataUrl = (blob: Blob) =>
    new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = (e) => resolve(reader.result);
      reader.readAsDataURL(blob);
    });

  private getDataUrlMimeType = (dataUrl: string): string | undefined => {
    let result: string | undefined = undefined;
    const typeMatch = dataUrl.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

    if (typeMatch && typeMatch.length) {
      result = typeMatch[1];
    }

    return result;
  }
}
