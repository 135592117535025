import * as dot from 'dot-object';

import { TranslationLibrary } from './TranslationLibrary';
import { getDummyProxy } from 'shared/utils/proxy';

export default class Translator {

  constructor(private translationGroup: any, private library: TranslationLibrary, private path: string) {
  }

  public getTranslationFor = (path: string): any => this.library.searchByPath(path, this.translationGroup);

  public getTranslator(path: string, fromRoot: boolean = false): Translator {
    return fromRoot ? this.library.createTranslationNode(path)
      : new Translator(this.library.searchByPath(path, this.translationGroup), this.library, this.path + path);
  }

  public translate(name: string, path?: string) {
    let group = this.translationGroup;
    if (path) {
      const pathParts: string[] = path.split('.');
      for (const pathPart of pathParts) {
        if (pathPart in group) {
          group = group[pathPart];
        }
      }
    }

    return group[name];
  }

  public createTranslationObject<T>(objectProps: string[], path?: string): T {
    let object: { [key: string]: string | any } = {};
    let selectedGroup = this.translationGroup;

    if (path) {
      selectedGroup = this.getTranslationFor(path);
    }

    for (const objectProp of objectProps) {
      if (objectProp in selectedGroup) {
        object[objectProp] = selectedGroup[objectProp];
      }
      else {
        object = { ...object, ...this.createNestedObject(selectedGroup, objectProp) };
      }
    }

    return this.createProxyObject(object) as T;
  }

  public compileTranslationObject<T>(objectProps: string[], commonGroup: string, path: string = ''): T | any {
    let selectedGroup = this.translationGroup;

    if (path) {
      selectedGroup = this.getTranslationFor(path);
      commonGroup = `${path}.${commonGroup}`;
    }

    let object: { [key: string]: string | any } = {};

    if ('modules' in selectedGroup) {
      const modules = selectedGroup['modules'];
      const moduleNames = Object.keys(modules)
      for (const objectProp of objectProps) {
        if (moduleNames.some(name => objectProp === name)) {
          object[objectProp] = this.createNestedObject(modules[objectProp]);
        }
        else {
          object = { ...object, ...this.createTranslationObject([objectProp], commonGroup) }
        }
      }
    }

    return this.createProxyObject(object);
  }

  public get version() {
    return this.library.version;
  }

  public getTranslationVersion = (object: any) => object['__version'];

  private createNestedObject(selectedGroup: any, objectProp?: string): any {
    let selectedProperties: { [key: string]: string | any } = {};

    for (const propertyName in selectedGroup) {
      const path: string[] = propertyName.split('_');

      if (objectProp) {
        if (path[0] !== objectProp) {
          continue;
        }
      }

      selectedProperties[propertyName.split('_').join('.')] = selectedGroup[propertyName];
    }

    return this.createProxyObject(dot.object(selectedProperties))
  }

  private createProxyObject(object: any) {
    object['__version'] = this.library.version;
    const objectProxy = new Proxy(object, {
      get: (target, prop, receiver) => {
        if (prop in target) {
          return Reflect.get(target, prop, receiver);
        } else {
          console.warn('error - translation. No data:' + prop.toString())
          return getDummyProxy('No Data');
        }
      }
    })

    return objectProxy;
  }

}
