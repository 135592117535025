import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Button,
  SectionContainer,
  transformMillisecToSec,
} from "component-library";

import {
  Breadcrumbs,
  IBreadcrumbsContent,
} from "components/common/Breadcrumbs";
import {
  ITranslationContextProps,
  Translator,
  withTranslation,
} from "contexts/TranslationContext";
import {
  IGlobalContextProps,
  withGlobalContext,
} from "shared/contexts/GlobalContext";
import { GlobalEventTypes } from "contexts/GlobalContext";
import {
  getAlertMessage,
  getSectionContainerProps,
  IAddNewAudioPageContent,
  getStoreLimitAlert,
  IAddNewAudioPageContentKeys,
} from "./AddNewAudioPageContent";
import { NewAudioForm } from "./components/NewAudioForm";
import {
  MediaAssetType,
  MediaMetadataPayload,
  MediaStorageSpace,
} from "mediaModules/media/models";
import { PopupTypes } from "mediaModules/media/pages/AddNewAudioPage/AddNewAudioPageContent";
import { getLocalUrl, Logger } from "common";
import mediaService from "mediaModules/media/services/MediaService";
import { scrollRefToTop } from "mediaModules/media/utils/page";
import { GAEvents } from "mediaModules/media/components/GAEvents";
import { IBreadcrumbsContentKeys } from "../../../../app/components/common/Breadcrumbs/Breadcrumbs";

import "./AddNewAudioPage.scss";

export interface IAddNewAudioPageProps
  extends ITranslationContextProps,
    IGlobalContextProps,
    RouteComponentProps {}

interface IAddNewAudioPageState {
  content: IAddNewAudioPageContent;
  metadataPayload: MediaMetadataPayload;
  storageSpace: MediaStorageSpace;
  isLoading: boolean;
  isFileUploading: boolean;
}

export class AddNewAudioPage extends React.Component<
  IAddNewAudioPageProps,
  IAddNewAudioPageState
> {
  private pageRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();

  constructor(props: IAddNewAudioPageProps) {
    super(props);

    this.state = {
      content: AddNewAudioPage.mapTranslation(props.translator),
      metadataPayload: new MediaMetadataPayload(),
      storageSpace: {
        allowedStorageSpace: {
          maxAllowedDisplayInSec: 0,
          maxAllowedStoreInSec: 0,
        },
        usedStorageSpace: {
          usedDisplayValueInSec: 0,
          usedStoreValueInSec: 0,
        },
      },
      isLoading: false,
      isFileUploading: false,
    };
  }

  public render() {
    const { content, metadataPayload, storageSpace } = this.state;

    const breadcrumbsContent: IBreadcrumbsContent =
      this.getBreadcrumbsTranslation();
    const sectionContainerProps = getSectionContainerProps(
      content,
      this.handleCloseClick
    );

    return (
      <div ref={this.pageRef}>
        <Breadcrumbs content={breadcrumbsContent} />
        <div className="g-content-area p-add-new-audio-page">
          <div className="g-col-lg-12 p-add-new-audio-page__container">
            <SectionContainer {...sectionContainerProps}>
              <div className="p-add-new-audio-page__content">
                <NewAudioForm
                  storageSpace={storageSpace}
                  content={content.newAudioForm}
                  metadataPayload={metadataPayload}
                  updateMetadata={this.updateMetadataPayload}
                  saveChanges={this.saveChanges}
                  showDeletePopup={this.showDeletePopup}
                  preUploadValidation={this.preUploadValidation}
                  disableSaveButton={this.disableSaveButton}
                >
                  {this.renderFormButtons}
                </NewAudioForm>
              </div>
            </SectionContainer>
          </div>
        </div>
      </div>
    );
  }

  public async componentDidMount() {
    try {
      const allowedStorageSpace = await mediaService.getAllowedStorageSpace(
        MediaAssetType.Audio
      );
      const mediaAssets = await mediaService.getMediaAssets(
        MediaAssetType.Audio,
        this.closeGlobalAlert
      );
      const usedStorageSpace = mediaService.getUsedStorageSpace(mediaAssets);

      this.setState({
        storageSpace: {
          allowedStorageSpace: allowedStorageSpace,
          usedStorageSpace: usedStorageSpace,
        },
      });

      GAEvents.trackPageLoad(
        "Add audio page is loaded",
        MediaAssetType.Audio,
        true
      );
    } catch (error) {
      Logger.appendLog(error);
    }
  }

  public static getDerivedStateFromProps(
    nextProps: IAddNewAudioPageProps,
    prevState: IAddNewAudioPageState
  ) {
    let stateUpdates = {};

    if (
      nextProps.translator.version !==
      nextProps.translator.getTranslationVersion(prevState.content)
    ) {
      const content = AddNewAudioPage.mapTranslation(nextProps.translator);
      stateUpdates = { content };
    }

    return stateUpdates;
  }

  private renderFormButtons = (submit: () => void) => {
    const { content, isLoading, isFileUploading } = this.state;

    return (
      <div className="p-add-new-audio-page__buttons">
        <div className="p-add-new-audio-page__button">
          <Button
            type="primary"
            disabled={isFileUploading}
            onClick={() => this.handleSaveClick(submit)}
            loading={isLoading}
            text={content.saveButton}
            loadingText={content.saveButton}
          />
        </div>
        <div className="p-add-new-audio-page__button">
          <Button
            type="secondary"
            onClick={this.handleCancelAndCloseClick}
            text={content.cancelButton}
          />
        </div>
      </div>
    );
  };

  private closeGlobalAlert = () =>
    this.props.globalContext.notifyListener(
      GlobalEventTypes.closeAllGlobalAlert
    );

  private handleSaveClick = (submit: () => void) => {
    GAEvents.addMediaAsset.trackSaveClick(MediaAssetType.Audio);
    submit();
  };

  private handleCancelAndCloseClick = async () => {
    GAEvents.addMediaAsset.trackCancelCloseClick(MediaAssetType.Audio);
    await this.closePage();
  };

  private handleCloseClick = async () => {
    GAEvents.addMediaAsset.trackCloseClick(MediaAssetType.Audio);
    await this.closePage();
  };

  private closePage = async () => {
    const { content, metadataPayload } = this.state;
    const { globalContext } = this.props;

    const isMetadataPayloadChanged = Boolean(
      metadataPayload.title || metadataPayload.mediaInfoId
    );

    if (isMetadataPayloadChanged) {
      const message = getAlertMessage(
        content,
        PopupTypes.CancelClose,
        this.closeGlobalAlert,
        async () => {
          this.closeGlobalAlert();
          await this.deleteMediaObject(metadataPayload.mediaInfoId);
          this.redirectTo();
        }
      );

      globalContext.notifyListener(
        GlobalEventTypes.notifyingGlobalAlert,
        message
      );
    } else {
      this.redirectTo();
    }
  };

  private showDeletePopup = async (
    metadataPayload: MediaMetadataPayload,
    callback
  ) => {
    const {
      content,
      metadataPayload: { mediaInfoId },
    } = this.state;
    const { globalContext } = this.props;

    const message = getAlertMessage(
      content,
      PopupTypes.Delete,
      this.closeGlobalAlert,
      async () => {
        this.closeGlobalAlert();
        this.updateMetadataPayload(metadataPayload);
        await this.deleteMediaObject(mediaInfoId);
        callback();
      }
    );

    globalContext.notifyListener(
      GlobalEventTypes.notifyingGlobalAlert,
      message
    );
  };

  private updateMetadataPayload = (metadataPayload: MediaMetadataPayload) =>
    this.setState({ metadataPayload });

  private saveChanges = async () => {
    const { metadataPayload } = this.state;

    if (metadataPayload.mediaInfoId) {
      this.setState({ isLoading: true });

      try {
        await mediaService.createMediaMetadata(metadataPayload);
        this.redirectTo();
      } catch (error) {
        this.setState({ isLoading: false });
        Logger.appendLog(error);
      }
    }
  };

  private deleteMediaObject = async (mediaInfoId: string) => {
    if (mediaInfoId) {
      try {
        await mediaService.deleteMediaObjectById(mediaInfoId);
      } catch (error) {
        Logger.appendLog("Error: Deleting process has been failed", error);
      }
    }
  };

  private redirectTo = () => {
    scrollRefToTop(this.pageRef);
    this.props.history.push(getLocalUrl("media/audio"));
  };

  private static mapTranslation = (translator: Translator) =>
    translator.compileTranslationObject<IAddNewAudioPageContent>(
      IAddNewAudioPageContentKeys,
      "common"
    );

  private getBreadcrumbsTranslation = () =>
    this.props.translator
      .getTranslator("breadcrumbs", true)
      .createTranslationObject<IBreadcrumbsContent>(IBreadcrumbsContentKeys);

  private preUploadValidation = (durationInMs: number) => {
    const { globalContext } = this.props;
    const {
      content,
      storageSpace: {
        usedStorageSpace: { usedStoreValueInSec },
        allowedStorageSpace: { maxAllowedStoreInSec },
      },
    } = this.state;

    const durationInSecAfterAdding =
      transformMillisecToSec(durationInMs) + usedStoreValueInSec;

    if (durationInSecAfterAdding > maxAllowedStoreInSec) {
      const message = getStoreLimitAlert(content.storeLimitAlert, () =>
        this.closeGlobalAlert()
      );
      globalContext.notifyListener(
        GlobalEventTypes.notifyingGlobalAlert,
        message
      );

      return false;
    }

    return true;
  };

  private disableSaveButton = (isFileUploading: boolean) =>
    this.setState({ isFileUploading });
}

export default withTranslation(
  withGlobalContext(AddNewAudioPage),
  "addNewAudioPage"
);
