import { config } from "config";
import { MediaAssetType } from "mediaModules/media/models";

import { ImageLocation } from "models";

export interface IPhotoMedia {
  imgWidth: number;
  screenWidth?: number;
}

const X_LARGE_SCREEN = 2500;
const CLOUDINARY_SHARPEN = "e_sharpen:62";
const CLOUDINARY_QUALITY = "q_auto:best";
const CLOUDINARY_CROPPING = "c_limit";
const CLOUDINARY_HEIGHT = "h_";
const CLOUDINARY_WIDTH = "w_";
const CLOUDINARY_KEEP_ATTRIBUTION = "fl_keep_attribution";

export enum ImageWidth {
  WidthXSmall = 240,
  WidthSmall = 480,
  WidthMedium = 645,
  WidthLarge = 960,
  WidthXLarge = 1290,
}

const MAIN_PHOTO_SIZES = { height: 0, width: ImageWidth.WidthXSmall };
const GALLERY_PHOTO_SIZES = { height: 200, width: 0 };
const GALLERY_VIEW_PHOTO_SIZES = { height: 0, width: ImageWidth.WidthLarge };
const EDIT_PHOTO_SIZES = { height: 0, width: 0 };

export const PHOTO_CONTAINER_WIDTH_LIST: IPhotoMedia[] = [
  { imgWidth: ImageWidth.WidthSmall, screenWidth: 500 },
  { imgWidth: ImageWidth.WidthMedium, screenWidth: 850 },
  { imgWidth: ImageWidth.WidthLarge, screenWidth: X_LARGE_SCREEN },
];

export const FULL_GALLERY_WIDTH_LIST: IPhotoMedia[] = [
  { imgWidth: ImageWidth.WidthSmall, screenWidth: 500 },
  { imgWidth: ImageWidth.WidthMedium, screenWidth: 720 },
  { imgWidth: ImageWidth.WidthLarge, screenWidth: 1200 },
  { imgWidth: ImageWidth.WidthXLarge, screenWidth: X_LARGE_SCREEN },
];

export function getApiUrl(...urlParts: string[]): string {
  return config.profileApiUrl + urlParts.join("");
}

export function getPortalApiUrl(...urlParts: string[]): string {
  return config.portalApi + urlParts.join("");
}

export function getLiteAgentApiUrl(...urlParts: string[]): string {
  return config.liteAgentApiRoot + urlParts.join("");
}

export function getTitaniumApiUrl(...urlParts: string[]): string {
  return config.mediaApiRoot + urlParts.join("");
}

export function getShortlistsApiUrl(...urlParts: string[]): string {
  return config.shortlistsApi + urlParts.join("");
}

export function getSearchAndFiltersApiUrl(...urlParts: string[]): string {
  return config.searchAndFiltersApi + urlParts.join("");
}

export function getLocationUrl(...urlParts: string[]): string {
  return config.locationApi + urlParts.join("");
}

export const getImageSourcesSettings = (
  imageId: string,
  imageWidthList: IPhotoMedia[]
): string[][] => {
  return (
    imageWidthList.map(({ imgWidth, screenWidth }) =>
      getImageSource(imageId, imgWidth, screenWidth)
    ) || []
  );
};

export const getImageSource = (
  imageId: string,
  imageWidth: number,
  screenWidth = X_LARGE_SCREEN
): string[] => {
  return [
    `(max-width: ${screenWidth}px)`,
    `${getImageUrlWithCustomWidth(
      imageId,
      imageWidth
    )}, ${getImageUrlWithCustomWidth(imageId, imageWidth * 2)} 2x`,
  ];
};

export function isDataImage(imageUrl: string): boolean {
  const headOfString = imageUrl.substring(0, 40);
  const matches = headOfString.match(/^data:([\w//]){0,20};base64/gm);

  return Boolean(matches && matches.length > 0);
}

export function getImageUrl(imageUrl: string, location: ImageLocation): string {
  if (isDataImage(imageUrl)) {
    return imageUrl;
  }

  let imageSize: { height: number; width: number };

  switch (location) {
    case ImageLocation.MainPhoto:
      imageSize = MAIN_PHOTO_SIZES;
      break;
    case ImageLocation.GalleryPhoto:
      imageSize = GALLERY_PHOTO_SIZES;
      break;
    case ImageLocation.ViewPhoto:
      imageSize = GALLERY_VIEW_PHOTO_SIZES;
      break;
    case ImageLocation.PhotoEditor:
      imageSize = EDIT_PHOTO_SIZES;
      break;
    default:
      throw Error("Unknown image location");
  }

  const imageParams = [
    CLOUDINARY_SHARPEN,
    CLOUDINARY_QUALITY,
    CLOUDINARY_CROPPING,
    CLOUDINARY_KEEP_ATTRIBUTION,
  ];

  if (imageSize.height) {
    imageParams.push(CLOUDINARY_HEIGHT + imageSize.height);
  }

  if (imageSize.width) {
    imageParams.push(CLOUDINARY_WIDTH + imageSize.width);
  }

  const imageId = imageUrl.split("/").reverse()[0];

  return config.cloudinaryApi
    .replace("{params}", imageParams.join(","))
    .replace("{image}", imageId);
}

export function getImageUrlWithCustomWidth(
  imageUrl: string,
  width: number
): string {
  if (isDataImage(imageUrl)) {
    return imageUrl;
  }

  const imageParams = [
    CLOUDINARY_SHARPEN,
    CLOUDINARY_QUALITY,
    CLOUDINARY_CROPPING,
    CLOUDINARY_KEEP_ATTRIBUTION,
    CLOUDINARY_WIDTH + width,
  ];

  return config.cloudinaryApi
    .replace("{params}", imageParams.join(","))
    .replace("{image}", imageUrl);
}

export function getOriginalImageUrl(imageUrl: string): string {
  return config.cloudinaryApi
    .replace("{params}/", "")
    .replace("{image}", imageUrl);
}

export function getUmbracoApiUrl(path: string): string {
  return config.umbracoApiUrl + "/umbraco/api/" + path;
}

export function getMediaViewerUrl(
  assetType: MediaAssetType,
  artistRef: string
): string {
  const mediaType =
    assetType === MediaAssetType.Video ? "showreels" : "audioclips";

  return `${config.mediaViewerUrl}/artist/${mediaType}?artistRef=${artistRef}`;
}

export function getProfileFrontEndUrl(path: string): string {
  return config.profileFrontEndUrl + "/" + path;
}

export function getSearchAndFiltersFrontendUrl(path: string): string {
  return config.searchAndFiltersFrontendUrl + "/" + path;
}

export function getSiteRootUrl(path: string): string {
  return config.siteRootUrl + path;
}
