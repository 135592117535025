import FacetGroup from './FacetGroup';
import FacetLevel from './FacetLevel';

export default class FacetCategory {
  public id: number;
  public name: string;
  public code: string;
  public maxActiveCount: number;
  public systemName: string;
  public facetGroups: FacetGroup[];
  public facetLevels: FacetLevel[];
}
