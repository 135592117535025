import React, { useState } from "react";
import _compact from "lodash/compact";
import { FacetType, IFacet } from "shared-services";
import { useTranslation } from "i18n";
import { IFilterTagProps } from "../../Atoms/FilterTag/FilterTag";
import { FilterControl } from "../FilterControl/FilterControl";
import {
  FilterDrawer,
  IFilterDrawerItem,
} from "../FilterControl/FilterDrawer/FilterDrawer";
import {
  FilterConfig,
  IFilterStats,
  IFilterValues,
  SetFilterValues,
} from "./types";
import "./index.scss";

interface Props<T> {
  values: T;
  defaultValues: T;
  filterDrawerValues: T;
  setFilterValues: SetFilterValues<T>;
  setFilterDrawerValues: SetFilterValues<T>;
  facets: Record<FacetType, IFacet[]>;
  stats: IFilterStats;
  config: FilterConfig<T>;
}

function Filters<T extends IFilterValues>({
  values,
  defaultValues,
  filterDrawerValues,
  setFilterValues,
  setFilterDrawerValues,
  facets,
  stats,
  config,
}: Readonly<Props<T>>) {
  const { t } = useTranslation();
  const [isVisibleFilterDrawer, setIsVisibleFilterDrawer] = useState(false);

  const closeFilterDrawer = () => setIsVisibleFilterDrawer(false);

  const { tags, filterDrawerConfig, filterControlComponents } = config.reduce<{
    tags: IFilterTagProps[];
    filterDrawerConfig: IFilterDrawerItem[];
    filterControlComponents: JSX.Element[];
  }>(
    (acc, { getFilterFiledProps, name, facetNames, modifiers }) => {
      const { tags, filterDrawerConfig, filterControlComponent } =
        getFilterFiledProps({
          name,
          facetNames,
          values,
          filterDrawerValues,
          setFilterValues,
          setFilterDrawerValues,
          facets,
          stats,
          modifiers,
          t,
        });

      return {
        tags: [...acc.tags, ...tags],
        filterDrawerConfig: [...acc.filterDrawerConfig, filterDrawerConfig],
        filterControlComponents: _compact([
          ...acc.filterControlComponents,
          filterControlComponent,
        ]),
      };
    },
    {
      tags: [],
      filterDrawerConfig: [],
      filterControlComponents: [],
    }
  );

  return (
    <div className="bg-color-neutral-three">
      <FilterControl
        onViewAllFilters={() => setIsVisibleFilterDrawer(true)}
        onClearAllFilters={() => {
          setFilterValues(defaultValues);
          closeFilterDrawer();
        }}
        tags={tags}
        items={filterControlComponents}
        isControlledTags
      />
      {isVisibleFilterDrawer && (
        <FilterDrawer
          className="g-bg-default"
          filters={filterDrawerConfig}
          onClose={closeFilterDrawer}
          onClearAllFilters={() => {
            setFilterValues(defaultValues);
            closeFilterDrawer();
          }}
          onApplyFilters={() => {
            setFilterValues(filterDrawerValues);
            closeFilterDrawer();
          }}
        />
      )}
    </div>
  );
}

export default Filters;
