import React from "react";
import { v4 as uuid } from "uuid";

import { ChangesTrackingService, Validator } from "./ChangesTrackingService";
import { ChangesTrackerContext } from "./withChangesTracker";

export interface IChangesTrackingProps {
  registerValidator: (validator: Validator) => void;
  cleanValidatorList: () => void;
}

export default function withChangesTracking<P extends IChangesTrackingProps>(
  WrappedComponent: React.ComponentClass<P>
): typeof React.Component {
  return class extends React.Component<Omit<P, keyof IChangesTrackingProps>> {
    private registeredValidatorIds: string[] = [];
    private contextInstance: ChangesTrackingService;

    public render() {
      return (
        <ChangesTrackerContext.Consumer>
          {(context) => {
            this.contextInstance = context;
            const props = {
              ...this.props,
              registerValidator: this.registerValidator,
              cleanValidatorList: this.cleanValidatorList,
            } as P;

            return <WrappedComponent {...props} />;
          }}
        </ChangesTrackerContext.Consumer>
      );
    }

    public componentWillUnmount() {
      this.cleanValidatorList();
    }

    private cleanValidatorList = () => {
      this.contextInstance.dropValidators(this.registeredValidatorIds);
    };

    private registerValidator = (validator: Validator) => {
      const id = uuid();
      this.registeredValidatorIds.push(id);

      this.contextInstance.setValidator(id, validator);
    };
  };
}
