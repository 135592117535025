import { PhotoContainer } from "flows/Common/PhotoContainer";
import React from "react";

import { GlobalAlertMessage } from "shared/modules/Common/GlobalAlert";
import {
  Stub,
  ActionButton,
  Button,
  debounce,
  IconTextLink,
} from "component-library";

import { Photo } from "models";

import {
  getHelpAndAdvice,
  IMainPhotoViewContent,
} from "./MainPhotoViewContent";

import "./MainPhotoView.scss";

const MIN_PHOTO_HEIGHT = 200;

export interface IMainPhotoViewProps {
  close: () => void;
  editPhoto: () => void;
  replacePhoto: () => void;
  showHelpPopup: (message: GlobalAlertMessage) => void;
  photo?: Photo;
  error?: string;
  content: IMainPhotoViewContent;
}

interface IMainPhotoViewState {
  occupiedHeight: number;
  minPhotoHeight: number;
  areCreditsVisible: boolean;
}

export default class MainPhotoView extends React.Component<
  IMainPhotoViewProps,
  IMainPhotoViewState
> {
  public readonly state: Readonly<IMainPhotoViewState> = {
    occupiedHeight: 0,
    minPhotoHeight: MIN_PHOTO_HEIGHT,
    areCreditsVisible: false,
  };

  private containerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private headerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private toolBarRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();
  private imageContainerRef: React.RefObject<HTMLDivElement> =
    React.createRef<HTMLDivElement>();

  constructor(props: IMainPhotoViewProps) {
    super(props);

    window.addEventListener("resize", this.adaptImageContainerDebounced, false);
    window.addEventListener(
      "orientationchange",
      this.adaptImageContainerDebounced,
      false
    );
  }

  public render() {
    const { content, close, error, photo } = this.props;

    return (
      <div ref={this.containerRef} className="c-main-photo-view">
        <div ref={this.headerRef} className="c-main-photo-view__title">
          {content.header}
        </div>
        <div
          ref={this.imageContainerRef}
          className="c-main-photo-view__image-container"
        >
          {!error ? <PhotoContainer photo={photo} /> : <Stub text={error} />}
        </div>
        <div ref={this.toolBarRef} className="c-main-photo-view__tools">
          {this.renderToolBar()}
          <div className="c-main-photo-view__buttons-container">
            <div className="c-main-photo-view__help g-hidden-sm g-hidden-md">
              <IconTextLink
                variant="quaternary"
                text={content.help.link?.text || ""}
                handleClick={this.showHelpAndAdvice}
                iconName="icon-lhnhelp"
              />
            </div>
            <div className="c-main-photo-view__button">
              <Button type="primary" text={content.close} onClick={close} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  public componentDidUpdate() {
    if (this.props.photo) {
      this.adaptImageContainer();
    }
  }

  public componentWillUnmount() {
    window.removeEventListener(
      "resize",
      this.adaptImageContainerDebounced,
      false
    );
    window.removeEventListener(
      "orientationchange",
      this.adaptImageContainerDebounced,
      false
    );
  }

  private renderToolBar = () => {
    const { photo, content, error, editPhoto, replacePhoto } = this.props;

    const isButtonDisabled = Boolean(error) || !Boolean(photo);

    return (
      <div className="c-main-photo-view__tool-bar">
        <ActionButton
          disabled={isButtonDisabled}
          onClick={editPhoto}
          icon="edit"
          label={content.editButton}
          ariaLabel={this.props.content.editButton}
          variant="toolbar"
        />
        <ActionButton
          disabled={isButtonDisabled}
          onClick={replacePhoto}
          icon="replace"
          label={content.replaceButton}
          ariaLabel={content.replaceButton}
          variant="toolbar"
        />
      </div>
    );
  };

  private showHelpAndAdvice = () =>
    this.props.showHelpPopup(getHelpAndAdvice(this.props.content.helpAdvice));

  private adaptImageContainer = () => {
    const toolBar = this.toolBarRef.current;
    const imageContainer = this.imageContainerRef.current;
    const container = this.containerRef.current;
    const header = this.headerRef.current;

    if (imageContainer && container && toolBar && header) {
      const containerStyles = window.getComputedStyle(container, null);
      const containerPaddings =
        parseFloat(containerStyles.getPropertyValue("padding-top")) +
        parseFloat(containerStyles.getPropertyValue("padding-bottom"));
      const occupiedHeight =
        toolBar.offsetHeight + header.offsetHeight + containerPaddings;

      imageContainer.style.maxHeight = `calc(100vh - ${occupiedHeight}px)`;
    }
  };

  private adaptImageContainerDebounced = debounce(
    this.adaptImageContainer,
    300
  );
}
