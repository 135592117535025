import { IPhotoEditingFlowContent } from "../PhotoEditingFlow";
import { IEditCreditsFormContent } from "./EditCreditsForm";
import { FileErrorStatuses } from "models";
import { IAlertMessageProps } from "component-library";

export interface IUploadingPhotoFlowContent {
  closePopup: string;
  photoEditFlow: IPhotoEditingFlowContent;
  editCreditsForm: IEditCreditsFormContent;
}
export const IUploadingPhotoFlowContentKeys = [
  "closePopup",
  "photoEditFlow",
  "editCreditsForm",
];

export const getError = (
  errorStatuses: FileErrorStatuses[],
  content: IPhotoEditingFlowContent
): any => {
  const errors = [
    {
      key: FileErrorStatuses.RejectedImageSize,
      message: content.errorDialog.imageSizeError,
    },
  ];

  let errorMessage = content.generalError;

  if (errorStatuses && errorStatuses.length > 0) {
    const firstError = errorStatuses[0];
    const error = errors.find((error) => error.key === firstError);
    if (error) {
      errorMessage = error.message;
    }
  }

  return errorMessage;
};

declare type SaveDialogSettings = {
  content: IPhotoEditingFlowContent;
  confirmSave: Function;
  photoEdit: Function;
  close: Function;
};

export const getSaveDialogSettings = (
  setting: SaveDialogSettings
): IAlertMessageProps => {
  const {
    content: { saveDialog },
    confirmSave,
    photoEdit,
    close,
  } = setting;
  return {
    icon: "notice",
    texts: {
      title: saveDialog.saveTitle,
      description: saveDialog.saveDescription,
    },
    buttons: [
      { name: saveDialog.continueButton, type: "primary", click: confirmSave },
      {
        name: saveDialog.backToEditButton,
        type: "secondary",
        click: photoEdit,
      },
      { name: saveDialog.discardButton, type: "secondary", click: close },
    ],
    focusOnOpen: true,
  };
};

declare type CancelDialogSettings = {
  content: IPhotoEditingFlowContent;
  close: Function;
  photoEdit: Function;
  confirmSave: Function;
};

export const getCancelDialogSettings = (
  setting: CancelDialogSettings
): IAlertMessageProps => {
  const {
    content: { cancelDialog },
    confirmSave,
    photoEdit,
    close,
  } = setting;

  return {
    icon: "notice",
    texts: {
      title: cancelDialog.cancelTitle,
      description: cancelDialog.cancelDescription,
    },
    buttons: [
      {
        name: cancelDialog.discardChangesButton,
        type: "primary",
        click: close,
      },
      {
        name: cancelDialog.rejectCancellation,
        type: "secondary",
        click: photoEdit,
      },
      { name: cancelDialog.saveButton, type: "secondary", click: confirmSave },
    ],
    focusOnOpen: true,
  };
};

declare type ErrorDialogSettings = {
  content: IPhotoEditingFlowContent;
  photoEdit: Function;
};

export const getErrorDialogSettings = (
  setting: ErrorDialogSettings
): IAlertMessageProps => {
  const {
    content: { errorDialog },
    photoEdit,
  } = setting;

  return {
    icon: "warning",
    texts: {
      title: errorDialog.errorTitle,
      description: "",
    },
    buttons: [
      { name: errorDialog.backToPhotoTool, type: "primary", click: photoEdit },
    ],
    focusOnOpen: true,
  };
};
