import { UserRoleManager, UserRoles } from "core";
import {
  AccountType,
  ILink,
  INavigation,
  INavigationHeaderLabelCollection,
  IPerformerSearchContent,
} from "component-library";

import "./HeaderSection.scss";

export const LOGO_LINK = "https://www.spotlight.com/";

const ROLE_TO_ACCOUNT_TYPE_MAPPING = {
  [UserRoles.spotlight]: AccountType.Performer,
  [UserRoles.performer]: AccountType.Performer,
  [UserRoles.agent]: AccountType.Agent,
  [UserRoles.castingDirector]: AccountType.CastingDirector,
  [UserRoles.castingDirectorSearchOnly]: AccountType.CastingDirector,
  [UserRoles.anonymous]: AccountType.DefaultUser,
};

export interface IMembershipType {
  typeId: string;
  translation: string;
}

export interface IHeaderSectionContent {
  myAccountLink: string;
  bottomNavLinks: ILink[];
  menuNav: INavigation;
  headerLabels: INavigationHeaderLabelCollection;
  performerSearch: IPerformerSearchContent;
}

export const getAccountType = (): AccountType => {
  let accountType: AccountType = AccountType.DefaultUser;
  const userRole = UserRoleManager.getUserRole() || "";

  if (Boolean(userRole)) {
    accountType = ROLE_TO_ACCOUNT_TYPE_MAPPING[userRole.toLowerCase()];
  }

  return accountType;
};
