import React from "react";

export interface IPictureProps extends React.ImgHTMLAttributes<any> {
  sourcesSettings: string[][];
  onError?: () => void;
  imgRef?: React.RefObject<HTMLImageElement>;
}

export const Picture: React.FC<IPictureProps> = ({
  sourcesSettings,
  onError,
  imgRef,
  ...props
}) => {
  const sources = sourcesSettings.map(([media, srcset], index) => (
    <source key={index} media={media} srcSet={srcset} />
  ));

  return (
    <picture>
      {sources}
      <img ref={imgRef} onError={onError} {...props} />
    </picture>
  );
};
