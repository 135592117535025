import React from "react";
import {
  Checkbox,
  Radio,
  AddPhoto,
  IAddPhotoTexts,
  Stub,
  debounce,
} from "component-library";
import { SortableElement, SortableContainer } from "react-sortable-hoc";

import GalleryValidation, {
  IGalleryValidationContent,
} from "../../GalleryValidation";
import Photo from "../Photo/Photo";

import { GlobalEventTypes } from "contexts/GlobalContext";
import {
  withGlobalContext,
  IGlobalContextProps,
} from "shared/contexts/GlobalContext";

import { BasePhoto } from "models";

import "./PhotoSet.scss";

export interface IPhotoSetTexts {
  imageAlt: string;
  hiddenPhoto: string;
  selectPhoto: string;
  tooManyPhotosAlerts: IGalleryValidationContent;
}

interface IPhotoSetBaseProps extends IGlobalContextProps {
  photos: BasePhoto[];
  texts: IPhotoSetTexts;
  photoClicked: (clickedPhoto: BasePhoto) => any;
  photoError?: (photo: BasePhoto) => any;
  error?: string;
}

export enum PHOTOSET_MODES {
  multiSelect = "multiselect",
  singleSelect = "singleSelect",
}

interface IPhotoSetSingleSelectProps extends IPhotoSetBaseProps {
  mode: PHOTOSET_MODES.singleSelect;
  photoSelected?: (photo: BasePhoto) => void;
  sortCompleted?: (photo: BasePhoto) => void;
  addPhotoTexts: IAddPhotoTexts;
  photoAdded: (img: HTMLImageElement) => any;
}

interface IPhotoSetEditProps extends IPhotoSetBaseProps {
  mode: typeof PHOTOSET_MODES.multiSelect;
  photoSelected: (
    selectedPhotos: BasePhoto[],
    currentlySelectedPhoto: BasePhoto | null
  ) => any;
  sortCompleted: (oldIndex: number, newIndex: number) => any;
  selectedPhotos: BasePhoto[];
  addPhotoTexts: IAddPhotoTexts;
  photoAdded: (img: HTMLImageElement) => any;
}

export type IPhotoSetProps = IPhotoSetEditProps | IPhotoSetSingleSelectProps;

const SortablePhoto = SortableElement(
  ({ value, photoSet }: { value: BasePhoto; photoSet: PhotoSet }) => {
    return (
      <li className="c-photoset__image-container">
        <Photo
          photo={value}
          texts={{
            imageAlt: photoSet.props.texts.imageAlt,
            hiddenPhoto: photoSet.props.texts.hiddenPhoto,
          }}
          onClick={photoSet.photoClicked.bind(photoSet, value)}
          onError={photoSet.photoError.bind(photoSet, value)}
        />
        {photoSet.props.mode == PHOTOSET_MODES.multiSelect && (
          <div className="c-photoset__checkbox">
            <Checkbox
              htmlId="photoSetSelectPhoto"
              name={`select_photo_${value.id}`}
              value={value.id}
              onChange={photoSet.toggleSelectPhoto(value)}
              ariaLabel={photoSet.props.texts.selectPhoto}
              defaultChecked={photoSet.props.selectedPhotos.indexOf(value) > -1}
            />
          </div>
        )}
        {photoSet.props.mode == PHOTOSET_MODES.singleSelect && (
          <Radio
            name="single_select_photo"
            value={value.id}
            onChange={photoSet.toggleSelectPhoto(value)}
            checked={
              photoSet.state.selectedPhoto
                ? photoSet.state.selectedPhoto.id === value.id
                : false
            }
          />
        )}
      </li>
    );
  }
);

const SortablePhotoList = SortableContainer(
  ({
    items,
    photoSet,
    addPhotoButton,
  }: {
    items: BasePhoto[];
    photoSet: PhotoSet;
    addPhotoButton: JSX.Element | null;
  }) => {
    return (
      <ul className="c-photoset__list">
        {items.map((photo, index) => (
          <SortablePhoto
            photoSet={photoSet}
            key={`item-${photo.id}`}
            index={index}
            value={photo}
          />
        ))}
        {addPhotoButton}
      </ul>
    );
  }
);

const SORT_OVERRIDE_TAGNAMES: string[] = [
  "input",
  "textarea",
  "select",
  "option",
  "label",
];

const DRAG_THRESHOLD_DISTANCE_PX = 2;

interface IPhotoSetState {
  selectedPhoto?: BasePhoto;
  disableSorting: boolean;
}

export class PhotoSet extends React.Component<IPhotoSetProps, IPhotoSetState> {
  private galleryValidation = new GalleryValidation();
  private addPhotoRef: React.RefObject<AddPhoto> = React.createRef<AddPhoto>();

  constructor(props) {
    super(props);
    this.state = {
      disableSorting: false,
    };
  }

  public render() {
    return (
      <div
        className={`c-photoset ${
          this.props.mode == PHOTOSET_MODES.singleSelect ? "single-select" : ""
        }`}
      >
        {!this.props.error ? (
          this.renderPhotos()
        ) : (
          <div className="c-photoset__error">
            <Stub className="c-photoset__stub" text={this.props.error} />
          </div>
        )}
      </div>
    );
  }

  private handleResize = debounce(() => {
    const disableSorting = window.innerWidth < 1200;
    this.setState({ disableSorting });
  }, 300);

  public componentDidMount() {
    window.addEventListener("resize", this.handleResize, false);
    this.handleResize();
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize, false);
  }

  public renderPhotos = () => {
    const isSortingDisabled =
      this.props.mode === PHOTOSET_MODES.multiSelect
        ? this.state.disableSorting
        : true;
    const addPhotoButton = (
      <AddPhoto
        as="panel"
        ref={this.addPhotoRef}
        preValidation={this.preValidationPhoto}
        photoAdded={this.props.photoAdded}
        texts={this.props.addPhotoTexts}
      />
    );

    return (
      <SortablePhotoList
        photoSet={this}
        addPhotoButton={addPhotoButton}
        items={this.props.photos}
        axis="xy"
        onSortEnd={this.sortCompleted}
        distance={DRAG_THRESHOLD_DISTANCE_PX}
        shouldCancelStart={this.shouldCancelSortingStart(isSortingDisabled)}
      />
    );
  };

  public photoClicked(photo: BasePhoto) {
    this.props.photoClicked && this.props.photoClicked(photo);
    this.setState({ selectedPhoto: photo });
  }

  public photoError(photo: BasePhoto) {
    this.props.photoError && this.props.photoError(photo);
  }

  public toggleSelectPhoto = (photo: BasePhoto) => {
    return (event) => {
      if (this.props.mode === PHOTOSET_MODES.multiSelect) {
        let currentlySelectedPhoto: BasePhoto | null = null;

        if (event.target.checked) {
          currentlySelectedPhoto = photo;
        }

        const onSelect = this.props.photoSelected;
        const selectedPhotos = this.getNewSelectedPhotosBasedOnPhoto(
          photo,
          this.props.selectedPhotos
        );

        if (selectedPhotos.length == 1) {
          currentlySelectedPhoto = selectedPhotos[0];
        } else if (event.target.checked) {
          currentlySelectedPhoto = photo;
        }

        return onSelect(selectedPhotos, currentlySelectedPhoto);
      }

      if (this.props.mode === PHOTOSET_MODES.singleSelect) {
        if (event.target.checked) {
          this.props.photoSelected && this.props.photoSelected(photo);
          this.setState({ selectedPhoto: photo });
        }
      }
    };
  };

  public getNewSelectedPhotosBasedOnPhoto = (
    photo: BasePhoto,
    selectedPhotos: BasePhoto[]
  ) => {
    const newSelectedPhotos = [...selectedPhotos];
    const index = newSelectedPhotos.findIndex((p) => p.id === photo.id);
    if (index > -1) {
      newSelectedPhotos.splice(index, 1);
    } else {
      newSelectedPhotos.push(photo);
    }

    return newSelectedPhotos;
  };

  private preValidationPhoto = () => {
    if (this.props.globalContext) {
      this.galleryValidation.preUploadPhotoValidation(
        this.props.photos.length,
        this.props.globalContext,
        this.addPhotoRef,
        this.clearGlobalAlert,
        this.props.texts.tooManyPhotosAlerts
      );
    }
  };

  private clearGlobalAlert = () =>
    this.props.globalContext.notifyListener(
      GlobalEventTypes.closeAllGlobalAlert
    );

  private sortCompleted = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    if (this.props.mode === PHOTOSET_MODES.multiSelect) {
      this.props.sortCompleted(oldIndex, newIndex);
    }
  };

  private shouldCancelSortingStart = (isSortingDisabled) => {
    return (event) => {
      (window as any).target = event.target;
      if (
        SORT_OVERRIDE_TAGNAMES.indexOf(event.target.tagName.toLowerCase()) !==
          -1 ||
        event.target.className.toLowerCase().indexOf("checkbox") !== -1
      ) {
        return true;
      }
      return isSortingDisabled;
    };
  };
}

export default withGlobalContext(PhotoSet);
