import React from "react";
import { authorizationService } from "shared-auth";

export default function withOidcAuthContext<P>(
  WrappedComponent: React.ComponentClass<P> | React.FC<P>
): typeof React.Component {
  return class extends React.Component<P> {
    public async componentWillMount() {
      await authorizationService.getUser();
    }

    public render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}
