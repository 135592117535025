import React from "react";

import { Omit } from "shared/utils/types";
import { TranslationLibrary } from "./TranslationLibrary";
import { GlobalEventTypes } from "contexts/GlobalContext";
import { withGlobalContext } from "shared/contexts/GlobalContext";
import Translator from "./Translator";

export interface ITranslationContextProps {
  translator: Translator;
}

export const TranslationContext = React.createContext(new TranslationLibrary());

export const TranslationProvider = TranslationContext.Provider;

export class TranslationTrackerConsumer extends React.Component<any, any> {
  public componentWillMount() {
    this.props.globalContext.addListener(
      GlobalEventTypes.updateLocalization,
      this.updateTranslator
    );
  }

  public render() {
    const Component = this.props.Component;
    return (
      <TranslationContext.Consumer>
        {(value: TranslationLibrary) => {
          const newProps = {
            ...this.props,
            translator: value.createTranslationNode(this.props.path),
          } as any;
          return <Component {...newProps} />;
        }}
      </TranslationContext.Consumer>
    );
  }

  public componentWillUnmount() {
    this.props.globalContext.removeListener(
      GlobalEventTypes.updateLocalization,
      this.updateTranslator
    );
  }

  private updateTranslator = () => this.forceUpdate();
}

export const TranslationContextConsumer = withGlobalContext(
  TranslationTrackerConsumer
);

export function withTranslation<P extends ITranslationContextProps>(
  Component: React.ComponentClass<P> | React.FC<P>,
  path: string = ""
): React.FC<Omit<P, keyof ITranslationContextProps>> {
  return function BoundComponent(
    props: Omit<P, keyof ITranslationContextProps>
  ) {
    return (
      <TranslationContextConsumer
        Component={Component}
        {...props}
        path={path}
      />
    );
  };
}

export const withTranslationF =
  (path: string = "") =>
  <P extends ITranslationContextProps>(
    Component: React.ComponentClass<P> | React.FC<P>
  ): React.FC<Omit<P, keyof ITranslationContextProps>> => {
    return function BoundComponent(
      props: Omit<P, keyof ITranslationContextProps>
    ) {
      return (
        <TranslationContextConsumer
          Component={Component}
          {...props}
          path={path}
        />
      );
    };
  };
